/* eslint-disable */
var proto = { lnrpc: {} };

/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.lnrpc.AMP', null, global);
goog.exportSymbol('proto.lnrpc.AMPInvoiceState', null, global);
goog.exportSymbol('proto.lnrpc.AMPRecord', null, global);
goog.exportSymbol('proto.lnrpc.AbandonChannelRequest', null, global);
goog.exportSymbol('proto.lnrpc.AbandonChannelResponse', null, global);
goog.exportSymbol('proto.lnrpc.AddInvoiceResponse', null, global);
goog.exportSymbol('proto.lnrpc.AddressType', null, global);
goog.exportSymbol('proto.lnrpc.Amount', null, global);
goog.exportSymbol('proto.lnrpc.BakeMacaroonRequest', null, global);
goog.exportSymbol('proto.lnrpc.BakeMacaroonResponse', null, global);
goog.exportSymbol('proto.lnrpc.BatchOpenChannel', null, global);
goog.exportSymbol('proto.lnrpc.BatchOpenChannelRequest', null, global);
goog.exportSymbol('proto.lnrpc.BatchOpenChannelResponse', null, global);
goog.exportSymbol('proto.lnrpc.Chain', null, global);
goog.exportSymbol('proto.lnrpc.ChanBackupExportRequest', null, global);
goog.exportSymbol('proto.lnrpc.ChanBackupSnapshot', null, global);
goog.exportSymbol('proto.lnrpc.ChanInfoRequest', null, global);
goog.exportSymbol('proto.lnrpc.ChanPointShim', null, global);
goog.exportSymbol('proto.lnrpc.Channel', null, global);
goog.exportSymbol('proto.lnrpc.ChannelAcceptRequest', null, global);
goog.exportSymbol('proto.lnrpc.ChannelAcceptResponse', null, global);
goog.exportSymbol('proto.lnrpc.ChannelBackup', null, global);
goog.exportSymbol('proto.lnrpc.ChannelBackupSubscription', null, global);
goog.exportSymbol('proto.lnrpc.ChannelBackups', null, global);
goog.exportSymbol('proto.lnrpc.ChannelBalanceRequest', null, global);
goog.exportSymbol('proto.lnrpc.ChannelBalanceResponse', null, global);
goog.exportSymbol('proto.lnrpc.ChannelCloseSummary', null, global);
goog.exportSymbol('proto.lnrpc.ChannelCloseSummary.ClosureType', null, global);
goog.exportSymbol('proto.lnrpc.ChannelCloseUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ChannelConstraints', null, global);
goog.exportSymbol('proto.lnrpc.ChannelEdge', null, global);
goog.exportSymbol('proto.lnrpc.ChannelEdgeUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ChannelEventSubscription', null, global);
goog.exportSymbol('proto.lnrpc.ChannelEventUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ChannelEventUpdate.UpdateType', null, global);
goog.exportSymbol('proto.lnrpc.ChannelFeeReport', null, global);
goog.exportSymbol('proto.lnrpc.ChannelGraph', null, global);
goog.exportSymbol('proto.lnrpc.ChannelGraphRequest', null, global);
goog.exportSymbol('proto.lnrpc.ChannelOpenUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ChannelPoint', null, global);
goog.exportSymbol('proto.lnrpc.ChannelUpdate', null, global);
goog.exportSymbol('proto.lnrpc.CheckMacPermRequest', null, global);
goog.exportSymbol('proto.lnrpc.CheckMacPermResponse', null, global);
goog.exportSymbol('proto.lnrpc.CloseChannelRequest', null, global);
goog.exportSymbol('proto.lnrpc.CloseStatusUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ClosedChannelUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ClosedChannelsRequest', null, global);
goog.exportSymbol('proto.lnrpc.ClosedChannelsResponse', null, global);
goog.exportSymbol('proto.lnrpc.CommitmentType', null, global);
goog.exportSymbol('proto.lnrpc.ConfirmationUpdate', null, global);
goog.exportSymbol('proto.lnrpc.ConnectPeerRequest', null, global);
goog.exportSymbol('proto.lnrpc.ConnectPeerResponse', null, global);
goog.exportSymbol('proto.lnrpc.CustomMessage', null, global);
goog.exportSymbol('proto.lnrpc.DebugLevelRequest', null, global);
goog.exportSymbol('proto.lnrpc.DebugLevelResponse', null, global);
goog.exportSymbol('proto.lnrpc.DeleteAllPaymentsRequest', null, global);
goog.exportSymbol('proto.lnrpc.DeleteAllPaymentsResponse', null, global);
goog.exportSymbol('proto.lnrpc.DeleteMacaroonIDRequest', null, global);
goog.exportSymbol('proto.lnrpc.DeleteMacaroonIDResponse', null, global);
goog.exportSymbol('proto.lnrpc.DeletePaymentRequest', null, global);
goog.exportSymbol('proto.lnrpc.DeletePaymentResponse', null, global);
goog.exportSymbol('proto.lnrpc.DisconnectPeerRequest', null, global);
goog.exportSymbol('proto.lnrpc.DisconnectPeerResponse', null, global);
goog.exportSymbol('proto.lnrpc.EdgeLocator', null, global);
goog.exportSymbol('proto.lnrpc.EstimateFeeRequest', null, global);
goog.exportSymbol('proto.lnrpc.EstimateFeeResponse', null, global);
goog.exportSymbol('proto.lnrpc.ExportChannelBackupRequest', null, global);
goog.exportSymbol('proto.lnrpc.FailedUpdate', null, global);
goog.exportSymbol('proto.lnrpc.Failure', null, global);
goog.exportSymbol('proto.lnrpc.Failure.FailureCode', null, global);
goog.exportSymbol('proto.lnrpc.Feature', null, global);
goog.exportSymbol('proto.lnrpc.FeatureBit', null, global);
goog.exportSymbol('proto.lnrpc.FeeLimit', null, global);
goog.exportSymbol('proto.lnrpc.FeeReportRequest', null, global);
goog.exportSymbol('proto.lnrpc.FeeReportResponse', null, global);
goog.exportSymbol('proto.lnrpc.FloatMetric', null, global);
goog.exportSymbol('proto.lnrpc.ForwardingEvent', null, global);
goog.exportSymbol('proto.lnrpc.ForwardingHistoryRequest', null, global);
goog.exportSymbol('proto.lnrpc.ForwardingHistoryResponse', null, global);
goog.exportSymbol('proto.lnrpc.FundingPsbtFinalize', null, global);
goog.exportSymbol('proto.lnrpc.FundingPsbtVerify', null, global);
goog.exportSymbol('proto.lnrpc.FundingShim', null, global);
goog.exportSymbol('proto.lnrpc.FundingShimCancel', null, global);
goog.exportSymbol('proto.lnrpc.FundingStateStepResp', null, global);
goog.exportSymbol('proto.lnrpc.FundingTransitionMsg', null, global);
goog.exportSymbol('proto.lnrpc.GetInfoRequest', null, global);
goog.exportSymbol('proto.lnrpc.GetInfoResponse', null, global);
goog.exportSymbol('proto.lnrpc.GetRecoveryInfoRequest', null, global);
goog.exportSymbol('proto.lnrpc.GetRecoveryInfoResponse', null, global);
goog.exportSymbol('proto.lnrpc.GetTransactionsRequest', null, global);
goog.exportSymbol('proto.lnrpc.GraphTopologySubscription', null, global);
goog.exportSymbol('proto.lnrpc.GraphTopologyUpdate', null, global);
goog.exportSymbol('proto.lnrpc.HTLC', null, global);
goog.exportSymbol('proto.lnrpc.HTLCAttempt', null, global);
goog.exportSymbol('proto.lnrpc.HTLCAttempt.HTLCStatus', null, global);
goog.exportSymbol('proto.lnrpc.Hop', null, global);
goog.exportSymbol('proto.lnrpc.HopHint', null, global);
goog.exportSymbol('proto.lnrpc.Initiator', null, global);
goog.exportSymbol('proto.lnrpc.InterceptFeedback', null, global);
goog.exportSymbol('proto.lnrpc.Invoice', null, global);
goog.exportSymbol('proto.lnrpc.Invoice.InvoiceState', null, global);
goog.exportSymbol('proto.lnrpc.InvoiceHTLC', null, global);
goog.exportSymbol('proto.lnrpc.InvoiceHTLCState', null, global);
goog.exportSymbol('proto.lnrpc.InvoiceSubscription', null, global);
goog.exportSymbol('proto.lnrpc.KeyDescriptor', null, global);
goog.exportSymbol('proto.lnrpc.KeyLocator', null, global);
goog.exportSymbol('proto.lnrpc.LightningAddress', null, global);
goog.exportSymbol('proto.lnrpc.LightningNode', null, global);
goog.exportSymbol('proto.lnrpc.ListChannelsRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListChannelsResponse', null, global);
goog.exportSymbol('proto.lnrpc.ListInvoiceRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListInvoiceResponse', null, global);
goog.exportSymbol('proto.lnrpc.ListMacaroonIDsRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListMacaroonIDsResponse', null, global);
goog.exportSymbol('proto.lnrpc.ListPaymentsRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListPaymentsResponse', null, global);
goog.exportSymbol('proto.lnrpc.ListPeersRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListPeersResponse', null, global);
goog.exportSymbol('proto.lnrpc.ListPermissionsRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListPermissionsResponse', null, global);
goog.exportSymbol('proto.lnrpc.ListUnspentRequest', null, global);
goog.exportSymbol('proto.lnrpc.ListUnspentResponse', null, global);
goog.exportSymbol('proto.lnrpc.MPPRecord', null, global);
goog.exportSymbol('proto.lnrpc.MacaroonId', null, global);
goog.exportSymbol('proto.lnrpc.MacaroonPermission', null, global);
goog.exportSymbol('proto.lnrpc.MacaroonPermissionList', null, global);
goog.exportSymbol('proto.lnrpc.MiddlewareRegistration', null, global);
goog.exportSymbol('proto.lnrpc.MultiChanBackup', null, global);
goog.exportSymbol('proto.lnrpc.NetworkInfo', null, global);
goog.exportSymbol('proto.lnrpc.NetworkInfoRequest', null, global);
goog.exportSymbol('proto.lnrpc.NewAddressRequest', null, global);
goog.exportSymbol('proto.lnrpc.NewAddressResponse', null, global);
goog.exportSymbol('proto.lnrpc.NodeAddress', null, global);
goog.exportSymbol('proto.lnrpc.NodeInfo', null, global);
goog.exportSymbol('proto.lnrpc.NodeInfoRequest', null, global);
goog.exportSymbol('proto.lnrpc.NodeMetricType', null, global);
goog.exportSymbol('proto.lnrpc.NodeMetricsRequest', null, global);
goog.exportSymbol('proto.lnrpc.NodeMetricsResponse', null, global);
goog.exportSymbol('proto.lnrpc.NodePair', null, global);
goog.exportSymbol('proto.lnrpc.NodeUpdate', null, global);
goog.exportSymbol('proto.lnrpc.Op', null, global);
goog.exportSymbol('proto.lnrpc.OpenChannelRequest', null, global);
goog.exportSymbol('proto.lnrpc.OpenStatusUpdate', null, global);
goog.exportSymbol('proto.lnrpc.OutPoint', null, global);
goog.exportSymbol('proto.lnrpc.OutputDetail', null, global);
goog.exportSymbol('proto.lnrpc.OutputScriptType', null, global);
goog.exportSymbol('proto.lnrpc.PayReq', null, global);
goog.exportSymbol('proto.lnrpc.PayReqString', null, global);
goog.exportSymbol('proto.lnrpc.Payment', null, global);
goog.exportSymbol('proto.lnrpc.Payment.PaymentStatus', null, global);
goog.exportSymbol('proto.lnrpc.PaymentFailureReason', null, global);
goog.exportSymbol('proto.lnrpc.PaymentHash', null, global);
goog.exportSymbol('proto.lnrpc.Peer', null, global);
goog.exportSymbol('proto.lnrpc.Peer.SyncType', null, global);
goog.exportSymbol('proto.lnrpc.PeerEvent', null, global);
goog.exportSymbol('proto.lnrpc.PeerEvent.EventType', null, global);
goog.exportSymbol('proto.lnrpc.PeerEventSubscription', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsRequest', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.ClosedChannel', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.Commitments', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.ForceClosedChannel', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.PendingChannel', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.PendingOpenChannel', null, global);
goog.exportSymbol('proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel', null, global);
goog.exportSymbol('proto.lnrpc.PendingHTLC', null, global);
goog.exportSymbol('proto.lnrpc.PendingUpdate', null, global);
goog.exportSymbol('proto.lnrpc.PolicyUpdateRequest', null, global);
goog.exportSymbol('proto.lnrpc.PolicyUpdateResponse', null, global);
goog.exportSymbol('proto.lnrpc.PsbtShim', null, global);
goog.exportSymbol('proto.lnrpc.QueryRoutesRequest', null, global);
goog.exportSymbol('proto.lnrpc.QueryRoutesResponse', null, global);
goog.exportSymbol('proto.lnrpc.RPCMessage', null, global);
goog.exportSymbol('proto.lnrpc.RPCMiddlewareRequest', null, global);
goog.exportSymbol('proto.lnrpc.RPCMiddlewareResponse', null, global);
goog.exportSymbol('proto.lnrpc.ReadyForPsbtFunding', null, global);
goog.exportSymbol('proto.lnrpc.Resolution', null, global);
goog.exportSymbol('proto.lnrpc.ResolutionOutcome', null, global);
goog.exportSymbol('proto.lnrpc.ResolutionType', null, global);
goog.exportSymbol('proto.lnrpc.RestoreBackupResponse', null, global);
goog.exportSymbol('proto.lnrpc.RestoreChanBackupRequest', null, global);
goog.exportSymbol('proto.lnrpc.Route', null, global);
goog.exportSymbol('proto.lnrpc.RouteHint', null, global);
goog.exportSymbol('proto.lnrpc.RoutingPolicy', null, global);
goog.exportSymbol('proto.lnrpc.SendCoinsRequest', null, global);
goog.exportSymbol('proto.lnrpc.SendCoinsResponse', null, global);
goog.exportSymbol('proto.lnrpc.SendCustomMessageRequest', null, global);
goog.exportSymbol('proto.lnrpc.SendCustomMessageResponse', null, global);
goog.exportSymbol('proto.lnrpc.SendManyRequest', null, global);
goog.exportSymbol('proto.lnrpc.SendManyResponse', null, global);
goog.exportSymbol('proto.lnrpc.SendRequest', null, global);
goog.exportSymbol('proto.lnrpc.SendResponse', null, global);
goog.exportSymbol('proto.lnrpc.SendToRouteRequest', null, global);
goog.exportSymbol('proto.lnrpc.SetID', null, global);
goog.exportSymbol('proto.lnrpc.SignMessageRequest', null, global);
goog.exportSymbol('proto.lnrpc.SignMessageResponse', null, global);
goog.exportSymbol('proto.lnrpc.StopRequest', null, global);
goog.exportSymbol('proto.lnrpc.StopResponse', null, global);
goog.exportSymbol('proto.lnrpc.StreamAuth', null, global);
goog.exportSymbol('proto.lnrpc.SubscribeCustomMessagesRequest', null, global);
goog.exportSymbol('proto.lnrpc.TimestampedError', null, global);
goog.exportSymbol('proto.lnrpc.Transaction', null, global);
goog.exportSymbol('proto.lnrpc.TransactionDetails', null, global);
goog.exportSymbol('proto.lnrpc.UpdateFailure', null, global);
goog.exportSymbol('proto.lnrpc.Utxo', null, global);
goog.exportSymbol('proto.lnrpc.VerifyChanBackupResponse', null, global);
goog.exportSymbol('proto.lnrpc.VerifyMessageRequest', null, global);
goog.exportSymbol('proto.lnrpc.VerifyMessageResponse', null, global);
goog.exportSymbol('proto.lnrpc.WalletAccountBalance', null, global);
goog.exportSymbol('proto.lnrpc.WalletBalanceRequest', null, global);
goog.exportSymbol('proto.lnrpc.WalletBalanceResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SubscribeCustomMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SubscribeCustomMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SubscribeCustomMessagesRequest.displayName = 'proto.lnrpc.SubscribeCustomMessagesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SubscribeCustomMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SubscribeCustomMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SubscribeCustomMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SubscribeCustomMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SubscribeCustomMessagesRequest}
 */
proto.lnrpc.SubscribeCustomMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SubscribeCustomMessagesRequest;
  return proto.lnrpc.SubscribeCustomMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SubscribeCustomMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SubscribeCustomMessagesRequest}
 */
proto.lnrpc.SubscribeCustomMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SubscribeCustomMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SubscribeCustomMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SubscribeCustomMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SubscribeCustomMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.CustomMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.CustomMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.CustomMessage.displayName = 'proto.lnrpc.CustomMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.CustomMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.CustomMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.CustomMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CustomMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    peer: msg.getPeer_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.CustomMessage}
 */
proto.lnrpc.CustomMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.CustomMessage;
  return proto.lnrpc.CustomMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.CustomMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.CustomMessage}
 */
proto.lnrpc.CustomMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPeer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.CustomMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.CustomMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.CustomMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CustomMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes peer = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.CustomMessage.prototype.getPeer = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes peer = 1;
 * This is a type-conversion wrapper around `getPeer()`
 * @return {string}
 */
proto.lnrpc.CustomMessage.prototype.getPeer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPeer()));
};


/**
 * optional bytes peer = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPeer()`
 * @return {!Uint8Array}
 */
proto.lnrpc.CustomMessage.prototype.getPeer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPeer()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.CustomMessage.prototype.setPeer = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional uint32 type = 2;
 * @return {number}
 */
proto.lnrpc.CustomMessage.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.CustomMessage.prototype.setType = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.CustomMessage.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.lnrpc.CustomMessage.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.lnrpc.CustomMessage.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.CustomMessage.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendCustomMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendCustomMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendCustomMessageRequest.displayName = 'proto.lnrpc.SendCustomMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendCustomMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendCustomMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCustomMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    peer: msg.getPeer_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendCustomMessageRequest}
 */
proto.lnrpc.SendCustomMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendCustomMessageRequest;
  return proto.lnrpc.SendCustomMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendCustomMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendCustomMessageRequest}
 */
proto.lnrpc.SendCustomMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPeer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendCustomMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendCustomMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCustomMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes peer = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getPeer = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes peer = 1;
 * This is a type-conversion wrapper around `getPeer()`
 * @return {string}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getPeer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPeer()));
};


/**
 * optional bytes peer = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPeer()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getPeer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPeer()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendCustomMessageRequest.prototype.setPeer = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional uint32 type = 2;
 * @return {number}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.SendCustomMessageRequest.prototype.setType = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendCustomMessageRequest.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendCustomMessageRequest.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendCustomMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendCustomMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendCustomMessageResponse.displayName = 'proto.lnrpc.SendCustomMessageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendCustomMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendCustomMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendCustomMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCustomMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendCustomMessageResponse}
 */
proto.lnrpc.SendCustomMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendCustomMessageResponse;
  return proto.lnrpc.SendCustomMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendCustomMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendCustomMessageResponse}
 */
proto.lnrpc.SendCustomMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendCustomMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendCustomMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendCustomMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCustomMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Utxo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Utxo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Utxo.displayName = 'proto.lnrpc.Utxo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Utxo.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Utxo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Utxo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Utxo.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amountSat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    pkScript: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outpoint: (f = msg.getOutpoint()) && proto.lnrpc.OutPoint.toObject(includeInstance, f),
    confirmations: jspb.Message.getFieldWithDefault(msg, 6, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Utxo}
 */
proto.lnrpc.Utxo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Utxo;
  return proto.lnrpc.Utxo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Utxo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Utxo}
 */
proto.lnrpc.Utxo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.lnrpc.AddressType} */ (reader.readEnum());
      msg.setAddressType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmountSat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPkScript(value);
      break;
    case 5:
      var value = new proto.lnrpc.OutPoint;
      reader.readMessage(value,proto.lnrpc.OutPoint.deserializeBinaryFromReader);
      msg.setOutpoint(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setConfirmations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Utxo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Utxo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Utxo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Utxo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmountSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getPkScript();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutpoint();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.lnrpc.OutPoint.serializeBinaryToWriter
    );
  }
  f = message.getConfirmations();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
};


/**
 * optional AddressType address_type = 1;
 * @return {!proto.lnrpc.AddressType}
 */
proto.lnrpc.Utxo.prototype.getAddressType = function() {
  return /** @type {!proto.lnrpc.AddressType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.AddressType} value */
proto.lnrpc.Utxo.prototype.setAddressType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.lnrpc.Utxo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.Utxo.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 amount_sat = 3;
 * @return {string}
 */
proto.lnrpc.Utxo.prototype.getAmountSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.Utxo.prototype.setAmountSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional string pk_script = 4;
 * @return {string}
 */
proto.lnrpc.Utxo.prototype.getPkScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.Utxo.prototype.setPkScript = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional OutPoint outpoint = 5;
 * @return {?proto.lnrpc.OutPoint}
 */
proto.lnrpc.Utxo.prototype.getOutpoint = function() {
  return /** @type{?proto.lnrpc.OutPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.OutPoint, 5));
};


/** @param {?proto.lnrpc.OutPoint|undefined} value */
proto.lnrpc.Utxo.prototype.setOutpoint = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.lnrpc.Utxo.prototype.clearOutpoint = function() {
  this.setOutpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Utxo.prototype.hasOutpoint = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 confirmations = 6;
 * @return {string}
 */
proto.lnrpc.Utxo.prototype.getConfirmations = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.Utxo.prototype.setConfirmations = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.OutputDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.OutputDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.OutputDetail.displayName = 'proto.lnrpc.OutputDetail';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.OutputDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.OutputDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.OutputDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OutputDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    outputType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pkScript: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outputIndex: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    amount: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    isOurAddress: jspb.Message.getFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.OutputDetail}
 */
proto.lnrpc.OutputDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.OutputDetail;
  return proto.lnrpc.OutputDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.OutputDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.OutputDetail}
 */
proto.lnrpc.OutputDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.lnrpc.OutputScriptType} */ (reader.readEnum());
      msg.setOutputType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPkScript(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setOutputIndex(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOurAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.OutputDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.OutputDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.OutputDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OutputDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutputType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPkScript();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutputIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getIsOurAddress();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional OutputScriptType output_type = 1;
 * @return {!proto.lnrpc.OutputScriptType}
 */
proto.lnrpc.OutputDetail.prototype.getOutputType = function() {
  return /** @type {!proto.lnrpc.OutputScriptType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.OutputScriptType} value */
proto.lnrpc.OutputDetail.prototype.setOutputType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.lnrpc.OutputDetail.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.OutputDetail.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pk_script = 3;
 * @return {string}
 */
proto.lnrpc.OutputDetail.prototype.getPkScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.OutputDetail.prototype.setPkScript = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 output_index = 4;
 * @return {string}
 */
proto.lnrpc.OutputDetail.prototype.getOutputIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.OutputDetail.prototype.setOutputIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 amount = 5;
 * @return {string}
 */
proto.lnrpc.OutputDetail.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.OutputDetail.prototype.setAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional bool is_our_address = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.OutputDetail.prototype.getIsOurAddress = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.lnrpc.OutputDetail.prototype.setIsOurAddress = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Transaction.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Transaction.displayName = 'proto.lnrpc.Transaction';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Transaction.repeatedFields_ = [8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    txHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    numConfirmations: jspb.Message.getFieldWithDefault(msg, 3, 0),
    blockHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
    blockHeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timeStamp: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    totalFees: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    destAddressesList: jspb.Message.getRepeatedField(msg, 8),
    outputDetailsList: jspb.Message.toObjectList(msg.getOutputDetailsList(),
    proto.lnrpc.OutputDetail.toObject, includeInstance),
    rawTxHex: jspb.Message.getFieldWithDefault(msg, 9, ""),
    label: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Transaction}
 */
proto.lnrpc.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Transaction;
  return proto.lnrpc.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Transaction}
 */
proto.lnrpc.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumConfirmations(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockHash(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlockHeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTimeStamp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalFees(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestAddresses(value);
      break;
    case 11:
      var value = new proto.lnrpc.OutputDetail;
      reader.readMessage(value,proto.lnrpc.OutputDetail.deserializeBinaryFromReader);
      msg.addOutputDetails(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawTxHex(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getNumConfirmations();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBlockHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBlockHeight();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTimeStamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getTotalFees();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getDestAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getOutputDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.lnrpc.OutputDetail.serializeBinaryToWriter
    );
  }
  f = message.getRawTxHex();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string tx_hash = 1;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setTxHash = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int32 num_confirmations = 3;
 * @return {number}
 */
proto.lnrpc.Transaction.prototype.getNumConfirmations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.Transaction.prototype.setNumConfirmations = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string block_hash = 4;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getBlockHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setBlockHash = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 block_height = 5;
 * @return {number}
 */
proto.lnrpc.Transaction.prototype.getBlockHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.Transaction.prototype.setBlockHeight = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 time_stamp = 6;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getTimeStamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setTimeStamp = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int64 total_fees = 7;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getTotalFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setTotalFees = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * repeated string dest_addresses = 8;
 * @return {!Array<string>}
 */
proto.lnrpc.Transaction.prototype.getDestAddressesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.lnrpc.Transaction.prototype.setDestAddressesList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.lnrpc.Transaction.prototype.addDestAddresses = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.lnrpc.Transaction.prototype.clearDestAddressesList = function() {
  this.setDestAddressesList([]);
};


/**
 * repeated OutputDetail output_details = 11;
 * @return {!Array<!proto.lnrpc.OutputDetail>}
 */
proto.lnrpc.Transaction.prototype.getOutputDetailsList = function() {
  return /** @type{!Array<!proto.lnrpc.OutputDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.OutputDetail, 11));
};


/** @param {!Array<!proto.lnrpc.OutputDetail>} value */
proto.lnrpc.Transaction.prototype.setOutputDetailsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.lnrpc.OutputDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.OutputDetail}
 */
proto.lnrpc.Transaction.prototype.addOutputDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.lnrpc.OutputDetail, opt_index);
};


proto.lnrpc.Transaction.prototype.clearOutputDetailsList = function() {
  this.setOutputDetailsList([]);
};


/**
 * optional string raw_tx_hex = 9;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getRawTxHex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setRawTxHex = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string label = 10;
 * @return {string}
 */
proto.lnrpc.Transaction.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.lnrpc.Transaction.prototype.setLabel = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GetTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.GetTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GetTransactionsRequest.displayName = 'proto.lnrpc.GetTransactionsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GetTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GetTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GetTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startHeight: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endHeight: jspb.Message.getFieldWithDefault(msg, 2, 0),
    account: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GetTransactionsRequest}
 */
proto.lnrpc.GetTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GetTransactionsRequest;
  return proto.lnrpc.GetTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GetTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GetTransactionsRequest}
 */
proto.lnrpc.GetTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartHeight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndHeight(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GetTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GetTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GetTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartHeight();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEndHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 start_height = 1;
 * @return {number}
 */
proto.lnrpc.GetTransactionsRequest.prototype.getStartHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.GetTransactionsRequest.prototype.setStartHeight = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end_height = 2;
 * @return {number}
 */
proto.lnrpc.GetTransactionsRequest.prototype.getEndHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.GetTransactionsRequest.prototype.setEndHeight = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account = 3;
 * @return {string}
 */
proto.lnrpc.GetTransactionsRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.GetTransactionsRequest.prototype.setAccount = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.TransactionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.TransactionDetails.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.TransactionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.TransactionDetails.displayName = 'proto.lnrpc.TransactionDetails';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.TransactionDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.TransactionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.TransactionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.TransactionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.TransactionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.lnrpc.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.TransactionDetails}
 */
proto.lnrpc.TransactionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.TransactionDetails;
  return proto.lnrpc.TransactionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.TransactionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.TransactionDetails}
 */
proto.lnrpc.TransactionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Transaction;
      reader.readMessage(value,proto.lnrpc.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.TransactionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.TransactionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.TransactionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.TransactionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.lnrpc.Transaction>}
 */
proto.lnrpc.TransactionDetails.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.lnrpc.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Transaction, 1));
};


/** @param {!Array<!proto.lnrpc.Transaction>} value */
proto.lnrpc.TransactionDetails.prototype.setTransactionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Transaction}
 */
proto.lnrpc.TransactionDetails.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.Transaction, opt_index);
};


proto.lnrpc.TransactionDetails.prototype.clearTransactionsList = function() {
  this.setTransactionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FeeLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.FeeLimit.oneofGroups_);
};
goog.inherits(proto.lnrpc.FeeLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FeeLimit.displayName = 'proto.lnrpc.FeeLimit';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.FeeLimit.oneofGroups_ = [[1,3,2]];

/**
 * @enum {number}
 */
proto.lnrpc.FeeLimit.LimitCase = {
  LIMIT_NOT_SET: 0,
  FIXED: 1,
  FIXED_MSAT: 3,
  PERCENT: 2
};

/**
 * @return {proto.lnrpc.FeeLimit.LimitCase}
 */
proto.lnrpc.FeeLimit.prototype.getLimitCase = function() {
  return /** @type {proto.lnrpc.FeeLimit.LimitCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.FeeLimit.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FeeLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FeeLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FeeLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FeeLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    fixed: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    fixedMsat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    percent: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FeeLimit}
 */
proto.lnrpc.FeeLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FeeLimit;
  return proto.lnrpc.FeeLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FeeLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FeeLimit}
 */
proto.lnrpc.FeeLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFixed(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFixedMsat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FeeLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FeeLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FeeLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FeeLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64String(
      2,
      f
    );
  }
};


/**
 * optional int64 fixed = 1;
 * @return {string}
 */
proto.lnrpc.FeeLimit.prototype.getFixed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.FeeLimit.prototype.setFixed = function(value) {
  jspb.Message.setOneofField(this, 1, proto.lnrpc.FeeLimit.oneofGroups_[0], value);
};


proto.lnrpc.FeeLimit.prototype.clearFixed = function() {
  jspb.Message.setOneofField(this, 1, proto.lnrpc.FeeLimit.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FeeLimit.prototype.hasFixed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 fixed_msat = 3;
 * @return {string}
 */
proto.lnrpc.FeeLimit.prototype.getFixedMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.FeeLimit.prototype.setFixedMsat = function(value) {
  jspb.Message.setOneofField(this, 3, proto.lnrpc.FeeLimit.oneofGroups_[0], value);
};


proto.lnrpc.FeeLimit.prototype.clearFixedMsat = function() {
  jspb.Message.setOneofField(this, 3, proto.lnrpc.FeeLimit.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FeeLimit.prototype.hasFixedMsat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 percent = 2;
 * @return {string}
 */
proto.lnrpc.FeeLimit.prototype.getPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.FeeLimit.prototype.setPercent = function(value) {
  jspb.Message.setOneofField(this, 2, proto.lnrpc.FeeLimit.oneofGroups_[0], value);
};


proto.lnrpc.FeeLimit.prototype.clearPercent = function() {
  jspb.Message.setOneofField(this, 2, proto.lnrpc.FeeLimit.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FeeLimit.prototype.hasPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.SendRequest.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.SendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendRequest.displayName = 'proto.lnrpc.SendRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.SendRequest.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dest: msg.getDest_asB64(),
    destString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    amtMsat: jspb.Message.getFieldWithDefault(msg, 12, "0"),
    paymentHash: msg.getPaymentHash_asB64(),
    paymentHashString: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paymentRequest: jspb.Message.getFieldWithDefault(msg, 6, ""),
    finalCltvDelta: jspb.Message.getFieldWithDefault(msg, 7, 0),
    feeLimit: (f = msg.getFeeLimit()) && proto.lnrpc.FeeLimit.toObject(includeInstance, f),
    outgoingChanId: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    lastHopPubkey: msg.getLastHopPubkey_asB64(),
    cltvLimit: jspb.Message.getFieldWithDefault(msg, 10, 0),
    destCustomRecordsMap: (f = msg.getDestCustomRecordsMap()) ? f.toObject(includeInstance, undefined) : [],
    allowSelfPayment: jspb.Message.getFieldWithDefault(msg, 14, false),
    destFeaturesList: jspb.Message.getRepeatedField(msg, 15),
    paymentAddr: msg.getPaymentAddr_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendRequest}
 */
proto.lnrpc.SendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendRequest;
  return proto.lnrpc.SendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendRequest}
 */
proto.lnrpc.SendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestString(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtMsat(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentHash(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentHashString(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentRequest(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinalCltvDelta(value);
      break;
    case 8:
      var value = new proto.lnrpc.FeeLimit;
      reader.readMessage(value,proto.lnrpc.FeeLimit.deserializeBinaryFromReader);
      msg.setFeeLimit(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setOutgoingChanId(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastHopPubkey(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCltvLimit(value);
      break;
    case 11:
      var value = msg.getDestCustomRecordsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint64, jspb.BinaryReader.prototype.readBytes, null, 0);
         });
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSelfPayment(value);
      break;
    case 15:
      var value = /** @type {!Array<!proto.lnrpc.FeatureBit>} */ (reader.readPackedEnum());
      msg.setDestFeaturesList(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDest_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getDestString();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      12,
      f
    );
  }
  f = message.getPaymentHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getPaymentHashString();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaymentRequest();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFinalCltvDelta();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFeeLimit();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.lnrpc.FeeLimit.serializeBinaryToWriter
    );
  }
  f = message.getOutgoingChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getLastHopPubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getCltvLimit();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getDestCustomRecordsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeUint64, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getAllowSelfPayment();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getDestFeaturesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      15,
      f
    );
  }
  f = message.getPaymentAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      16,
      f
    );
  }
};


/**
 * optional bytes dest = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendRequest.prototype.getDest = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes dest = 1;
 * This is a type-conversion wrapper around `getDest()`
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getDest_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDest()));
};


/**
 * optional bytes dest = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDest()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendRequest.prototype.getDest_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDest()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendRequest.prototype.setDest = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string dest_string = 2;
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getDestString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.SendRequest.prototype.setDestString = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 amt = 3;
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.SendRequest.prototype.setAmt = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 amt_msat = 12;
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, "0"));
};


/** @param {string} value */
proto.lnrpc.SendRequest.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 12, value);
};


/**
 * optional bytes payment_hash = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendRequest.prototype.getPaymentHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes payment_hash = 4;
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getPaymentHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentHash()));
};


/**
 * optional bytes payment_hash = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendRequest.prototype.getPaymentHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendRequest.prototype.setPaymentHash = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional string payment_hash_string = 5;
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getPaymentHashString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.SendRequest.prototype.setPaymentHashString = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string payment_request = 6;
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getPaymentRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.lnrpc.SendRequest.prototype.setPaymentRequest = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 final_cltv_delta = 7;
 * @return {number}
 */
proto.lnrpc.SendRequest.prototype.getFinalCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.lnrpc.SendRequest.prototype.setFinalCltvDelta = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional FeeLimit fee_limit = 8;
 * @return {?proto.lnrpc.FeeLimit}
 */
proto.lnrpc.SendRequest.prototype.getFeeLimit = function() {
  return /** @type{?proto.lnrpc.FeeLimit} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FeeLimit, 8));
};


/** @param {?proto.lnrpc.FeeLimit|undefined} value */
proto.lnrpc.SendRequest.prototype.setFeeLimit = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.lnrpc.SendRequest.prototype.clearFeeLimit = function() {
  this.setFeeLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.SendRequest.prototype.hasFeeLimit = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 outgoing_chan_id = 9;
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getOutgoingChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.SendRequest.prototype.setOutgoingChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional bytes last_hop_pubkey = 13;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendRequest.prototype.getLastHopPubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes last_hop_pubkey = 13;
 * This is a type-conversion wrapper around `getLastHopPubkey()`
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getLastHopPubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastHopPubkey()));
};


/**
 * optional bytes last_hop_pubkey = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastHopPubkey()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendRequest.prototype.getLastHopPubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastHopPubkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendRequest.prototype.setLastHopPubkey = function(value) {
  jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional uint32 cltv_limit = 10;
 * @return {number}
 */
proto.lnrpc.SendRequest.prototype.getCltvLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.lnrpc.SendRequest.prototype.setCltvLimit = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * map<uint64, bytes> dest_custom_records = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!(string|Uint8Array)>}
 */
proto.lnrpc.SendRequest.prototype.getDestCustomRecordsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      null));
};


proto.lnrpc.SendRequest.prototype.clearDestCustomRecordsMap = function() {
  this.getDestCustomRecordsMap().clear();
};


/**
 * optional bool allow_self_payment = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.SendRequest.prototype.getAllowSelfPayment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.lnrpc.SendRequest.prototype.setAllowSelfPayment = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated FeatureBit dest_features = 15;
 * @return {!Array<!proto.lnrpc.FeatureBit>}
 */
proto.lnrpc.SendRequest.prototype.getDestFeaturesList = function() {
  return /** @type {!Array<!proto.lnrpc.FeatureBit>} */ (jspb.Message.getRepeatedField(this, 15));
};


/** @param {!Array<!proto.lnrpc.FeatureBit>} value */
proto.lnrpc.SendRequest.prototype.setDestFeaturesList = function(value) {
  jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {!proto.lnrpc.FeatureBit} value
 * @param {number=} opt_index
 */
proto.lnrpc.SendRequest.prototype.addDestFeatures = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


proto.lnrpc.SendRequest.prototype.clearDestFeaturesList = function() {
  this.setDestFeaturesList([]);
};


/**
 * optional bytes payment_addr = 16;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendRequest.prototype.getPaymentAddr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes payment_addr = 16;
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {string}
 */
proto.lnrpc.SendRequest.prototype.getPaymentAddr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentAddr()));
};


/**
 * optional bytes payment_addr = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendRequest.prototype.getPaymentAddr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentAddr()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendRequest.prototype.setPaymentAddr = function(value) {
  jspb.Message.setProto3BytesField(this, 16, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendResponse.displayName = 'proto.lnrpc.SendResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentError: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentPreimage: msg.getPaymentPreimage_asB64(),
    paymentRoute: (f = msg.getPaymentRoute()) && proto.lnrpc.Route.toObject(includeInstance, f),
    paymentHash: msg.getPaymentHash_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendResponse}
 */
proto.lnrpc.SendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendResponse;
  return proto.lnrpc.SendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendResponse}
 */
proto.lnrpc.SendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentPreimage(value);
      break;
    case 3:
      var value = new proto.lnrpc.Route;
      reader.readMessage(value,proto.lnrpc.Route.deserializeBinaryFromReader);
      msg.setPaymentRoute(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentPreimage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getPaymentRoute();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.Route.serializeBinaryToWriter
    );
  }
  f = message.getPaymentHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string payment_error = 1;
 * @return {string}
 */
proto.lnrpc.SendResponse.prototype.getPaymentError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.SendResponse.prototype.setPaymentError = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes payment_preimage = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendResponse.prototype.getPaymentPreimage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes payment_preimage = 2;
 * This is a type-conversion wrapper around `getPaymentPreimage()`
 * @return {string}
 */
proto.lnrpc.SendResponse.prototype.getPaymentPreimage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentPreimage()));
};


/**
 * optional bytes payment_preimage = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentPreimage()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendResponse.prototype.getPaymentPreimage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentPreimage()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendResponse.prototype.setPaymentPreimage = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Route payment_route = 3;
 * @return {?proto.lnrpc.Route}
 */
proto.lnrpc.SendResponse.prototype.getPaymentRoute = function() {
  return /** @type{?proto.lnrpc.Route} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Route, 3));
};


/** @param {?proto.lnrpc.Route|undefined} value */
proto.lnrpc.SendResponse.prototype.setPaymentRoute = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.lnrpc.SendResponse.prototype.clearPaymentRoute = function() {
  this.setPaymentRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.SendResponse.prototype.hasPaymentRoute = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes payment_hash = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendResponse.prototype.getPaymentHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes payment_hash = 4;
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {string}
 */
proto.lnrpc.SendResponse.prototype.getPaymentHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentHash()));
};


/**
 * optional bytes payment_hash = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendResponse.prototype.getPaymentHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendResponse.prototype.setPaymentHash = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendToRouteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendToRouteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendToRouteRequest.displayName = 'proto.lnrpc.SendToRouteRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendToRouteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendToRouteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendToRouteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendToRouteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentHash: msg.getPaymentHash_asB64(),
    paymentHashString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    route: (f = msg.getRoute()) && proto.lnrpc.Route.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendToRouteRequest}
 */
proto.lnrpc.SendToRouteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendToRouteRequest;
  return proto.lnrpc.SendToRouteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendToRouteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendToRouteRequest}
 */
proto.lnrpc.SendToRouteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentHashString(value);
      break;
    case 4:
      var value = new proto.lnrpc.Route;
      reader.readMessage(value,proto.lnrpc.Route.deserializeBinaryFromReader);
      msg.setRoute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendToRouteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendToRouteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendToRouteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendToRouteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPaymentHashString();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.Route.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes payment_hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SendToRouteRequest.prototype.getPaymentHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes payment_hash = 1;
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {string}
 */
proto.lnrpc.SendToRouteRequest.prototype.getPaymentHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentHash()));
};


/**
 * optional bytes payment_hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SendToRouteRequest.prototype.getPaymentHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SendToRouteRequest.prototype.setPaymentHash = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string payment_hash_string = 2;
 * @return {string}
 */
proto.lnrpc.SendToRouteRequest.prototype.getPaymentHashString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.SendToRouteRequest.prototype.setPaymentHashString = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Route route = 4;
 * @return {?proto.lnrpc.Route}
 */
proto.lnrpc.SendToRouteRequest.prototype.getRoute = function() {
  return /** @type{?proto.lnrpc.Route} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Route, 4));
};


/** @param {?proto.lnrpc.Route|undefined} value */
proto.lnrpc.SendToRouteRequest.prototype.setRoute = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.lnrpc.SendToRouteRequest.prototype.clearRoute = function() {
  this.setRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.SendToRouteRequest.prototype.hasRoute = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelAcceptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelAcceptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelAcceptRequest.displayName = 'proto.lnrpc.ChannelAcceptRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelAcceptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelAcceptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelAcceptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodePubkey: msg.getNodePubkey_asB64(),
    chainHash: msg.getChainHash_asB64(),
    pendingChanId: msg.getPendingChanId_asB64(),
    fundingAmt: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    pushAmt: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    dustLimit: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    maxValueInFlight: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    channelReserve: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    minHtlc: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    feePerKw: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    csvDelay: jspb.Message.getFieldWithDefault(msg, 11, 0),
    maxAcceptedHtlcs: jspb.Message.getFieldWithDefault(msg, 12, 0),
    channelFlags: jspb.Message.getFieldWithDefault(msg, 13, 0),
    commitmentType: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelAcceptRequest}
 */
proto.lnrpc.ChannelAcceptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelAcceptRequest;
  return proto.lnrpc.ChannelAcceptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelAcceptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelAcceptRequest}
 */
proto.lnrpc.ChannelAcceptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNodePubkey(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChainHash(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFundingAmt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setPushAmt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setDustLimit(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxValueInFlight(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChannelReserve(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinHtlc(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFeePerKw(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCsvDelay(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxAcceptedHtlcs(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelFlags(value);
      break;
    case 14:
      var value = /** @type {!proto.lnrpc.CommitmentType} */ (reader.readEnum());
      msg.setCommitmentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelAcceptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelAcceptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelAcceptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodePubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChainHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getFundingAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getPushAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getDustLimit();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getMaxValueInFlight();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getChannelReserve();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      8,
      f
    );
  }
  f = message.getMinHtlc();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getFeePerKw();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      10,
      f
    );
  }
  f = message.getCsvDelay();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getMaxAcceptedHtlcs();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getChannelFlags();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getCommitmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional bytes node_pubkey = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getNodePubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes node_pubkey = 1;
 * This is a type-conversion wrapper around `getNodePubkey()`
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getNodePubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNodePubkey()));
};


/**
 * optional bytes node_pubkey = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNodePubkey()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getNodePubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNodePubkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setNodePubkey = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes chain_hash = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getChainHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chain_hash = 2;
 * This is a type-conversion wrapper around `getChainHash()`
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getChainHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChainHash()));
};


/**
 * optional bytes chain_hash = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChainHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getChainHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChainHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setChainHash = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes pending_chan_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes pending_chan_id = 3;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional uint64 funding_amt = 4;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getFundingAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setFundingAmt = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 push_amt = 5;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getPushAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setPushAmt = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint64 dust_limit = 6;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getDustLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setDustLimit = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint64 max_value_in_flight = 7;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getMaxValueInFlight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setMaxValueInFlight = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional uint64 channel_reserve = 8;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getChannelReserve = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setChannelReserve = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional uint64 min_htlc = 9;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getMinHtlc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setMinHtlc = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint64 fee_per_kw = 10;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getFeePerKw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setFeePerKw = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional uint32 csv_delay = 11;
 * @return {number}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getCsvDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setCsvDelay = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 max_accepted_htlcs = 12;
 * @return {number}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getMaxAcceptedHtlcs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setMaxAcceptedHtlcs = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 channel_flags = 13;
 * @return {number}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getChannelFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setChannelFlags = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional CommitmentType commitment_type = 14;
 * @return {!proto.lnrpc.CommitmentType}
 */
proto.lnrpc.ChannelAcceptRequest.prototype.getCommitmentType = function() {
  return /** @type {!proto.lnrpc.CommitmentType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {!proto.lnrpc.CommitmentType} value */
proto.lnrpc.ChannelAcceptRequest.prototype.setCommitmentType = function(value) {
  jspb.Message.setProto3EnumField(this, 14, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelAcceptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelAcceptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelAcceptResponse.displayName = 'proto.lnrpc.ChannelAcceptResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelAcceptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelAcceptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelAcceptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accept: jspb.Message.getFieldWithDefault(msg, 1, false),
    pendingChanId: msg.getPendingChanId_asB64(),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    upfrontShutdown: jspb.Message.getFieldWithDefault(msg, 4, ""),
    csvDelay: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reserveSat: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    inFlightMaxMsat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    maxHtlcCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    minHtlcIn: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    minAcceptDepth: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelAcceptResponse}
 */
proto.lnrpc.ChannelAcceptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelAcceptResponse;
  return proto.lnrpc.ChannelAcceptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelAcceptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelAcceptResponse}
 */
proto.lnrpc.ChannelAcceptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccept(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpfrontShutdown(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCsvDelay(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setReserveSat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setInFlightMaxMsat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxHtlcCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinHtlcIn(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinAcceptDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelAcceptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelAcceptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelAcceptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccept();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpfrontShutdown();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCsvDelay();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getReserveSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getInFlightMaxMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getMaxHtlcCount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getMinHtlcIn();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getMinAcceptDepth();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional bool accept = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getAccept = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setAccept = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bytes pending_chan_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes pending_chan_id = 2;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string upfront_shutdown = 4;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getUpfrontShutdown = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setUpfrontShutdown = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 csv_delay = 5;
 * @return {number}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getCsvDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setCsvDelay = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 reserve_sat = 6;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getReserveSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setReserveSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint64 in_flight_max_msat = 7;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getInFlightMaxMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setInFlightMaxMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional uint32 max_htlc_count = 8;
 * @return {number}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getMaxHtlcCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setMaxHtlcCount = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 min_htlc_in = 9;
 * @return {string}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getMinHtlcIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setMinHtlcIn = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint32 min_accept_depth = 10;
 * @return {number}
 */
proto.lnrpc.ChannelAcceptResponse.prototype.getMinAcceptDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelAcceptResponse.prototype.setMinAcceptDepth = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.ChannelPoint.oneofGroups_);
};
goog.inherits(proto.lnrpc.ChannelPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelPoint.displayName = 'proto.lnrpc.ChannelPoint';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.ChannelPoint.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.lnrpc.ChannelPoint.FundingTxidCase = {
  FUNDING_TXID_NOT_SET: 0,
  FUNDING_TXID_BYTES: 1,
  FUNDING_TXID_STR: 2
};

/**
 * @return {proto.lnrpc.ChannelPoint.FundingTxidCase}
 */
proto.lnrpc.ChannelPoint.prototype.getFundingTxidCase = function() {
  return /** @type {proto.lnrpc.ChannelPoint.FundingTxidCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.ChannelPoint.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingTxidBytes: msg.getFundingTxidBytes_asB64(),
    fundingTxidStr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outputIndex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelPoint;
  return proto.lnrpc.ChannelPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFundingTxidBytes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundingTxidStr(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOutputIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutputIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional bytes funding_txid_bytes = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelPoint.prototype.getFundingTxidBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes funding_txid_bytes = 1;
 * This is a type-conversion wrapper around `getFundingTxidBytes()`
 * @return {string}
 */
proto.lnrpc.ChannelPoint.prototype.getFundingTxidBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFundingTxidBytes()));
};


/**
 * optional bytes funding_txid_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFundingTxidBytes()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelPoint.prototype.getFundingTxidBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFundingTxidBytes()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelPoint.prototype.setFundingTxidBytes = function(value) {
  jspb.Message.setOneofField(this, 1, proto.lnrpc.ChannelPoint.oneofGroups_[0], value);
};


proto.lnrpc.ChannelPoint.prototype.clearFundingTxidBytes = function() {
  jspb.Message.setOneofField(this, 1, proto.lnrpc.ChannelPoint.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelPoint.prototype.hasFundingTxidBytes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string funding_txid_str = 2;
 * @return {string}
 */
proto.lnrpc.ChannelPoint.prototype.getFundingTxidStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelPoint.prototype.setFundingTxidStr = function(value) {
  jspb.Message.setOneofField(this, 2, proto.lnrpc.ChannelPoint.oneofGroups_[0], value);
};


proto.lnrpc.ChannelPoint.prototype.clearFundingTxidStr = function() {
  jspb.Message.setOneofField(this, 2, proto.lnrpc.ChannelPoint.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelPoint.prototype.hasFundingTxidStr = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 output_index = 3;
 * @return {number}
 */
proto.lnrpc.ChannelPoint.prototype.getOutputIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelPoint.prototype.setOutputIndex = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.OutPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.OutPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.OutPoint.displayName = 'proto.lnrpc.OutPoint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.OutPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.OutPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.OutPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OutPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    txidBytes: msg.getTxidBytes_asB64(),
    txidStr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outputIndex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.OutPoint}
 */
proto.lnrpc.OutPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.OutPoint;
  return proto.lnrpc.OutPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.OutPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.OutPoint}
 */
proto.lnrpc.OutPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTxidBytes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxidStr(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOutputIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.OutPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.OutPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.OutPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OutPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxidBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTxidStr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutputIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional bytes txid_bytes = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.OutPoint.prototype.getTxidBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes txid_bytes = 1;
 * This is a type-conversion wrapper around `getTxidBytes()`
 * @return {string}
 */
proto.lnrpc.OutPoint.prototype.getTxidBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTxidBytes()));
};


/**
 * optional bytes txid_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTxidBytes()`
 * @return {!Uint8Array}
 */
proto.lnrpc.OutPoint.prototype.getTxidBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTxidBytes()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.OutPoint.prototype.setTxidBytes = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string txid_str = 2;
 * @return {string}
 */
proto.lnrpc.OutPoint.prototype.getTxidStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.OutPoint.prototype.setTxidStr = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 output_index = 3;
 * @return {number}
 */
proto.lnrpc.OutPoint.prototype.getOutputIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.OutPoint.prototype.setOutputIndex = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.LightningAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.LightningAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.LightningAddress.displayName = 'proto.lnrpc.LightningAddress';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.LightningAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.LightningAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.LightningAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.LightningAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    pubkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    host: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.LightningAddress}
 */
proto.lnrpc.LightningAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.LightningAddress;
  return proto.lnrpc.LightningAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.LightningAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.LightningAddress}
 */
proto.lnrpc.LightningAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubkey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.LightningAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.LightningAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.LightningAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.LightningAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string pubkey = 1;
 * @return {string}
 */
proto.lnrpc.LightningAddress.prototype.getPubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.LightningAddress.prototype.setPubkey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string host = 2;
 * @return {string}
 */
proto.lnrpc.LightningAddress.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.LightningAddress.prototype.setHost = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.EstimateFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.EstimateFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.EstimateFeeRequest.displayName = 'proto.lnrpc.EstimateFeeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.EstimateFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.EstimateFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.EstimateFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.EstimateFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addrtoamountMap: (f = msg.getAddrtoamountMap()) ? f.toObject(includeInstance, undefined) : [],
    targetConf: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minConfs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    spendUnconfirmed: jspb.Message.getFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.EstimateFeeRequest}
 */
proto.lnrpc.EstimateFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.EstimateFeeRequest;
  return proto.lnrpc.EstimateFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.EstimateFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.EstimateFeeRequest}
 */
proto.lnrpc.EstimateFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getAddrtoamountMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "");
         });
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetConf(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinConfs(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpendUnconfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.EstimateFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.EstimateFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.EstimateFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.EstimateFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddrtoamountMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getTargetConf();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMinConfs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSpendUnconfirmed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * map<string, int64> AddrToAmount = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.lnrpc.EstimateFeeRequest.prototype.getAddrtoamountMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


proto.lnrpc.EstimateFeeRequest.prototype.clearAddrtoamountMap = function() {
  this.getAddrtoamountMap().clear();
};


/**
 * optional int32 target_conf = 2;
 * @return {number}
 */
proto.lnrpc.EstimateFeeRequest.prototype.getTargetConf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.EstimateFeeRequest.prototype.setTargetConf = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 min_confs = 3;
 * @return {number}
 */
proto.lnrpc.EstimateFeeRequest.prototype.getMinConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.EstimateFeeRequest.prototype.setMinConfs = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool spend_unconfirmed = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.EstimateFeeRequest.prototype.getSpendUnconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.lnrpc.EstimateFeeRequest.prototype.setSpendUnconfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.EstimateFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.EstimateFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.EstimateFeeResponse.displayName = 'proto.lnrpc.EstimateFeeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.EstimateFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.EstimateFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.EstimateFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.EstimateFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeSat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    feerateSatPerByte: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    satPerVbyte: jspb.Message.getFieldWithDefault(msg, 3, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.EstimateFeeResponse}
 */
proto.lnrpc.EstimateFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.EstimateFeeResponse;
  return proto.lnrpc.EstimateFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.EstimateFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.EstimateFeeResponse}
 */
proto.lnrpc.EstimateFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeeSat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeerateSatPerByte(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSatPerVbyte(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.EstimateFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.EstimateFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.EstimateFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.EstimateFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getFeerateSatPerByte();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
};


/**
 * optional int64 fee_sat = 1;
 * @return {string}
 */
proto.lnrpc.EstimateFeeResponse.prototype.getFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.EstimateFeeResponse.prototype.setFeeSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 feerate_sat_per_byte = 2;
 * @return {string}
 */
proto.lnrpc.EstimateFeeResponse.prototype.getFeerateSatPerByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.EstimateFeeResponse.prototype.setFeerateSatPerByte = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 sat_per_vbyte = 3;
 * @return {string}
 */
proto.lnrpc.EstimateFeeResponse.prototype.getSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.EstimateFeeResponse.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendManyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendManyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendManyRequest.displayName = 'proto.lnrpc.SendManyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendManyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendManyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendManyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendManyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addrtoamountMap: (f = msg.getAddrtoamountMap()) ? f.toObject(includeInstance, undefined) : [],
    targetConf: jspb.Message.getFieldWithDefault(msg, 3, 0),
    satPerVbyte: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    satPerByte: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    minConfs: jspb.Message.getFieldWithDefault(msg, 7, 0),
    spendUnconfirmed: jspb.Message.getFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendManyRequest}
 */
proto.lnrpc.SendManyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendManyRequest;
  return proto.lnrpc.SendManyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendManyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendManyRequest}
 */
proto.lnrpc.SendManyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getAddrtoamountMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "");
         });
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetConf(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSatPerVbyte(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatPerByte(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinConfs(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpendUnconfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendManyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendManyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendManyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendManyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddrtoamountMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getTargetConf();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getSatPerByte();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMinConfs();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSpendUnconfirmed();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * map<string, int64> AddrToAmount = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.lnrpc.SendManyRequest.prototype.getAddrtoamountMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


proto.lnrpc.SendManyRequest.prototype.clearAddrtoamountMap = function() {
  this.getAddrtoamountMap().clear();
};


/**
 * optional int32 target_conf = 3;
 * @return {number}
 */
proto.lnrpc.SendManyRequest.prototype.getTargetConf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.SendManyRequest.prototype.setTargetConf = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 sat_per_vbyte = 4;
 * @return {string}
 */
proto.lnrpc.SendManyRequest.prototype.getSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.SendManyRequest.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 sat_per_byte = 5;
 * @return {string}
 */
proto.lnrpc.SendManyRequest.prototype.getSatPerByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.SendManyRequest.prototype.setSatPerByte = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.lnrpc.SendManyRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.lnrpc.SendManyRequest.prototype.setLabel = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 min_confs = 7;
 * @return {number}
 */
proto.lnrpc.SendManyRequest.prototype.getMinConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.lnrpc.SendManyRequest.prototype.setMinConfs = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool spend_unconfirmed = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.SendManyRequest.prototype.getSpendUnconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.lnrpc.SendManyRequest.prototype.setSpendUnconfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendManyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendManyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendManyResponse.displayName = 'proto.lnrpc.SendManyResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendManyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendManyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendManyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendManyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    txid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendManyResponse}
 */
proto.lnrpc.SendManyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendManyResponse;
  return proto.lnrpc.SendManyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendManyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendManyResponse}
 */
proto.lnrpc.SendManyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendManyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendManyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendManyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendManyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string txid = 1;
 * @return {string}
 */
proto.lnrpc.SendManyResponse.prototype.getTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.SendManyResponse.prototype.setTxid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendCoinsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendCoinsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendCoinsRequest.displayName = 'proto.lnrpc.SendCoinsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendCoinsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendCoinsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendCoinsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCoinsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    targetConf: jspb.Message.getFieldWithDefault(msg, 3, 0),
    satPerVbyte: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    satPerByte: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    sendAll: jspb.Message.getFieldWithDefault(msg, 6, false),
    label: jspb.Message.getFieldWithDefault(msg, 7, ""),
    minConfs: jspb.Message.getFieldWithDefault(msg, 8, 0),
    spendUnconfirmed: jspb.Message.getFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendCoinsRequest}
 */
proto.lnrpc.SendCoinsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendCoinsRequest;
  return proto.lnrpc.SendCoinsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendCoinsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendCoinsRequest}
 */
proto.lnrpc.SendCoinsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetConf(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSatPerVbyte(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatPerByte(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendAll(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinConfs(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpendUnconfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendCoinsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendCoinsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendCoinsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCoinsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getTargetConf();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getSatPerByte();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getSendAll();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMinConfs();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSpendUnconfirmed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string addr = 1;
 * @return {string}
 */
proto.lnrpc.SendCoinsRequest.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.SendCoinsRequest.prototype.setAddr = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {string}
 */
proto.lnrpc.SendCoinsRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.SendCoinsRequest.prototype.setAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int32 target_conf = 3;
 * @return {number}
 */
proto.lnrpc.SendCoinsRequest.prototype.getTargetConf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.SendCoinsRequest.prototype.setTargetConf = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 sat_per_vbyte = 4;
 * @return {string}
 */
proto.lnrpc.SendCoinsRequest.prototype.getSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.SendCoinsRequest.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 sat_per_byte = 5;
 * @return {string}
 */
proto.lnrpc.SendCoinsRequest.prototype.getSatPerByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.SendCoinsRequest.prototype.setSatPerByte = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional bool send_all = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.SendCoinsRequest.prototype.getSendAll = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.lnrpc.SendCoinsRequest.prototype.setSendAll = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string label = 7;
 * @return {string}
 */
proto.lnrpc.SendCoinsRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.lnrpc.SendCoinsRequest.prototype.setLabel = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 min_confs = 8;
 * @return {number}
 */
proto.lnrpc.SendCoinsRequest.prototype.getMinConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.lnrpc.SendCoinsRequest.prototype.setMinConfs = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool spend_unconfirmed = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.SendCoinsRequest.prototype.getSpendUnconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.lnrpc.SendCoinsRequest.prototype.setSpendUnconfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SendCoinsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SendCoinsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SendCoinsResponse.displayName = 'proto.lnrpc.SendCoinsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SendCoinsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SendCoinsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SendCoinsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCoinsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    txid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SendCoinsResponse}
 */
proto.lnrpc.SendCoinsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SendCoinsResponse;
  return proto.lnrpc.SendCoinsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SendCoinsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SendCoinsResponse}
 */
proto.lnrpc.SendCoinsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SendCoinsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SendCoinsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SendCoinsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SendCoinsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string txid = 1;
 * @return {string}
 */
proto.lnrpc.SendCoinsResponse.prototype.getTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.SendCoinsResponse.prototype.setTxid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListUnspentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListUnspentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListUnspentRequest.displayName = 'proto.lnrpc.ListUnspentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListUnspentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListUnspentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListUnspentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListUnspentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    minConfs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxConfs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    account: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListUnspentRequest}
 */
proto.lnrpc.ListUnspentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListUnspentRequest;
  return proto.lnrpc.ListUnspentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListUnspentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListUnspentRequest}
 */
proto.lnrpc.ListUnspentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinConfs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxConfs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListUnspentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListUnspentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListUnspentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListUnspentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinConfs();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxConfs();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 min_confs = 1;
 * @return {number}
 */
proto.lnrpc.ListUnspentRequest.prototype.getMinConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.ListUnspentRequest.prototype.setMinConfs = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_confs = 2;
 * @return {number}
 */
proto.lnrpc.ListUnspentRequest.prototype.getMaxConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.ListUnspentRequest.prototype.setMaxConfs = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account = 3;
 * @return {string}
 */
proto.lnrpc.ListUnspentRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.ListUnspentRequest.prototype.setAccount = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListUnspentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ListUnspentResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ListUnspentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListUnspentResponse.displayName = 'proto.lnrpc.ListUnspentResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ListUnspentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListUnspentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListUnspentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListUnspentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListUnspentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    utxosList: jspb.Message.toObjectList(msg.getUtxosList(),
    proto.lnrpc.Utxo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListUnspentResponse}
 */
proto.lnrpc.ListUnspentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListUnspentResponse;
  return proto.lnrpc.ListUnspentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListUnspentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListUnspentResponse}
 */
proto.lnrpc.ListUnspentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Utxo;
      reader.readMessage(value,proto.lnrpc.Utxo.deserializeBinaryFromReader);
      msg.addUtxos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListUnspentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListUnspentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListUnspentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListUnspentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtxosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.Utxo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Utxo utxos = 1;
 * @return {!Array<!proto.lnrpc.Utxo>}
 */
proto.lnrpc.ListUnspentResponse.prototype.getUtxosList = function() {
  return /** @type{!Array<!proto.lnrpc.Utxo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Utxo, 1));
};


/** @param {!Array<!proto.lnrpc.Utxo>} value */
proto.lnrpc.ListUnspentResponse.prototype.setUtxosList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.Utxo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Utxo}
 */
proto.lnrpc.ListUnspentResponse.prototype.addUtxos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.Utxo, opt_index);
};


proto.lnrpc.ListUnspentResponse.prototype.clearUtxosList = function() {
  this.setUtxosList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NewAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NewAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NewAddressRequest.displayName = 'proto.lnrpc.NewAddressRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NewAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NewAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NewAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NewAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    account: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NewAddressRequest}
 */
proto.lnrpc.NewAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NewAddressRequest;
  return proto.lnrpc.NewAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NewAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NewAddressRequest}
 */
proto.lnrpc.NewAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.lnrpc.AddressType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NewAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NewAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NewAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NewAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AddressType type = 1;
 * @return {!proto.lnrpc.AddressType}
 */
proto.lnrpc.NewAddressRequest.prototype.getType = function() {
  return /** @type {!proto.lnrpc.AddressType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.AddressType} value */
proto.lnrpc.NewAddressRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.lnrpc.NewAddressRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.NewAddressRequest.prototype.setAccount = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NewAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NewAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NewAddressResponse.displayName = 'proto.lnrpc.NewAddressResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NewAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NewAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NewAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NewAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NewAddressResponse}
 */
proto.lnrpc.NewAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NewAddressResponse;
  return proto.lnrpc.NewAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NewAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NewAddressResponse}
 */
proto.lnrpc.NewAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NewAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NewAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NewAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NewAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.lnrpc.NewAddressResponse.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.NewAddressResponse.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SignMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SignMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SignMessageRequest.displayName = 'proto.lnrpc.SignMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SignMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SignMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SignMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SignMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    msg: msg.getMsg_asB64(),
    singleHash: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SignMessageRequest}
 */
proto.lnrpc.SignMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SignMessageRequest;
  return proto.lnrpc.SignMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SignMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SignMessageRequest}
 */
proto.lnrpc.SignMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMsg(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingleHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SignMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SignMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SignMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SignMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsg_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSingleHash();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes msg = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SignMessageRequest.prototype.getMsg = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes msg = 1;
 * This is a type-conversion wrapper around `getMsg()`
 * @return {string}
 */
proto.lnrpc.SignMessageRequest.prototype.getMsg_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMsg()));
};


/**
 * optional bytes msg = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMsg()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SignMessageRequest.prototype.getMsg_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMsg()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SignMessageRequest.prototype.setMsg = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bool single_hash = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.SignMessageRequest.prototype.getSingleHash = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.SignMessageRequest.prototype.setSingleHash = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SignMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SignMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SignMessageResponse.displayName = 'proto.lnrpc.SignMessageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SignMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SignMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SignMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SignMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    signature: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SignMessageResponse}
 */
proto.lnrpc.SignMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SignMessageResponse;
  return proto.lnrpc.SignMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SignMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SignMessageResponse}
 */
proto.lnrpc.SignMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SignMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SignMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SignMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SignMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string signature = 1;
 * @return {string}
 */
proto.lnrpc.SignMessageResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.SignMessageResponse.prototype.setSignature = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.VerifyMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.VerifyMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.VerifyMessageRequest.displayName = 'proto.lnrpc.VerifyMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.VerifyMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.VerifyMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.VerifyMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.VerifyMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    msg: msg.getMsg_asB64(),
    signature: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.VerifyMessageRequest}
 */
proto.lnrpc.VerifyMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.VerifyMessageRequest;
  return proto.lnrpc.VerifyMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.VerifyMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.VerifyMessageRequest}
 */
proto.lnrpc.VerifyMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMsg(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.VerifyMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.VerifyMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.VerifyMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.VerifyMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsg_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes msg = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.VerifyMessageRequest.prototype.getMsg = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes msg = 1;
 * This is a type-conversion wrapper around `getMsg()`
 * @return {string}
 */
proto.lnrpc.VerifyMessageRequest.prototype.getMsg_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMsg()));
};


/**
 * optional bytes msg = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMsg()`
 * @return {!Uint8Array}
 */
proto.lnrpc.VerifyMessageRequest.prototype.getMsg_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMsg()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.VerifyMessageRequest.prototype.setMsg = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string signature = 2;
 * @return {string}
 */
proto.lnrpc.VerifyMessageRequest.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.VerifyMessageRequest.prototype.setSignature = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.VerifyMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.VerifyMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.VerifyMessageResponse.displayName = 'proto.lnrpc.VerifyMessageResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.VerifyMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.VerifyMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.VerifyMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.VerifyMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false),
    pubkey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.VerifyMessageResponse}
 */
proto.lnrpc.VerifyMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.VerifyMessageResponse;
  return proto.lnrpc.VerifyMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.VerifyMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.VerifyMessageResponse}
 */
proto.lnrpc.VerifyMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.VerifyMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.VerifyMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.VerifyMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.VerifyMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPubkey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.VerifyMessageResponse.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.VerifyMessageResponse.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string pubkey = 2;
 * @return {string}
 */
proto.lnrpc.VerifyMessageResponse.prototype.getPubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.VerifyMessageResponse.prototype.setPubkey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ConnectPeerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ConnectPeerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ConnectPeerRequest.displayName = 'proto.lnrpc.ConnectPeerRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ConnectPeerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ConnectPeerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ConnectPeerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ConnectPeerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addr: (f = msg.getAddr()) && proto.lnrpc.LightningAddress.toObject(includeInstance, f),
    perm: jspb.Message.getFieldWithDefault(msg, 2, false),
    timeout: jspb.Message.getFieldWithDefault(msg, 3, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ConnectPeerRequest}
 */
proto.lnrpc.ConnectPeerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ConnectPeerRequest;
  return proto.lnrpc.ConnectPeerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ConnectPeerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ConnectPeerRequest}
 */
proto.lnrpc.ConnectPeerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.LightningAddress;
      reader.readMessage(value,proto.lnrpc.LightningAddress.deserializeBinaryFromReader);
      msg.setAddr(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPerm(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setTimeout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ConnectPeerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ConnectPeerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ConnectPeerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ConnectPeerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.LightningAddress.serializeBinaryToWriter
    );
  }
  f = message.getPerm();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTimeout();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
};


/**
 * optional LightningAddress addr = 1;
 * @return {?proto.lnrpc.LightningAddress}
 */
proto.lnrpc.ConnectPeerRequest.prototype.getAddr = function() {
  return /** @type{?proto.lnrpc.LightningAddress} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.LightningAddress, 1));
};


/** @param {?proto.lnrpc.LightningAddress|undefined} value */
proto.lnrpc.ConnectPeerRequest.prototype.setAddr = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.ConnectPeerRequest.prototype.clearAddr = function() {
  this.setAddr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ConnectPeerRequest.prototype.hasAddr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool perm = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ConnectPeerRequest.prototype.getPerm = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.ConnectPeerRequest.prototype.setPerm = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint64 timeout = 3;
 * @return {string}
 */
proto.lnrpc.ConnectPeerRequest.prototype.getTimeout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ConnectPeerRequest.prototype.setTimeout = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ConnectPeerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ConnectPeerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ConnectPeerResponse.displayName = 'proto.lnrpc.ConnectPeerResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ConnectPeerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ConnectPeerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ConnectPeerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ConnectPeerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ConnectPeerResponse}
 */
proto.lnrpc.ConnectPeerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ConnectPeerResponse;
  return proto.lnrpc.ConnectPeerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ConnectPeerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ConnectPeerResponse}
 */
proto.lnrpc.ConnectPeerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ConnectPeerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ConnectPeerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ConnectPeerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ConnectPeerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DisconnectPeerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DisconnectPeerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DisconnectPeerRequest.displayName = 'proto.lnrpc.DisconnectPeerRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DisconnectPeerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DisconnectPeerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DisconnectPeerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DisconnectPeerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pubKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DisconnectPeerRequest}
 */
proto.lnrpc.DisconnectPeerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DisconnectPeerRequest;
  return proto.lnrpc.DisconnectPeerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DisconnectPeerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DisconnectPeerRequest}
 */
proto.lnrpc.DisconnectPeerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DisconnectPeerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DisconnectPeerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DisconnectPeerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DisconnectPeerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pub_key = 1;
 * @return {string}
 */
proto.lnrpc.DisconnectPeerRequest.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.DisconnectPeerRequest.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DisconnectPeerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DisconnectPeerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DisconnectPeerResponse.displayName = 'proto.lnrpc.DisconnectPeerResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DisconnectPeerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DisconnectPeerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DisconnectPeerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DisconnectPeerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DisconnectPeerResponse}
 */
proto.lnrpc.DisconnectPeerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DisconnectPeerResponse;
  return proto.lnrpc.DisconnectPeerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DisconnectPeerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DisconnectPeerResponse}
 */
proto.lnrpc.DisconnectPeerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DisconnectPeerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DisconnectPeerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DisconnectPeerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DisconnectPeerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.HTLC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.HTLC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.HTLC.displayName = 'proto.lnrpc.HTLC';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.HTLC.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.HTLC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.HTLC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.HTLC.toObject = function(includeInstance, msg) {
  var f, obj = {
    incoming: jspb.Message.getFieldWithDefault(msg, 1, false),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    hashLock: msg.getHashLock_asB64(),
    expirationHeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    htlcIndex: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    forwardingChannel: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    forwardingHtlcIndex: jspb.Message.getFieldWithDefault(msg, 7, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.HTLC}
 */
proto.lnrpc.HTLC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.HTLC;
  return proto.lnrpc.HTLC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.HTLC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.HTLC}
 */
proto.lnrpc.HTLC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncoming(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHashLock(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpirationHeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setHtlcIndex(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setForwardingChannel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setForwardingHtlcIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.HTLC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.HTLC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.HTLC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.HTLC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncoming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getHashLock_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getExpirationHeight();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getHtlcIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getForwardingChannel();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getForwardingHtlcIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
};


/**
 * optional bool incoming = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.HTLC.prototype.getIncoming = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.HTLC.prototype.setIncoming = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {string}
 */
proto.lnrpc.HTLC.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLC.prototype.setAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional bytes hash_lock = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.HTLC.prototype.getHashLock = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes hash_lock = 3;
 * This is a type-conversion wrapper around `getHashLock()`
 * @return {string}
 */
proto.lnrpc.HTLC.prototype.getHashLock_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHashLock()));
};


/**
 * optional bytes hash_lock = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHashLock()`
 * @return {!Uint8Array}
 */
proto.lnrpc.HTLC.prototype.getHashLock_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHashLock()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.HTLC.prototype.setHashLock = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional uint32 expiration_height = 4;
 * @return {number}
 */
proto.lnrpc.HTLC.prototype.getExpirationHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.HTLC.prototype.setExpirationHeight = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 htlc_index = 5;
 * @return {string}
 */
proto.lnrpc.HTLC.prototype.getHtlcIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLC.prototype.setHtlcIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint64 forwarding_channel = 6;
 * @return {string}
 */
proto.lnrpc.HTLC.prototype.getForwardingChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLC.prototype.setForwardingChannel = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint64 forwarding_htlc_index = 7;
 * @return {string}
 */
proto.lnrpc.HTLC.prototype.getForwardingHtlcIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLC.prototype.setForwardingHtlcIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelConstraints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelConstraints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelConstraints.displayName = 'proto.lnrpc.ChannelConstraints';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelConstraints.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelConstraints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelConstraints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelConstraints.toObject = function(includeInstance, msg) {
  var f, obj = {
    csvDelay: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chanReserveSat: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    dustLimitSat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    maxPendingAmtMsat: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    minHtlcMsat: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    maxAcceptedHtlcs: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelConstraints}
 */
proto.lnrpc.ChannelConstraints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelConstraints;
  return proto.lnrpc.ChannelConstraints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelConstraints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelConstraints}
 */
proto.lnrpc.ChannelConstraints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCsvDelay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanReserveSat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setDustLimitSat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxPendingAmtMsat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinHtlcMsat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxAcceptedHtlcs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelConstraints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelConstraints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelConstraints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelConstraints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCsvDelay();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getChanReserveSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getDustLimitSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getMaxPendingAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getMinHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getMaxAcceptedHtlcs();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 csv_delay = 1;
 * @return {number}
 */
proto.lnrpc.ChannelConstraints.prototype.getCsvDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelConstraints.prototype.setCsvDelay = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 chan_reserve_sat = 2;
 * @return {string}
 */
proto.lnrpc.ChannelConstraints.prototype.getChanReserveSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelConstraints.prototype.setChanReserveSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 dust_limit_sat = 3;
 * @return {string}
 */
proto.lnrpc.ChannelConstraints.prototype.getDustLimitSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelConstraints.prototype.setDustLimitSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional uint64 max_pending_amt_msat = 4;
 * @return {string}
 */
proto.lnrpc.ChannelConstraints.prototype.getMaxPendingAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelConstraints.prototype.setMaxPendingAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 min_htlc_msat = 5;
 * @return {string}
 */
proto.lnrpc.ChannelConstraints.prototype.getMinHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelConstraints.prototype.setMinHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint32 max_accepted_htlcs = 6;
 * @return {number}
 */
proto.lnrpc.ChannelConstraints.prototype.getMaxAcceptedHtlcs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelConstraints.prototype.setMaxAcceptedHtlcs = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Channel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Channel.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Channel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Channel.displayName = 'proto.lnrpc.Channel';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Channel.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Channel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Channel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Channel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Channel.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getFieldWithDefault(msg, 1, false),
    remotePubkey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    channelPoint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chanId: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    capacity: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    localBalance: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    remoteBalance: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    commitFee: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    commitWeight: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    feePerKw: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    unsettledBalance: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    totalSatoshisSent: jspb.Message.getFieldWithDefault(msg, 12, "0"),
    totalSatoshisReceived: jspb.Message.getFieldWithDefault(msg, 13, "0"),
    numUpdates: jspb.Message.getFieldWithDefault(msg, 14, "0"),
    pendingHtlcsList: jspb.Message.toObjectList(msg.getPendingHtlcsList(),
    proto.lnrpc.HTLC.toObject, includeInstance),
    csvDelay: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pb_private: jspb.Message.getFieldWithDefault(msg, 17, false),
    initiator: jspb.Message.getFieldWithDefault(msg, 18, false),
    chanStatusFlags: jspb.Message.getFieldWithDefault(msg, 19, ""),
    localChanReserveSat: jspb.Message.getFieldWithDefault(msg, 20, "0"),
    remoteChanReserveSat: jspb.Message.getFieldWithDefault(msg, 21, "0"),
    staticRemoteKey: jspb.Message.getFieldWithDefault(msg, 22, false),
    commitmentType: jspb.Message.getFieldWithDefault(msg, 26, 0),
    lifetime: jspb.Message.getFieldWithDefault(msg, 23, "0"),
    uptime: jspb.Message.getFieldWithDefault(msg, 24, "0"),
    closeAddress: jspb.Message.getFieldWithDefault(msg, 25, ""),
    pushAmountSat: jspb.Message.getFieldWithDefault(msg, 27, "0"),
    thawHeight: jspb.Message.getFieldWithDefault(msg, 28, 0),
    localConstraints: (f = msg.getLocalConstraints()) && proto.lnrpc.ChannelConstraints.toObject(includeInstance, f),
    remoteConstraints: (f = msg.getRemoteConstraints()) && proto.lnrpc.ChannelConstraints.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Channel}
 */
proto.lnrpc.Channel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Channel;
  return proto.lnrpc.Channel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Channel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Channel}
 */
proto.lnrpc.Channel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemotePubkey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelPoint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCapacity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLocalBalance(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setRemoteBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCommitFee(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCommitWeight(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeePerKw(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setUnsettledBalance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalSatoshisSent(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalSatoshisReceived(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setNumUpdates(value);
      break;
    case 15:
      var value = new proto.lnrpc.HTLC;
      reader.readMessage(value,proto.lnrpc.HTLC.deserializeBinaryFromReader);
      msg.addPendingHtlcs(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCsvDelay(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInitiator(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setChanStatusFlags(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLocalChanReserveSat(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setRemoteChanReserveSat(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStaticRemoteKey(value);
      break;
    case 26:
      var value = /** @type {!proto.lnrpc.CommitmentType} */ (reader.readEnum());
      msg.setCommitmentType(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLifetime(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setUptime(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseAddress(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setPushAmountSat(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setThawHeight(value);
      break;
    case 29:
      var value = new proto.lnrpc.ChannelConstraints;
      reader.readMessage(value,proto.lnrpc.ChannelConstraints.deserializeBinaryFromReader);
      msg.setLocalConstraints(value);
      break;
    case 30:
      var value = new proto.lnrpc.ChannelConstraints;
      reader.readMessage(value,proto.lnrpc.ChannelConstraints.deserializeBinaryFromReader);
      msg.setRemoteConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Channel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Channel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Channel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Channel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRemotePubkey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChannelPoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getLocalBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getRemoteBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getCommitFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      8,
      f
    );
  }
  f = message.getCommitWeight();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getFeePerKw();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      10,
      f
    );
  }
  f = message.getUnsettledBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      11,
      f
    );
  }
  f = message.getTotalSatoshisSent();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      12,
      f
    );
  }
  f = message.getTotalSatoshisReceived();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      13,
      f
    );
  }
  f = message.getNumUpdates();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      14,
      f
    );
  }
  f = message.getPendingHtlcsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.lnrpc.HTLC.serializeBinaryToWriter
    );
  }
  f = message.getCsvDelay();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getInitiator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getChanStatusFlags();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLocalChanReserveSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      20,
      f
    );
  }
  f = message.getRemoteChanReserveSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      21,
      f
    );
  }
  f = message.getStaticRemoteKey();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getCommitmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getLifetime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      23,
      f
    );
  }
  f = message.getUptime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      24,
      f
    );
  }
  f = message.getCloseAddress();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPushAmountSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      27,
      f
    );
  }
  f = message.getThawHeight();
  if (f !== 0) {
    writer.writeUint32(
      28,
      f
    );
  }
  f = message.getLocalConstraints();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.lnrpc.ChannelConstraints.serializeBinaryToWriter
    );
  }
  f = message.getRemoteConstraints();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.lnrpc.ChannelConstraints.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool active = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Channel.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.Channel.prototype.setActive = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string remote_pubkey = 2;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getRemotePubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setRemotePubkey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string channel_point = 3;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getChannelPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setChannelPoint = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 chan_id = 4;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 capacity = 5;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 local_balance = 6;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getLocalBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setLocalBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int64 remote_balance = 7;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getRemoteBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setRemoteBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional int64 commit_fee = 8;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getCommitFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setCommitFee = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional int64 commit_weight = 9;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getCommitWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setCommitWeight = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional int64 fee_per_kw = 10;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getFeePerKw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setFeePerKw = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional int64 unsettled_balance = 11;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getUnsettledBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setUnsettledBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional int64 total_satoshis_sent = 12;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getTotalSatoshisSent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setTotalSatoshisSent = function(value) {
  jspb.Message.setProto3StringIntField(this, 12, value);
};


/**
 * optional int64 total_satoshis_received = 13;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getTotalSatoshisReceived = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setTotalSatoshisReceived = function(value) {
  jspb.Message.setProto3StringIntField(this, 13, value);
};


/**
 * optional uint64 num_updates = 14;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getNumUpdates = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setNumUpdates = function(value) {
  jspb.Message.setProto3StringIntField(this, 14, value);
};


/**
 * repeated HTLC pending_htlcs = 15;
 * @return {!Array<!proto.lnrpc.HTLC>}
 */
proto.lnrpc.Channel.prototype.getPendingHtlcsList = function() {
  return /** @type{!Array<!proto.lnrpc.HTLC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.HTLC, 15));
};


/** @param {!Array<!proto.lnrpc.HTLC>} value */
proto.lnrpc.Channel.prototype.setPendingHtlcsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.lnrpc.HTLC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.HTLC}
 */
proto.lnrpc.Channel.prototype.addPendingHtlcs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.lnrpc.HTLC, opt_index);
};


proto.lnrpc.Channel.prototype.clearPendingHtlcsList = function() {
  this.setPendingHtlcsList([]);
};


/**
 * optional uint32 csv_delay = 16;
 * @return {number}
 */
proto.lnrpc.Channel.prototype.getCsvDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.lnrpc.Channel.prototype.setCsvDelay = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool private = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Channel.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.lnrpc.Channel.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool initiator = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Channel.prototype.getInitiator = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.lnrpc.Channel.prototype.setInitiator = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string chan_status_flags = 19;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getChanStatusFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setChanStatusFlags = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 local_chan_reserve_sat = 20;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getLocalChanReserveSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setLocalChanReserveSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 20, value);
};


/**
 * optional int64 remote_chan_reserve_sat = 21;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getRemoteChanReserveSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setRemoteChanReserveSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 21, value);
};


/**
 * optional bool static_remote_key = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Channel.prototype.getStaticRemoteKey = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.lnrpc.Channel.prototype.setStaticRemoteKey = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional CommitmentType commitment_type = 26;
 * @return {!proto.lnrpc.CommitmentType}
 */
proto.lnrpc.Channel.prototype.getCommitmentType = function() {
  return /** @type {!proto.lnrpc.CommitmentType} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/** @param {!proto.lnrpc.CommitmentType} value */
proto.lnrpc.Channel.prototype.setCommitmentType = function(value) {
  jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional int64 lifetime = 23;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getLifetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setLifetime = function(value) {
  jspb.Message.setProto3StringIntField(this, 23, value);
};


/**
 * optional int64 uptime = 24;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getUptime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setUptime = function(value) {
  jspb.Message.setProto3StringIntField(this, 24, value);
};


/**
 * optional string close_address = 25;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getCloseAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setCloseAddress = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional uint64 push_amount_sat = 27;
 * @return {string}
 */
proto.lnrpc.Channel.prototype.getPushAmountSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, "0"));
};


/** @param {string} value */
proto.lnrpc.Channel.prototype.setPushAmountSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 27, value);
};


/**
 * optional uint32 thaw_height = 28;
 * @return {number}
 */
proto.lnrpc.Channel.prototype.getThawHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/** @param {number} value */
proto.lnrpc.Channel.prototype.setThawHeight = function(value) {
  jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional ChannelConstraints local_constraints = 29;
 * @return {?proto.lnrpc.ChannelConstraints}
 */
proto.lnrpc.Channel.prototype.getLocalConstraints = function() {
  return /** @type{?proto.lnrpc.ChannelConstraints} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelConstraints, 29));
};


/** @param {?proto.lnrpc.ChannelConstraints|undefined} value */
proto.lnrpc.Channel.prototype.setLocalConstraints = function(value) {
  jspb.Message.setWrapperField(this, 29, value);
};


proto.lnrpc.Channel.prototype.clearLocalConstraints = function() {
  this.setLocalConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Channel.prototype.hasLocalConstraints = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional ChannelConstraints remote_constraints = 30;
 * @return {?proto.lnrpc.ChannelConstraints}
 */
proto.lnrpc.Channel.prototype.getRemoteConstraints = function() {
  return /** @type{?proto.lnrpc.ChannelConstraints} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelConstraints, 30));
};


/** @param {?proto.lnrpc.ChannelConstraints|undefined} value */
proto.lnrpc.Channel.prototype.setRemoteConstraints = function(value) {
  jspb.Message.setWrapperField(this, 30, value);
};


proto.lnrpc.Channel.prototype.clearRemoteConstraints = function() {
  this.setRemoteConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Channel.prototype.hasRemoteConstraints = function() {
  return jspb.Message.getField(this, 30) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListChannelsRequest.displayName = 'proto.lnrpc.ListChannelsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeOnly: jspb.Message.getFieldWithDefault(msg, 1, false),
    inactiveOnly: jspb.Message.getFieldWithDefault(msg, 2, false),
    publicOnly: jspb.Message.getFieldWithDefault(msg, 3, false),
    privateOnly: jspb.Message.getFieldWithDefault(msg, 4, false),
    peer: msg.getPeer_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListChannelsRequest}
 */
proto.lnrpc.ListChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListChannelsRequest;
  return proto.lnrpc.ListChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListChannelsRequest}
 */
proto.lnrpc.ListChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveOnly(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInactiveOnly(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicOnly(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateOnly(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPeer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInactiveOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPublicOnly();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPrivateOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPeer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional bool active_only = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListChannelsRequest.prototype.getActiveOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ListChannelsRequest.prototype.setActiveOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool inactive_only = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListChannelsRequest.prototype.getInactiveOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.ListChannelsRequest.prototype.setInactiveOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool public_only = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListChannelsRequest.prototype.getPublicOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.ListChannelsRequest.prototype.setPublicOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool private_only = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListChannelsRequest.prototype.getPrivateOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.lnrpc.ListChannelsRequest.prototype.setPrivateOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bytes peer = 5;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ListChannelsRequest.prototype.getPeer = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes peer = 5;
 * This is a type-conversion wrapper around `getPeer()`
 * @return {string}
 */
proto.lnrpc.ListChannelsRequest.prototype.getPeer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPeer()));
};


/**
 * optional bytes peer = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPeer()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ListChannelsRequest.prototype.getPeer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPeer()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ListChannelsRequest.prototype.setPeer = function(value) {
  jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ListChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ListChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListChannelsResponse.displayName = 'proto.lnrpc.ListChannelsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ListChannelsResponse.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.lnrpc.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListChannelsResponse}
 */
proto.lnrpc.ListChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListChannelsResponse;
  return proto.lnrpc.ListChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListChannelsResponse}
 */
proto.lnrpc.ListChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = new proto.lnrpc.Channel;
      reader.readMessage(value,proto.lnrpc.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.lnrpc.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Channel channels = 11;
 * @return {!Array<!proto.lnrpc.Channel>}
 */
proto.lnrpc.ListChannelsResponse.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Channel, 11));
};


/** @param {!Array<!proto.lnrpc.Channel>} value */
proto.lnrpc.ListChannelsResponse.prototype.setChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.lnrpc.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Channel}
 */
proto.lnrpc.ListChannelsResponse.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.lnrpc.Channel, opt_index);
};


proto.lnrpc.ListChannelsResponse.prototype.clearChannelsList = function() {
  this.setChannelsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelCloseSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ChannelCloseSummary.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ChannelCloseSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelCloseSummary.displayName = 'proto.lnrpc.ChannelCloseSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ChannelCloseSummary.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelCloseSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelCloseSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelCloseSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelCloseSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelPoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chanId: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    chainHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
    closingTxHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
    remotePubkey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    closeHeight: jspb.Message.getFieldWithDefault(msg, 7, 0),
    settledBalance: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    timeLockedBalance: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    closeType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    openInitiator: jspb.Message.getFieldWithDefault(msg, 11, 0),
    closeInitiator: jspb.Message.getFieldWithDefault(msg, 12, 0),
    resolutionsList: jspb.Message.toObjectList(msg.getResolutionsList(),
    proto.lnrpc.Resolution.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelCloseSummary}
 */
proto.lnrpc.ChannelCloseSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelCloseSummary;
  return proto.lnrpc.ChannelCloseSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelCloseSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelCloseSummary}
 */
proto.lnrpc.ChannelCloseSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelPoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainHash(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingTxHash(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemotePubkey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCapacity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCloseHeight(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSettledBalance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTimeLockedBalance(value);
      break;
    case 10:
      var value = /** @type {!proto.lnrpc.ChannelCloseSummary.ClosureType} */ (reader.readEnum());
      msg.setCloseType(value);
      break;
    case 11:
      var value = /** @type {!proto.lnrpc.Initiator} */ (reader.readEnum());
      msg.setOpenInitiator(value);
      break;
    case 12:
      var value = /** @type {!proto.lnrpc.Initiator} */ (reader.readEnum());
      msg.setCloseInitiator(value);
      break;
    case 13:
      var value = new proto.lnrpc.Resolution;
      reader.readMessage(value,proto.lnrpc.Resolution.deserializeBinaryFromReader);
      msg.addResolutions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelCloseSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelCloseSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelCloseSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelCloseSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelPoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getChainHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClosingTxHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRemotePubkey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getCloseHeight();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getSettledBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      8,
      f
    );
  }
  f = message.getTimeLockedBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getCloseType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getOpenInitiator();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getCloseInitiator();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getResolutionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.lnrpc.Resolution.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.ChannelCloseSummary.ClosureType = {
  COOPERATIVE_CLOSE: 0,
  LOCAL_FORCE_CLOSE: 1,
  REMOTE_FORCE_CLOSE: 2,
  BREACH_CLOSE: 3,
  FUNDING_CANCELED: 4,
  ABANDONED: 5
};

/**
 * optional string channel_point = 1;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getChannelPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setChannelPoint = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 chan_id = 2;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional string chain_hash = 3;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getChainHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setChainHash = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string closing_tx_hash = 4;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getClosingTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setClosingTxHash = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string remote_pubkey = 5;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getRemotePubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setRemotePubkey = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 capacity = 6;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint32 close_height = 7;
 * @return {number}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getCloseHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelCloseSummary.prototype.setCloseHeight = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 settled_balance = 8;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getSettledBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setSettledBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional int64 time_locked_balance = 9;
 * @return {string}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getTimeLockedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelCloseSummary.prototype.setTimeLockedBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional ClosureType close_type = 10;
 * @return {!proto.lnrpc.ChannelCloseSummary.ClosureType}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getCloseType = function() {
  return /** @type {!proto.lnrpc.ChannelCloseSummary.ClosureType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.lnrpc.ChannelCloseSummary.ClosureType} value */
proto.lnrpc.ChannelCloseSummary.prototype.setCloseType = function(value) {
  jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Initiator open_initiator = 11;
 * @return {!proto.lnrpc.Initiator}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getOpenInitiator = function() {
  return /** @type {!proto.lnrpc.Initiator} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {!proto.lnrpc.Initiator} value */
proto.lnrpc.ChannelCloseSummary.prototype.setOpenInitiator = function(value) {
  jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional Initiator close_initiator = 12;
 * @return {!proto.lnrpc.Initiator}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getCloseInitiator = function() {
  return /** @type {!proto.lnrpc.Initiator} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {!proto.lnrpc.Initiator} value */
proto.lnrpc.ChannelCloseSummary.prototype.setCloseInitiator = function(value) {
  jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated Resolution resolutions = 13;
 * @return {!Array<!proto.lnrpc.Resolution>}
 */
proto.lnrpc.ChannelCloseSummary.prototype.getResolutionsList = function() {
  return /** @type{!Array<!proto.lnrpc.Resolution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Resolution, 13));
};


/** @param {!Array<!proto.lnrpc.Resolution>} value */
proto.lnrpc.ChannelCloseSummary.prototype.setResolutionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.lnrpc.Resolution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Resolution}
 */
proto.lnrpc.ChannelCloseSummary.prototype.addResolutions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.lnrpc.Resolution, opt_index);
};


proto.lnrpc.ChannelCloseSummary.prototype.clearResolutionsList = function() {
  this.setResolutionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Resolution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Resolution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Resolution.displayName = 'proto.lnrpc.Resolution';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Resolution.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Resolution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Resolution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Resolution.toObject = function(includeInstance, msg) {
  var f, obj = {
    resolutionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcome: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outpoint: (f = msg.getOutpoint()) && proto.lnrpc.OutPoint.toObject(includeInstance, f),
    amountSat: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    sweepTxid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Resolution}
 */
proto.lnrpc.Resolution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Resolution;
  return proto.lnrpc.Resolution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Resolution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Resolution}
 */
proto.lnrpc.Resolution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.lnrpc.ResolutionType} */ (reader.readEnum());
      msg.setResolutionType(value);
      break;
    case 2:
      var value = /** @type {!proto.lnrpc.ResolutionOutcome} */ (reader.readEnum());
      msg.setOutcome(value);
      break;
    case 3:
      var value = new proto.lnrpc.OutPoint;
      reader.readMessage(value,proto.lnrpc.OutPoint.deserializeBinaryFromReader);
      msg.setOutpoint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmountSat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSweepTxid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Resolution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Resolution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Resolution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Resolution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResolutionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOutcome();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOutpoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.OutPoint.serializeBinaryToWriter
    );
  }
  f = message.getAmountSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getSweepTxid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional ResolutionType resolution_type = 1;
 * @return {!proto.lnrpc.ResolutionType}
 */
proto.lnrpc.Resolution.prototype.getResolutionType = function() {
  return /** @type {!proto.lnrpc.ResolutionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.ResolutionType} value */
proto.lnrpc.Resolution.prototype.setResolutionType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ResolutionOutcome outcome = 2;
 * @return {!proto.lnrpc.ResolutionOutcome}
 */
proto.lnrpc.Resolution.prototype.getOutcome = function() {
  return /** @type {!proto.lnrpc.ResolutionOutcome} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.lnrpc.ResolutionOutcome} value */
proto.lnrpc.Resolution.prototype.setOutcome = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional OutPoint outpoint = 3;
 * @return {?proto.lnrpc.OutPoint}
 */
proto.lnrpc.Resolution.prototype.getOutpoint = function() {
  return /** @type{?proto.lnrpc.OutPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.OutPoint, 3));
};


/** @param {?proto.lnrpc.OutPoint|undefined} value */
proto.lnrpc.Resolution.prototype.setOutpoint = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.lnrpc.Resolution.prototype.clearOutpoint = function() {
  this.setOutpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Resolution.prototype.hasOutpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 amount_sat = 4;
 * @return {string}
 */
proto.lnrpc.Resolution.prototype.getAmountSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.Resolution.prototype.setAmountSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional string sweep_txid = 5;
 * @return {string}
 */
proto.lnrpc.Resolution.prototype.getSweepTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.Resolution.prototype.setSweepTxid = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ClosedChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ClosedChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ClosedChannelsRequest.displayName = 'proto.lnrpc.ClosedChannelsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ClosedChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ClosedChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ClosedChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cooperative: jspb.Message.getFieldWithDefault(msg, 1, false),
    localForce: jspb.Message.getFieldWithDefault(msg, 2, false),
    remoteForce: jspb.Message.getFieldWithDefault(msg, 3, false),
    breach: jspb.Message.getFieldWithDefault(msg, 4, false),
    fundingCanceled: jspb.Message.getFieldWithDefault(msg, 5, false),
    abandoned: jspb.Message.getFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ClosedChannelsRequest}
 */
proto.lnrpc.ClosedChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ClosedChannelsRequest;
  return proto.lnrpc.ClosedChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ClosedChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ClosedChannelsRequest}
 */
proto.lnrpc.ClosedChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCooperative(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocalForce(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoteForce(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBreach(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFundingCanceled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAbandoned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ClosedChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ClosedChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ClosedChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCooperative();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLocalForce();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRemoteForce();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBreach();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFundingCanceled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAbandoned();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool cooperative = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.getCooperative = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ClosedChannelsRequest.prototype.setCooperative = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool local_force = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.getLocalForce = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.ClosedChannelsRequest.prototype.setLocalForce = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool remote_force = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.getRemoteForce = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.ClosedChannelsRequest.prototype.setRemoteForce = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool breach = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.getBreach = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.lnrpc.ClosedChannelsRequest.prototype.setBreach = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool funding_canceled = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.getFundingCanceled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.lnrpc.ClosedChannelsRequest.prototype.setFundingCanceled = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool abandoned = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ClosedChannelsRequest.prototype.getAbandoned = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.lnrpc.ClosedChannelsRequest.prototype.setAbandoned = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ClosedChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ClosedChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ClosedChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ClosedChannelsResponse.displayName = 'proto.lnrpc.ClosedChannelsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ClosedChannelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ClosedChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ClosedChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ClosedChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ClosedChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.lnrpc.ChannelCloseSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ClosedChannelsResponse}
 */
proto.lnrpc.ClosedChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ClosedChannelsResponse;
  return proto.lnrpc.ClosedChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ClosedChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ClosedChannelsResponse}
 */
proto.lnrpc.ClosedChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelCloseSummary;
      reader.readMessage(value,proto.lnrpc.ChannelCloseSummary.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ClosedChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ClosedChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ClosedChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ClosedChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.ChannelCloseSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChannelCloseSummary channels = 1;
 * @return {!Array<!proto.lnrpc.ChannelCloseSummary>}
 */
proto.lnrpc.ClosedChannelsResponse.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelCloseSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelCloseSummary, 1));
};


/** @param {!Array<!proto.lnrpc.ChannelCloseSummary>} value */
proto.lnrpc.ClosedChannelsResponse.prototype.setChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.ChannelCloseSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelCloseSummary}
 */
proto.lnrpc.ClosedChannelsResponse.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.ChannelCloseSummary, opt_index);
};


proto.lnrpc.ClosedChannelsResponse.prototype.clearChannelsList = function() {
  this.setChannelsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Peer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Peer.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Peer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Peer.displayName = 'proto.lnrpc.Peer';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Peer.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Peer.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Peer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Peer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Peer.toObject = function(includeInstance, msg) {
  var f, obj = {
    pubKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bytesSent: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    bytesRecv: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    satSent: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    satRecv: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    inbound: jspb.Message.getFieldWithDefault(msg, 8, false),
    pingTime: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    syncType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    featuresMap: (f = msg.getFeaturesMap()) ? f.toObject(includeInstance, proto.lnrpc.Feature.toObject) : [],
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.lnrpc.TimestampedError.toObject, includeInstance),
    flapCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    lastFlapNs: jspb.Message.getFieldWithDefault(msg, 14, "0"),
    lastPingPayload: msg.getLastPingPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Peer}
 */
proto.lnrpc.Peer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Peer;
  return proto.lnrpc.Peer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Peer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Peer}
 */
proto.lnrpc.Peer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setBytesSent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setBytesRecv(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatSent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatRecv(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setPingTime(value);
      break;
    case 10:
      var value = /** @type {!proto.lnrpc.Peer.SyncType} */ (reader.readEnum());
      msg.setSyncType(value);
      break;
    case 11:
      var value = msg.getFeaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.Feature.deserializeBinaryFromReader, 0);
         });
      break;
    case 12:
      var value = new proto.lnrpc.TimestampedError;
      reader.readMessage(value,proto.lnrpc.TimestampedError.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlapCount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLastFlapNs(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastPingPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Peer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Peer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Peer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Peer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBytesSent();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getBytesRecv();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getSatSent();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getSatRecv();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPingTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getSyncType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getFeaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.Feature.serializeBinaryToWriter);
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.lnrpc.TimestampedError.serializeBinaryToWriter
    );
  }
  f = message.getFlapCount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLastFlapNs();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      14,
      f
    );
  }
  f = message.getLastPingPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      15,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.Peer.SyncType = {
  UNKNOWN_SYNC: 0,
  ACTIVE_SYNC: 1,
  PASSIVE_SYNC: 2,
  PINNED_SYNC: 3
};

/**
 * optional string pub_key = 1;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setAddress = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 bytes_sent = 4;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getBytesSent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setBytesSent = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 bytes_recv = 5;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getBytesRecv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setBytesRecv = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 sat_sent = 6;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getSatSent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setSatSent = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int64 sat_recv = 7;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getSatRecv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setSatRecv = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional bool inbound = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Peer.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.lnrpc.Peer.prototype.setInbound = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 ping_time = 9;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getPingTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setPingTime = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional SyncType sync_type = 10;
 * @return {!proto.lnrpc.Peer.SyncType}
 */
proto.lnrpc.Peer.prototype.getSyncType = function() {
  return /** @type {!proto.lnrpc.Peer.SyncType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.lnrpc.Peer.SyncType} value */
proto.lnrpc.Peer.prototype.setSyncType = function(value) {
  jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * map<uint32, Feature> features = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.lnrpc.Feature>}
 */
proto.lnrpc.Peer.prototype.getFeaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.lnrpc.Feature>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      proto.lnrpc.Feature));
};


proto.lnrpc.Peer.prototype.clearFeaturesMap = function() {
  this.getFeaturesMap().clear();
};


/**
 * repeated TimestampedError errors = 12;
 * @return {!Array<!proto.lnrpc.TimestampedError>}
 */
proto.lnrpc.Peer.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.lnrpc.TimestampedError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.TimestampedError, 12));
};


/** @param {!Array<!proto.lnrpc.TimestampedError>} value */
proto.lnrpc.Peer.prototype.setErrorsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.lnrpc.TimestampedError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.TimestampedError}
 */
proto.lnrpc.Peer.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.lnrpc.TimestampedError, opt_index);
};


proto.lnrpc.Peer.prototype.clearErrorsList = function() {
  this.setErrorsList([]);
};


/**
 * optional int32 flap_count = 13;
 * @return {number}
 */
proto.lnrpc.Peer.prototype.getFlapCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.lnrpc.Peer.prototype.setFlapCount = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 last_flap_ns = 14;
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getLastFlapNs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, "0"));
};


/** @param {string} value */
proto.lnrpc.Peer.prototype.setLastFlapNs = function(value) {
  jspb.Message.setProto3StringIntField(this, 14, value);
};


/**
 * optional bytes last_ping_payload = 15;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Peer.prototype.getLastPingPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes last_ping_payload = 15;
 * This is a type-conversion wrapper around `getLastPingPayload()`
 * @return {string}
 */
proto.lnrpc.Peer.prototype.getLastPingPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastPingPayload()));
};


/**
 * optional bytes last_ping_payload = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastPingPayload()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Peer.prototype.getLastPingPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastPingPayload()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Peer.prototype.setLastPingPayload = function(value) {
  jspb.Message.setProto3BytesField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.TimestampedError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.TimestampedError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.TimestampedError.displayName = 'proto.lnrpc.TimestampedError';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.TimestampedError.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.TimestampedError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.TimestampedError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.TimestampedError.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.TimestampedError}
 */
proto.lnrpc.TimestampedError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.TimestampedError;
  return proto.lnrpc.TimestampedError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.TimestampedError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.TimestampedError}
 */
proto.lnrpc.TimestampedError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.TimestampedError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.TimestampedError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.TimestampedError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.TimestampedError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 timestamp = 1;
 * @return {string}
 */
proto.lnrpc.TimestampedError.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.TimestampedError.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.lnrpc.TimestampedError.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.TimestampedError.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListPeersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListPeersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListPeersRequest.displayName = 'proto.lnrpc.ListPeersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListPeersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListPeersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListPeersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPeersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestError: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListPeersRequest}
 */
proto.lnrpc.ListPeersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListPeersRequest;
  return proto.lnrpc.ListPeersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListPeersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListPeersRequest}
 */
proto.lnrpc.ListPeersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLatestError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListPeersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListPeersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListPeersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPeersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestError();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool latest_error = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListPeersRequest.prototype.getLatestError = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ListPeersRequest.prototype.setLatestError = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListPeersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ListPeersResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ListPeersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListPeersResponse.displayName = 'proto.lnrpc.ListPeersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ListPeersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListPeersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListPeersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListPeersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPeersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    peersList: jspb.Message.toObjectList(msg.getPeersList(),
    proto.lnrpc.Peer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListPeersResponse}
 */
proto.lnrpc.ListPeersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListPeersResponse;
  return proto.lnrpc.ListPeersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListPeersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListPeersResponse}
 */
proto.lnrpc.ListPeersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Peer;
      reader.readMessage(value,proto.lnrpc.Peer.deserializeBinaryFromReader);
      msg.addPeers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListPeersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListPeersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListPeersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPeersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.Peer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Peer peers = 1;
 * @return {!Array<!proto.lnrpc.Peer>}
 */
proto.lnrpc.ListPeersResponse.prototype.getPeersList = function() {
  return /** @type{!Array<!proto.lnrpc.Peer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Peer, 1));
};


/** @param {!Array<!proto.lnrpc.Peer>} value */
proto.lnrpc.ListPeersResponse.prototype.setPeersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.Peer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Peer}
 */
proto.lnrpc.ListPeersResponse.prototype.addPeers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.Peer, opt_index);
};


proto.lnrpc.ListPeersResponse.prototype.clearPeersList = function() {
  this.setPeersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PeerEventSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PeerEventSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PeerEventSubscription.displayName = 'proto.lnrpc.PeerEventSubscription';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PeerEventSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PeerEventSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PeerEventSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PeerEventSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PeerEventSubscription}
 */
proto.lnrpc.PeerEventSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PeerEventSubscription;
  return proto.lnrpc.PeerEventSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PeerEventSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PeerEventSubscription}
 */
proto.lnrpc.PeerEventSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PeerEventSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PeerEventSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PeerEventSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PeerEventSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PeerEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PeerEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PeerEvent.displayName = 'proto.lnrpc.PeerEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PeerEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PeerEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PeerEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PeerEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    pubKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PeerEvent}
 */
proto.lnrpc.PeerEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PeerEvent;
  return proto.lnrpc.PeerEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PeerEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PeerEvent}
 */
proto.lnrpc.PeerEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    case 2:
      var value = /** @type {!proto.lnrpc.PeerEvent.EventType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PeerEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PeerEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PeerEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PeerEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.PeerEvent.EventType = {
  PEER_ONLINE: 0,
  PEER_OFFLINE: 1
};

/**
 * optional string pub_key = 1;
 * @return {string}
 */
proto.lnrpc.PeerEvent.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.PeerEvent.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EventType type = 2;
 * @return {!proto.lnrpc.PeerEvent.EventType}
 */
proto.lnrpc.PeerEvent.prototype.getType = function() {
  return /** @type {!proto.lnrpc.PeerEvent.EventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.lnrpc.PeerEvent.EventType} value */
proto.lnrpc.PeerEvent.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GetInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.GetInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GetInfoRequest.displayName = 'proto.lnrpc.GetInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GetInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GetInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GetInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GetInfoRequest}
 */
proto.lnrpc.GetInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GetInfoRequest;
  return proto.lnrpc.GetInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GetInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GetInfoRequest}
 */
proto.lnrpc.GetInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GetInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GetInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GetInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GetInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.GetInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.GetInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GetInfoResponse.displayName = 'proto.lnrpc.GetInfoResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.GetInfoResponse.repeatedFields_ = [16,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GetInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GetInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GetInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 14, ""),
    commitHash: jspb.Message.getFieldWithDefault(msg, 20, ""),
    identityPubkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 17, ""),
    numPendingChannels: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numActiveChannels: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numInactiveChannels: jspb.Message.getFieldWithDefault(msg, 15, 0),
    numPeers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    blockHeight: jspb.Message.getFieldWithDefault(msg, 6, 0),
    blockHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bestHeaderTimestamp: jspb.Message.getFieldWithDefault(msg, 13, "0"),
    syncedToChain: jspb.Message.getFieldWithDefault(msg, 9, false),
    syncedToGraph: jspb.Message.getFieldWithDefault(msg, 18, false),
    testnet: jspb.Message.getFieldWithDefault(msg, 10, false),
    chainsList: jspb.Message.toObjectList(msg.getChainsList(),
    proto.lnrpc.Chain.toObject, includeInstance),
    urisList: jspb.Message.getRepeatedField(msg, 12),
    featuresMap: (f = msg.getFeaturesMap()) ? f.toObject(includeInstance, proto.lnrpc.Feature.toObject) : [],
    requireHtlcInterceptor: jspb.Message.getFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GetInfoResponse}
 */
proto.lnrpc.GetInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GetInfoResponse;
  return proto.lnrpc.GetInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GetInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GetInfoResponse}
 */
proto.lnrpc.GetInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommitHash(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityPubkey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumPendingChannels(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumActiveChannels(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumInactiveChannels(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumPeers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlockHeight(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockHash(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setBestHeaderTimestamp(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncedToChain(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncedToGraph(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTestnet(value);
      break;
    case 16:
      var value = new proto.lnrpc.Chain;
      reader.readMessage(value,proto.lnrpc.Chain.deserializeBinaryFromReader);
      msg.addChains(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addUris(value);
      break;
    case 19:
      var value = msg.getFeaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.Feature.deserializeBinaryFromReader, 0);
         });
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireHtlcInterceptor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GetInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GetInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GetInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCommitHash();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIdentityPubkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNumPendingChannels();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNumActiveChannels();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getNumInactiveChannels();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getNumPeers();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBlockHeight();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getBlockHash();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBestHeaderTimestamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      13,
      f
    );
  }
  f = message.getSyncedToChain();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSyncedToGraph();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getTestnet();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getChainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.lnrpc.Chain.serializeBinaryToWriter
    );
  }
  f = message.getUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getFeaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(19, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.Feature.serializeBinaryToWriter);
  }
  f = message.getRequireHtlcInterceptor();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string version = 14;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setVersion = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string commit_hash = 20;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getCommitHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setCommitHash = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string identity_pubkey = 1;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getIdentityPubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setIdentityPubkey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setAlias = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 17;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setColor = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 num_pending_channels = 3;
 * @return {number}
 */
proto.lnrpc.GetInfoResponse.prototype.getNumPendingChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.GetInfoResponse.prototype.setNumPendingChannels = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 num_active_channels = 4;
 * @return {number}
 */
proto.lnrpc.GetInfoResponse.prototype.getNumActiveChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.GetInfoResponse.prototype.setNumActiveChannels = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 num_inactive_channels = 15;
 * @return {number}
 */
proto.lnrpc.GetInfoResponse.prototype.getNumInactiveChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.lnrpc.GetInfoResponse.prototype.setNumInactiveChannels = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 num_peers = 5;
 * @return {number}
 */
proto.lnrpc.GetInfoResponse.prototype.getNumPeers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.GetInfoResponse.prototype.setNumPeers = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 block_height = 6;
 * @return {number}
 */
proto.lnrpc.GetInfoResponse.prototype.getBlockHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.GetInfoResponse.prototype.setBlockHeight = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string block_hash = 8;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getBlockHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setBlockHash = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 best_header_timestamp = 13;
 * @return {string}
 */
proto.lnrpc.GetInfoResponse.prototype.getBestHeaderTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, "0"));
};


/** @param {string} value */
proto.lnrpc.GetInfoResponse.prototype.setBestHeaderTimestamp = function(value) {
  jspb.Message.setProto3StringIntField(this, 13, value);
};


/**
 * optional bool synced_to_chain = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.GetInfoResponse.prototype.getSyncedToChain = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.lnrpc.GetInfoResponse.prototype.setSyncedToChain = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool synced_to_graph = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.GetInfoResponse.prototype.getSyncedToGraph = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.lnrpc.GetInfoResponse.prototype.setSyncedToGraph = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool testnet = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.GetInfoResponse.prototype.getTestnet = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.lnrpc.GetInfoResponse.prototype.setTestnet = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated Chain chains = 16;
 * @return {!Array<!proto.lnrpc.Chain>}
 */
proto.lnrpc.GetInfoResponse.prototype.getChainsList = function() {
  return /** @type{!Array<!proto.lnrpc.Chain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Chain, 16));
};


/** @param {!Array<!proto.lnrpc.Chain>} value */
proto.lnrpc.GetInfoResponse.prototype.setChainsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.lnrpc.Chain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Chain}
 */
proto.lnrpc.GetInfoResponse.prototype.addChains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.lnrpc.Chain, opt_index);
};


proto.lnrpc.GetInfoResponse.prototype.clearChainsList = function() {
  this.setChainsList([]);
};


/**
 * repeated string uris = 12;
 * @return {!Array<string>}
 */
proto.lnrpc.GetInfoResponse.prototype.getUrisList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<string>} value */
proto.lnrpc.GetInfoResponse.prototype.setUrisList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.lnrpc.GetInfoResponse.prototype.addUris = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.lnrpc.GetInfoResponse.prototype.clearUrisList = function() {
  this.setUrisList([]);
};


/**
 * map<uint32, Feature> features = 19;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.lnrpc.Feature>}
 */
proto.lnrpc.GetInfoResponse.prototype.getFeaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.lnrpc.Feature>} */ (
      jspb.Message.getMapField(this, 19, opt_noLazyCreate,
      proto.lnrpc.Feature));
};


proto.lnrpc.GetInfoResponse.prototype.clearFeaturesMap = function() {
  this.getFeaturesMap().clear();
};


/**
 * optional bool require_htlc_interceptor = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.GetInfoResponse.prototype.getRequireHtlcInterceptor = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.lnrpc.GetInfoResponse.prototype.setRequireHtlcInterceptor = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GetRecoveryInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.GetRecoveryInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GetRecoveryInfoRequest.displayName = 'proto.lnrpc.GetRecoveryInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GetRecoveryInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GetRecoveryInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GetRecoveryInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetRecoveryInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GetRecoveryInfoRequest}
 */
proto.lnrpc.GetRecoveryInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GetRecoveryInfoRequest;
  return proto.lnrpc.GetRecoveryInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GetRecoveryInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GetRecoveryInfoRequest}
 */
proto.lnrpc.GetRecoveryInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GetRecoveryInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GetRecoveryInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GetRecoveryInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetRecoveryInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GetRecoveryInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.GetRecoveryInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GetRecoveryInfoResponse.displayName = 'proto.lnrpc.GetRecoveryInfoResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GetRecoveryInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GetRecoveryInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GetRecoveryInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetRecoveryInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recoveryMode: jspb.Message.getFieldWithDefault(msg, 1, false),
    recoveryFinished: jspb.Message.getFieldWithDefault(msg, 2, false),
    progress: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GetRecoveryInfoResponse}
 */
proto.lnrpc.GetRecoveryInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GetRecoveryInfoResponse;
  return proto.lnrpc.GetRecoveryInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GetRecoveryInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GetRecoveryInfoResponse}
 */
proto.lnrpc.GetRecoveryInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecoveryMode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecoveryFinished(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GetRecoveryInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GetRecoveryInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GetRecoveryInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GetRecoveryInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecoveryMode();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRecoveryFinished();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional bool recovery_mode = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.GetRecoveryInfoResponse.prototype.getRecoveryMode = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.GetRecoveryInfoResponse.prototype.setRecoveryMode = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool recovery_finished = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.GetRecoveryInfoResponse.prototype.getRecoveryFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.GetRecoveryInfoResponse.prototype.setRecoveryFinished = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional double progress = 3;
 * @return {number}
 */
proto.lnrpc.GetRecoveryInfoResponse.prototype.getProgress = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.lnrpc.GetRecoveryInfoResponse.prototype.setProgress = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Chain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Chain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Chain.displayName = 'proto.lnrpc.Chain';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Chain.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Chain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Chain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Chain.toObject = function(includeInstance, msg) {
  var f, obj = {
    chain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    network: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Chain}
 */
proto.lnrpc.Chain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Chain;
  return proto.lnrpc.Chain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Chain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Chain}
 */
proto.lnrpc.Chain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Chain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Chain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Chain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Chain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNetwork();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string chain = 1;
 * @return {string}
 */
proto.lnrpc.Chain.prototype.getChain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.Chain.prototype.setChain = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string network = 2;
 * @return {string}
 */
proto.lnrpc.Chain.prototype.getNetwork = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.Chain.prototype.setNetwork = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ConfirmationUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ConfirmationUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ConfirmationUpdate.displayName = 'proto.lnrpc.ConfirmationUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ConfirmationUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ConfirmationUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ConfirmationUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ConfirmationUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockSha: msg.getBlockSha_asB64(),
    blockHeight: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numConfsLeft: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ConfirmationUpdate}
 */
proto.lnrpc.ConfirmationUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ConfirmationUpdate;
  return proto.lnrpc.ConfirmationUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ConfirmationUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ConfirmationUpdate}
 */
proto.lnrpc.ConfirmationUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBlockSha(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlockHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumConfsLeft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ConfirmationUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ConfirmationUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ConfirmationUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ConfirmationUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockSha_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getBlockHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumConfsLeft();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional bytes block_sha = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ConfirmationUpdate.prototype.getBlockSha = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes block_sha = 1;
 * This is a type-conversion wrapper around `getBlockSha()`
 * @return {string}
 */
proto.lnrpc.ConfirmationUpdate.prototype.getBlockSha_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBlockSha()));
};


/**
 * optional bytes block_sha = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockSha()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ConfirmationUpdate.prototype.getBlockSha_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBlockSha()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ConfirmationUpdate.prototype.setBlockSha = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int32 block_height = 2;
 * @return {number}
 */
proto.lnrpc.ConfirmationUpdate.prototype.getBlockHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.ConfirmationUpdate.prototype.setBlockHeight = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 num_confs_left = 3;
 * @return {number}
 */
proto.lnrpc.ConfirmationUpdate.prototype.getNumConfsLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.ConfirmationUpdate.prototype.setNumConfsLeft = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelOpenUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelOpenUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelOpenUpdate.displayName = 'proto.lnrpc.ChannelOpenUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelOpenUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelOpenUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelOpenUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelOpenUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelPoint: (f = msg.getChannelPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelOpenUpdate}
 */
proto.lnrpc.ChannelOpenUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelOpenUpdate;
  return proto.lnrpc.ChannelOpenUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelOpenUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelOpenUpdate}
 */
proto.lnrpc.ChannelOpenUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChannelPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelOpenUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelOpenUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelOpenUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelOpenUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChannelPoint channel_point = 1;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelOpenUpdate.prototype.getChannelPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 1));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChannelOpenUpdate.prototype.setChannelPoint = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.ChannelOpenUpdate.prototype.clearChannelPoint = function() {
  this.setChannelPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelOpenUpdate.prototype.hasChannelPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelCloseUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelCloseUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelCloseUpdate.displayName = 'proto.lnrpc.ChannelCloseUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelCloseUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelCloseUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelCloseUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelCloseUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    closingTxid: msg.getClosingTxid_asB64(),
    success: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelCloseUpdate}
 */
proto.lnrpc.ChannelCloseUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelCloseUpdate;
  return proto.lnrpc.ChannelCloseUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelCloseUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelCloseUpdate}
 */
proto.lnrpc.ChannelCloseUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClosingTxid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelCloseUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelCloseUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelCloseUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelCloseUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClosingTxid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes closing_txid = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelCloseUpdate.prototype.getClosingTxid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes closing_txid = 1;
 * This is a type-conversion wrapper around `getClosingTxid()`
 * @return {string}
 */
proto.lnrpc.ChannelCloseUpdate.prototype.getClosingTxid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClosingTxid()));
};


/**
 * optional bytes closing_txid = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClosingTxid()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelCloseUpdate.prototype.getClosingTxid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClosingTxid()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelCloseUpdate.prototype.setClosingTxid = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bool success = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ChannelCloseUpdate.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.ChannelCloseUpdate.prototype.setSuccess = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.CloseChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.CloseChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.CloseChannelRequest.displayName = 'proto.lnrpc.CloseChannelRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.CloseChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.CloseChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.CloseChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CloseChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelPoint: (f = msg.getChannelPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    force: jspb.Message.getFieldWithDefault(msg, 2, false),
    targetConf: jspb.Message.getFieldWithDefault(msg, 3, 0),
    satPerByte: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    deliveryAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    satPerVbyte: jspb.Message.getFieldWithDefault(msg, 6, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.CloseChannelRequest}
 */
proto.lnrpc.CloseChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.CloseChannelRequest;
  return proto.lnrpc.CloseChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.CloseChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.CloseChannelRequest}
 */
proto.lnrpc.CloseChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChannelPoint(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetConf(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatPerByte(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSatPerVbyte(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.CloseChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.CloseChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.CloseChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CloseChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getForce();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTargetConf();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSatPerByte();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getDeliveryAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
};


/**
 * optional ChannelPoint channel_point = 1;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.CloseChannelRequest.prototype.getChannelPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 1));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.CloseChannelRequest.prototype.setChannelPoint = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.CloseChannelRequest.prototype.clearChannelPoint = function() {
  this.setChannelPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.CloseChannelRequest.prototype.hasChannelPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool force = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.CloseChannelRequest.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.CloseChannelRequest.prototype.setForce = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 target_conf = 3;
 * @return {number}
 */
proto.lnrpc.CloseChannelRequest.prototype.getTargetConf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.CloseChannelRequest.prototype.setTargetConf = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 sat_per_byte = 4;
 * @return {string}
 */
proto.lnrpc.CloseChannelRequest.prototype.getSatPerByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.CloseChannelRequest.prototype.setSatPerByte = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional string delivery_address = 5;
 * @return {string}
 */
proto.lnrpc.CloseChannelRequest.prototype.getDeliveryAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.CloseChannelRequest.prototype.setDeliveryAddress = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 sat_per_vbyte = 6;
 * @return {string}
 */
proto.lnrpc.CloseChannelRequest.prototype.getSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.CloseChannelRequest.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.CloseStatusUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.CloseStatusUpdate.oneofGroups_);
};
goog.inherits(proto.lnrpc.CloseStatusUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.CloseStatusUpdate.displayName = 'proto.lnrpc.CloseStatusUpdate';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.CloseStatusUpdate.oneofGroups_ = [[1,3]];

/**
 * @enum {number}
 */
proto.lnrpc.CloseStatusUpdate.UpdateCase = {
  UPDATE_NOT_SET: 0,
  CLOSE_PENDING: 1,
  CHAN_CLOSE: 3
};

/**
 * @return {proto.lnrpc.CloseStatusUpdate.UpdateCase}
 */
proto.lnrpc.CloseStatusUpdate.prototype.getUpdateCase = function() {
  return /** @type {proto.lnrpc.CloseStatusUpdate.UpdateCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.CloseStatusUpdate.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.CloseStatusUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.CloseStatusUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.CloseStatusUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CloseStatusUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    closePending: (f = msg.getClosePending()) && proto.lnrpc.PendingUpdate.toObject(includeInstance, f),
    chanClose: (f = msg.getChanClose()) && proto.lnrpc.ChannelCloseUpdate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.CloseStatusUpdate}
 */
proto.lnrpc.CloseStatusUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.CloseStatusUpdate;
  return proto.lnrpc.CloseStatusUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.CloseStatusUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.CloseStatusUpdate}
 */
proto.lnrpc.CloseStatusUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingUpdate;
      reader.readMessage(value,proto.lnrpc.PendingUpdate.deserializeBinaryFromReader);
      msg.setClosePending(value);
      break;
    case 3:
      var value = new proto.lnrpc.ChannelCloseUpdate;
      reader.readMessage(value,proto.lnrpc.ChannelCloseUpdate.deserializeBinaryFromReader);
      msg.setChanClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.CloseStatusUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.CloseStatusUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.CloseStatusUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CloseStatusUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClosePending();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.PendingUpdate.serializeBinaryToWriter
    );
  }
  f = message.getChanClose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.ChannelCloseUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional PendingUpdate close_pending = 1;
 * @return {?proto.lnrpc.PendingUpdate}
 */
proto.lnrpc.CloseStatusUpdate.prototype.getClosePending = function() {
  return /** @type{?proto.lnrpc.PendingUpdate} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingUpdate, 1));
};


/** @param {?proto.lnrpc.PendingUpdate|undefined} value */
proto.lnrpc.CloseStatusUpdate.prototype.setClosePending = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.lnrpc.CloseStatusUpdate.oneofGroups_[0], value);
};


proto.lnrpc.CloseStatusUpdate.prototype.clearClosePending = function() {
  this.setClosePending(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.CloseStatusUpdate.prototype.hasClosePending = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChannelCloseUpdate chan_close = 3;
 * @return {?proto.lnrpc.ChannelCloseUpdate}
 */
proto.lnrpc.CloseStatusUpdate.prototype.getChanClose = function() {
  return /** @type{?proto.lnrpc.ChannelCloseUpdate} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelCloseUpdate, 3));
};


/** @param {?proto.lnrpc.ChannelCloseUpdate|undefined} value */
proto.lnrpc.CloseStatusUpdate.prototype.setChanClose = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.lnrpc.CloseStatusUpdate.oneofGroups_[0], value);
};


proto.lnrpc.CloseStatusUpdate.prototype.clearChanClose = function() {
  this.setChanClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.CloseStatusUpdate.prototype.hasChanClose = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingUpdate.displayName = 'proto.lnrpc.PendingUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    txid: msg.getTxid_asB64(),
    outputIndex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingUpdate}
 */
proto.lnrpc.PendingUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingUpdate;
  return proto.lnrpc.PendingUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingUpdate}
 */
proto.lnrpc.PendingUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTxid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOutputIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getOutputIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional bytes txid = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.PendingUpdate.prototype.getTxid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes txid = 1;
 * This is a type-conversion wrapper around `getTxid()`
 * @return {string}
 */
proto.lnrpc.PendingUpdate.prototype.getTxid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTxid()));
};


/**
 * optional bytes txid = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTxid()`
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingUpdate.prototype.getTxid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTxid()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.PendingUpdate.prototype.setTxid = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional uint32 output_index = 2;
 * @return {number}
 */
proto.lnrpc.PendingUpdate.prototype.getOutputIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.PendingUpdate.prototype.setOutputIndex = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ReadyForPsbtFunding = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ReadyForPsbtFunding, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ReadyForPsbtFunding.displayName = 'proto.lnrpc.ReadyForPsbtFunding';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ReadyForPsbtFunding.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ReadyForPsbtFunding} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ReadyForPsbtFunding.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fundingAmount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    psbt: msg.getPsbt_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ReadyForPsbtFunding}
 */
proto.lnrpc.ReadyForPsbtFunding.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ReadyForPsbtFunding;
  return proto.lnrpc.ReadyForPsbtFunding.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ReadyForPsbtFunding} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ReadyForPsbtFunding}
 */
proto.lnrpc.ReadyForPsbtFunding.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundingAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFundingAmount(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPsbt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ReadyForPsbtFunding.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ReadyForPsbtFunding} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ReadyForPsbtFunding.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFundingAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getPsbt_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string funding_address = 1;
 * @return {string}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.getFundingAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.ReadyForPsbtFunding.prototype.setFundingAddress = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 funding_amount = 2;
 * @return {string}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.getFundingAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ReadyForPsbtFunding.prototype.setFundingAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional bytes psbt = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.getPsbt = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes psbt = 3;
 * This is a type-conversion wrapper around `getPsbt()`
 * @return {string}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.getPsbt_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPsbt()));
};


/**
 * optional bytes psbt = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPsbt()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ReadyForPsbtFunding.prototype.getPsbt_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPsbt()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ReadyForPsbtFunding.prototype.setPsbt = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.BatchOpenChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.BatchOpenChannelRequest.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.BatchOpenChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.BatchOpenChannelRequest.displayName = 'proto.lnrpc.BatchOpenChannelRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.BatchOpenChannelRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.BatchOpenChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.BatchOpenChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BatchOpenChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.lnrpc.BatchOpenChannel.toObject, includeInstance),
    targetConf: jspb.Message.getFieldWithDefault(msg, 2, 0),
    satPerVbyte: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    minConfs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    spendUnconfirmed: jspb.Message.getFieldWithDefault(msg, 5, false),
    label: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.BatchOpenChannelRequest}
 */
proto.lnrpc.BatchOpenChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.BatchOpenChannelRequest;
  return proto.lnrpc.BatchOpenChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.BatchOpenChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.BatchOpenChannelRequest}
 */
proto.lnrpc.BatchOpenChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.BatchOpenChannel;
      reader.readMessage(value,proto.lnrpc.BatchOpenChannel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetConf(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatPerVbyte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinConfs(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpendUnconfirmed(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.BatchOpenChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.BatchOpenChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BatchOpenChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.BatchOpenChannel.serializeBinaryToWriter
    );
  }
  f = message.getTargetConf();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getMinConfs();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSpendUnconfirmed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated BatchOpenChannel channels = 1;
 * @return {!Array<!proto.lnrpc.BatchOpenChannel>}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.BatchOpenChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.BatchOpenChannel, 1));
};


/** @param {!Array<!proto.lnrpc.BatchOpenChannel>} value */
proto.lnrpc.BatchOpenChannelRequest.prototype.setChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.BatchOpenChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.BatchOpenChannel}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.BatchOpenChannel, opt_index);
};


proto.lnrpc.BatchOpenChannelRequest.prototype.clearChannelsList = function() {
  this.setChannelsList([]);
};


/**
 * optional int32 target_conf = 2;
 * @return {number}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.getTargetConf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.BatchOpenChannelRequest.prototype.setTargetConf = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 sat_per_vbyte = 3;
 * @return {string}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.getSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.BatchOpenChannelRequest.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int32 min_confs = 4;
 * @return {number}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.getMinConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.BatchOpenChannelRequest.prototype.setMinConfs = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool spend_unconfirmed = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.getSpendUnconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.lnrpc.BatchOpenChannelRequest.prototype.setSpendUnconfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.lnrpc.BatchOpenChannelRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.lnrpc.BatchOpenChannelRequest.prototype.setLabel = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.BatchOpenChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.BatchOpenChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.BatchOpenChannel.displayName = 'proto.lnrpc.BatchOpenChannel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.BatchOpenChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.BatchOpenChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.BatchOpenChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BatchOpenChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodePubkey: msg.getNodePubkey_asB64(),
    localFundingAmount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    pushSat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    pb_private: jspb.Message.getFieldWithDefault(msg, 4, false),
    minHtlcMsat: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    remoteCsvDelay: jspb.Message.getFieldWithDefault(msg, 6, 0),
    closeAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pendingChanId: msg.getPendingChanId_asB64(),
    commitmentType: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.BatchOpenChannel}
 */
proto.lnrpc.BatchOpenChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.BatchOpenChannel;
  return proto.lnrpc.BatchOpenChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.BatchOpenChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.BatchOpenChannel}
 */
proto.lnrpc.BatchOpenChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNodePubkey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLocalFundingAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setPushSat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMinHtlcMsat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemoteCsvDelay(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseAddress(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 9:
      var value = /** @type {!proto.lnrpc.CommitmentType} */ (reader.readEnum());
      msg.setCommitmentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.BatchOpenChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.BatchOpenChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.BatchOpenChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BatchOpenChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodePubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getLocalFundingAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getPushSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMinHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getRemoteCsvDelay();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCloseAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getCommitmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional bytes node_pubkey = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.BatchOpenChannel.prototype.getNodePubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes node_pubkey = 1;
 * This is a type-conversion wrapper around `getNodePubkey()`
 * @return {string}
 */
proto.lnrpc.BatchOpenChannel.prototype.getNodePubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNodePubkey()));
};


/**
 * optional bytes node_pubkey = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNodePubkey()`
 * @return {!Uint8Array}
 */
proto.lnrpc.BatchOpenChannel.prototype.getNodePubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNodePubkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.BatchOpenChannel.prototype.setNodePubkey = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 local_funding_amount = 2;
 * @return {string}
 */
proto.lnrpc.BatchOpenChannel.prototype.getLocalFundingAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.BatchOpenChannel.prototype.setLocalFundingAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 push_sat = 3;
 * @return {string}
 */
proto.lnrpc.BatchOpenChannel.prototype.getPushSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.BatchOpenChannel.prototype.setPushSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional bool private = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.BatchOpenChannel.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.lnrpc.BatchOpenChannel.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 min_htlc_msat = 5;
 * @return {string}
 */
proto.lnrpc.BatchOpenChannel.prototype.getMinHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.BatchOpenChannel.prototype.setMinHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint32 remote_csv_delay = 6;
 * @return {number}
 */
proto.lnrpc.BatchOpenChannel.prototype.getRemoteCsvDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.BatchOpenChannel.prototype.setRemoteCsvDelay = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string close_address = 7;
 * @return {string}
 */
proto.lnrpc.BatchOpenChannel.prototype.getCloseAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.lnrpc.BatchOpenChannel.prototype.setCloseAddress = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bytes pending_chan_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.BatchOpenChannel.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes pending_chan_id = 8;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.BatchOpenChannel.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.BatchOpenChannel.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.BatchOpenChannel.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional CommitmentType commitment_type = 9;
 * @return {!proto.lnrpc.CommitmentType}
 */
proto.lnrpc.BatchOpenChannel.prototype.getCommitmentType = function() {
  return /** @type {!proto.lnrpc.CommitmentType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.lnrpc.CommitmentType} value */
proto.lnrpc.BatchOpenChannel.prototype.setCommitmentType = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.BatchOpenChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.BatchOpenChannelResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.BatchOpenChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.BatchOpenChannelResponse.displayName = 'proto.lnrpc.BatchOpenChannelResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.BatchOpenChannelResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.BatchOpenChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.BatchOpenChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.BatchOpenChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BatchOpenChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingChannelsList: jspb.Message.toObjectList(msg.getPendingChannelsList(),
    proto.lnrpc.PendingUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.BatchOpenChannelResponse}
 */
proto.lnrpc.BatchOpenChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.BatchOpenChannelResponse;
  return proto.lnrpc.BatchOpenChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.BatchOpenChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.BatchOpenChannelResponse}
 */
proto.lnrpc.BatchOpenChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingUpdate;
      reader.readMessage(value,proto.lnrpc.PendingUpdate.deserializeBinaryFromReader);
      msg.addPendingChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.BatchOpenChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.BatchOpenChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.BatchOpenChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BatchOpenChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.PendingUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingUpdate pending_channels = 1;
 * @return {!Array<!proto.lnrpc.PendingUpdate>}
 */
proto.lnrpc.BatchOpenChannelResponse.prototype.getPendingChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.PendingUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.PendingUpdate, 1));
};


/** @param {!Array<!proto.lnrpc.PendingUpdate>} value */
proto.lnrpc.BatchOpenChannelResponse.prototype.setPendingChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.PendingUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.PendingUpdate}
 */
proto.lnrpc.BatchOpenChannelResponse.prototype.addPendingChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.PendingUpdate, opt_index);
};


proto.lnrpc.BatchOpenChannelResponse.prototype.clearPendingChannelsList = function() {
  this.setPendingChannelsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.OpenChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.OpenChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.OpenChannelRequest.displayName = 'proto.lnrpc.OpenChannelRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.OpenChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.OpenChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.OpenChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OpenChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    satPerVbyte: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    nodePubkey: msg.getNodePubkey_asB64(),
    nodePubkeyString: jspb.Message.getFieldWithDefault(msg, 3, ""),
    localFundingAmount: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    pushSat: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    targetConf: jspb.Message.getFieldWithDefault(msg, 6, 0),
    satPerByte: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    pb_private: jspb.Message.getFieldWithDefault(msg, 8, false),
    minHtlcMsat: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    remoteCsvDelay: jspb.Message.getFieldWithDefault(msg, 10, 0),
    minConfs: jspb.Message.getFieldWithDefault(msg, 11, 0),
    spendUnconfirmed: jspb.Message.getFieldWithDefault(msg, 12, false),
    closeAddress: jspb.Message.getFieldWithDefault(msg, 13, ""),
    fundingShim: (f = msg.getFundingShim()) && proto.lnrpc.FundingShim.toObject(includeInstance, f),
    remoteMaxValueInFlightMsat: jspb.Message.getFieldWithDefault(msg, 15, "0"),
    remoteMaxHtlcs: jspb.Message.getFieldWithDefault(msg, 16, 0),
    maxLocalCsv: jspb.Message.getFieldWithDefault(msg, 17, 0),
    commitmentType: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.OpenChannelRequest}
 */
proto.lnrpc.OpenChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.OpenChannelRequest;
  return proto.lnrpc.OpenChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.OpenChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.OpenChannelRequest}
 */
proto.lnrpc.OpenChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSatPerVbyte(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNodePubkey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodePubkeyString(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLocalFundingAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setPushSat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetConf(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSatPerByte(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMinHtlcMsat(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemoteCsvDelay(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinConfs(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpendUnconfirmed(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCloseAddress(value);
      break;
    case 14:
      var value = new proto.lnrpc.FundingShim;
      reader.readMessage(value,proto.lnrpc.FundingShim.deserializeBinaryFromReader);
      msg.setFundingShim(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRemoteMaxValueInFlightMsat(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemoteMaxHtlcs(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxLocalCsv(value);
      break;
    case 18:
      var value = /** @type {!proto.lnrpc.CommitmentType} */ (reader.readEnum());
      msg.setCommitmentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.OpenChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.OpenChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.OpenChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OpenChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSatPerVbyte();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getNodePubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getNodePubkeyString();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocalFundingAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getPushSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getTargetConf();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSatPerByte();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMinHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getRemoteCsvDelay();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getMinConfs();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getSpendUnconfirmed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCloseAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFundingShim();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.lnrpc.FundingShim.serializeBinaryToWriter
    );
  }
  f = message.getRemoteMaxValueInFlightMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      15,
      f
    );
  }
  f = message.getRemoteMaxHtlcs();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getMaxLocalCsv();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getCommitmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * optional uint64 sat_per_vbyte = 1;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getSatPerVbyte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setSatPerVbyte = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional bytes node_pubkey = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.OpenChannelRequest.prototype.getNodePubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes node_pubkey = 2;
 * This is a type-conversion wrapper around `getNodePubkey()`
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getNodePubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNodePubkey()));
};


/**
 * optional bytes node_pubkey = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNodePubkey()`
 * @return {!Uint8Array}
 */
proto.lnrpc.OpenChannelRequest.prototype.getNodePubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNodePubkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.OpenChannelRequest.prototype.setNodePubkey = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string node_pubkey_string = 3;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getNodePubkeyString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setNodePubkeyString = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 local_funding_amount = 4;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getLocalFundingAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setLocalFundingAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 push_sat = 5;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getPushSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setPushSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int32 target_conf = 6;
 * @return {number}
 */
proto.lnrpc.OpenChannelRequest.prototype.getTargetConf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.OpenChannelRequest.prototype.setTargetConf = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 sat_per_byte = 7;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getSatPerByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setSatPerByte = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional bool private = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.OpenChannelRequest.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.lnrpc.OpenChannelRequest.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 min_htlc_msat = 9;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getMinHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setMinHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint32 remote_csv_delay = 10;
 * @return {number}
 */
proto.lnrpc.OpenChannelRequest.prototype.getRemoteCsvDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.lnrpc.OpenChannelRequest.prototype.setRemoteCsvDelay = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 min_confs = 11;
 * @return {number}
 */
proto.lnrpc.OpenChannelRequest.prototype.getMinConfs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.lnrpc.OpenChannelRequest.prototype.setMinConfs = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool spend_unconfirmed = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.OpenChannelRequest.prototype.getSpendUnconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.lnrpc.OpenChannelRequest.prototype.setSpendUnconfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string close_address = 13;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getCloseAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setCloseAddress = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional FundingShim funding_shim = 14;
 * @return {?proto.lnrpc.FundingShim}
 */
proto.lnrpc.OpenChannelRequest.prototype.getFundingShim = function() {
  return /** @type{?proto.lnrpc.FundingShim} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FundingShim, 14));
};


/** @param {?proto.lnrpc.FundingShim|undefined} value */
proto.lnrpc.OpenChannelRequest.prototype.setFundingShim = function(value) {
  jspb.Message.setWrapperField(this, 14, value);
};


proto.lnrpc.OpenChannelRequest.prototype.clearFundingShim = function() {
  this.setFundingShim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.OpenChannelRequest.prototype.hasFundingShim = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional uint64 remote_max_value_in_flight_msat = 15;
 * @return {string}
 */
proto.lnrpc.OpenChannelRequest.prototype.getRemoteMaxValueInFlightMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, "0"));
};


/** @param {string} value */
proto.lnrpc.OpenChannelRequest.prototype.setRemoteMaxValueInFlightMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 15, value);
};


/**
 * optional uint32 remote_max_htlcs = 16;
 * @return {number}
 */
proto.lnrpc.OpenChannelRequest.prototype.getRemoteMaxHtlcs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.lnrpc.OpenChannelRequest.prototype.setRemoteMaxHtlcs = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 max_local_csv = 17;
 * @return {number}
 */
proto.lnrpc.OpenChannelRequest.prototype.getMaxLocalCsv = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.lnrpc.OpenChannelRequest.prototype.setMaxLocalCsv = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional CommitmentType commitment_type = 18;
 * @return {!proto.lnrpc.CommitmentType}
 */
proto.lnrpc.OpenChannelRequest.prototype.getCommitmentType = function() {
  return /** @type {!proto.lnrpc.CommitmentType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {!proto.lnrpc.CommitmentType} value */
proto.lnrpc.OpenChannelRequest.prototype.setCommitmentType = function(value) {
  jspb.Message.setProto3EnumField(this, 18, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.OpenStatusUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.OpenStatusUpdate.oneofGroups_);
};
goog.inherits(proto.lnrpc.OpenStatusUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.OpenStatusUpdate.displayName = 'proto.lnrpc.OpenStatusUpdate';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.OpenStatusUpdate.oneofGroups_ = [[1,3,5]];

/**
 * @enum {number}
 */
proto.lnrpc.OpenStatusUpdate.UpdateCase = {
  UPDATE_NOT_SET: 0,
  CHAN_PENDING: 1,
  CHAN_OPEN: 3,
  PSBT_FUND: 5
};

/**
 * @return {proto.lnrpc.OpenStatusUpdate.UpdateCase}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getUpdateCase = function() {
  return /** @type {proto.lnrpc.OpenStatusUpdate.UpdateCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.OpenStatusUpdate.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.OpenStatusUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.OpenStatusUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.OpenStatusUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OpenStatusUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanPending: (f = msg.getChanPending()) && proto.lnrpc.PendingUpdate.toObject(includeInstance, f),
    chanOpen: (f = msg.getChanOpen()) && proto.lnrpc.ChannelOpenUpdate.toObject(includeInstance, f),
    psbtFund: (f = msg.getPsbtFund()) && proto.lnrpc.ReadyForPsbtFunding.toObject(includeInstance, f),
    pendingChanId: msg.getPendingChanId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.OpenStatusUpdate}
 */
proto.lnrpc.OpenStatusUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.OpenStatusUpdate;
  return proto.lnrpc.OpenStatusUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.OpenStatusUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.OpenStatusUpdate}
 */
proto.lnrpc.OpenStatusUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingUpdate;
      reader.readMessage(value,proto.lnrpc.PendingUpdate.deserializeBinaryFromReader);
      msg.setChanPending(value);
      break;
    case 3:
      var value = new proto.lnrpc.ChannelOpenUpdate;
      reader.readMessage(value,proto.lnrpc.ChannelOpenUpdate.deserializeBinaryFromReader);
      msg.setChanOpen(value);
      break;
    case 5:
      var value = new proto.lnrpc.ReadyForPsbtFunding;
      reader.readMessage(value,proto.lnrpc.ReadyForPsbtFunding.deserializeBinaryFromReader);
      msg.setPsbtFund(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.OpenStatusUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.OpenStatusUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.OpenStatusUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.OpenStatusUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanPending();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.PendingUpdate.serializeBinaryToWriter
    );
  }
  f = message.getChanOpen();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.ChannelOpenUpdate.serializeBinaryToWriter
    );
  }
  f = message.getPsbtFund();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.lnrpc.ReadyForPsbtFunding.serializeBinaryToWriter
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional PendingUpdate chan_pending = 1;
 * @return {?proto.lnrpc.PendingUpdate}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getChanPending = function() {
  return /** @type{?proto.lnrpc.PendingUpdate} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingUpdate, 1));
};


/** @param {?proto.lnrpc.PendingUpdate|undefined} value */
proto.lnrpc.OpenStatusUpdate.prototype.setChanPending = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.lnrpc.OpenStatusUpdate.oneofGroups_[0], value);
};


proto.lnrpc.OpenStatusUpdate.prototype.clearChanPending = function() {
  this.setChanPending(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.OpenStatusUpdate.prototype.hasChanPending = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChannelOpenUpdate chan_open = 3;
 * @return {?proto.lnrpc.ChannelOpenUpdate}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getChanOpen = function() {
  return /** @type{?proto.lnrpc.ChannelOpenUpdate} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelOpenUpdate, 3));
};


/** @param {?proto.lnrpc.ChannelOpenUpdate|undefined} value */
proto.lnrpc.OpenStatusUpdate.prototype.setChanOpen = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.lnrpc.OpenStatusUpdate.oneofGroups_[0], value);
};


proto.lnrpc.OpenStatusUpdate.prototype.clearChanOpen = function() {
  this.setChanOpen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.OpenStatusUpdate.prototype.hasChanOpen = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReadyForPsbtFunding psbt_fund = 5;
 * @return {?proto.lnrpc.ReadyForPsbtFunding}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getPsbtFund = function() {
  return /** @type{?proto.lnrpc.ReadyForPsbtFunding} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ReadyForPsbtFunding, 5));
};


/** @param {?proto.lnrpc.ReadyForPsbtFunding|undefined} value */
proto.lnrpc.OpenStatusUpdate.prototype.setPsbtFund = function(value) {
  jspb.Message.setOneofWrapperField(this, 5, proto.lnrpc.OpenStatusUpdate.oneofGroups_[0], value);
};


proto.lnrpc.OpenStatusUpdate.prototype.clearPsbtFund = function() {
  this.setPsbtFund(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.OpenStatusUpdate.prototype.hasPsbtFund = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes pending_chan_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes pending_chan_id = 4;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.OpenStatusUpdate.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.OpenStatusUpdate.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.KeyLocator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.KeyLocator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.KeyLocator.displayName = 'proto.lnrpc.KeyLocator';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.KeyLocator.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.KeyLocator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.KeyLocator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.KeyLocator.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyFamily: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keyIndex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.KeyLocator}
 */
proto.lnrpc.KeyLocator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.KeyLocator;
  return proto.lnrpc.KeyLocator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.KeyLocator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.KeyLocator}
 */
proto.lnrpc.KeyLocator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeyFamily(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeyIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.KeyLocator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.KeyLocator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.KeyLocator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.KeyLocator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyFamily();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKeyIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 key_family = 1;
 * @return {number}
 */
proto.lnrpc.KeyLocator.prototype.getKeyFamily = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.KeyLocator.prototype.setKeyFamily = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 key_index = 2;
 * @return {number}
 */
proto.lnrpc.KeyLocator.prototype.getKeyIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.KeyLocator.prototype.setKeyIndex = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.KeyDescriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.KeyDescriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.KeyDescriptor.displayName = 'proto.lnrpc.KeyDescriptor';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.KeyDescriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.KeyDescriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.KeyDescriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.KeyDescriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    rawKeyBytes: msg.getRawKeyBytes_asB64(),
    keyLoc: (f = msg.getKeyLoc()) && proto.lnrpc.KeyLocator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.KeyDescriptor}
 */
proto.lnrpc.KeyDescriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.KeyDescriptor;
  return proto.lnrpc.KeyDescriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.KeyDescriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.KeyDescriptor}
 */
proto.lnrpc.KeyDescriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawKeyBytes(value);
      break;
    case 2:
      var value = new proto.lnrpc.KeyLocator;
      reader.readMessage(value,proto.lnrpc.KeyLocator.deserializeBinaryFromReader);
      msg.setKeyLoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.KeyDescriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.KeyDescriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.KeyDescriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.KeyDescriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRawKeyBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getKeyLoc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.KeyLocator.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes raw_key_bytes = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.KeyDescriptor.prototype.getRawKeyBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes raw_key_bytes = 1;
 * This is a type-conversion wrapper around `getRawKeyBytes()`
 * @return {string}
 */
proto.lnrpc.KeyDescriptor.prototype.getRawKeyBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawKeyBytes()));
};


/**
 * optional bytes raw_key_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawKeyBytes()`
 * @return {!Uint8Array}
 */
proto.lnrpc.KeyDescriptor.prototype.getRawKeyBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawKeyBytes()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.KeyDescriptor.prototype.setRawKeyBytes = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional KeyLocator key_loc = 2;
 * @return {?proto.lnrpc.KeyLocator}
 */
proto.lnrpc.KeyDescriptor.prototype.getKeyLoc = function() {
  return /** @type{?proto.lnrpc.KeyLocator} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.KeyLocator, 2));
};


/** @param {?proto.lnrpc.KeyLocator|undefined} value */
proto.lnrpc.KeyDescriptor.prototype.setKeyLoc = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.lnrpc.KeyDescriptor.prototype.clearKeyLoc = function() {
  this.setKeyLoc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.KeyDescriptor.prototype.hasKeyLoc = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChanPointShim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChanPointShim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChanPointShim.displayName = 'proto.lnrpc.ChanPointShim';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChanPointShim.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChanPointShim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChanPointShim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanPointShim.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    chanPoint: (f = msg.getChanPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    localKey: (f = msg.getLocalKey()) && proto.lnrpc.KeyDescriptor.toObject(includeInstance, f),
    remoteKey: msg.getRemoteKey_asB64(),
    pendingChanId: msg.getPendingChanId_asB64(),
    thawHeight: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChanPointShim}
 */
proto.lnrpc.ChanPointShim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChanPointShim;
  return proto.lnrpc.ChanPointShim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChanPointShim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChanPointShim}
 */
proto.lnrpc.ChanPointShim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 2:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChanPoint(value);
      break;
    case 3:
      var value = new proto.lnrpc.KeyDescriptor;
      reader.readMessage(value,proto.lnrpc.KeyDescriptor.deserializeBinaryFromReader);
      msg.setLocalKey(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRemoteKey(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setThawHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChanPointShim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChanPointShim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChanPointShim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanPointShim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getChanPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getLocalKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.KeyDescriptor.serializeBinaryToWriter
    );
  }
  f = message.getRemoteKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getThawHeight();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional int64 amt = 1;
 * @return {string}
 */
proto.lnrpc.ChanPointShim.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ChanPointShim.prototype.setAmt = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional ChannelPoint chan_point = 2;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChanPointShim.prototype.getChanPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 2));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChanPointShim.prototype.setChanPoint = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.lnrpc.ChanPointShim.prototype.clearChanPoint = function() {
  this.setChanPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChanPointShim.prototype.hasChanPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional KeyDescriptor local_key = 3;
 * @return {?proto.lnrpc.KeyDescriptor}
 */
proto.lnrpc.ChanPointShim.prototype.getLocalKey = function() {
  return /** @type{?proto.lnrpc.KeyDescriptor} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.KeyDescriptor, 3));
};


/** @param {?proto.lnrpc.KeyDescriptor|undefined} value */
proto.lnrpc.ChanPointShim.prototype.setLocalKey = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.lnrpc.ChanPointShim.prototype.clearLocalKey = function() {
  this.setLocalKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChanPointShim.prototype.hasLocalKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes remote_key = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChanPointShim.prototype.getRemoteKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes remote_key = 4;
 * This is a type-conversion wrapper around `getRemoteKey()`
 * @return {string}
 */
proto.lnrpc.ChanPointShim.prototype.getRemoteKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRemoteKey()));
};


/**
 * optional bytes remote_key = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRemoteKey()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChanPointShim.prototype.getRemoteKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRemoteKey()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChanPointShim.prototype.setRemoteKey = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes pending_chan_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChanPointShim.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes pending_chan_id = 5;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.ChanPointShim.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChanPointShim.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChanPointShim.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional uint32 thaw_height = 6;
 * @return {number}
 */
proto.lnrpc.ChanPointShim.prototype.getThawHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.ChanPointShim.prototype.setThawHeight = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PsbtShim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PsbtShim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PsbtShim.displayName = 'proto.lnrpc.PsbtShim';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PsbtShim.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PsbtShim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PsbtShim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PsbtShim.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingChanId: msg.getPendingChanId_asB64(),
    basePsbt: msg.getBasePsbt_asB64(),
    noPublish: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PsbtShim}
 */
proto.lnrpc.PsbtShim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PsbtShim;
  return proto.lnrpc.PsbtShim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PsbtShim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PsbtShim}
 */
proto.lnrpc.PsbtShim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBasePsbt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoPublish(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PsbtShim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PsbtShim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PsbtShim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PsbtShim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getBasePsbt_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getNoPublish();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bytes pending_chan_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.PsbtShim.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes pending_chan_id = 1;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.PsbtShim.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.PsbtShim.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.PsbtShim.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes base_psbt = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.PsbtShim.prototype.getBasePsbt = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes base_psbt = 2;
 * This is a type-conversion wrapper around `getBasePsbt()`
 * @return {string}
 */
proto.lnrpc.PsbtShim.prototype.getBasePsbt_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBasePsbt()));
};


/**
 * optional bytes base_psbt = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBasePsbt()`
 * @return {!Uint8Array}
 */
proto.lnrpc.PsbtShim.prototype.getBasePsbt_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBasePsbt()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.PsbtShim.prototype.setBasePsbt = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bool no_publish = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.PsbtShim.prototype.getNoPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.PsbtShim.prototype.setNoPublish = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FundingShim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.FundingShim.oneofGroups_);
};
goog.inherits(proto.lnrpc.FundingShim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FundingShim.displayName = 'proto.lnrpc.FundingShim';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.FundingShim.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.lnrpc.FundingShim.ShimCase = {
  SHIM_NOT_SET: 0,
  CHAN_POINT_SHIM: 1,
  PSBT_SHIM: 2
};

/**
 * @return {proto.lnrpc.FundingShim.ShimCase}
 */
proto.lnrpc.FundingShim.prototype.getShimCase = function() {
  return /** @type {proto.lnrpc.FundingShim.ShimCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.FundingShim.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FundingShim.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FundingShim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FundingShim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingShim.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanPointShim: (f = msg.getChanPointShim()) && proto.lnrpc.ChanPointShim.toObject(includeInstance, f),
    psbtShim: (f = msg.getPsbtShim()) && proto.lnrpc.PsbtShim.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FundingShim}
 */
proto.lnrpc.FundingShim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FundingShim;
  return proto.lnrpc.FundingShim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FundingShim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FundingShim}
 */
proto.lnrpc.FundingShim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChanPointShim;
      reader.readMessage(value,proto.lnrpc.ChanPointShim.deserializeBinaryFromReader);
      msg.setChanPointShim(value);
      break;
    case 2:
      var value = new proto.lnrpc.PsbtShim;
      reader.readMessage(value,proto.lnrpc.PsbtShim.deserializeBinaryFromReader);
      msg.setPsbtShim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingShim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FundingShim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FundingShim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingShim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanPointShim();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChanPointShim.serializeBinaryToWriter
    );
  }
  f = message.getPsbtShim();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.PsbtShim.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChanPointShim chan_point_shim = 1;
 * @return {?proto.lnrpc.ChanPointShim}
 */
proto.lnrpc.FundingShim.prototype.getChanPointShim = function() {
  return /** @type{?proto.lnrpc.ChanPointShim} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChanPointShim, 1));
};


/** @param {?proto.lnrpc.ChanPointShim|undefined} value */
proto.lnrpc.FundingShim.prototype.setChanPointShim = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.lnrpc.FundingShim.oneofGroups_[0], value);
};


proto.lnrpc.FundingShim.prototype.clearChanPointShim = function() {
  this.setChanPointShim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FundingShim.prototype.hasChanPointShim = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PsbtShim psbt_shim = 2;
 * @return {?proto.lnrpc.PsbtShim}
 */
proto.lnrpc.FundingShim.prototype.getPsbtShim = function() {
  return /** @type{?proto.lnrpc.PsbtShim} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PsbtShim, 2));
};


/** @param {?proto.lnrpc.PsbtShim|undefined} value */
proto.lnrpc.FundingShim.prototype.setPsbtShim = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.lnrpc.FundingShim.oneofGroups_[0], value);
};


proto.lnrpc.FundingShim.prototype.clearPsbtShim = function() {
  this.setPsbtShim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FundingShim.prototype.hasPsbtShim = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FundingShimCancel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FundingShimCancel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FundingShimCancel.displayName = 'proto.lnrpc.FundingShimCancel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FundingShimCancel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FundingShimCancel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FundingShimCancel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingShimCancel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingChanId: msg.getPendingChanId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FundingShimCancel}
 */
proto.lnrpc.FundingShimCancel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FundingShimCancel;
  return proto.lnrpc.FundingShimCancel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FundingShimCancel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FundingShimCancel}
 */
proto.lnrpc.FundingShimCancel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingShimCancel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FundingShimCancel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FundingShimCancel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingShimCancel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes pending_chan_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.FundingShimCancel.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes pending_chan_id = 1;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.FundingShimCancel.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingShimCancel.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.FundingShimCancel.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FundingPsbtVerify = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FundingPsbtVerify, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FundingPsbtVerify.displayName = 'proto.lnrpc.FundingPsbtVerify';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FundingPsbtVerify.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FundingPsbtVerify.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FundingPsbtVerify} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingPsbtVerify.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundedPsbt: msg.getFundedPsbt_asB64(),
    pendingChanId: msg.getPendingChanId_asB64(),
    skipFinalize: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FundingPsbtVerify}
 */
proto.lnrpc.FundingPsbtVerify.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FundingPsbtVerify;
  return proto.lnrpc.FundingPsbtVerify.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FundingPsbtVerify} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FundingPsbtVerify}
 */
proto.lnrpc.FundingPsbtVerify.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFundedPsbt(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipFinalize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtVerify.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FundingPsbtVerify.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FundingPsbtVerify} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingPsbtVerify.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundedPsbt_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSkipFinalize();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bytes funded_psbt = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getFundedPsbt = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes funded_psbt = 1;
 * This is a type-conversion wrapper around `getFundedPsbt()`
 * @return {string}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getFundedPsbt_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFundedPsbt()));
};


/**
 * optional bytes funded_psbt = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFundedPsbt()`
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getFundedPsbt_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFundedPsbt()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.FundingPsbtVerify.prototype.setFundedPsbt = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes pending_chan_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes pending_chan_id = 2;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.FundingPsbtVerify.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bool skip_finalize = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.FundingPsbtVerify.prototype.getSkipFinalize = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.FundingPsbtVerify.prototype.setSkipFinalize = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FundingPsbtFinalize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FundingPsbtFinalize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FundingPsbtFinalize.displayName = 'proto.lnrpc.FundingPsbtFinalize';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FundingPsbtFinalize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FundingPsbtFinalize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingPsbtFinalize.toObject = function(includeInstance, msg) {
  var f, obj = {
    signedPsbt: msg.getSignedPsbt_asB64(),
    pendingChanId: msg.getPendingChanId_asB64(),
    finalRawTx: msg.getFinalRawTx_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FundingPsbtFinalize}
 */
proto.lnrpc.FundingPsbtFinalize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FundingPsbtFinalize;
  return proto.lnrpc.FundingPsbtFinalize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FundingPsbtFinalize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FundingPsbtFinalize}
 */
proto.lnrpc.FundingPsbtFinalize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignedPsbt(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPendingChanId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFinalRawTx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FundingPsbtFinalize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FundingPsbtFinalize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingPsbtFinalize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignedPsbt_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPendingChanId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFinalRawTx_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes signed_psbt = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getSignedPsbt = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes signed_psbt = 1;
 * This is a type-conversion wrapper around `getSignedPsbt()`
 * @return {string}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getSignedPsbt_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignedPsbt()));
};


/**
 * optional bytes signed_psbt = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignedPsbt()`
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getSignedPsbt_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignedPsbt()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.FundingPsbtFinalize.prototype.setSignedPsbt = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes pending_chan_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getPendingChanId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes pending_chan_id = 2;
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {string}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getPendingChanId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPendingChanId()));
};


/**
 * optional bytes pending_chan_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPendingChanId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getPendingChanId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPendingChanId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.FundingPsbtFinalize.prototype.setPendingChanId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes final_raw_tx = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getFinalRawTx = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes final_raw_tx = 3;
 * This is a type-conversion wrapper around `getFinalRawTx()`
 * @return {string}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getFinalRawTx_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFinalRawTx()));
};


/**
 * optional bytes final_raw_tx = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFinalRawTx()`
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingPsbtFinalize.prototype.getFinalRawTx_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFinalRawTx()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.FundingPsbtFinalize.prototype.setFinalRawTx = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FundingTransitionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.FundingTransitionMsg.oneofGroups_);
};
goog.inherits(proto.lnrpc.FundingTransitionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FundingTransitionMsg.displayName = 'proto.lnrpc.FundingTransitionMsg';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.FundingTransitionMsg.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.lnrpc.FundingTransitionMsg.TriggerCase = {
  TRIGGER_NOT_SET: 0,
  SHIM_REGISTER: 1,
  SHIM_CANCEL: 2,
  PSBT_VERIFY: 3,
  PSBT_FINALIZE: 4
};

/**
 * @return {proto.lnrpc.FundingTransitionMsg.TriggerCase}
 */
proto.lnrpc.FundingTransitionMsg.prototype.getTriggerCase = function() {
  return /** @type {proto.lnrpc.FundingTransitionMsg.TriggerCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.FundingTransitionMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FundingTransitionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FundingTransitionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FundingTransitionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingTransitionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    shimRegister: (f = msg.getShimRegister()) && proto.lnrpc.FundingShim.toObject(includeInstance, f),
    shimCancel: (f = msg.getShimCancel()) && proto.lnrpc.FundingShimCancel.toObject(includeInstance, f),
    psbtVerify: (f = msg.getPsbtVerify()) && proto.lnrpc.FundingPsbtVerify.toObject(includeInstance, f),
    psbtFinalize: (f = msg.getPsbtFinalize()) && proto.lnrpc.FundingPsbtFinalize.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FundingTransitionMsg}
 */
proto.lnrpc.FundingTransitionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FundingTransitionMsg;
  return proto.lnrpc.FundingTransitionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FundingTransitionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FundingTransitionMsg}
 */
proto.lnrpc.FundingTransitionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.FundingShim;
      reader.readMessage(value,proto.lnrpc.FundingShim.deserializeBinaryFromReader);
      msg.setShimRegister(value);
      break;
    case 2:
      var value = new proto.lnrpc.FundingShimCancel;
      reader.readMessage(value,proto.lnrpc.FundingShimCancel.deserializeBinaryFromReader);
      msg.setShimCancel(value);
      break;
    case 3:
      var value = new proto.lnrpc.FundingPsbtVerify;
      reader.readMessage(value,proto.lnrpc.FundingPsbtVerify.deserializeBinaryFromReader);
      msg.setPsbtVerify(value);
      break;
    case 4:
      var value = new proto.lnrpc.FundingPsbtFinalize;
      reader.readMessage(value,proto.lnrpc.FundingPsbtFinalize.deserializeBinaryFromReader);
      msg.setPsbtFinalize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingTransitionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FundingTransitionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FundingTransitionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingTransitionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShimRegister();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.FundingShim.serializeBinaryToWriter
    );
  }
  f = message.getShimCancel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.FundingShimCancel.serializeBinaryToWriter
    );
  }
  f = message.getPsbtVerify();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.FundingPsbtVerify.serializeBinaryToWriter
    );
  }
  f = message.getPsbtFinalize();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.FundingPsbtFinalize.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingShim shim_register = 1;
 * @return {?proto.lnrpc.FundingShim}
 */
proto.lnrpc.FundingTransitionMsg.prototype.getShimRegister = function() {
  return /** @type{?proto.lnrpc.FundingShim} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FundingShim, 1));
};


/** @param {?proto.lnrpc.FundingShim|undefined} value */
proto.lnrpc.FundingTransitionMsg.prototype.setShimRegister = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.lnrpc.FundingTransitionMsg.oneofGroups_[0], value);
};


proto.lnrpc.FundingTransitionMsg.prototype.clearShimRegister = function() {
  this.setShimRegister(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FundingTransitionMsg.prototype.hasShimRegister = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FundingShimCancel shim_cancel = 2;
 * @return {?proto.lnrpc.FundingShimCancel}
 */
proto.lnrpc.FundingTransitionMsg.prototype.getShimCancel = function() {
  return /** @type{?proto.lnrpc.FundingShimCancel} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FundingShimCancel, 2));
};


/** @param {?proto.lnrpc.FundingShimCancel|undefined} value */
proto.lnrpc.FundingTransitionMsg.prototype.setShimCancel = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.lnrpc.FundingTransitionMsg.oneofGroups_[0], value);
};


proto.lnrpc.FundingTransitionMsg.prototype.clearShimCancel = function() {
  this.setShimCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FundingTransitionMsg.prototype.hasShimCancel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FundingPsbtVerify psbt_verify = 3;
 * @return {?proto.lnrpc.FundingPsbtVerify}
 */
proto.lnrpc.FundingTransitionMsg.prototype.getPsbtVerify = function() {
  return /** @type{?proto.lnrpc.FundingPsbtVerify} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FundingPsbtVerify, 3));
};


/** @param {?proto.lnrpc.FundingPsbtVerify|undefined} value */
proto.lnrpc.FundingTransitionMsg.prototype.setPsbtVerify = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.lnrpc.FundingTransitionMsg.oneofGroups_[0], value);
};


proto.lnrpc.FundingTransitionMsg.prototype.clearPsbtVerify = function() {
  this.setPsbtVerify(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FundingTransitionMsg.prototype.hasPsbtVerify = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FundingPsbtFinalize psbt_finalize = 4;
 * @return {?proto.lnrpc.FundingPsbtFinalize}
 */
proto.lnrpc.FundingTransitionMsg.prototype.getPsbtFinalize = function() {
  return /** @type{?proto.lnrpc.FundingPsbtFinalize} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FundingPsbtFinalize, 4));
};


/** @param {?proto.lnrpc.FundingPsbtFinalize|undefined} value */
proto.lnrpc.FundingTransitionMsg.prototype.setPsbtFinalize = function(value) {
  jspb.Message.setOneofWrapperField(this, 4, proto.lnrpc.FundingTransitionMsg.oneofGroups_[0], value);
};


proto.lnrpc.FundingTransitionMsg.prototype.clearPsbtFinalize = function() {
  this.setPsbtFinalize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FundingTransitionMsg.prototype.hasPsbtFinalize = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FundingStateStepResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FundingStateStepResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FundingStateStepResp.displayName = 'proto.lnrpc.FundingStateStepResp';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FundingStateStepResp.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FundingStateStepResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FundingStateStepResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingStateStepResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FundingStateStepResp}
 */
proto.lnrpc.FundingStateStepResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FundingStateStepResp;
  return proto.lnrpc.FundingStateStepResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FundingStateStepResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FundingStateStepResp}
 */
proto.lnrpc.FundingStateStepResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FundingStateStepResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FundingStateStepResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FundingStateStepResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FundingStateStepResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingHTLC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingHTLC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingHTLC.displayName = 'proto.lnrpc.PendingHTLC';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingHTLC.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingHTLC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingHTLC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingHTLC.toObject = function(includeInstance, msg) {
  var f, obj = {
    incoming: jspb.Message.getFieldWithDefault(msg, 1, false),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    outpoint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    maturityHeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blocksTilMaturity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    stage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingHTLC}
 */
proto.lnrpc.PendingHTLC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingHTLC;
  return proto.lnrpc.PendingHTLC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingHTLC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingHTLC}
 */
proto.lnrpc.PendingHTLC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncoming(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutpoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaturityHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlocksTilMaturity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingHTLC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingHTLC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingHTLC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingHTLC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncoming();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAmount();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getOutpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMaturityHeight();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBlocksTilMaturity();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStage();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional bool incoming = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.PendingHTLC.prototype.getIncoming = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.PendingHTLC.prototype.setIncoming = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {string}
 */
proto.lnrpc.PendingHTLC.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingHTLC.prototype.setAmount = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional string outpoint = 3;
 * @return {string}
 */
proto.lnrpc.PendingHTLC.prototype.getOutpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.PendingHTLC.prototype.setOutpoint = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 maturity_height = 4;
 * @return {number}
 */
proto.lnrpc.PendingHTLC.prototype.getMaturityHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.PendingHTLC.prototype.setMaturityHeight = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 blocks_til_maturity = 5;
 * @return {number}
 */
proto.lnrpc.PendingHTLC.prototype.getBlocksTilMaturity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.PendingHTLC.prototype.setBlocksTilMaturity = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 stage = 6;
 * @return {number}
 */
proto.lnrpc.PendingHTLC.prototype.getStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.PendingHTLC.prototype.setStage = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsRequest.displayName = 'proto.lnrpc.PendingChannelsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsRequest}
 */
proto.lnrpc.PendingChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsRequest;
  return proto.lnrpc.PendingChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsRequest}
 */
proto.lnrpc.PendingChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.PendingChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.displayName = 'proto.lnrpc.PendingChannelsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.PendingChannelsResponse.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalLimboBalance: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    pendingOpenChannelsList: jspb.Message.toObjectList(msg.getPendingOpenChannelsList(),
    proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.toObject, includeInstance),
    pendingClosingChannelsList: jspb.Message.toObjectList(msg.getPendingClosingChannelsList(),
    proto.lnrpc.PendingChannelsResponse.ClosedChannel.toObject, includeInstance),
    pendingForceClosingChannelsList: jspb.Message.toObjectList(msg.getPendingForceClosingChannelsList(),
    proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.toObject, includeInstance),
    waitingCloseChannelsList: jspb.Message.toObjectList(msg.getWaitingCloseChannelsList(),
    proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse}
 */
proto.lnrpc.PendingChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse;
  return proto.lnrpc.PendingChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse}
 */
proto.lnrpc.PendingChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalLimboBalance(value);
      break;
    case 2:
      var value = new proto.lnrpc.PendingChannelsResponse.PendingOpenChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.deserializeBinaryFromReader);
      msg.addPendingOpenChannels(value);
      break;
    case 3:
      var value = new proto.lnrpc.PendingChannelsResponse.ClosedChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.ClosedChannel.deserializeBinaryFromReader);
      msg.addPendingClosingChannels(value);
      break;
    case 4:
      var value = new proto.lnrpc.PendingChannelsResponse.ForceClosedChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.deserializeBinaryFromReader);
      msg.addPendingForceClosingChannels(value);
      break;
    case 5:
      var value = new proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.deserializeBinaryFromReader);
      msg.addWaitingCloseChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalLimboBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getPendingOpenChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.serializeBinaryToWriter
    );
  }
  f = message.getPendingClosingChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.lnrpc.PendingChannelsResponse.ClosedChannel.serializeBinaryToWriter
    );
  }
  f = message.getPendingForceClosingChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.serializeBinaryToWriter
    );
  }
  f = message.getWaitingCloseChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.serializeBinaryToWriter
    );
  }
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse.PendingChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.PendingChannel.displayName = 'proto.lnrpc.PendingChannelsResponse.PendingChannel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.PendingChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    remoteNodePub: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelPoint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    localBalance: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    remoteBalance: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    localChanReserveSat: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    remoteChanReserveSat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    initiator: jspb.Message.getFieldWithDefault(msg, 8, 0),
    commitmentType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    numForwardingPackages: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    chanStatusFlags: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pb_private: jspb.Message.getFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse.PendingChannel}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse.PendingChannel;
  return proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse.PendingChannel}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteNodePub(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelPoint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCapacity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLocalBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setRemoteBalance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLocalChanReserveSat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setRemoteChanReserveSat(value);
      break;
    case 8:
      var value = /** @type {!proto.lnrpc.Initiator} */ (reader.readEnum());
      msg.setInitiator(value);
      break;
    case 9:
      var value = /** @type {!proto.lnrpc.CommitmentType} */ (reader.readEnum());
      msg.setCommitmentType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setNumForwardingPackages(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setChanStatusFlags(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.PendingChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemoteNodePub();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelPoint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getLocalBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getRemoteBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getLocalChanReserveSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getRemoteChanReserveSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getInitiator();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCommitmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getNumForwardingPackages();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      10,
      f
    );
  }
  f = message.getChanStatusFlags();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string remote_node_pub = 1;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getRemoteNodePub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setRemoteNodePub = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_point = 2;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getChannelPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setChannelPoint = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 capacity = 3;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 local_balance = 4;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getLocalBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setLocalBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 remote_balance = 5;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getRemoteBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setRemoteBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 local_chan_reserve_sat = 6;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getLocalChanReserveSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setLocalChanReserveSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int64 remote_chan_reserve_sat = 7;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getRemoteChanReserveSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setRemoteChanReserveSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional Initiator initiator = 8;
 * @return {!proto.lnrpc.Initiator}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getInitiator = function() {
  return /** @type {!proto.lnrpc.Initiator} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.lnrpc.Initiator} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setInitiator = function(value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional CommitmentType commitment_type = 9;
 * @return {!proto.lnrpc.CommitmentType}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getCommitmentType = function() {
  return /** @type {!proto.lnrpc.CommitmentType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.lnrpc.CommitmentType} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setCommitmentType = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 num_forwarding_packages = 10;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getNumForwardingPackages = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setNumForwardingPackages = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional string chan_status_flags = 11;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getChanStatusFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setChanStatusFlags = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool private = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.lnrpc.PendingChannelsResponse.PendingChannel.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse.PendingOpenChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.displayName = 'proto.lnrpc.PendingChannelsResponse.PendingOpenChannel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && proto.lnrpc.PendingChannelsResponse.PendingChannel.toObject(includeInstance, f),
    commitFee: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    commitWeight: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    feePerKw: jspb.Message.getFieldWithDefault(msg, 6, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse.PendingOpenChannel;
  return proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingChannelsResponse.PendingChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCommitFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCommitWeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeePerKw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.PendingChannelsResponse.PendingChannel.serializeBinaryToWriter
    );
  }
  f = message.getCommitFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getCommitWeight();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getFeePerKw();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
};


/**
 * optional PendingChannel channel = 1;
 * @return {?proto.lnrpc.PendingChannelsResponse.PendingChannel}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.getChannel = function() {
  return /** @type{?proto.lnrpc.PendingChannelsResponse.PendingChannel} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingChannelsResponse.PendingChannel, 1));
};


/** @param {?proto.lnrpc.PendingChannelsResponse.PendingChannel|undefined} value */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.setChannel = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.clearChannel = function() {
  this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 commit_fee = 4;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.getCommitFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.setCommitFee = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 commit_weight = 5;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.getCommitWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.setCommitWeight = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 fee_per_kw = 6;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.getFeePerKw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.PendingOpenChannel.prototype.setFeePerKw = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.displayName = 'proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && proto.lnrpc.PendingChannelsResponse.PendingChannel.toObject(includeInstance, f),
    limboBalance: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    commitments: (f = msg.getCommitments()) && proto.lnrpc.PendingChannelsResponse.Commitments.toObject(includeInstance, f),
    closingTxid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel;
  return proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingChannelsResponse.PendingChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLimboBalance(value);
      break;
    case 3:
      var value = new proto.lnrpc.PendingChannelsResponse.Commitments;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.Commitments.deserializeBinaryFromReader);
      msg.setCommitments(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingTxid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.PendingChannelsResponse.PendingChannel.serializeBinaryToWriter
    );
  }
  f = message.getLimboBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getCommitments();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.PendingChannelsResponse.Commitments.serializeBinaryToWriter
    );
  }
  f = message.getClosingTxid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional PendingChannel channel = 1;
 * @return {?proto.lnrpc.PendingChannelsResponse.PendingChannel}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.getChannel = function() {
  return /** @type{?proto.lnrpc.PendingChannelsResponse.PendingChannel} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingChannelsResponse.PendingChannel, 1));
};


/** @param {?proto.lnrpc.PendingChannelsResponse.PendingChannel|undefined} value */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.setChannel = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.clearChannel = function() {
  this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 limbo_balance = 2;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.getLimboBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.setLimboBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional Commitments commitments = 3;
 * @return {?proto.lnrpc.PendingChannelsResponse.Commitments}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.getCommitments = function() {
  return /** @type{?proto.lnrpc.PendingChannelsResponse.Commitments} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingChannelsResponse.Commitments, 3));
};


/** @param {?proto.lnrpc.PendingChannelsResponse.Commitments|undefined} value */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.setCommitments = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.clearCommitments = function() {
  this.setCommitments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.hasCommitments = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string closing_txid = 4;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.getClosingTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel.prototype.setClosingTxid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse.Commitments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse.Commitments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.Commitments.displayName = 'proto.lnrpc.PendingChannelsResponse.Commitments';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.Commitments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse.Commitments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.Commitments.toObject = function(includeInstance, msg) {
  var f, obj = {
    localTxid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    remoteTxid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    remotePendingTxid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    localCommitFeeSat: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    remoteCommitFeeSat: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    remotePendingCommitFeeSat: jspb.Message.getFieldWithDefault(msg, 6, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse.Commitments}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse.Commitments;
  return proto.lnrpc.PendingChannelsResponse.Commitments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse.Commitments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse.Commitments}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalTxid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteTxid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemotePendingTxid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setLocalCommitFeeSat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRemoteCommitFeeSat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRemotePendingCommitFeeSat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.Commitments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse.Commitments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.Commitments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalTxid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRemoteTxid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemotePendingTxid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocalCommitFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getRemoteCommitFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getRemotePendingCommitFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
};


/**
 * optional string local_txid = 1;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.getLocalTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.setLocalTxid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string remote_txid = 2;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.getRemoteTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.setRemoteTxid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string remote_pending_txid = 3;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.getRemotePendingTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.setRemotePendingTxid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 local_commit_fee_sat = 4;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.getLocalCommitFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.setLocalCommitFeeSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 remote_commit_fee_sat = 5;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.getRemoteCommitFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.setRemoteCommitFeeSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint64 remote_pending_commit_fee_sat = 6;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.getRemotePendingCommitFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.Commitments.prototype.setRemotePendingCommitFeeSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse.ClosedChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.ClosedChannel.displayName = 'proto.lnrpc.PendingChannelsResponse.ClosedChannel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.ClosedChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse.ClosedChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && proto.lnrpc.PendingChannelsResponse.PendingChannel.toObject(includeInstance, f),
    closingTxid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse.ClosedChannel}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse.ClosedChannel;
  return proto.lnrpc.PendingChannelsResponse.ClosedChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse.ClosedChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse.ClosedChannel}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingChannelsResponse.PendingChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingTxid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.ClosedChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse.ClosedChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.PendingChannelsResponse.PendingChannel.serializeBinaryToWriter
    );
  }
  f = message.getClosingTxid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional PendingChannel channel = 1;
 * @return {?proto.lnrpc.PendingChannelsResponse.PendingChannel}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.getChannel = function() {
  return /** @type{?proto.lnrpc.PendingChannelsResponse.PendingChannel} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingChannelsResponse.PendingChannel, 1));
};


/** @param {?proto.lnrpc.PendingChannelsResponse.PendingChannel|undefined} value */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.setChannel = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.clearChannel = function() {
  this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string closing_txid = 2;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.getClosingTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.ClosedChannel.prototype.setClosingTxid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.PendingChannelsResponse.ForceClosedChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.displayName = 'proto.lnrpc.PendingChannelsResponse.ForceClosedChannel';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: (f = msg.getChannel()) && proto.lnrpc.PendingChannelsResponse.PendingChannel.toObject(includeInstance, f),
    closingTxid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limboBalance: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    maturityHeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blocksTilMaturity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recoveredBalance: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    pendingHtlcsList: jspb.Message.toObjectList(msg.getPendingHtlcsList(),
    proto.lnrpc.PendingHTLC.toObject, includeInstance),
    anchor: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PendingChannelsResponse.ForceClosedChannel;
  return proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.PendingChannelsResponse.PendingChannel;
      reader.readMessage(value,proto.lnrpc.PendingChannelsResponse.PendingChannel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingTxid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLimboBalance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaturityHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlocksTilMaturity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setRecoveredBalance(value);
      break;
    case 8:
      var value = new proto.lnrpc.PendingHTLC;
      reader.readMessage(value,proto.lnrpc.PendingHTLC.deserializeBinaryFromReader);
      msg.addPendingHtlcs(value);
      break;
    case 9:
      var value = /** @type {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState} */ (reader.readEnum());
      msg.setAnchor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.PendingChannelsResponse.PendingChannel.serializeBinaryToWriter
    );
  }
  f = message.getClosingTxid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimboBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getMaturityHeight();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBlocksTilMaturity();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRecoveredBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getPendingHtlcsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.lnrpc.PendingHTLC.serializeBinaryToWriter
    );
  }
  f = message.getAnchor();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState = {
  LIMBO: 0,
  RECOVERED: 1,
  LOST: 2
};

/**
 * optional PendingChannel channel = 1;
 * @return {?proto.lnrpc.PendingChannelsResponse.PendingChannel}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getChannel = function() {
  return /** @type{?proto.lnrpc.PendingChannelsResponse.PendingChannel} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingChannelsResponse.PendingChannel, 1));
};


/** @param {?proto.lnrpc.PendingChannelsResponse.PendingChannel|undefined} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setChannel = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.clearChannel = function() {
  this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string closing_txid = 2;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getClosingTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setClosingTxid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 limbo_balance = 3;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getLimboBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setLimboBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional uint32 maturity_height = 4;
 * @return {number}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getMaturityHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setMaturityHeight = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 blocks_til_maturity = 5;
 * @return {number}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getBlocksTilMaturity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setBlocksTilMaturity = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 recovered_balance = 6;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getRecoveredBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setRecoveredBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * repeated PendingHTLC pending_htlcs = 8;
 * @return {!Array<!proto.lnrpc.PendingHTLC>}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getPendingHtlcsList = function() {
  return /** @type{!Array<!proto.lnrpc.PendingHTLC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.PendingHTLC, 8));
};


/** @param {!Array<!proto.lnrpc.PendingHTLC>} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setPendingHtlcsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.lnrpc.PendingHTLC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.PendingHTLC}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.addPendingHtlcs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.lnrpc.PendingHTLC, opt_index);
};


proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.clearPendingHtlcsList = function() {
  this.setPendingHtlcsList([]);
};


/**
 * optional AnchorState anchor = 9;
 * @return {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState}
 */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.getAnchor = function() {
  return /** @type {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState} value */
proto.lnrpc.PendingChannelsResponse.ForceClosedChannel.prototype.setAnchor = function(value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 total_limbo_balance = 1;
 * @return {string}
 */
proto.lnrpc.PendingChannelsResponse.prototype.getTotalLimboBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.PendingChannelsResponse.prototype.setTotalLimboBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * repeated PendingOpenChannel pending_open_channels = 2;
 * @return {!Array<!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel>}
 */
proto.lnrpc.PendingChannelsResponse.prototype.getPendingOpenChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.PendingChannelsResponse.PendingOpenChannel, 2));
};


/** @param {!Array<!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel>} value */
proto.lnrpc.PendingChannelsResponse.prototype.setPendingOpenChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.PendingChannelsResponse.PendingOpenChannel}
 */
proto.lnrpc.PendingChannelsResponse.prototype.addPendingOpenChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.lnrpc.PendingChannelsResponse.PendingOpenChannel, opt_index);
};


proto.lnrpc.PendingChannelsResponse.prototype.clearPendingOpenChannelsList = function() {
  this.setPendingOpenChannelsList([]);
};


/**
 * repeated ClosedChannel pending_closing_channels = 3;
 * @return {!Array<!proto.lnrpc.PendingChannelsResponse.ClosedChannel>}
 */
proto.lnrpc.PendingChannelsResponse.prototype.getPendingClosingChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.PendingChannelsResponse.ClosedChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.PendingChannelsResponse.ClosedChannel, 3));
};


/** @param {!Array<!proto.lnrpc.PendingChannelsResponse.ClosedChannel>} value */
proto.lnrpc.PendingChannelsResponse.prototype.setPendingClosingChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.lnrpc.PendingChannelsResponse.ClosedChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.PendingChannelsResponse.ClosedChannel}
 */
proto.lnrpc.PendingChannelsResponse.prototype.addPendingClosingChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.lnrpc.PendingChannelsResponse.ClosedChannel, opt_index);
};


proto.lnrpc.PendingChannelsResponse.prototype.clearPendingClosingChannelsList = function() {
  this.setPendingClosingChannelsList([]);
};


/**
 * repeated ForceClosedChannel pending_force_closing_channels = 4;
 * @return {!Array<!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel>}
 */
proto.lnrpc.PendingChannelsResponse.prototype.getPendingForceClosingChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.PendingChannelsResponse.ForceClosedChannel, 4));
};


/** @param {!Array<!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel>} value */
proto.lnrpc.PendingChannelsResponse.prototype.setPendingForceClosingChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.PendingChannelsResponse.ForceClosedChannel}
 */
proto.lnrpc.PendingChannelsResponse.prototype.addPendingForceClosingChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.lnrpc.PendingChannelsResponse.ForceClosedChannel, opt_index);
};


proto.lnrpc.PendingChannelsResponse.prototype.clearPendingForceClosingChannelsList = function() {
  this.setPendingForceClosingChannelsList([]);
};


/**
 * repeated WaitingCloseChannel waiting_close_channels = 5;
 * @return {!Array<!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel>}
 */
proto.lnrpc.PendingChannelsResponse.prototype.getWaitingCloseChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel, 5));
};


/** @param {!Array<!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel>} value */
proto.lnrpc.PendingChannelsResponse.prototype.setWaitingCloseChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel}
 */
proto.lnrpc.PendingChannelsResponse.prototype.addWaitingCloseChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.lnrpc.PendingChannelsResponse.WaitingCloseChannel, opt_index);
};


proto.lnrpc.PendingChannelsResponse.prototype.clearWaitingCloseChannelsList = function() {
  this.setWaitingCloseChannelsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelEventSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelEventSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelEventSubscription.displayName = 'proto.lnrpc.ChannelEventSubscription';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelEventSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelEventSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelEventSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEventSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelEventSubscription}
 */
proto.lnrpc.ChannelEventSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelEventSubscription;
  return proto.lnrpc.ChannelEventSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelEventSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelEventSubscription}
 */
proto.lnrpc.ChannelEventSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelEventSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelEventSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelEventSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEventSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelEventUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.ChannelEventUpdate.oneofGroups_);
};
goog.inherits(proto.lnrpc.ChannelEventUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelEventUpdate.displayName = 'proto.lnrpc.ChannelEventUpdate';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.ChannelEventUpdate.oneofGroups_ = [[1,2,3,4,6,7]];

/**
 * @enum {number}
 */
proto.lnrpc.ChannelEventUpdate.ChannelCase = {
  CHANNEL_NOT_SET: 0,
  OPEN_CHANNEL: 1,
  CLOSED_CHANNEL: 2,
  ACTIVE_CHANNEL: 3,
  INACTIVE_CHANNEL: 4,
  PENDING_OPEN_CHANNEL: 6,
  FULLY_RESOLVED_CHANNEL: 7
};

/**
 * @return {proto.lnrpc.ChannelEventUpdate.ChannelCase}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getChannelCase = function() {
  return /** @type {proto.lnrpc.ChannelEventUpdate.ChannelCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelEventUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelEventUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelEventUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEventUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    openChannel: (f = msg.getOpenChannel()) && proto.lnrpc.Channel.toObject(includeInstance, f),
    closedChannel: (f = msg.getClosedChannel()) && proto.lnrpc.ChannelCloseSummary.toObject(includeInstance, f),
    activeChannel: (f = msg.getActiveChannel()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    inactiveChannel: (f = msg.getInactiveChannel()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    pendingOpenChannel: (f = msg.getPendingOpenChannel()) && proto.lnrpc.PendingUpdate.toObject(includeInstance, f),
    fullyResolvedChannel: (f = msg.getFullyResolvedChannel()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelEventUpdate}
 */
proto.lnrpc.ChannelEventUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelEventUpdate;
  return proto.lnrpc.ChannelEventUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelEventUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelEventUpdate}
 */
proto.lnrpc.ChannelEventUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Channel;
      reader.readMessage(value,proto.lnrpc.Channel.deserializeBinaryFromReader);
      msg.setOpenChannel(value);
      break;
    case 2:
      var value = new proto.lnrpc.ChannelCloseSummary;
      reader.readMessage(value,proto.lnrpc.ChannelCloseSummary.deserializeBinaryFromReader);
      msg.setClosedChannel(value);
      break;
    case 3:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setActiveChannel(value);
      break;
    case 4:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setInactiveChannel(value);
      break;
    case 6:
      var value = new proto.lnrpc.PendingUpdate;
      reader.readMessage(value,proto.lnrpc.PendingUpdate.deserializeBinaryFromReader);
      msg.setPendingOpenChannel(value);
      break;
    case 7:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setFullyResolvedChannel(value);
      break;
    case 5:
      var value = /** @type {!proto.lnrpc.ChannelEventUpdate.UpdateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelEventUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelEventUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelEventUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEventUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpenChannel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.Channel.serializeBinaryToWriter
    );
  }
  f = message.getClosedChannel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.ChannelCloseSummary.serializeBinaryToWriter
    );
  }
  f = message.getActiveChannel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getInactiveChannel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getPendingOpenChannel();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.lnrpc.PendingUpdate.serializeBinaryToWriter
    );
  }
  f = message.getFullyResolvedChannel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.ChannelEventUpdate.UpdateType = {
  OPEN_CHANNEL: 0,
  CLOSED_CHANNEL: 1,
  ACTIVE_CHANNEL: 2,
  INACTIVE_CHANNEL: 3,
  PENDING_OPEN_CHANNEL: 4,
  FULLY_RESOLVED_CHANNEL: 5
};

/**
 * optional Channel open_channel = 1;
 * @return {?proto.lnrpc.Channel}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getOpenChannel = function() {
  return /** @type{?proto.lnrpc.Channel} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Channel, 1));
};


/** @param {?proto.lnrpc.Channel|undefined} value */
proto.lnrpc.ChannelEventUpdate.prototype.setOpenChannel = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0], value);
};


proto.lnrpc.ChannelEventUpdate.prototype.clearOpenChannel = function() {
  this.setOpenChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEventUpdate.prototype.hasOpenChannel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChannelCloseSummary closed_channel = 2;
 * @return {?proto.lnrpc.ChannelCloseSummary}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getClosedChannel = function() {
  return /** @type{?proto.lnrpc.ChannelCloseSummary} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelCloseSummary, 2));
};


/** @param {?proto.lnrpc.ChannelCloseSummary|undefined} value */
proto.lnrpc.ChannelEventUpdate.prototype.setClosedChannel = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0], value);
};


proto.lnrpc.ChannelEventUpdate.prototype.clearClosedChannel = function() {
  this.setClosedChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEventUpdate.prototype.hasClosedChannel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ChannelPoint active_channel = 3;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getActiveChannel = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 3));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChannelEventUpdate.prototype.setActiveChannel = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0], value);
};


proto.lnrpc.ChannelEventUpdate.prototype.clearActiveChannel = function() {
  this.setActiveChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEventUpdate.prototype.hasActiveChannel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ChannelPoint inactive_channel = 4;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getInactiveChannel = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 4));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChannelEventUpdate.prototype.setInactiveChannel = function(value) {
  jspb.Message.setOneofWrapperField(this, 4, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0], value);
};


proto.lnrpc.ChannelEventUpdate.prototype.clearInactiveChannel = function() {
  this.setInactiveChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEventUpdate.prototype.hasInactiveChannel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PendingUpdate pending_open_channel = 6;
 * @return {?proto.lnrpc.PendingUpdate}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getPendingOpenChannel = function() {
  return /** @type{?proto.lnrpc.PendingUpdate} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.PendingUpdate, 6));
};


/** @param {?proto.lnrpc.PendingUpdate|undefined} value */
proto.lnrpc.ChannelEventUpdate.prototype.setPendingOpenChannel = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0], value);
};


proto.lnrpc.ChannelEventUpdate.prototype.clearPendingOpenChannel = function() {
  this.setPendingOpenChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEventUpdate.prototype.hasPendingOpenChannel = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ChannelPoint fully_resolved_channel = 7;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getFullyResolvedChannel = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 7));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChannelEventUpdate.prototype.setFullyResolvedChannel = function(value) {
  jspb.Message.setOneofWrapperField(this, 7, proto.lnrpc.ChannelEventUpdate.oneofGroups_[0], value);
};


proto.lnrpc.ChannelEventUpdate.prototype.clearFullyResolvedChannel = function() {
  this.setFullyResolvedChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEventUpdate.prototype.hasFullyResolvedChannel = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UpdateType type = 5;
 * @return {!proto.lnrpc.ChannelEventUpdate.UpdateType}
 */
proto.lnrpc.ChannelEventUpdate.prototype.getType = function() {
  return /** @type {!proto.lnrpc.ChannelEventUpdate.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.lnrpc.ChannelEventUpdate.UpdateType} value */
proto.lnrpc.ChannelEventUpdate.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.WalletAccountBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.WalletAccountBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.WalletAccountBalance.displayName = 'proto.lnrpc.WalletAccountBalance';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.WalletAccountBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.WalletAccountBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.WalletAccountBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.WalletAccountBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirmedBalance: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    unconfirmedBalance: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.WalletAccountBalance}
 */
proto.lnrpc.WalletAccountBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.WalletAccountBalance;
  return proto.lnrpc.WalletAccountBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.WalletAccountBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.WalletAccountBalance}
 */
proto.lnrpc.WalletAccountBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setConfirmedBalance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setUnconfirmedBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.WalletAccountBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.WalletAccountBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.WalletAccountBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.WalletAccountBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfirmedBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getUnconfirmedBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
};


/**
 * optional int64 confirmed_balance = 1;
 * @return {string}
 */
proto.lnrpc.WalletAccountBalance.prototype.getConfirmedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.WalletAccountBalance.prototype.setConfirmedBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 unconfirmed_balance = 2;
 * @return {string}
 */
proto.lnrpc.WalletAccountBalance.prototype.getUnconfirmedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.WalletAccountBalance.prototype.setUnconfirmedBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.WalletBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.WalletBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.WalletBalanceRequest.displayName = 'proto.lnrpc.WalletBalanceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.WalletBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.WalletBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.WalletBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.WalletBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.WalletBalanceRequest}
 */
proto.lnrpc.WalletBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.WalletBalanceRequest;
  return proto.lnrpc.WalletBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.WalletBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.WalletBalanceRequest}
 */
proto.lnrpc.WalletBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.WalletBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.WalletBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.WalletBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.WalletBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.WalletBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.WalletBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.WalletBalanceResponse.displayName = 'proto.lnrpc.WalletBalanceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.WalletBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.WalletBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.WalletBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.WalletBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalBalance: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    confirmedBalance: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    unconfirmedBalance: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    lockedBalance: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    accountBalanceMap: (f = msg.getAccountBalanceMap()) ? f.toObject(includeInstance, proto.lnrpc.WalletAccountBalance.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.WalletBalanceResponse}
 */
proto.lnrpc.WalletBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.WalletBalanceResponse;
  return proto.lnrpc.WalletBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.WalletBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.WalletBalanceResponse}
 */
proto.lnrpc.WalletBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalBalance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setConfirmedBalance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setUnconfirmedBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLockedBalance(value);
      break;
    case 4:
      var value = msg.getAccountBalanceMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.WalletAccountBalance.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.WalletBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.WalletBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.WalletBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.WalletBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getConfirmedBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getUnconfirmedBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getLockedBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getAccountBalanceMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.WalletAccountBalance.serializeBinaryToWriter);
  }
};


/**
 * optional int64 total_balance = 1;
 * @return {string}
 */
proto.lnrpc.WalletBalanceResponse.prototype.getTotalBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.WalletBalanceResponse.prototype.setTotalBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 confirmed_balance = 2;
 * @return {string}
 */
proto.lnrpc.WalletBalanceResponse.prototype.getConfirmedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.WalletBalanceResponse.prototype.setConfirmedBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 unconfirmed_balance = 3;
 * @return {string}
 */
proto.lnrpc.WalletBalanceResponse.prototype.getUnconfirmedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.WalletBalanceResponse.prototype.setUnconfirmedBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 locked_balance = 5;
 * @return {string}
 */
proto.lnrpc.WalletBalanceResponse.prototype.getLockedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.WalletBalanceResponse.prototype.setLockedBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * map<string, WalletAccountBalance> account_balance = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.lnrpc.WalletAccountBalance>}
 */
proto.lnrpc.WalletBalanceResponse.prototype.getAccountBalanceMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.lnrpc.WalletAccountBalance>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.lnrpc.WalletAccountBalance));
};


proto.lnrpc.WalletBalanceResponse.prototype.clearAccountBalanceMap = function() {
  this.getAccountBalanceMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Amount.displayName = 'proto.lnrpc.Amount';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    sat: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    msat: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Amount}
 */
proto.lnrpc.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Amount;
  return proto.lnrpc.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Amount}
 */
proto.lnrpc.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
};


/**
 * optional uint64 sat = 1;
 * @return {string}
 */
proto.lnrpc.Amount.prototype.getSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.Amount.prototype.setSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 msat = 2;
 * @return {string}
 */
proto.lnrpc.Amount.prototype.getMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.Amount.prototype.setMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelBalanceRequest.displayName = 'proto.lnrpc.ChannelBalanceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelBalanceRequest}
 */
proto.lnrpc.ChannelBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelBalanceRequest;
  return proto.lnrpc.ChannelBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelBalanceRequest}
 */
proto.lnrpc.ChannelBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelBalanceResponse.displayName = 'proto.lnrpc.ChannelBalanceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    balance: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    pendingOpenBalance: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    localBalance: (f = msg.getLocalBalance()) && proto.lnrpc.Amount.toObject(includeInstance, f),
    remoteBalance: (f = msg.getRemoteBalance()) && proto.lnrpc.Amount.toObject(includeInstance, f),
    unsettledLocalBalance: (f = msg.getUnsettledLocalBalance()) && proto.lnrpc.Amount.toObject(includeInstance, f),
    unsettledRemoteBalance: (f = msg.getUnsettledRemoteBalance()) && proto.lnrpc.Amount.toObject(includeInstance, f),
    pendingOpenLocalBalance: (f = msg.getPendingOpenLocalBalance()) && proto.lnrpc.Amount.toObject(includeInstance, f),
    pendingOpenRemoteBalance: (f = msg.getPendingOpenRemoteBalance()) && proto.lnrpc.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelBalanceResponse}
 */
proto.lnrpc.ChannelBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelBalanceResponse;
  return proto.lnrpc.ChannelBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelBalanceResponse}
 */
proto.lnrpc.ChannelBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setBalance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setPendingOpenBalance(value);
      break;
    case 3:
      var value = new proto.lnrpc.Amount;
      reader.readMessage(value,proto.lnrpc.Amount.deserializeBinaryFromReader);
      msg.setLocalBalance(value);
      break;
    case 4:
      var value = new proto.lnrpc.Amount;
      reader.readMessage(value,proto.lnrpc.Amount.deserializeBinaryFromReader);
      msg.setRemoteBalance(value);
      break;
    case 5:
      var value = new proto.lnrpc.Amount;
      reader.readMessage(value,proto.lnrpc.Amount.deserializeBinaryFromReader);
      msg.setUnsettledLocalBalance(value);
      break;
    case 6:
      var value = new proto.lnrpc.Amount;
      reader.readMessage(value,proto.lnrpc.Amount.deserializeBinaryFromReader);
      msg.setUnsettledRemoteBalance(value);
      break;
    case 7:
      var value = new proto.lnrpc.Amount;
      reader.readMessage(value,proto.lnrpc.Amount.deserializeBinaryFromReader);
      msg.setPendingOpenLocalBalance(value);
      break;
    case 8:
      var value = new proto.lnrpc.Amount;
      reader.readMessage(value,proto.lnrpc.Amount.deserializeBinaryFromReader);
      msg.setPendingOpenRemoteBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getPendingOpenBalance();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getLocalBalance();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.Amount.serializeBinaryToWriter
    );
  }
  f = message.getRemoteBalance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.Amount.serializeBinaryToWriter
    );
  }
  f = message.getUnsettledLocalBalance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.lnrpc.Amount.serializeBinaryToWriter
    );
  }
  f = message.getUnsettledRemoteBalance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.lnrpc.Amount.serializeBinaryToWriter
    );
  }
  f = message.getPendingOpenLocalBalance();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.lnrpc.Amount.serializeBinaryToWriter
    );
  }
  f = message.getPendingOpenRemoteBalance();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.lnrpc.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 balance = 1;
 * @return {string}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 pending_open_balance = 2;
 * @return {string}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getPendingOpenBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setPendingOpenBalance = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional Amount local_balance = 3;
 * @return {?proto.lnrpc.Amount}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getLocalBalance = function() {
  return /** @type{?proto.lnrpc.Amount} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Amount, 3));
};


/** @param {?proto.lnrpc.Amount|undefined} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setLocalBalance = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.lnrpc.ChannelBalanceResponse.prototype.clearLocalBalance = function() {
  this.setLocalBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.hasLocalBalance = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Amount remote_balance = 4;
 * @return {?proto.lnrpc.Amount}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getRemoteBalance = function() {
  return /** @type{?proto.lnrpc.Amount} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Amount, 4));
};


/** @param {?proto.lnrpc.Amount|undefined} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setRemoteBalance = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.lnrpc.ChannelBalanceResponse.prototype.clearRemoteBalance = function() {
  this.setRemoteBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.hasRemoteBalance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Amount unsettled_local_balance = 5;
 * @return {?proto.lnrpc.Amount}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getUnsettledLocalBalance = function() {
  return /** @type{?proto.lnrpc.Amount} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Amount, 5));
};


/** @param {?proto.lnrpc.Amount|undefined} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setUnsettledLocalBalance = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.lnrpc.ChannelBalanceResponse.prototype.clearUnsettledLocalBalance = function() {
  this.setUnsettledLocalBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.hasUnsettledLocalBalance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Amount unsettled_remote_balance = 6;
 * @return {?proto.lnrpc.Amount}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getUnsettledRemoteBalance = function() {
  return /** @type{?proto.lnrpc.Amount} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Amount, 6));
};


/** @param {?proto.lnrpc.Amount|undefined} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setUnsettledRemoteBalance = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.lnrpc.ChannelBalanceResponse.prototype.clearUnsettledRemoteBalance = function() {
  this.setUnsettledRemoteBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.hasUnsettledRemoteBalance = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Amount pending_open_local_balance = 7;
 * @return {?proto.lnrpc.Amount}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getPendingOpenLocalBalance = function() {
  return /** @type{?proto.lnrpc.Amount} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Amount, 7));
};


/** @param {?proto.lnrpc.Amount|undefined} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setPendingOpenLocalBalance = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.lnrpc.ChannelBalanceResponse.prototype.clearPendingOpenLocalBalance = function() {
  this.setPendingOpenLocalBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.hasPendingOpenLocalBalance = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Amount pending_open_remote_balance = 8;
 * @return {?proto.lnrpc.Amount}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.getPendingOpenRemoteBalance = function() {
  return /** @type{?proto.lnrpc.Amount} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Amount, 8));
};


/** @param {?proto.lnrpc.Amount|undefined} value */
proto.lnrpc.ChannelBalanceResponse.prototype.setPendingOpenRemoteBalance = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.lnrpc.ChannelBalanceResponse.prototype.clearPendingOpenRemoteBalance = function() {
  this.setPendingOpenRemoteBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBalanceResponse.prototype.hasPendingOpenRemoteBalance = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.QueryRoutesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.QueryRoutesRequest.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.QueryRoutesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.QueryRoutesRequest.displayName = 'proto.lnrpc.QueryRoutesRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.QueryRoutesRequest.repeatedFields_ = [6,7,10,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.QueryRoutesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.QueryRoutesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.QueryRoutesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.QueryRoutesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pubKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    amtMsat: jspb.Message.getFieldWithDefault(msg, 12, "0"),
    finalCltvDelta: jspb.Message.getFieldWithDefault(msg, 4, 0),
    feeLimit: (f = msg.getFeeLimit()) && proto.lnrpc.FeeLimit.toObject(includeInstance, f),
    ignoredNodesList: msg.getIgnoredNodesList_asB64(),
    ignoredEdgesList: jspb.Message.toObjectList(msg.getIgnoredEdgesList(),
    proto.lnrpc.EdgeLocator.toObject, includeInstance),
    sourcePubKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    useMissionControl: jspb.Message.getFieldWithDefault(msg, 9, false),
    ignoredPairsList: jspb.Message.toObjectList(msg.getIgnoredPairsList(),
    proto.lnrpc.NodePair.toObject, includeInstance),
    cltvLimit: jspb.Message.getFieldWithDefault(msg, 11, 0),
    destCustomRecordsMap: (f = msg.getDestCustomRecordsMap()) ? f.toObject(includeInstance, undefined) : [],
    outgoingChanId: jspb.Message.getFieldWithDefault(msg, 14, "0"),
    lastHopPubkey: msg.getLastHopPubkey_asB64(),
    routeHintsList: jspb.Message.toObjectList(msg.getRouteHintsList(),
    proto.lnrpc.RouteHint.toObject, includeInstance),
    destFeaturesList: jspb.Message.getRepeatedField(msg, 17),
    timePref: +jspb.Message.getFieldWithDefault(msg, 18, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.QueryRoutesRequest}
 */
proto.lnrpc.QueryRoutesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.QueryRoutesRequest;
  return proto.lnrpc.QueryRoutesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.QueryRoutesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.QueryRoutesRequest}
 */
proto.lnrpc.QueryRoutesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinalCltvDelta(value);
      break;
    case 5:
      var value = new proto.lnrpc.FeeLimit;
      reader.readMessage(value,proto.lnrpc.FeeLimit.deserializeBinaryFromReader);
      msg.setFeeLimit(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addIgnoredNodes(value);
      break;
    case 7:
      var value = new proto.lnrpc.EdgeLocator;
      reader.readMessage(value,proto.lnrpc.EdgeLocator.deserializeBinaryFromReader);
      msg.addIgnoredEdges(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourcePubKey(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseMissionControl(value);
      break;
    case 10:
      var value = new proto.lnrpc.NodePair;
      reader.readMessage(value,proto.lnrpc.NodePair.deserializeBinaryFromReader);
      msg.addIgnoredPairs(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCltvLimit(value);
      break;
    case 13:
      var value = msg.getDestCustomRecordsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint64, jspb.BinaryReader.prototype.readBytes, null, 0);
         });
      break;
    case 14:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setOutgoingChanId(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastHopPubkey(value);
      break;
    case 16:
      var value = new proto.lnrpc.RouteHint;
      reader.readMessage(value,proto.lnrpc.RouteHint.deserializeBinaryFromReader);
      msg.addRouteHints(value);
      break;
    case 17:
      var value = /** @type {!Array<!proto.lnrpc.FeatureBit>} */ (reader.readPackedEnum());
      msg.setDestFeaturesList(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimePref(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.QueryRoutesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.QueryRoutesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.QueryRoutesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.QueryRoutesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      12,
      f
    );
  }
  f = message.getFinalCltvDelta();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFeeLimit();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.lnrpc.FeeLimit.serializeBinaryToWriter
    );
  }
  f = message.getIgnoredNodesList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      6,
      f
    );
  }
  f = message.getIgnoredEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.lnrpc.EdgeLocator.serializeBinaryToWriter
    );
  }
  f = message.getSourcePubKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUseMissionControl();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIgnoredPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.lnrpc.NodePair.serializeBinaryToWriter
    );
  }
  f = message.getCltvLimit();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getDestCustomRecordsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeUint64, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getOutgoingChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      14,
      f
    );
  }
  f = message.getLastHopPubkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.lnrpc.RouteHint.serializeBinaryToWriter
    );
  }
  f = message.getDestFeaturesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      17,
      f
    );
  }
  f = message.getTimePref();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
};


/**
 * optional string pub_key = 1;
 * @return {string}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.QueryRoutesRequest.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amt = 2;
 * @return {string}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.QueryRoutesRequest.prototype.setAmt = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 amt_msat = 12;
 * @return {string}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, "0"));
};


/** @param {string} value */
proto.lnrpc.QueryRoutesRequest.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 12, value);
};


/**
 * optional int32 final_cltv_delta = 4;
 * @return {number}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getFinalCltvDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.QueryRoutesRequest.prototype.setFinalCltvDelta = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional FeeLimit fee_limit = 5;
 * @return {?proto.lnrpc.FeeLimit}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getFeeLimit = function() {
  return /** @type{?proto.lnrpc.FeeLimit} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.FeeLimit, 5));
};


/** @param {?proto.lnrpc.FeeLimit|undefined} value */
proto.lnrpc.QueryRoutesRequest.prototype.setFeeLimit = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.lnrpc.QueryRoutesRequest.prototype.clearFeeLimit = function() {
  this.setFeeLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.QueryRoutesRequest.prototype.hasFeeLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated bytes ignored_nodes = 6;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getIgnoredNodesList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * repeated bytes ignored_nodes = 6;
 * This is a type-conversion wrapper around `getIgnoredNodesList()`
 * @return {!Array<string>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getIgnoredNodesList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getIgnoredNodesList()));
};


/**
 * repeated bytes ignored_nodes = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIgnoredNodesList()`
 * @return {!Array<!Uint8Array>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getIgnoredNodesList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getIgnoredNodesList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.lnrpc.QueryRoutesRequest.prototype.setIgnoredNodesList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.lnrpc.QueryRoutesRequest.prototype.addIgnoredNodes = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


proto.lnrpc.QueryRoutesRequest.prototype.clearIgnoredNodesList = function() {
  this.setIgnoredNodesList([]);
};


/**
 * repeated EdgeLocator ignored_edges = 7;
 * @return {!Array<!proto.lnrpc.EdgeLocator>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getIgnoredEdgesList = function() {
  return /** @type{!Array<!proto.lnrpc.EdgeLocator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.EdgeLocator, 7));
};


/** @param {!Array<!proto.lnrpc.EdgeLocator>} value */
proto.lnrpc.QueryRoutesRequest.prototype.setIgnoredEdgesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.lnrpc.EdgeLocator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.EdgeLocator}
 */
proto.lnrpc.QueryRoutesRequest.prototype.addIgnoredEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.lnrpc.EdgeLocator, opt_index);
};


proto.lnrpc.QueryRoutesRequest.prototype.clearIgnoredEdgesList = function() {
  this.setIgnoredEdgesList([]);
};


/**
 * optional string source_pub_key = 8;
 * @return {string}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getSourcePubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.lnrpc.QueryRoutesRequest.prototype.setSourcePubKey = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool use_mission_control = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getUseMissionControl = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.lnrpc.QueryRoutesRequest.prototype.setUseMissionControl = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated NodePair ignored_pairs = 10;
 * @return {!Array<!proto.lnrpc.NodePair>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getIgnoredPairsList = function() {
  return /** @type{!Array<!proto.lnrpc.NodePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.NodePair, 10));
};


/** @param {!Array<!proto.lnrpc.NodePair>} value */
proto.lnrpc.QueryRoutesRequest.prototype.setIgnoredPairsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.lnrpc.NodePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.NodePair}
 */
proto.lnrpc.QueryRoutesRequest.prototype.addIgnoredPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.lnrpc.NodePair, opt_index);
};


proto.lnrpc.QueryRoutesRequest.prototype.clearIgnoredPairsList = function() {
  this.setIgnoredPairsList([]);
};


/**
 * optional uint32 cltv_limit = 11;
 * @return {number}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getCltvLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.lnrpc.QueryRoutesRequest.prototype.setCltvLimit = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * map<uint64, bytes> dest_custom_records = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!(string|Uint8Array)>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getDestCustomRecordsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      null));
};


proto.lnrpc.QueryRoutesRequest.prototype.clearDestCustomRecordsMap = function() {
  this.getDestCustomRecordsMap().clear();
};


/**
 * optional uint64 outgoing_chan_id = 14;
 * @return {string}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getOutgoingChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, "0"));
};


/** @param {string} value */
proto.lnrpc.QueryRoutesRequest.prototype.setOutgoingChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 14, value);
};


/**
 * optional bytes last_hop_pubkey = 15;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getLastHopPubkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes last_hop_pubkey = 15;
 * This is a type-conversion wrapper around `getLastHopPubkey()`
 * @return {string}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getLastHopPubkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastHopPubkey()));
};


/**
 * optional bytes last_hop_pubkey = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastHopPubkey()`
 * @return {!Uint8Array}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getLastHopPubkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastHopPubkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.QueryRoutesRequest.prototype.setLastHopPubkey = function(value) {
  jspb.Message.setProto3BytesField(this, 15, value);
};


/**
 * repeated RouteHint route_hints = 16;
 * @return {!Array<!proto.lnrpc.RouteHint>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getRouteHintsList = function() {
  return /** @type{!Array<!proto.lnrpc.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.RouteHint, 16));
};


/** @param {!Array<!proto.lnrpc.RouteHint>} value */
proto.lnrpc.QueryRoutesRequest.prototype.setRouteHintsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.lnrpc.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.RouteHint}
 */
proto.lnrpc.QueryRoutesRequest.prototype.addRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.lnrpc.RouteHint, opt_index);
};


proto.lnrpc.QueryRoutesRequest.prototype.clearRouteHintsList = function() {
  this.setRouteHintsList([]);
};


/**
 * repeated FeatureBit dest_features = 17;
 * @return {!Array<!proto.lnrpc.FeatureBit>}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getDestFeaturesList = function() {
  return /** @type {!Array<!proto.lnrpc.FeatureBit>} */ (jspb.Message.getRepeatedField(this, 17));
};


/** @param {!Array<!proto.lnrpc.FeatureBit>} value */
proto.lnrpc.QueryRoutesRequest.prototype.setDestFeaturesList = function(value) {
  jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {!proto.lnrpc.FeatureBit} value
 * @param {number=} opt_index
 */
proto.lnrpc.QueryRoutesRequest.prototype.addDestFeatures = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


proto.lnrpc.QueryRoutesRequest.prototype.clearDestFeaturesList = function() {
  this.setDestFeaturesList([]);
};


/**
 * optional double time_pref = 18;
 * @return {number}
 */
proto.lnrpc.QueryRoutesRequest.prototype.getTimePref = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 18, 0.0));
};


/** @param {number} value */
proto.lnrpc.QueryRoutesRequest.prototype.setTimePref = function(value) {
  jspb.Message.setProto3FloatField(this, 18, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodePair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NodePair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodePair.displayName = 'proto.lnrpc.NodePair';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodePair.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodePair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodePair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodePair.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: msg.getFrom_asB64(),
    to: msg.getTo_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodePair}
 */
proto.lnrpc.NodePair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodePair;
  return proto.lnrpc.NodePair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodePair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodePair}
 */
proto.lnrpc.NodePair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodePair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodePair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodePair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodePair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTo_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes from = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.NodePair.prototype.getFrom = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes from = 1;
 * This is a type-conversion wrapper around `getFrom()`
 * @return {string}
 */
proto.lnrpc.NodePair.prototype.getFrom_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFrom()));
};


/**
 * optional bytes from = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFrom()`
 * @return {!Uint8Array}
 */
proto.lnrpc.NodePair.prototype.getFrom_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFrom()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.NodePair.prototype.setFrom = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes to = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.NodePair.prototype.getTo = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes to = 2;
 * This is a type-conversion wrapper around `getTo()`
 * @return {string}
 */
proto.lnrpc.NodePair.prototype.getTo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTo()));
};


/**
 * optional bytes to = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTo()`
 * @return {!Uint8Array}
 */
proto.lnrpc.NodePair.prototype.getTo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTo()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.NodePair.prototype.setTo = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.EdgeLocator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.EdgeLocator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.EdgeLocator.displayName = 'proto.lnrpc.EdgeLocator';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.EdgeLocator.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.EdgeLocator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.EdgeLocator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.EdgeLocator.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    directionReverse: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.EdgeLocator}
 */
proto.lnrpc.EdgeLocator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.EdgeLocator;
  return proto.lnrpc.EdgeLocator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.EdgeLocator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.EdgeLocator}
 */
proto.lnrpc.EdgeLocator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirectionReverse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.EdgeLocator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.EdgeLocator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.EdgeLocator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.EdgeLocator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getDirectionReverse();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint64 channel_id = 1;
 * @return {string}
 */
proto.lnrpc.EdgeLocator.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.EdgeLocator.prototype.setChannelId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional bool direction_reverse = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.EdgeLocator.prototype.getDirectionReverse = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.EdgeLocator.prototype.setDirectionReverse = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.QueryRoutesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.QueryRoutesResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.QueryRoutesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.QueryRoutesResponse.displayName = 'proto.lnrpc.QueryRoutesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.QueryRoutesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.QueryRoutesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.QueryRoutesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.QueryRoutesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.QueryRoutesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    routesList: jspb.Message.toObjectList(msg.getRoutesList(),
    proto.lnrpc.Route.toObject, includeInstance),
    successProb: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.QueryRoutesResponse}
 */
proto.lnrpc.QueryRoutesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.QueryRoutesResponse;
  return proto.lnrpc.QueryRoutesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.QueryRoutesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.QueryRoutesResponse}
 */
proto.lnrpc.QueryRoutesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Route;
      reader.readMessage(value,proto.lnrpc.Route.deserializeBinaryFromReader);
      msg.addRoutes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSuccessProb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.QueryRoutesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.QueryRoutesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.QueryRoutesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.QueryRoutesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.Route.serializeBinaryToWriter
    );
  }
  f = message.getSuccessProb();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * repeated Route routes = 1;
 * @return {!Array<!proto.lnrpc.Route>}
 */
proto.lnrpc.QueryRoutesResponse.prototype.getRoutesList = function() {
  return /** @type{!Array<!proto.lnrpc.Route>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Route, 1));
};


/** @param {!Array<!proto.lnrpc.Route>} value */
proto.lnrpc.QueryRoutesResponse.prototype.setRoutesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.Route=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Route}
 */
proto.lnrpc.QueryRoutesResponse.prototype.addRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.Route, opt_index);
};


proto.lnrpc.QueryRoutesResponse.prototype.clearRoutesList = function() {
  this.setRoutesList([]);
};


/**
 * optional double success_prob = 2;
 * @return {number}
 */
proto.lnrpc.QueryRoutesResponse.prototype.getSuccessProb = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.lnrpc.QueryRoutesResponse.prototype.setSuccessProb = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Hop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Hop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Hop.displayName = 'proto.lnrpc.Hop';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Hop.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Hop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Hop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Hop.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    chanCapacity: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    amtToForward: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    fee: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    expiry: jspb.Message.getFieldWithDefault(msg, 5, 0),
    amtToForwardMsat: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    feeMsat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    pubKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tlvPayload: jspb.Message.getFieldWithDefault(msg, 9, false),
    mppRecord: (f = msg.getMppRecord()) && proto.lnrpc.MPPRecord.toObject(includeInstance, f),
    ampRecord: (f = msg.getAmpRecord()) && proto.lnrpc.AMPRecord.toObject(includeInstance, f),
    customRecordsMap: (f = msg.getCustomRecordsMap()) ? f.toObject(includeInstance, undefined) : [],
    metadata: msg.getMetadata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Hop}
 */
proto.lnrpc.Hop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Hop;
  return proto.lnrpc.Hop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Hop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Hop}
 */
proto.lnrpc.Hop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setChanCapacity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtToForward(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtToForwardMsat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeeMsat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTlvPayload(value);
      break;
    case 10:
      var value = new proto.lnrpc.MPPRecord;
      reader.readMessage(value,proto.lnrpc.MPPRecord.deserializeBinaryFromReader);
      msg.setMppRecord(value);
      break;
    case 12:
      var value = new proto.lnrpc.AMPRecord;
      reader.readMessage(value,proto.lnrpc.AMPRecord.deserializeBinaryFromReader);
      msg.setAmpRecord(value);
      break;
    case 11:
      var value = msg.getCustomRecordsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint64, jspb.BinaryReader.prototype.readBytes, null, 0);
         });
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Hop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Hop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Hop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Hop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getChanCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getAmtToForward();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getExpiry();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAmtToForwardMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getFeeMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTlvPayload();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMppRecord();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.lnrpc.MPPRecord.serializeBinaryToWriter
    );
  }
  f = message.getAmpRecord();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.lnrpc.AMPRecord.serializeBinaryToWriter
    );
  }
  f = message.getCustomRecordsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeUint64, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getMetadata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 chan_capacity = 2;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getChanCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setChanCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 amt_to_forward = 3;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getAmtToForward = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setAmtToForward = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 fee = 4;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setFee = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint32 expiry = 5;
 * @return {number}
 */
proto.lnrpc.Hop.prototype.getExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.Hop.prototype.setExpiry = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 amt_to_forward_msat = 6;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getAmtToForwardMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setAmtToForwardMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int64 fee_msat = 7;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getFeeMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setFeeMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional string pub_key = 8;
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.lnrpc.Hop.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool tlv_payload = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Hop.prototype.getTlvPayload = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.lnrpc.Hop.prototype.setTlvPayload = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional MPPRecord mpp_record = 10;
 * @return {?proto.lnrpc.MPPRecord}
 */
proto.lnrpc.Hop.prototype.getMppRecord = function() {
  return /** @type{?proto.lnrpc.MPPRecord} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.MPPRecord, 10));
};


/** @param {?proto.lnrpc.MPPRecord|undefined} value */
proto.lnrpc.Hop.prototype.setMppRecord = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.lnrpc.Hop.prototype.clearMppRecord = function() {
  this.setMppRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Hop.prototype.hasMppRecord = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AMPRecord amp_record = 12;
 * @return {?proto.lnrpc.AMPRecord}
 */
proto.lnrpc.Hop.prototype.getAmpRecord = function() {
  return /** @type{?proto.lnrpc.AMPRecord} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.AMPRecord, 12));
};


/** @param {?proto.lnrpc.AMPRecord|undefined} value */
proto.lnrpc.Hop.prototype.setAmpRecord = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


proto.lnrpc.Hop.prototype.clearAmpRecord = function() {
  this.setAmpRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Hop.prototype.hasAmpRecord = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * map<uint64, bytes> custom_records = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!(string|Uint8Array)>}
 */
proto.lnrpc.Hop.prototype.getCustomRecordsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      null));
};


proto.lnrpc.Hop.prototype.clearCustomRecordsMap = function() {
  this.getCustomRecordsMap().clear();
};


/**
 * optional bytes metadata = 13;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Hop.prototype.getMetadata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes metadata = 13;
 * This is a type-conversion wrapper around `getMetadata()`
 * @return {string}
 */
proto.lnrpc.Hop.prototype.getMetadata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMetadata()));
};


/**
 * optional bytes metadata = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMetadata()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Hop.prototype.getMetadata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMetadata()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Hop.prototype.setMetadata = function(value) {
  jspb.Message.setProto3BytesField(this, 13, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.MPPRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.MPPRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.MPPRecord.displayName = 'proto.lnrpc.MPPRecord';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.MPPRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.MPPRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.MPPRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MPPRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentAddr: msg.getPaymentAddr_asB64(),
    totalAmtMsat: jspb.Message.getFieldWithDefault(msg, 10, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.MPPRecord}
 */
proto.lnrpc.MPPRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.MPPRecord;
  return proto.lnrpc.MPPRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.MPPRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.MPPRecord}
 */
proto.lnrpc.MPPRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentAddr(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalAmtMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.MPPRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.MPPRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.MPPRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MPPRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getTotalAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      10,
      f
    );
  }
};


/**
 * optional bytes payment_addr = 11;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.MPPRecord.prototype.getPaymentAddr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes payment_addr = 11;
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {string}
 */
proto.lnrpc.MPPRecord.prototype.getPaymentAddr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentAddr()));
};


/**
 * optional bytes payment_addr = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {!Uint8Array}
 */
proto.lnrpc.MPPRecord.prototype.getPaymentAddr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentAddr()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.MPPRecord.prototype.setPaymentAddr = function(value) {
  jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional int64 total_amt_msat = 10;
 * @return {string}
 */
proto.lnrpc.MPPRecord.prototype.getTotalAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.MPPRecord.prototype.setTotalAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.AMPRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.AMPRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.AMPRecord.displayName = 'proto.lnrpc.AMPRecord';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.AMPRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.AMPRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.AMPRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AMPRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootShare: msg.getRootShare_asB64(),
    setId: msg.getSetId_asB64(),
    childIndex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.AMPRecord}
 */
proto.lnrpc.AMPRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.AMPRecord;
  return proto.lnrpc.AMPRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.AMPRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.AMPRecord}
 */
proto.lnrpc.AMPRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRootShare(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChildIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.AMPRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.AMPRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.AMPRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AMPRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootShare_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSetId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getChildIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional bytes root_share = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AMPRecord.prototype.getRootShare = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes root_share = 1;
 * This is a type-conversion wrapper around `getRootShare()`
 * @return {string}
 */
proto.lnrpc.AMPRecord.prototype.getRootShare_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRootShare()));
};


/**
 * optional bytes root_share = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRootShare()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AMPRecord.prototype.getRootShare_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRootShare()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AMPRecord.prototype.setRootShare = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes set_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AMPRecord.prototype.getSetId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes set_id = 2;
 * This is a type-conversion wrapper around `getSetId()`
 * @return {string}
 */
proto.lnrpc.AMPRecord.prototype.getSetId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSetId()));
};


/**
 * optional bytes set_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSetId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AMPRecord.prototype.getSetId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSetId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AMPRecord.prototype.setSetId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional uint32 child_index = 3;
 * @return {number}
 */
proto.lnrpc.AMPRecord.prototype.getChildIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.AMPRecord.prototype.setChildIndex = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Route = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Route.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Route.displayName = 'proto.lnrpc.Route';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Route.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Route.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Route.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Route} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Route.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalTimeLock: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalFees: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    totalAmt: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    hopsList: jspb.Message.toObjectList(msg.getHopsList(),
    proto.lnrpc.Hop.toObject, includeInstance),
    totalFeesMsat: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    totalAmtMsat: jspb.Message.getFieldWithDefault(msg, 6, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Route}
 */
proto.lnrpc.Route.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Route;
  return proto.lnrpc.Route.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Route}
 */
proto.lnrpc.Route.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalTimeLock(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalFees(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalAmt(value);
      break;
    case 4:
      var value = new proto.lnrpc.Hop;
      reader.readMessage(value,proto.lnrpc.Hop.deserializeBinaryFromReader);
      msg.addHops(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalFeesMsat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalAmtMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Route.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Route.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalTimeLock();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalFees();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getTotalAmt();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getHopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.lnrpc.Hop.serializeBinaryToWriter
    );
  }
  f = message.getTotalFeesMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getTotalAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
};


/**
 * optional uint32 total_time_lock = 1;
 * @return {number}
 */
proto.lnrpc.Route.prototype.getTotalTimeLock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.Route.prototype.setTotalTimeLock = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 total_fees = 2;
 * @return {string}
 */
proto.lnrpc.Route.prototype.getTotalFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.Route.prototype.setTotalFees = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 total_amt = 3;
 * @return {string}
 */
proto.lnrpc.Route.prototype.getTotalAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.Route.prototype.setTotalAmt = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * repeated Hop hops = 4;
 * @return {!Array<!proto.lnrpc.Hop>}
 */
proto.lnrpc.Route.prototype.getHopsList = function() {
  return /** @type{!Array<!proto.lnrpc.Hop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Hop, 4));
};


/** @param {!Array<!proto.lnrpc.Hop>} value */
proto.lnrpc.Route.prototype.setHopsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.lnrpc.Hop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Hop}
 */
proto.lnrpc.Route.prototype.addHops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.lnrpc.Hop, opt_index);
};


proto.lnrpc.Route.prototype.clearHopsList = function() {
  this.setHopsList([]);
};


/**
 * optional int64 total_fees_msat = 5;
 * @return {string}
 */
proto.lnrpc.Route.prototype.getTotalFeesMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.Route.prototype.setTotalFeesMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 total_amt_msat = 6;
 * @return {string}
 */
proto.lnrpc.Route.prototype.getTotalAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.Route.prototype.setTotalAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodeInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NodeInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodeInfoRequest.displayName = 'proto.lnrpc.NodeInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodeInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodeInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodeInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pubKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeChannels: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodeInfoRequest}
 */
proto.lnrpc.NodeInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodeInfoRequest;
  return proto.lnrpc.NodeInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodeInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodeInfoRequest}
 */
proto.lnrpc.NodeInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodeInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodeInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeChannels();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string pub_key = 1;
 * @return {string}
 */
proto.lnrpc.NodeInfoRequest.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.NodeInfoRequest.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_channels = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.NodeInfoRequest.prototype.getIncludeChannels = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.NodeInfoRequest.prototype.setIncludeChannels = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.NodeInfo.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.NodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodeInfo.displayName = 'proto.lnrpc.NodeInfo';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.NodeInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.lnrpc.LightningNode.toObject(includeInstance, f),
    numChannels: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalCapacity: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.lnrpc.ChannelEdge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodeInfo}
 */
proto.lnrpc.NodeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodeInfo;
  return proto.lnrpc.NodeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodeInfo}
 */
proto.lnrpc.NodeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.LightningNode;
      reader.readMessage(value,proto.lnrpc.LightningNode.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumChannels(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalCapacity(value);
      break;
    case 4:
      var value = new proto.lnrpc.ChannelEdge;
      reader.readMessage(value,proto.lnrpc.ChannelEdge.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.LightningNode.serializeBinaryToWriter
    );
  }
  f = message.getNumChannels();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.lnrpc.ChannelEdge.serializeBinaryToWriter
    );
  }
};


/**
 * optional LightningNode node = 1;
 * @return {?proto.lnrpc.LightningNode}
 */
proto.lnrpc.NodeInfo.prototype.getNode = function() {
  return /** @type{?proto.lnrpc.LightningNode} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.LightningNode, 1));
};


/** @param {?proto.lnrpc.LightningNode|undefined} value */
proto.lnrpc.NodeInfo.prototype.setNode = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.NodeInfo.prototype.clearNode = function() {
  this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.NodeInfo.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 num_channels = 2;
 * @return {number}
 */
proto.lnrpc.NodeInfo.prototype.getNumChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.NodeInfo.prototype.setNumChannels = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_capacity = 3;
 * @return {string}
 */
proto.lnrpc.NodeInfo.prototype.getTotalCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.NodeInfo.prototype.setTotalCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * repeated ChannelEdge channels = 4;
 * @return {!Array<!proto.lnrpc.ChannelEdge>}
 */
proto.lnrpc.NodeInfo.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelEdge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelEdge, 4));
};


/** @param {!Array<!proto.lnrpc.ChannelEdge>} value */
proto.lnrpc.NodeInfo.prototype.setChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.lnrpc.ChannelEdge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelEdge}
 */
proto.lnrpc.NodeInfo.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.lnrpc.ChannelEdge, opt_index);
};


proto.lnrpc.NodeInfo.prototype.clearChannelsList = function() {
  this.setChannelsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.LightningNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.LightningNode.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.LightningNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.LightningNode.displayName = 'proto.lnrpc.LightningNode';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.LightningNode.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.LightningNode.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.LightningNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.LightningNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.LightningNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pubKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    proto.lnrpc.NodeAddress.toObject, includeInstance),
    color: jspb.Message.getFieldWithDefault(msg, 5, ""),
    featuresMap: (f = msg.getFeaturesMap()) ? f.toObject(includeInstance, proto.lnrpc.Feature.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.LightningNode}
 */
proto.lnrpc.LightningNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.LightningNode;
  return proto.lnrpc.LightningNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.LightningNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.LightningNode}
 */
proto.lnrpc.LightningNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPubKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 4:
      var value = new proto.lnrpc.NodeAddress;
      reader.readMessage(value,proto.lnrpc.NodeAddress.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 6:
      var value = msg.getFeaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.Feature.deserializeBinaryFromReader, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.LightningNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.LightningNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.LightningNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.LightningNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastUpdate();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPubKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.lnrpc.NodeAddress.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFeaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.Feature.serializeBinaryToWriter);
  }
};


/**
 * optional uint32 last_update = 1;
 * @return {number}
 */
proto.lnrpc.LightningNode.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.LightningNode.prototype.setLastUpdate = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string pub_key = 2;
 * @return {string}
 */
proto.lnrpc.LightningNode.prototype.getPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.LightningNode.prototype.setPubKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alias = 3;
 * @return {string}
 */
proto.lnrpc.LightningNode.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.LightningNode.prototype.setAlias = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated NodeAddress addresses = 4;
 * @return {!Array<!proto.lnrpc.NodeAddress>}
 */
proto.lnrpc.LightningNode.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.lnrpc.NodeAddress>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.NodeAddress, 4));
};


/** @param {!Array<!proto.lnrpc.NodeAddress>} value */
proto.lnrpc.LightningNode.prototype.setAddressesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.lnrpc.NodeAddress=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.NodeAddress}
 */
proto.lnrpc.LightningNode.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.lnrpc.NodeAddress, opt_index);
};


proto.lnrpc.LightningNode.prototype.clearAddressesList = function() {
  this.setAddressesList([]);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.lnrpc.LightningNode.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.LightningNode.prototype.setColor = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * map<uint32, Feature> features = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.lnrpc.Feature>}
 */
proto.lnrpc.LightningNode.prototype.getFeaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.lnrpc.Feature>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.lnrpc.Feature));
};


proto.lnrpc.LightningNode.prototype.clearFeaturesMap = function() {
  this.getFeaturesMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodeAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NodeAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodeAddress.displayName = 'proto.lnrpc.NodeAddress';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodeAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodeAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodeAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    network: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addr: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodeAddress}
 */
proto.lnrpc.NodeAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodeAddress;
  return proto.lnrpc.NodeAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodeAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodeAddress}
 */
proto.lnrpc.NodeAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetwork(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodeAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodeAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetwork();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string network = 1;
 * @return {string}
 */
proto.lnrpc.NodeAddress.prototype.getNetwork = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.NodeAddress.prototype.setNetwork = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addr = 2;
 * @return {string}
 */
proto.lnrpc.NodeAddress.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.NodeAddress.prototype.setAddr = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RoutingPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.RoutingPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RoutingPolicy.displayName = 'proto.lnrpc.RoutingPolicy';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RoutingPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RoutingPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RoutingPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RoutingPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeLockDelta: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minHtlc: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    feeBaseMsat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    feeRateMilliMsat: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    disabled: jspb.Message.getFieldWithDefault(msg, 5, false),
    maxHtlcMsat: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RoutingPolicy}
 */
proto.lnrpc.RoutingPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RoutingPolicy;
  return proto.lnrpc.RoutingPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RoutingPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RoutingPolicy}
 */
proto.lnrpc.RoutingPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeLockDelta(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMinHtlc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeeBaseMsat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeeRateMilliMsat(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxHtlcMsat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RoutingPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RoutingPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RoutingPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RoutingPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeLockDelta();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinHtlc();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getFeeBaseMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getFeeRateMilliMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMaxHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getLastUpdate();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional uint32 time_lock_delta = 1;
 * @return {number}
 */
proto.lnrpc.RoutingPolicy.prototype.getTimeLockDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.RoutingPolicy.prototype.setTimeLockDelta = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_htlc = 2;
 * @return {string}
 */
proto.lnrpc.RoutingPolicy.prototype.getMinHtlc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.RoutingPolicy.prototype.setMinHtlc = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 fee_base_msat = 3;
 * @return {string}
 */
proto.lnrpc.RoutingPolicy.prototype.getFeeBaseMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.RoutingPolicy.prototype.setFeeBaseMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 fee_rate_milli_msat = 4;
 * @return {string}
 */
proto.lnrpc.RoutingPolicy.prototype.getFeeRateMilliMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.RoutingPolicy.prototype.setFeeRateMilliMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional bool disabled = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.RoutingPolicy.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.lnrpc.RoutingPolicy.prototype.setDisabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional uint64 max_htlc_msat = 6;
 * @return {string}
 */
proto.lnrpc.RoutingPolicy.prototype.getMaxHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.RoutingPolicy.prototype.setMaxHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint32 last_update = 7;
 * @return {number}
 */
proto.lnrpc.RoutingPolicy.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.lnrpc.RoutingPolicy.prototype.setLastUpdate = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelEdge.displayName = 'proto.lnrpc.ChannelEdge';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    chanPoint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    node1Pub: jspb.Message.getFieldWithDefault(msg, 4, ""),
    node2Pub: jspb.Message.getFieldWithDefault(msg, 5, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    node1Policy: (f = msg.getNode1Policy()) && proto.lnrpc.RoutingPolicy.toObject(includeInstance, f),
    node2Policy: (f = msg.getNode2Policy()) && proto.lnrpc.RoutingPolicy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelEdge}
 */
proto.lnrpc.ChannelEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelEdge;
  return proto.lnrpc.ChannelEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelEdge}
 */
proto.lnrpc.ChannelEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChanPoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNode1Pub(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNode2Pub(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCapacity(value);
      break;
    case 7:
      var value = new proto.lnrpc.RoutingPolicy;
      reader.readMessage(value,proto.lnrpc.RoutingPolicy.deserializeBinaryFromReader);
      msg.setNode1Policy(value);
      break;
    case 8:
      var value = new proto.lnrpc.RoutingPolicy;
      reader.readMessage(value,proto.lnrpc.RoutingPolicy.deserializeBinaryFromReader);
      msg.setNode2Policy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getChanPoint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastUpdate();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNode1Pub();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNode2Pub();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getNode1Policy();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.lnrpc.RoutingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getNode2Policy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.lnrpc.RoutingPolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 channel_id = 1;
 * @return {string}
 */
proto.lnrpc.ChannelEdge.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelEdge.prototype.setChannelId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional string chan_point = 2;
 * @return {string}
 */
proto.lnrpc.ChannelEdge.prototype.getChanPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelEdge.prototype.setChanPoint = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 last_update = 3;
 * @return {number}
 */
proto.lnrpc.ChannelEdge.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelEdge.prototype.setLastUpdate = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string node1_pub = 4;
 * @return {string}
 */
proto.lnrpc.ChannelEdge.prototype.getNode1Pub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelEdge.prototype.setNode1Pub = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string node2_pub = 5;
 * @return {string}
 */
proto.lnrpc.ChannelEdge.prototype.getNode2Pub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelEdge.prototype.setNode2Pub = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 capacity = 6;
 * @return {string}
 */
proto.lnrpc.ChannelEdge.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelEdge.prototype.setCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional RoutingPolicy node1_policy = 7;
 * @return {?proto.lnrpc.RoutingPolicy}
 */
proto.lnrpc.ChannelEdge.prototype.getNode1Policy = function() {
  return /** @type{?proto.lnrpc.RoutingPolicy} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.RoutingPolicy, 7));
};


/** @param {?proto.lnrpc.RoutingPolicy|undefined} value */
proto.lnrpc.ChannelEdge.prototype.setNode1Policy = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.lnrpc.ChannelEdge.prototype.clearNode1Policy = function() {
  this.setNode1Policy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEdge.prototype.hasNode1Policy = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RoutingPolicy node2_policy = 8;
 * @return {?proto.lnrpc.RoutingPolicy}
 */
proto.lnrpc.ChannelEdge.prototype.getNode2Policy = function() {
  return /** @type{?proto.lnrpc.RoutingPolicy} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.RoutingPolicy, 8));
};


/** @param {?proto.lnrpc.RoutingPolicy|undefined} value */
proto.lnrpc.ChannelEdge.prototype.setNode2Policy = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.lnrpc.ChannelEdge.prototype.clearNode2Policy = function() {
  this.setNode2Policy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEdge.prototype.hasNode2Policy = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelGraphRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelGraphRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelGraphRequest.displayName = 'proto.lnrpc.ChannelGraphRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelGraphRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelGraphRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelGraphRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelGraphRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeUnannounced: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelGraphRequest}
 */
proto.lnrpc.ChannelGraphRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelGraphRequest;
  return proto.lnrpc.ChannelGraphRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelGraphRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelGraphRequest}
 */
proto.lnrpc.ChannelGraphRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeUnannounced(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelGraphRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelGraphRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelGraphRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelGraphRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeUnannounced();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_unannounced = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ChannelGraphRequest.prototype.getIncludeUnannounced = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ChannelGraphRequest.prototype.setIncludeUnannounced = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelGraph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ChannelGraph.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ChannelGraph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelGraph.displayName = 'proto.lnrpc.ChannelGraph';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ChannelGraph.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelGraph.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelGraph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelGraph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelGraph.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.lnrpc.LightningNode.toObject, includeInstance),
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    proto.lnrpc.ChannelEdge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelGraph}
 */
proto.lnrpc.ChannelGraph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelGraph;
  return proto.lnrpc.ChannelGraph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelGraph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelGraph}
 */
proto.lnrpc.ChannelGraph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.LightningNode;
      reader.readMessage(value,proto.lnrpc.LightningNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 2:
      var value = new proto.lnrpc.ChannelEdge;
      reader.readMessage(value,proto.lnrpc.ChannelEdge.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelGraph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelGraph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelGraph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelGraph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.LightningNode.serializeBinaryToWriter
    );
  }
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.lnrpc.ChannelEdge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LightningNode nodes = 1;
 * @return {!Array<!proto.lnrpc.LightningNode>}
 */
proto.lnrpc.ChannelGraph.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.lnrpc.LightningNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.LightningNode, 1));
};


/** @param {!Array<!proto.lnrpc.LightningNode>} value */
proto.lnrpc.ChannelGraph.prototype.setNodesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.LightningNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.LightningNode}
 */
proto.lnrpc.ChannelGraph.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.LightningNode, opt_index);
};


proto.lnrpc.ChannelGraph.prototype.clearNodesList = function() {
  this.setNodesList([]);
};


/**
 * repeated ChannelEdge edges = 2;
 * @return {!Array<!proto.lnrpc.ChannelEdge>}
 */
proto.lnrpc.ChannelGraph.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelEdge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelEdge, 2));
};


/** @param {!Array<!proto.lnrpc.ChannelEdge>} value */
proto.lnrpc.ChannelGraph.prototype.setEdgesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.lnrpc.ChannelEdge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelEdge}
 */
proto.lnrpc.ChannelGraph.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.lnrpc.ChannelEdge, opt_index);
};


proto.lnrpc.ChannelGraph.prototype.clearEdgesList = function() {
  this.setEdgesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodeMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.NodeMetricsRequest.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.NodeMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodeMetricsRequest.displayName = 'proto.lnrpc.NodeMetricsRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.NodeMetricsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodeMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodeMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodeMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    typesList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodeMetricsRequest}
 */
proto.lnrpc.NodeMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodeMetricsRequest;
  return proto.lnrpc.NodeMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodeMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodeMetricsRequest}
 */
proto.lnrpc.NodeMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.lnrpc.NodeMetricType>} */ (reader.readPackedEnum());
      msg.setTypesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodeMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodeMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated NodeMetricType types = 1;
 * @return {!Array<!proto.lnrpc.NodeMetricType>}
 */
proto.lnrpc.NodeMetricsRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.lnrpc.NodeMetricType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<!proto.lnrpc.NodeMetricType>} value */
proto.lnrpc.NodeMetricsRequest.prototype.setTypesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.lnrpc.NodeMetricType} value
 * @param {number=} opt_index
 */
proto.lnrpc.NodeMetricsRequest.prototype.addTypes = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.lnrpc.NodeMetricsRequest.prototype.clearTypesList = function() {
  this.setTypesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodeMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NodeMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodeMetricsResponse.displayName = 'proto.lnrpc.NodeMetricsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodeMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodeMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodeMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    betweennessCentralityMap: (f = msg.getBetweennessCentralityMap()) ? f.toObject(includeInstance, proto.lnrpc.FloatMetric.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodeMetricsResponse}
 */
proto.lnrpc.NodeMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodeMetricsResponse;
  return proto.lnrpc.NodeMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodeMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodeMetricsResponse}
 */
proto.lnrpc.NodeMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getBetweennessCentralityMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.FloatMetric.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodeMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodeMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBetweennessCentralityMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.FloatMetric.serializeBinaryToWriter);
  }
};


/**
 * map<string, FloatMetric> betweenness_centrality = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.lnrpc.FloatMetric>}
 */
proto.lnrpc.NodeMetricsResponse.prototype.getBetweennessCentralityMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.lnrpc.FloatMetric>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.lnrpc.FloatMetric));
};


proto.lnrpc.NodeMetricsResponse.prototype.clearBetweennessCentralityMap = function() {
  this.getBetweennessCentralityMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FloatMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FloatMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FloatMetric.displayName = 'proto.lnrpc.FloatMetric';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FloatMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FloatMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FloatMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FloatMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    normalizedValue: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FloatMetric}
 */
proto.lnrpc.FloatMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FloatMetric;
  return proto.lnrpc.FloatMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FloatMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FloatMetric}
 */
proto.lnrpc.FloatMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNormalizedValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FloatMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FloatMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FloatMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FloatMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNormalizedValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.lnrpc.FloatMetric.prototype.getValue = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.lnrpc.FloatMetric.prototype.setValue = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double normalized_value = 2;
 * @return {number}
 */
proto.lnrpc.FloatMetric.prototype.getNormalizedValue = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.lnrpc.FloatMetric.prototype.setNormalizedValue = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChanInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChanInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChanInfoRequest.displayName = 'proto.lnrpc.ChanInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChanInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChanInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChanInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanId: jspb.Message.getFieldWithDefault(msg, 1, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChanInfoRequest}
 */
proto.lnrpc.ChanInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChanInfoRequest;
  return proto.lnrpc.ChanInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChanInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChanInfoRequest}
 */
proto.lnrpc.ChanInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChanInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChanInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChanInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {string}
 */
proto.lnrpc.ChanInfoRequest.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ChanInfoRequest.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NetworkInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NetworkInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NetworkInfoRequest.displayName = 'proto.lnrpc.NetworkInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NetworkInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NetworkInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NetworkInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NetworkInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NetworkInfoRequest}
 */
proto.lnrpc.NetworkInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NetworkInfoRequest;
  return proto.lnrpc.NetworkInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NetworkInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NetworkInfoRequest}
 */
proto.lnrpc.NetworkInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NetworkInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NetworkInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NetworkInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NetworkInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NetworkInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.NetworkInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NetworkInfo.displayName = 'proto.lnrpc.NetworkInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NetworkInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NetworkInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NetworkInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NetworkInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    graphDiameter: jspb.Message.getFieldWithDefault(msg, 1, 0),
    avgOutDegree: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    maxOutDegree: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numNodes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numChannels: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalNetworkCapacity: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    avgChannelSize: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    minChannelSize: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    maxChannelSize: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    medianChannelSizeSat: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    numZombieChans: jspb.Message.getFieldWithDefault(msg, 11, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NetworkInfo}
 */
proto.lnrpc.NetworkInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NetworkInfo;
  return proto.lnrpc.NetworkInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NetworkInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NetworkInfo}
 */
proto.lnrpc.NetworkInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGraphDiameter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgOutDegree(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxOutDegree(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumNodes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumChannels(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTotalNetworkCapacity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgChannelSize(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMinChannelSize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMaxChannelSize(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setMedianChannelSizeSat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setNumZombieChans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NetworkInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NetworkInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NetworkInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NetworkInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraphDiameter();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAvgOutDegree();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMaxOutDegree();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNumNodes();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getNumChannels();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTotalNetworkCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getAvgChannelSize();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMinChannelSize();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      8,
      f
    );
  }
  f = message.getMaxChannelSize();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getMedianChannelSizeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      10,
      f
    );
  }
  f = message.getNumZombieChans();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
};


/**
 * optional uint32 graph_diameter = 1;
 * @return {number}
 */
proto.lnrpc.NetworkInfo.prototype.getGraphDiameter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.lnrpc.NetworkInfo.prototype.setGraphDiameter = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double avg_out_degree = 2;
 * @return {number}
 */
proto.lnrpc.NetworkInfo.prototype.getAvgOutDegree = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.lnrpc.NetworkInfo.prototype.setAvgOutDegree = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint32 max_out_degree = 3;
 * @return {number}
 */
proto.lnrpc.NetworkInfo.prototype.getMaxOutDegree = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.NetworkInfo.prototype.setMaxOutDegree = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 num_nodes = 4;
 * @return {number}
 */
proto.lnrpc.NetworkInfo.prototype.getNumNodes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.NetworkInfo.prototype.setNumNodes = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 num_channels = 5;
 * @return {number}
 */
proto.lnrpc.NetworkInfo.prototype.getNumChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.NetworkInfo.prototype.setNumChannels = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 total_network_capacity = 6;
 * @return {string}
 */
proto.lnrpc.NetworkInfo.prototype.getTotalNetworkCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.NetworkInfo.prototype.setTotalNetworkCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional double avg_channel_size = 7;
 * @return {number}
 */
proto.lnrpc.NetworkInfo.prototype.getAvgChannelSize = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.lnrpc.NetworkInfo.prototype.setAvgChannelSize = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 min_channel_size = 8;
 * @return {string}
 */
proto.lnrpc.NetworkInfo.prototype.getMinChannelSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.NetworkInfo.prototype.setMinChannelSize = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional int64 max_channel_size = 9;
 * @return {string}
 */
proto.lnrpc.NetworkInfo.prototype.getMaxChannelSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.NetworkInfo.prototype.setMaxChannelSize = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional int64 median_channel_size_sat = 10;
 * @return {string}
 */
proto.lnrpc.NetworkInfo.prototype.getMedianChannelSizeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.NetworkInfo.prototype.setMedianChannelSizeSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional uint64 num_zombie_chans = 11;
 * @return {string}
 */
proto.lnrpc.NetworkInfo.prototype.getNumZombieChans = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/** @param {string} value */
proto.lnrpc.NetworkInfo.prototype.setNumZombieChans = function(value) {
  jspb.Message.setProto3StringIntField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.StopRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.StopRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.StopRequest.displayName = 'proto.lnrpc.StopRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.StopRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.StopRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.StopRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.StopRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.StopRequest}
 */
proto.lnrpc.StopRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.StopRequest;
  return proto.lnrpc.StopRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.StopRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.StopRequest}
 */
proto.lnrpc.StopRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.StopRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.StopRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.StopRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.StopRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.StopResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.StopResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.StopResponse.displayName = 'proto.lnrpc.StopResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.StopResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.StopResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.StopResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.StopResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.StopResponse}
 */
proto.lnrpc.StopResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.StopResponse;
  return proto.lnrpc.StopResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.StopResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.StopResponse}
 */
proto.lnrpc.StopResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.StopResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.StopResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.StopResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.StopResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GraphTopologySubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.GraphTopologySubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GraphTopologySubscription.displayName = 'proto.lnrpc.GraphTopologySubscription';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GraphTopologySubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GraphTopologySubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GraphTopologySubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GraphTopologySubscription.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GraphTopologySubscription}
 */
proto.lnrpc.GraphTopologySubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GraphTopologySubscription;
  return proto.lnrpc.GraphTopologySubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GraphTopologySubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GraphTopologySubscription}
 */
proto.lnrpc.GraphTopologySubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GraphTopologySubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GraphTopologySubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GraphTopologySubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GraphTopologySubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.GraphTopologyUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.GraphTopologyUpdate.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.GraphTopologyUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.GraphTopologyUpdate.displayName = 'proto.lnrpc.GraphTopologyUpdate';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.GraphTopologyUpdate.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.GraphTopologyUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.GraphTopologyUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GraphTopologyUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeUpdatesList: jspb.Message.toObjectList(msg.getNodeUpdatesList(),
    proto.lnrpc.NodeUpdate.toObject, includeInstance),
    channelUpdatesList: jspb.Message.toObjectList(msg.getChannelUpdatesList(),
    proto.lnrpc.ChannelEdgeUpdate.toObject, includeInstance),
    closedChansList: jspb.Message.toObjectList(msg.getClosedChansList(),
    proto.lnrpc.ClosedChannelUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.GraphTopologyUpdate}
 */
proto.lnrpc.GraphTopologyUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.GraphTopologyUpdate;
  return proto.lnrpc.GraphTopologyUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.GraphTopologyUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.GraphTopologyUpdate}
 */
proto.lnrpc.GraphTopologyUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.NodeUpdate;
      reader.readMessage(value,proto.lnrpc.NodeUpdate.deserializeBinaryFromReader);
      msg.addNodeUpdates(value);
      break;
    case 2:
      var value = new proto.lnrpc.ChannelEdgeUpdate;
      reader.readMessage(value,proto.lnrpc.ChannelEdgeUpdate.deserializeBinaryFromReader);
      msg.addChannelUpdates(value);
      break;
    case 3:
      var value = new proto.lnrpc.ClosedChannelUpdate;
      reader.readMessage(value,proto.lnrpc.ClosedChannelUpdate.deserializeBinaryFromReader);
      msg.addClosedChans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.GraphTopologyUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.GraphTopologyUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.GraphTopologyUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.NodeUpdate.serializeBinaryToWriter
    );
  }
  f = message.getChannelUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.lnrpc.ChannelEdgeUpdate.serializeBinaryToWriter
    );
  }
  f = message.getClosedChansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.lnrpc.ClosedChannelUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeUpdate node_updates = 1;
 * @return {!Array<!proto.lnrpc.NodeUpdate>}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.getNodeUpdatesList = function() {
  return /** @type{!Array<!proto.lnrpc.NodeUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.NodeUpdate, 1));
};


/** @param {!Array<!proto.lnrpc.NodeUpdate>} value */
proto.lnrpc.GraphTopologyUpdate.prototype.setNodeUpdatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.NodeUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.NodeUpdate}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.addNodeUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.NodeUpdate, opt_index);
};


proto.lnrpc.GraphTopologyUpdate.prototype.clearNodeUpdatesList = function() {
  this.setNodeUpdatesList([]);
};


/**
 * repeated ChannelEdgeUpdate channel_updates = 2;
 * @return {!Array<!proto.lnrpc.ChannelEdgeUpdate>}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.getChannelUpdatesList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelEdgeUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelEdgeUpdate, 2));
};


/** @param {!Array<!proto.lnrpc.ChannelEdgeUpdate>} value */
proto.lnrpc.GraphTopologyUpdate.prototype.setChannelUpdatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.lnrpc.ChannelEdgeUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelEdgeUpdate}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.addChannelUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.lnrpc.ChannelEdgeUpdate, opt_index);
};


proto.lnrpc.GraphTopologyUpdate.prototype.clearChannelUpdatesList = function() {
  this.setChannelUpdatesList([]);
};


/**
 * repeated ClosedChannelUpdate closed_chans = 3;
 * @return {!Array<!proto.lnrpc.ClosedChannelUpdate>}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.getClosedChansList = function() {
  return /** @type{!Array<!proto.lnrpc.ClosedChannelUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ClosedChannelUpdate, 3));
};


/** @param {!Array<!proto.lnrpc.ClosedChannelUpdate>} value */
proto.lnrpc.GraphTopologyUpdate.prototype.setClosedChansList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.lnrpc.ClosedChannelUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ClosedChannelUpdate}
 */
proto.lnrpc.GraphTopologyUpdate.prototype.addClosedChans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.lnrpc.ClosedChannelUpdate, opt_index);
};


proto.lnrpc.GraphTopologyUpdate.prototype.clearClosedChansList = function() {
  this.setClosedChansList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.NodeUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.NodeUpdate.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.NodeUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.NodeUpdate.displayName = 'proto.lnrpc.NodeUpdate';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.NodeUpdate.repeatedFields_ = [1,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.NodeUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.NodeUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.NodeUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressesList: jspb.Message.getRepeatedField(msg, 1),
    identityKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    globalFeatures: msg.getGlobalFeatures_asB64(),
    alias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    color: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nodeAddressesList: jspb.Message.toObjectList(msg.getNodeAddressesList(),
    proto.lnrpc.NodeAddress.toObject, includeInstance),
    featuresMap: (f = msg.getFeaturesMap()) ? f.toObject(includeInstance, proto.lnrpc.Feature.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.NodeUpdate}
 */
proto.lnrpc.NodeUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.NodeUpdate;
  return proto.lnrpc.NodeUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.NodeUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.NodeUpdate}
 */
proto.lnrpc.NodeUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddresses(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGlobalFeatures(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 7:
      var value = new proto.lnrpc.NodeAddress;
      reader.readMessage(value,proto.lnrpc.NodeAddress.deserializeBinaryFromReader);
      msg.addNodeAddresses(value);
      break;
    case 6:
      var value = msg.getFeaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.Feature.deserializeBinaryFromReader, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.NodeUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.NodeUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.NodeUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIdentityKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGlobalFeatures_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNodeAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.lnrpc.NodeAddress.serializeBinaryToWriter
    );
  }
  f = message.getFeaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.Feature.serializeBinaryToWriter);
  }
};


/**
 * repeated string addresses = 1;
 * @return {!Array<string>}
 */
proto.lnrpc.NodeUpdate.prototype.getAddressesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.lnrpc.NodeUpdate.prototype.setAddressesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.lnrpc.NodeUpdate.prototype.addAddresses = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.lnrpc.NodeUpdate.prototype.clearAddressesList = function() {
  this.setAddressesList([]);
};


/**
 * optional string identity_key = 2;
 * @return {string}
 */
proto.lnrpc.NodeUpdate.prototype.getIdentityKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.NodeUpdate.prototype.setIdentityKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes global_features = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.NodeUpdate.prototype.getGlobalFeatures = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes global_features = 3;
 * This is a type-conversion wrapper around `getGlobalFeatures()`
 * @return {string}
 */
proto.lnrpc.NodeUpdate.prototype.getGlobalFeatures_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGlobalFeatures()));
};


/**
 * optional bytes global_features = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGlobalFeatures()`
 * @return {!Uint8Array}
 */
proto.lnrpc.NodeUpdate.prototype.getGlobalFeatures_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGlobalFeatures()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.NodeUpdate.prototype.setGlobalFeatures = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string alias = 4;
 * @return {string}
 */
proto.lnrpc.NodeUpdate.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.lnrpc.NodeUpdate.prototype.setAlias = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.lnrpc.NodeUpdate.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.NodeUpdate.prototype.setColor = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated NodeAddress node_addresses = 7;
 * @return {!Array<!proto.lnrpc.NodeAddress>}
 */
proto.lnrpc.NodeUpdate.prototype.getNodeAddressesList = function() {
  return /** @type{!Array<!proto.lnrpc.NodeAddress>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.NodeAddress, 7));
};


/** @param {!Array<!proto.lnrpc.NodeAddress>} value */
proto.lnrpc.NodeUpdate.prototype.setNodeAddressesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.lnrpc.NodeAddress=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.NodeAddress}
 */
proto.lnrpc.NodeUpdate.prototype.addNodeAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.lnrpc.NodeAddress, opt_index);
};


proto.lnrpc.NodeUpdate.prototype.clearNodeAddressesList = function() {
  this.setNodeAddressesList([]);
};


/**
 * map<uint32, Feature> features = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.lnrpc.Feature>}
 */
proto.lnrpc.NodeUpdate.prototype.getFeaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.lnrpc.Feature>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.lnrpc.Feature));
};


proto.lnrpc.NodeUpdate.prototype.clearFeaturesMap = function() {
  this.getFeaturesMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelEdgeUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelEdgeUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelEdgeUpdate.displayName = 'proto.lnrpc.ChannelEdgeUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelEdgeUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelEdgeUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEdgeUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    chanPoint: (f = msg.getChanPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    capacity: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    routingPolicy: (f = msg.getRoutingPolicy()) && proto.lnrpc.RoutingPolicy.toObject(includeInstance, f),
    advertisingNode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    connectingNode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelEdgeUpdate}
 */
proto.lnrpc.ChannelEdgeUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelEdgeUpdate;
  return proto.lnrpc.ChannelEdgeUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelEdgeUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelEdgeUpdate}
 */
proto.lnrpc.ChannelEdgeUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 2:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChanPoint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCapacity(value);
      break;
    case 4:
      var value = new proto.lnrpc.RoutingPolicy;
      reader.readMessage(value,proto.lnrpc.RoutingPolicy.deserializeBinaryFromReader);
      msg.setRoutingPolicy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertisingNode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectingNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelEdgeUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelEdgeUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelEdgeUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getChanPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getRoutingPolicy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.RoutingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getAdvertisingNode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConnectingNode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {string}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelEdgeUpdate.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional ChannelPoint chan_point = 2;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.getChanPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 2));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChannelEdgeUpdate.prototype.setChanPoint = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.lnrpc.ChannelEdgeUpdate.prototype.clearChanPoint = function() {
  this.setChanPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.hasChanPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 capacity = 3;
 * @return {string}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelEdgeUpdate.prototype.setCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional RoutingPolicy routing_policy = 4;
 * @return {?proto.lnrpc.RoutingPolicy}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.getRoutingPolicy = function() {
  return /** @type{?proto.lnrpc.RoutingPolicy} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.RoutingPolicy, 4));
};


/** @param {?proto.lnrpc.RoutingPolicy|undefined} value */
proto.lnrpc.ChannelEdgeUpdate.prototype.setRoutingPolicy = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.lnrpc.ChannelEdgeUpdate.prototype.clearRoutingPolicy = function() {
  this.setRoutingPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.hasRoutingPolicy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string advertising_node = 5;
 * @return {string}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.getAdvertisingNode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelEdgeUpdate.prototype.setAdvertisingNode = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string connecting_node = 6;
 * @return {string}
 */
proto.lnrpc.ChannelEdgeUpdate.prototype.getConnectingNode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelEdgeUpdate.prototype.setConnectingNode = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ClosedChannelUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ClosedChannelUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ClosedChannelUpdate.displayName = 'proto.lnrpc.ClosedChannelUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ClosedChannelUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ClosedChannelUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ClosedChannelUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    capacity: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    closedHeight: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chanPoint: (f = msg.getChanPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ClosedChannelUpdate}
 */
proto.lnrpc.ClosedChannelUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ClosedChannelUpdate;
  return proto.lnrpc.ClosedChannelUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ClosedChannelUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ClosedChannelUpdate}
 */
proto.lnrpc.ClosedChannelUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCapacity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClosedHeight(value);
      break;
    case 4:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChanPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ClosedChannelUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ClosedChannelUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ClosedChannelUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getCapacity();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getClosedHeight();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getChanPoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {string}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ClosedChannelUpdate.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional int64 capacity = 2;
 * @return {string}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ClosedChannelUpdate.prototype.setCapacity = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint32 closed_height = 3;
 * @return {number}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.getClosedHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.ClosedChannelUpdate.prototype.setClosedHeight = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ChannelPoint chan_point = 4;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.getChanPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 4));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ClosedChannelUpdate.prototype.setChanPoint = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.lnrpc.ClosedChannelUpdate.prototype.clearChanPoint = function() {
  this.setChanPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ClosedChannelUpdate.prototype.hasChanPoint = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.HopHint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.HopHint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.HopHint.displayName = 'proto.lnrpc.HopHint';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.HopHint.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.HopHint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.HopHint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.HopHint.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chanId: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    feeBaseMsat: jspb.Message.getFieldWithDefault(msg, 3, 0),
    feeProportionalMillionths: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cltvExpiryDelta: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.HopHint}
 */
proto.lnrpc.HopHint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.HopHint;
  return proto.lnrpc.HopHint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.HopHint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.HopHint}
 */
proto.lnrpc.HopHint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFeeBaseMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFeeProportionalMillionths(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCltvExpiryDelta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.HopHint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.HopHint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.HopHint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.HopHint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getFeeBaseMsat();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFeeProportionalMillionths();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCltvExpiryDelta();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.lnrpc.HopHint.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.HopHint.prototype.setNodeId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 chan_id = 2;
 * @return {string}
 */
proto.lnrpc.HopHint.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.HopHint.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint32 fee_base_msat = 3;
 * @return {number}
 */
proto.lnrpc.HopHint.prototype.getFeeBaseMsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.HopHint.prototype.setFeeBaseMsat = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 fee_proportional_millionths = 4;
 * @return {number}
 */
proto.lnrpc.HopHint.prototype.getFeeProportionalMillionths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.HopHint.prototype.setFeeProportionalMillionths = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 cltv_expiry_delta = 5;
 * @return {number}
 */
proto.lnrpc.HopHint.prototype.getCltvExpiryDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.HopHint.prototype.setCltvExpiryDelta = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.SetID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.SetID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.SetID.displayName = 'proto.lnrpc.SetID';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.SetID.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.SetID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.SetID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SetID.toObject = function(includeInstance, msg) {
  var f, obj = {
    setId: msg.getSetId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.SetID}
 */
proto.lnrpc.SetID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.SetID;
  return proto.lnrpc.SetID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.SetID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.SetID}
 */
proto.lnrpc.SetID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.SetID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.SetID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.SetID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.SetID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes set_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.SetID.prototype.getSetId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes set_id = 1;
 * This is a type-conversion wrapper around `getSetId()`
 * @return {string}
 */
proto.lnrpc.SetID.prototype.getSetId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSetId()));
};


/**
 * optional bytes set_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSetId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.SetID.prototype.getSetId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSetId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.SetID.prototype.setSetId = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RouteHint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.RouteHint.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.RouteHint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RouteHint.displayName = 'proto.lnrpc.RouteHint';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.RouteHint.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RouteHint.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RouteHint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RouteHint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RouteHint.toObject = function(includeInstance, msg) {
  var f, obj = {
    hopHintsList: jspb.Message.toObjectList(msg.getHopHintsList(),
    proto.lnrpc.HopHint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RouteHint}
 */
proto.lnrpc.RouteHint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RouteHint;
  return proto.lnrpc.RouteHint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RouteHint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RouteHint}
 */
proto.lnrpc.RouteHint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.HopHint;
      reader.readMessage(value,proto.lnrpc.HopHint.deserializeBinaryFromReader);
      msg.addHopHints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RouteHint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RouteHint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RouteHint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RouteHint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHopHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.HopHint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HopHint hop_hints = 1;
 * @return {!Array<!proto.lnrpc.HopHint>}
 */
proto.lnrpc.RouteHint.prototype.getHopHintsList = function() {
  return /** @type{!Array<!proto.lnrpc.HopHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.HopHint, 1));
};


/** @param {!Array<!proto.lnrpc.HopHint>} value */
proto.lnrpc.RouteHint.prototype.setHopHintsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.HopHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.HopHint}
 */
proto.lnrpc.RouteHint.prototype.addHopHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.HopHint, opt_index);
};


proto.lnrpc.RouteHint.prototype.clearHopHintsList = function() {
  this.setHopHintsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.AMPInvoiceState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.AMPInvoiceState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.AMPInvoiceState.displayName = 'proto.lnrpc.AMPInvoiceState';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.AMPInvoiceState.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.AMPInvoiceState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.AMPInvoiceState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AMPInvoiceState.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settleIndex: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    settleTime: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    amtPaidMsat: jspb.Message.getFieldWithDefault(msg, 5, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.AMPInvoiceState}
 */
proto.lnrpc.AMPInvoiceState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.AMPInvoiceState;
  return proto.lnrpc.AMPInvoiceState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.AMPInvoiceState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.AMPInvoiceState}
 */
proto.lnrpc.AMPInvoiceState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.lnrpc.InvoiceHTLCState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSettleIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSettleTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtPaidMsat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.AMPInvoiceState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.AMPInvoiceState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.AMPInvoiceState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AMPInvoiceState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSettleIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getSettleTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getAmtPaidMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
};


/**
 * optional InvoiceHTLCState state = 1;
 * @return {!proto.lnrpc.InvoiceHTLCState}
 */
proto.lnrpc.AMPInvoiceState.prototype.getState = function() {
  return /** @type {!proto.lnrpc.InvoiceHTLCState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.InvoiceHTLCState} value */
proto.lnrpc.AMPInvoiceState.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 settle_index = 2;
 * @return {string}
 */
proto.lnrpc.AMPInvoiceState.prototype.getSettleIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.AMPInvoiceState.prototype.setSettleIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 settle_time = 3;
 * @return {string}
 */
proto.lnrpc.AMPInvoiceState.prototype.getSettleTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.AMPInvoiceState.prototype.setSettleTime = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 amt_paid_msat = 5;
 * @return {string}
 */
proto.lnrpc.AMPInvoiceState.prototype.getAmtPaidMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.AMPInvoiceState.prototype.setAmtPaidMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Invoice.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Invoice.displayName = 'proto.lnrpc.Invoice';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Invoice.repeatedFields_ = [14,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    memo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rPreimage: msg.getRPreimage_asB64(),
    rHash: msg.getRHash_asB64(),
    value: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    valueMsat: jspb.Message.getFieldWithDefault(msg, 23, "0"),
    settled: jspb.Message.getFieldWithDefault(msg, 6, false),
    creationDate: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    settleDate: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    paymentRequest: jspb.Message.getFieldWithDefault(msg, 9, ""),
    descriptionHash: msg.getDescriptionHash_asB64(),
    expiry: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    fallbackAddr: jspb.Message.getFieldWithDefault(msg, 12, ""),
    cltvExpiry: jspb.Message.getFieldWithDefault(msg, 13, "0"),
    routeHintsList: jspb.Message.toObjectList(msg.getRouteHintsList(),
    proto.lnrpc.RouteHint.toObject, includeInstance),
    pb_private: jspb.Message.getFieldWithDefault(msg, 15, false),
    addIndex: jspb.Message.getFieldWithDefault(msg, 16, "0"),
    settleIndex: jspb.Message.getFieldWithDefault(msg, 17, "0"),
    amtPaid: jspb.Message.getFieldWithDefault(msg, 18, "0"),
    amtPaidSat: jspb.Message.getFieldWithDefault(msg, 19, "0"),
    amtPaidMsat: jspb.Message.getFieldWithDefault(msg, 20, "0"),
    state: jspb.Message.getFieldWithDefault(msg, 21, 0),
    htlcsList: jspb.Message.toObjectList(msg.getHtlcsList(),
    proto.lnrpc.InvoiceHTLC.toObject, includeInstance),
    featuresMap: (f = msg.getFeaturesMap()) ? f.toObject(includeInstance, proto.lnrpc.Feature.toObject) : [],
    isKeysend: jspb.Message.getFieldWithDefault(msg, 25, false),
    paymentAddr: msg.getPaymentAddr_asB64(),
    isAmp: jspb.Message.getFieldWithDefault(msg, 27, false),
    ampInvoiceStateMap: (f = msg.getAmpInvoiceStateMap()) ? f.toObject(includeInstance, proto.lnrpc.AMPInvoiceState.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Invoice}
 */
proto.lnrpc.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Invoice;
  return proto.lnrpc.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Invoice}
 */
proto.lnrpc.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRPreimage(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRHash(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setValue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setValueMsat(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCreationDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSettleDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentRequest(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescriptionHash(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setExpiry(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFallbackAddr(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setCltvExpiry(value);
      break;
    case 14:
      var value = new proto.lnrpc.RouteHint;
      reader.readMessage(value,proto.lnrpc.RouteHint.deserializeBinaryFromReader);
      msg.addRouteHints(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAddIndex(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSettleIndex(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtPaid(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtPaidSat(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAmtPaidMsat(value);
      break;
    case 21:
      var value = /** @type {!proto.lnrpc.Invoice.InvoiceState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 22:
      var value = new proto.lnrpc.InvoiceHTLC;
      reader.readMessage(value,proto.lnrpc.InvoiceHTLC.deserializeBinaryFromReader);
      msg.addHtlcs(value);
      break;
    case 24:
      var value = msg.getFeaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.Feature.deserializeBinaryFromReader, 0);
         });
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsKeysend(value);
      break;
    case 26:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentAddr(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAmp(value);
      break;
    case 28:
      var value = msg.getAmpInvoiceStateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.AMPInvoiceState.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRPreimage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getRHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getValue();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getValueMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      23,
      f
    );
  }
  f = message.getSettled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCreationDate();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getSettleDate();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      8,
      f
    );
  }
  f = message.getPaymentRequest();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescriptionHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = message.getExpiry();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      11,
      f
    );
  }
  f = message.getFallbackAddr();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCltvExpiry();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      13,
      f
    );
  }
  f = message.getRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.lnrpc.RouteHint.serializeBinaryToWriter
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getAddIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      16,
      f
    );
  }
  f = message.getSettleIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      17,
      f
    );
  }
  f = message.getAmtPaid();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      18,
      f
    );
  }
  f = message.getAmtPaidSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      19,
      f
    );
  }
  f = message.getAmtPaidMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      20,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getHtlcsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.lnrpc.InvoiceHTLC.serializeBinaryToWriter
    );
  }
  f = message.getFeaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(24, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.Feature.serializeBinaryToWriter);
  }
  f = message.getIsKeysend();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getPaymentAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      26,
      f
    );
  }
  f = message.getIsAmp();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getAmpInvoiceStateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(28, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.AMPInvoiceState.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.Invoice.InvoiceState = {
  OPEN: 0,
  SETTLED: 1,
  CANCELED: 2,
  ACCEPTED: 3
};

/**
 * optional string memo = 1;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setMemo = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes r_preimage = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Invoice.prototype.getRPreimage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes r_preimage = 3;
 * This is a type-conversion wrapper around `getRPreimage()`
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getRPreimage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRPreimage()));
};


/**
 * optional bytes r_preimage = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRPreimage()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Invoice.prototype.getRPreimage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRPreimage()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Invoice.prototype.setRPreimage = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes r_hash = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Invoice.prototype.getRHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes r_hash = 4;
 * This is a type-conversion wrapper around `getRHash()`
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getRHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRHash()));
};


/**
 * optional bytes r_hash = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Invoice.prototype.getRHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Invoice.prototype.setRHash = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional int64 value = 5;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setValue = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 value_msat = 23;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getValueMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setValueMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 23, value);
};


/**
 * optional bool settled = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Invoice.prototype.getSettled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.lnrpc.Invoice.prototype.setSettled = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 creation_date = 7;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getCreationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setCreationDate = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional int64 settle_date = 8;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getSettleDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setSettleDate = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional string payment_request = 9;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getPaymentRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setPaymentRequest = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bytes description_hash = 10;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Invoice.prototype.getDescriptionHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes description_hash = 10;
 * This is a type-conversion wrapper around `getDescriptionHash()`
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getDescriptionHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescriptionHash()));
};


/**
 * optional bytes description_hash = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescriptionHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Invoice.prototype.getDescriptionHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescriptionHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Invoice.prototype.setDescriptionHash = function(value) {
  jspb.Message.setProto3BytesField(this, 10, value);
};


/**
 * optional int64 expiry = 11;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getExpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setExpiry = function(value) {
  jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional string fallback_addr = 12;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getFallbackAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setFallbackAddr = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint64 cltv_expiry = 13;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getCltvExpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setCltvExpiry = function(value) {
  jspb.Message.setProto3StringIntField(this, 13, value);
};


/**
 * repeated RouteHint route_hints = 14;
 * @return {!Array<!proto.lnrpc.RouteHint>}
 */
proto.lnrpc.Invoice.prototype.getRouteHintsList = function() {
  return /** @type{!Array<!proto.lnrpc.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.RouteHint, 14));
};


/** @param {!Array<!proto.lnrpc.RouteHint>} value */
proto.lnrpc.Invoice.prototype.setRouteHintsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.lnrpc.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.RouteHint}
 */
proto.lnrpc.Invoice.prototype.addRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.lnrpc.RouteHint, opt_index);
};


proto.lnrpc.Invoice.prototype.clearRouteHintsList = function() {
  this.setRouteHintsList([]);
};


/**
 * optional bool private = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Invoice.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.lnrpc.Invoice.prototype.setPrivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint64 add_index = 16;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getAddIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setAddIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 16, value);
};


/**
 * optional uint64 settle_index = 17;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getSettleIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setSettleIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 17, value);
};


/**
 * optional int64 amt_paid = 18;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getAmtPaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setAmtPaid = function(value) {
  jspb.Message.setProto3StringIntField(this, 18, value);
};


/**
 * optional int64 amt_paid_sat = 19;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getAmtPaidSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setAmtPaidSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 19, value);
};


/**
 * optional int64 amt_paid_msat = 20;
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getAmtPaidMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, "0"));
};


/** @param {string} value */
proto.lnrpc.Invoice.prototype.setAmtPaidMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 20, value);
};


/**
 * optional InvoiceState state = 21;
 * @return {!proto.lnrpc.Invoice.InvoiceState}
 */
proto.lnrpc.Invoice.prototype.getState = function() {
  return /** @type {!proto.lnrpc.Invoice.InvoiceState} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {!proto.lnrpc.Invoice.InvoiceState} value */
proto.lnrpc.Invoice.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * repeated InvoiceHTLC htlcs = 22;
 * @return {!Array<!proto.lnrpc.InvoiceHTLC>}
 */
proto.lnrpc.Invoice.prototype.getHtlcsList = function() {
  return /** @type{!Array<!proto.lnrpc.InvoiceHTLC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.InvoiceHTLC, 22));
};


/** @param {!Array<!proto.lnrpc.InvoiceHTLC>} value */
proto.lnrpc.Invoice.prototype.setHtlcsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.lnrpc.InvoiceHTLC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.InvoiceHTLC}
 */
proto.lnrpc.Invoice.prototype.addHtlcs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.lnrpc.InvoiceHTLC, opt_index);
};


proto.lnrpc.Invoice.prototype.clearHtlcsList = function() {
  this.setHtlcsList([]);
};


/**
 * map<uint32, Feature> features = 24;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.lnrpc.Feature>}
 */
proto.lnrpc.Invoice.prototype.getFeaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.lnrpc.Feature>} */ (
      jspb.Message.getMapField(this, 24, opt_noLazyCreate,
      proto.lnrpc.Feature));
};


proto.lnrpc.Invoice.prototype.clearFeaturesMap = function() {
  this.getFeaturesMap().clear();
};


/**
 * optional bool is_keysend = 25;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Invoice.prototype.getIsKeysend = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 25, false));
};


/** @param {boolean} value */
proto.lnrpc.Invoice.prototype.setIsKeysend = function(value) {
  jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bytes payment_addr = 26;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Invoice.prototype.getPaymentAddr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * optional bytes payment_addr = 26;
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {string}
 */
proto.lnrpc.Invoice.prototype.getPaymentAddr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentAddr()));
};


/**
 * optional bytes payment_addr = 26;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Invoice.prototype.getPaymentAddr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentAddr()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Invoice.prototype.setPaymentAddr = function(value) {
  jspb.Message.setProto3BytesField(this, 26, value);
};


/**
 * optional bool is_amp = 27;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Invoice.prototype.getIsAmp = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 27, false));
};


/** @param {boolean} value */
proto.lnrpc.Invoice.prototype.setIsAmp = function(value) {
  jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * map<string, AMPInvoiceState> amp_invoice_state = 28;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.lnrpc.AMPInvoiceState>}
 */
proto.lnrpc.Invoice.prototype.getAmpInvoiceStateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.lnrpc.AMPInvoiceState>} */ (
      jspb.Message.getMapField(this, 28, opt_noLazyCreate,
      proto.lnrpc.AMPInvoiceState));
};


proto.lnrpc.Invoice.prototype.clearAmpInvoiceStateMap = function() {
  this.getAmpInvoiceStateMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.InvoiceHTLC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.InvoiceHTLC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.InvoiceHTLC.displayName = 'proto.lnrpc.InvoiceHTLC';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.InvoiceHTLC.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.InvoiceHTLC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.InvoiceHTLC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.InvoiceHTLC.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    htlcIndex: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    amtMsat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    acceptHeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    acceptTime: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    resolveTime: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    expiryHeight: jspb.Message.getFieldWithDefault(msg, 7, 0),
    state: jspb.Message.getFieldWithDefault(msg, 8, 0),
    customRecordsMap: (f = msg.getCustomRecordsMap()) ? f.toObject(includeInstance, undefined) : [],
    mppTotalAmtMsat: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    amp: (f = msg.getAmp()) && proto.lnrpc.AMP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.InvoiceHTLC}
 */
proto.lnrpc.InvoiceHTLC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.InvoiceHTLC;
  return proto.lnrpc.InvoiceHTLC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.InvoiceHTLC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.InvoiceHTLC}
 */
proto.lnrpc.InvoiceHTLC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setHtlcIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmtMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAcceptHeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAcceptTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setResolveTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryHeight(value);
      break;
    case 8:
      var value = /** @type {!proto.lnrpc.InvoiceHTLCState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 9:
      var value = msg.getCustomRecordsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint64, jspb.BinaryReader.prototype.readBytes, null, 0);
         });
      break;
    case 10:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMppTotalAmtMsat(value);
      break;
    case 11:
      var value = new proto.lnrpc.AMP;
      reader.readMessage(value,proto.lnrpc.AMP.deserializeBinaryFromReader);
      msg.setAmp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.InvoiceHTLC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.InvoiceHTLC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.InvoiceHTLC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.InvoiceHTLC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getHtlcIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getAcceptHeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAcceptTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getResolveTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      6,
      f
    );
  }
  f = message.getExpiryHeight();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCustomRecordsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeUint64, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getMppTotalAmtMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      10,
      f
    );
  }
  f = message.getAmp();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.lnrpc.AMP.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 chan_id = 1;
 * @return {string}
 */
proto.lnrpc.InvoiceHTLC.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceHTLC.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 htlc_index = 2;
 * @return {string}
 */
proto.lnrpc.InvoiceHTLC.prototype.getHtlcIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceHTLC.prototype.setHtlcIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 amt_msat = 3;
 * @return {string}
 */
proto.lnrpc.InvoiceHTLC.prototype.getAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceHTLC.prototype.setAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int32 accept_height = 4;
 * @return {number}
 */
proto.lnrpc.InvoiceHTLC.prototype.getAcceptHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.InvoiceHTLC.prototype.setAcceptHeight = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 accept_time = 5;
 * @return {string}
 */
proto.lnrpc.InvoiceHTLC.prototype.getAcceptTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceHTLC.prototype.setAcceptTime = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional int64 resolve_time = 6;
 * @return {string}
 */
proto.lnrpc.InvoiceHTLC.prototype.getResolveTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceHTLC.prototype.setResolveTime = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional int32 expiry_height = 7;
 * @return {number}
 */
proto.lnrpc.InvoiceHTLC.prototype.getExpiryHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.lnrpc.InvoiceHTLC.prototype.setExpiryHeight = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional InvoiceHTLCState state = 8;
 * @return {!proto.lnrpc.InvoiceHTLCState}
 */
proto.lnrpc.InvoiceHTLC.prototype.getState = function() {
  return /** @type {!proto.lnrpc.InvoiceHTLCState} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.lnrpc.InvoiceHTLCState} value */
proto.lnrpc.InvoiceHTLC.prototype.setState = function(value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * map<uint64, bytes> custom_records = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!(string|Uint8Array)>}
 */
proto.lnrpc.InvoiceHTLC.prototype.getCustomRecordsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


proto.lnrpc.InvoiceHTLC.prototype.clearCustomRecordsMap = function() {
  this.getCustomRecordsMap().clear();
};


/**
 * optional uint64 mpp_total_amt_msat = 10;
 * @return {string}
 */
proto.lnrpc.InvoiceHTLC.prototype.getMppTotalAmtMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceHTLC.prototype.setMppTotalAmtMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional AMP amp = 11;
 * @return {?proto.lnrpc.AMP}
 */
proto.lnrpc.InvoiceHTLC.prototype.getAmp = function() {
  return /** @type{?proto.lnrpc.AMP} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.AMP, 11));
};


/** @param {?proto.lnrpc.AMP|undefined} value */
proto.lnrpc.InvoiceHTLC.prototype.setAmp = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.lnrpc.InvoiceHTLC.prototype.clearAmp = function() {
  this.setAmp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.InvoiceHTLC.prototype.hasAmp = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.AMP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.AMP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.AMP.displayName = 'proto.lnrpc.AMP';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.AMP.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.AMP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.AMP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AMP.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootShare: msg.getRootShare_asB64(),
    setId: msg.getSetId_asB64(),
    childIndex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hash: msg.getHash_asB64(),
    preimage: msg.getPreimage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.AMP}
 */
proto.lnrpc.AMP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.AMP;
  return proto.lnrpc.AMP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.AMP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.AMP}
 */
proto.lnrpc.AMP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRootShare(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChildIndex(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPreimage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.AMP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.AMP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.AMP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AMP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootShare_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSetId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getChildIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getPreimage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional bytes root_share = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AMP.prototype.getRootShare = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes root_share = 1;
 * This is a type-conversion wrapper around `getRootShare()`
 * @return {string}
 */
proto.lnrpc.AMP.prototype.getRootShare_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRootShare()));
};


/**
 * optional bytes root_share = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRootShare()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AMP.prototype.getRootShare_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRootShare()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AMP.prototype.setRootShare = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes set_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AMP.prototype.getSetId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes set_id = 2;
 * This is a type-conversion wrapper around `getSetId()`
 * @return {string}
 */
proto.lnrpc.AMP.prototype.getSetId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSetId()));
};


/**
 * optional bytes set_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSetId()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AMP.prototype.getSetId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSetId()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AMP.prototype.setSetId = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional uint32 child_index = 3;
 * @return {number}
 */
proto.lnrpc.AMP.prototype.getChildIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.AMP.prototype.setChildIndex = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes hash = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AMP.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes hash = 4;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.lnrpc.AMP.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AMP.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AMP.prototype.setHash = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes preimage = 5;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AMP.prototype.getPreimage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes preimage = 5;
 * This is a type-conversion wrapper around `getPreimage()`
 * @return {string}
 */
proto.lnrpc.AMP.prototype.getPreimage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPreimage()));
};


/**
 * optional bytes preimage = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPreimage()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AMP.prototype.getPreimage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPreimage()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AMP.prototype.setPreimage = function(value) {
  jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.AddInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.AddInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.AddInvoiceResponse.displayName = 'proto.lnrpc.AddInvoiceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.AddInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.AddInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.AddInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AddInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rHash: msg.getRHash_asB64(),
    paymentRequest: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addIndex: jspb.Message.getFieldWithDefault(msg, 16, "0"),
    paymentAddr: msg.getPaymentAddr_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.AddInvoiceResponse}
 */
proto.lnrpc.AddInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.AddInvoiceResponse;
  return proto.lnrpc.AddInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.AddInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.AddInvoiceResponse}
 */
proto.lnrpc.AddInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentRequest(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAddIndex(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.AddInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.AddInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.AddInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AddInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPaymentRequest();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      16,
      f
    );
  }
  f = message.getPaymentAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
};


/**
 * optional bytes r_hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getRHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes r_hash = 1;
 * This is a type-conversion wrapper around `getRHash()`
 * @return {string}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getRHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRHash()));
};


/**
 * optional bytes r_hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getRHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AddInvoiceResponse.prototype.setRHash = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string payment_request = 2;
 * @return {string}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getPaymentRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.AddInvoiceResponse.prototype.setPaymentRequest = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 add_index = 16;
 * @return {string}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getAddIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, "0"));
};


/** @param {string} value */
proto.lnrpc.AddInvoiceResponse.prototype.setAddIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 16, value);
};


/**
 * optional bytes payment_addr = 17;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getPaymentAddr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes payment_addr = 17;
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {string}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getPaymentAddr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentAddr()));
};


/**
 * optional bytes payment_addr = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {!Uint8Array}
 */
proto.lnrpc.AddInvoiceResponse.prototype.getPaymentAddr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentAddr()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.AddInvoiceResponse.prototype.setPaymentAddr = function(value) {
  jspb.Message.setProto3BytesField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PaymentHash = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PaymentHash, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PaymentHash.displayName = 'proto.lnrpc.PaymentHash';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PaymentHash.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PaymentHash.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PaymentHash} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PaymentHash.toObject = function(includeInstance, msg) {
  var f, obj = {
    rHashStr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rHash: msg.getRHash_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PaymentHash}
 */
proto.lnrpc.PaymentHash.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PaymentHash;
  return proto.lnrpc.PaymentHash.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PaymentHash} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PaymentHash}
 */
proto.lnrpc.PaymentHash.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRHashStr(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PaymentHash.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PaymentHash.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PaymentHash} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PaymentHash.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRHashStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string r_hash_str = 1;
 * @return {string}
 */
proto.lnrpc.PaymentHash.prototype.getRHashStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.PaymentHash.prototype.setRHashStr = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes r_hash = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.PaymentHash.prototype.getRHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes r_hash = 2;
 * This is a type-conversion wrapper around `getRHash()`
 * @return {string}
 */
proto.lnrpc.PaymentHash.prototype.getRHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRHash()));
};


/**
 * optional bytes r_hash = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.PaymentHash.prototype.getRHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.PaymentHash.prototype.setRHash = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListInvoiceRequest.displayName = 'proto.lnrpc.ListInvoiceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingOnly: jspb.Message.getFieldWithDefault(msg, 1, false),
    indexOffset: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    numMaxInvoices: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    reversed: jspb.Message.getFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListInvoiceRequest}
 */
proto.lnrpc.ListInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListInvoiceRequest;
  return proto.lnrpc.ListInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListInvoiceRequest}
 */
proto.lnrpc.ListInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingOnly(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setIndexOffset(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setNumMaxInvoices(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReversed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIndexOffset();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getNumMaxInvoices();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getReversed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool pending_only = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListInvoiceRequest.prototype.getPendingOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ListInvoiceRequest.prototype.setPendingOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 index_offset = 4;
 * @return {string}
 */
proto.lnrpc.ListInvoiceRequest.prototype.getIndexOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.ListInvoiceRequest.prototype.setIndexOffset = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 num_max_invoices = 5;
 * @return {string}
 */
proto.lnrpc.ListInvoiceRequest.prototype.getNumMaxInvoices = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.ListInvoiceRequest.prototype.setNumMaxInvoices = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional bool reversed = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListInvoiceRequest.prototype.getReversed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.lnrpc.ListInvoiceRequest.prototype.setReversed = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ListInvoiceResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ListInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListInvoiceResponse.displayName = 'proto.lnrpc.ListInvoiceResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ListInvoiceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.lnrpc.Invoice.toObject, includeInstance),
    lastIndexOffset: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    firstIndexOffset: jspb.Message.getFieldWithDefault(msg, 3, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListInvoiceResponse}
 */
proto.lnrpc.ListInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListInvoiceResponse;
  return proto.lnrpc.ListInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListInvoiceResponse}
 */
proto.lnrpc.ListInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Invoice;
      reader.readMessage(value,proto.lnrpc.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setLastIndexOffset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFirstIndexOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.Invoice.serializeBinaryToWriter
    );
  }
  f = message.getLastIndexOffset();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getFirstIndexOffset();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
};


/**
 * repeated Invoice invoices = 1;
 * @return {!Array<!proto.lnrpc.Invoice>}
 */
proto.lnrpc.ListInvoiceResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.lnrpc.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Invoice, 1));
};


/** @param {!Array<!proto.lnrpc.Invoice>} value */
proto.lnrpc.ListInvoiceResponse.prototype.setInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Invoice}
 */
proto.lnrpc.ListInvoiceResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.Invoice, opt_index);
};


proto.lnrpc.ListInvoiceResponse.prototype.clearInvoicesList = function() {
  this.setInvoicesList([]);
};


/**
 * optional uint64 last_index_offset = 2;
 * @return {string}
 */
proto.lnrpc.ListInvoiceResponse.prototype.getLastIndexOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ListInvoiceResponse.prototype.setLastIndexOffset = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 first_index_offset = 3;
 * @return {string}
 */
proto.lnrpc.ListInvoiceResponse.prototype.getFirstIndexOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ListInvoiceResponse.prototype.setFirstIndexOffset = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.InvoiceSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.InvoiceSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.InvoiceSubscription.displayName = 'proto.lnrpc.InvoiceSubscription';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.InvoiceSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.InvoiceSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.InvoiceSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.InvoiceSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    addIndex: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    settleIndex: jspb.Message.getFieldWithDefault(msg, 2, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.InvoiceSubscription}
 */
proto.lnrpc.InvoiceSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.InvoiceSubscription;
  return proto.lnrpc.InvoiceSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.InvoiceSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.InvoiceSubscription}
 */
proto.lnrpc.InvoiceSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAddIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setSettleIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.InvoiceSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.InvoiceSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.InvoiceSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.InvoiceSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getSettleIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
};


/**
 * optional uint64 add_index = 1;
 * @return {string}
 */
proto.lnrpc.InvoiceSubscription.prototype.getAddIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceSubscription.prototype.setAddIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 settle_index = 2;
 * @return {string}
 */
proto.lnrpc.InvoiceSubscription.prototype.getSettleIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.InvoiceSubscription.prototype.setSettleIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Payment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Payment.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Payment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Payment.displayName = 'proto.lnrpc.Payment';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Payment.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Payment.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Payment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Payment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Payment.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    creationDate: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    fee: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    paymentPreimage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    valueSat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    valueMsat: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    paymentRequest: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    feeSat: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    feeMsat: jspb.Message.getFieldWithDefault(msg, 12, "0"),
    creationTimeNs: jspb.Message.getFieldWithDefault(msg, 13, "0"),
    htlcsList: jspb.Message.toObjectList(msg.getHtlcsList(),
    proto.lnrpc.HTLCAttempt.toObject, includeInstance),
    paymentIndex: jspb.Message.getFieldWithDefault(msg, 15, "0"),
    failureReason: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Payment}
 */
proto.lnrpc.Payment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Payment;
  return proto.lnrpc.Payment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Payment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Payment}
 */
proto.lnrpc.Payment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCreationDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentPreimage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setValueSat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setValueMsat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentRequest(value);
      break;
    case 10:
      var value = /** @type {!proto.lnrpc.Payment.PaymentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeeSat(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeeMsat(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCreationTimeNs(value);
      break;
    case 14:
      var value = new proto.lnrpc.HTLCAttempt;
      reader.readMessage(value,proto.lnrpc.HTLCAttempt.deserializeBinaryFromReader);
      msg.addHtlcs(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setPaymentIndex(value);
      break;
    case 16:
      var value = /** @type {!proto.lnrpc.PaymentFailureReason} */ (reader.readEnum());
      msg.setFailureReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Payment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Payment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Payment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Payment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getCreationDate();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getPaymentPreimage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getValueSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      7,
      f
    );
  }
  f = message.getValueMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      8,
      f
    );
  }
  f = message.getPaymentRequest();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getFeeSat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      11,
      f
    );
  }
  f = message.getFeeMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      12,
      f
    );
  }
  f = message.getCreationTimeNs();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      13,
      f
    );
  }
  f = message.getHtlcsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.lnrpc.HTLCAttempt.serializeBinaryToWriter
    );
  }
  f = message.getPaymentIndex();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      15,
      f
    );
  }
  f = message.getFailureReason();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.Payment.PaymentStatus = {
  UNKNOWN: 0,
  IN_FLIGHT: 1,
  SUCCEEDED: 2,
  FAILED: 3
};

/**
 * optional string payment_hash = 1;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getPaymentHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setPaymentHash = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setValue = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 creation_date = 3;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getCreationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setCreationDate = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 fee = 5;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setFee = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional string payment_preimage = 6;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getPaymentPreimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setPaymentPreimage = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 value_sat = 7;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getValueSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setValueSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional int64 value_msat = 8;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getValueMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setValueMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional string payment_request = 9;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getPaymentRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setPaymentRequest = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional PaymentStatus status = 10;
 * @return {!proto.lnrpc.Payment.PaymentStatus}
 */
proto.lnrpc.Payment.prototype.getStatus = function() {
  return /** @type {!proto.lnrpc.Payment.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.lnrpc.Payment.PaymentStatus} value */
proto.lnrpc.Payment.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional int64 fee_sat = 11;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getFeeSat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setFeeSat = function(value) {
  jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional int64 fee_msat = 12;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getFeeMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setFeeMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 12, value);
};


/**
 * optional int64 creation_time_ns = 13;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getCreationTimeNs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setCreationTimeNs = function(value) {
  jspb.Message.setProto3StringIntField(this, 13, value);
};


/**
 * repeated HTLCAttempt htlcs = 14;
 * @return {!Array<!proto.lnrpc.HTLCAttempt>}
 */
proto.lnrpc.Payment.prototype.getHtlcsList = function() {
  return /** @type{!Array<!proto.lnrpc.HTLCAttempt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.HTLCAttempt, 14));
};


/** @param {!Array<!proto.lnrpc.HTLCAttempt>} value */
proto.lnrpc.Payment.prototype.setHtlcsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.lnrpc.HTLCAttempt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.HTLCAttempt}
 */
proto.lnrpc.Payment.prototype.addHtlcs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.lnrpc.HTLCAttempt, opt_index);
};


proto.lnrpc.Payment.prototype.clearHtlcsList = function() {
  this.setHtlcsList([]);
};


/**
 * optional uint64 payment_index = 15;
 * @return {string}
 */
proto.lnrpc.Payment.prototype.getPaymentIndex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, "0"));
};


/** @param {string} value */
proto.lnrpc.Payment.prototype.setPaymentIndex = function(value) {
  jspb.Message.setProto3StringIntField(this, 15, value);
};


/**
 * optional PaymentFailureReason failure_reason = 16;
 * @return {!proto.lnrpc.PaymentFailureReason}
 */
proto.lnrpc.Payment.prototype.getFailureReason = function() {
  return /** @type {!proto.lnrpc.PaymentFailureReason} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {!proto.lnrpc.PaymentFailureReason} value */
proto.lnrpc.Payment.prototype.setFailureReason = function(value) {
  jspb.Message.setProto3EnumField(this, 16, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.HTLCAttempt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.HTLCAttempt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.HTLCAttempt.displayName = 'proto.lnrpc.HTLCAttempt';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.HTLCAttempt.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.HTLCAttempt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.HTLCAttempt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.HTLCAttempt.toObject = function(includeInstance, msg) {
  var f, obj = {
    attemptId: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    route: (f = msg.getRoute()) && proto.lnrpc.Route.toObject(includeInstance, f),
    attemptTimeNs: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    resolveTimeNs: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    failure: (f = msg.getFailure()) && proto.lnrpc.Failure.toObject(includeInstance, f),
    preimage: msg.getPreimage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.HTLCAttempt}
 */
proto.lnrpc.HTLCAttempt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.HTLCAttempt;
  return proto.lnrpc.HTLCAttempt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.HTLCAttempt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.HTLCAttempt}
 */
proto.lnrpc.HTLCAttempt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAttemptId(value);
      break;
    case 1:
      var value = /** @type {!proto.lnrpc.HTLCAttempt.HTLCStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.lnrpc.Route;
      reader.readMessage(value,proto.lnrpc.Route.deserializeBinaryFromReader);
      msg.setRoute(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setAttemptTimeNs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setResolveTimeNs(value);
      break;
    case 5:
      var value = new proto.lnrpc.Failure;
      reader.readMessage(value,proto.lnrpc.Failure.deserializeBinaryFromReader);
      msg.setFailure(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPreimage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.HTLCAttempt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.HTLCAttempt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.HTLCAttempt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.HTLCAttempt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttemptId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRoute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.Route.serializeBinaryToWriter
    );
  }
  f = message.getAttemptTimeNs();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getResolveTimeNs();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getFailure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.lnrpc.Failure.serializeBinaryToWriter
    );
  }
  f = message.getPreimage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.HTLCAttempt.HTLCStatus = {
  IN_FLIGHT: 0,
  SUCCEEDED: 1,
  FAILED: 2
};

/**
 * optional uint64 attempt_id = 7;
 * @return {string}
 */
proto.lnrpc.HTLCAttempt.prototype.getAttemptId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLCAttempt.prototype.setAttemptId = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional HTLCStatus status = 1;
 * @return {!proto.lnrpc.HTLCAttempt.HTLCStatus}
 */
proto.lnrpc.HTLCAttempt.prototype.getStatus = function() {
  return /** @type {!proto.lnrpc.HTLCAttempt.HTLCStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.HTLCAttempt.HTLCStatus} value */
proto.lnrpc.HTLCAttempt.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Route route = 2;
 * @return {?proto.lnrpc.Route}
 */
proto.lnrpc.HTLCAttempt.prototype.getRoute = function() {
  return /** @type{?proto.lnrpc.Route} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Route, 2));
};


/** @param {?proto.lnrpc.Route|undefined} value */
proto.lnrpc.HTLCAttempt.prototype.setRoute = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.lnrpc.HTLCAttempt.prototype.clearRoute = function() {
  this.setRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.HTLCAttempt.prototype.hasRoute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 attempt_time_ns = 3;
 * @return {string}
 */
proto.lnrpc.HTLCAttempt.prototype.getAttemptTimeNs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLCAttempt.prototype.setAttemptTimeNs = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 resolve_time_ns = 4;
 * @return {string}
 */
proto.lnrpc.HTLCAttempt.prototype.getResolveTimeNs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.HTLCAttempt.prototype.setResolveTimeNs = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional Failure failure = 5;
 * @return {?proto.lnrpc.Failure}
 */
proto.lnrpc.HTLCAttempt.prototype.getFailure = function() {
  return /** @type{?proto.lnrpc.Failure} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.Failure, 5));
};


/** @param {?proto.lnrpc.Failure|undefined} value */
proto.lnrpc.HTLCAttempt.prototype.setFailure = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.lnrpc.HTLCAttempt.prototype.clearFailure = function() {
  this.setFailure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.HTLCAttempt.prototype.hasFailure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes preimage = 6;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.HTLCAttempt.prototype.getPreimage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes preimage = 6;
 * This is a type-conversion wrapper around `getPreimage()`
 * @return {string}
 */
proto.lnrpc.HTLCAttempt.prototype.getPreimage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPreimage()));
};


/**
 * optional bytes preimage = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPreimage()`
 * @return {!Uint8Array}
 */
proto.lnrpc.HTLCAttempt.prototype.getPreimage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPreimage()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.HTLCAttempt.prototype.setPreimage = function(value) {
  jspb.Message.setProto3BytesField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListPaymentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListPaymentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListPaymentsRequest.displayName = 'proto.lnrpc.ListPaymentsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListPaymentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListPaymentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListPaymentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPaymentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeIncomplete: jspb.Message.getFieldWithDefault(msg, 1, false),
    indexOffset: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    maxPayments: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    reversed: jspb.Message.getFieldWithDefault(msg, 4, false),
    countTotalPayments: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListPaymentsRequest}
 */
proto.lnrpc.ListPaymentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListPaymentsRequest;
  return proto.lnrpc.ListPaymentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListPaymentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListPaymentsRequest}
 */
proto.lnrpc.ListPaymentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeIncomplete(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setIndexOffset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxPayments(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReversed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCountTotalPayments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListPaymentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListPaymentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListPaymentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPaymentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeIncomplete();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIndexOffset();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getMaxPayments();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getReversed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCountTotalPayments();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool include_incomplete = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListPaymentsRequest.prototype.getIncludeIncomplete = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.ListPaymentsRequest.prototype.setIncludeIncomplete = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint64 index_offset = 2;
 * @return {string}
 */
proto.lnrpc.ListPaymentsRequest.prototype.getIndexOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ListPaymentsRequest.prototype.setIndexOffset = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 max_payments = 3;
 * @return {string}
 */
proto.lnrpc.ListPaymentsRequest.prototype.getMaxPayments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ListPaymentsRequest.prototype.setMaxPayments = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional bool reversed = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListPaymentsRequest.prototype.getReversed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.lnrpc.ListPaymentsRequest.prototype.setReversed = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool count_total_payments = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.ListPaymentsRequest.prototype.getCountTotalPayments = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.lnrpc.ListPaymentsRequest.prototype.setCountTotalPayments = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListPaymentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ListPaymentsResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ListPaymentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListPaymentsResponse.displayName = 'proto.lnrpc.ListPaymentsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ListPaymentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListPaymentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListPaymentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListPaymentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPaymentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentsList: jspb.Message.toObjectList(msg.getPaymentsList(),
    proto.lnrpc.Payment.toObject, includeInstance),
    firstIndexOffset: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    lastIndexOffset: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    totalNumPayments: jspb.Message.getFieldWithDefault(msg, 4, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListPaymentsResponse}
 */
proto.lnrpc.ListPaymentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListPaymentsResponse;
  return proto.lnrpc.ListPaymentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListPaymentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListPaymentsResponse}
 */
proto.lnrpc.ListPaymentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.Payment;
      reader.readMessage(value,proto.lnrpc.Payment.deserializeBinaryFromReader);
      msg.addPayments(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFirstIndexOffset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setLastIndexOffset(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setTotalNumPayments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListPaymentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListPaymentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListPaymentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPaymentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.Payment.serializeBinaryToWriter
    );
  }
  f = message.getFirstIndexOffset();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getLastIndexOffset();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getTotalNumPayments();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
};


/**
 * repeated Payment payments = 1;
 * @return {!Array<!proto.lnrpc.Payment>}
 */
proto.lnrpc.ListPaymentsResponse.prototype.getPaymentsList = function() {
  return /** @type{!Array<!proto.lnrpc.Payment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Payment, 1));
};


/** @param {!Array<!proto.lnrpc.Payment>} value */
proto.lnrpc.ListPaymentsResponse.prototype.setPaymentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.Payment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Payment}
 */
proto.lnrpc.ListPaymentsResponse.prototype.addPayments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.Payment, opt_index);
};


proto.lnrpc.ListPaymentsResponse.prototype.clearPaymentsList = function() {
  this.setPaymentsList([]);
};


/**
 * optional uint64 first_index_offset = 2;
 * @return {string}
 */
proto.lnrpc.ListPaymentsResponse.prototype.getFirstIndexOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ListPaymentsResponse.prototype.setFirstIndexOffset = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 last_index_offset = 3;
 * @return {string}
 */
proto.lnrpc.ListPaymentsResponse.prototype.getLastIndexOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ListPaymentsResponse.prototype.setLastIndexOffset = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional uint64 total_num_payments = 4;
 * @return {string}
 */
proto.lnrpc.ListPaymentsResponse.prototype.getTotalNumPayments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.ListPaymentsResponse.prototype.setTotalNumPayments = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DeletePaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DeletePaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DeletePaymentRequest.displayName = 'proto.lnrpc.DeletePaymentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DeletePaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DeletePaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DeletePaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeletePaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentHash: msg.getPaymentHash_asB64(),
    failedHtlcsOnly: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DeletePaymentRequest}
 */
proto.lnrpc.DeletePaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DeletePaymentRequest;
  return proto.lnrpc.DeletePaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DeletePaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DeletePaymentRequest}
 */
proto.lnrpc.DeletePaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentHash(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFailedHtlcsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DeletePaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DeletePaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DeletePaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeletePaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFailedHtlcsOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes payment_hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.DeletePaymentRequest.prototype.getPaymentHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes payment_hash = 1;
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {string}
 */
proto.lnrpc.DeletePaymentRequest.prototype.getPaymentHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentHash()));
};


/**
 * optional bytes payment_hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.DeletePaymentRequest.prototype.getPaymentHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.DeletePaymentRequest.prototype.setPaymentHash = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bool failed_htlcs_only = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.DeletePaymentRequest.prototype.getFailedHtlcsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.DeletePaymentRequest.prototype.setFailedHtlcsOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DeleteAllPaymentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DeleteAllPaymentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DeleteAllPaymentsRequest.displayName = 'proto.lnrpc.DeleteAllPaymentsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DeleteAllPaymentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DeleteAllPaymentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DeleteAllPaymentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteAllPaymentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    failedPaymentsOnly: jspb.Message.getFieldWithDefault(msg, 1, false),
    failedHtlcsOnly: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DeleteAllPaymentsRequest}
 */
proto.lnrpc.DeleteAllPaymentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DeleteAllPaymentsRequest;
  return proto.lnrpc.DeleteAllPaymentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DeleteAllPaymentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DeleteAllPaymentsRequest}
 */
proto.lnrpc.DeleteAllPaymentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFailedPaymentsOnly(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFailedHtlcsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DeleteAllPaymentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DeleteAllPaymentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DeleteAllPaymentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteAllPaymentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFailedPaymentsOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFailedHtlcsOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool failed_payments_only = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.DeleteAllPaymentsRequest.prototype.getFailedPaymentsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.DeleteAllPaymentsRequest.prototype.setFailedPaymentsOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool failed_htlcs_only = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.DeleteAllPaymentsRequest.prototype.getFailedHtlcsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.DeleteAllPaymentsRequest.prototype.setFailedHtlcsOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DeletePaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DeletePaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DeletePaymentResponse.displayName = 'proto.lnrpc.DeletePaymentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DeletePaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DeletePaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DeletePaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeletePaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DeletePaymentResponse}
 */
proto.lnrpc.DeletePaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DeletePaymentResponse;
  return proto.lnrpc.DeletePaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DeletePaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DeletePaymentResponse}
 */
proto.lnrpc.DeletePaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DeletePaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DeletePaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DeletePaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeletePaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DeleteAllPaymentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DeleteAllPaymentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DeleteAllPaymentsResponse.displayName = 'proto.lnrpc.DeleteAllPaymentsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DeleteAllPaymentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DeleteAllPaymentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DeleteAllPaymentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteAllPaymentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DeleteAllPaymentsResponse}
 */
proto.lnrpc.DeleteAllPaymentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DeleteAllPaymentsResponse;
  return proto.lnrpc.DeleteAllPaymentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DeleteAllPaymentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DeleteAllPaymentsResponse}
 */
proto.lnrpc.DeleteAllPaymentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DeleteAllPaymentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DeleteAllPaymentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DeleteAllPaymentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteAllPaymentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.AbandonChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.AbandonChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.AbandonChannelRequest.displayName = 'proto.lnrpc.AbandonChannelRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.AbandonChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.AbandonChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.AbandonChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AbandonChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelPoint: (f = msg.getChannelPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    pendingFundingShimOnly: jspb.Message.getFieldWithDefault(msg, 2, false),
    iKnowWhatIAmDoing: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.AbandonChannelRequest}
 */
proto.lnrpc.AbandonChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.AbandonChannelRequest;
  return proto.lnrpc.AbandonChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.AbandonChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.AbandonChannelRequest}
 */
proto.lnrpc.AbandonChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChannelPoint(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingFundingShimOnly(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIKnowWhatIAmDoing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.AbandonChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.AbandonChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.AbandonChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AbandonChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getPendingFundingShimOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIKnowWhatIAmDoing();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional ChannelPoint channel_point = 1;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.AbandonChannelRequest.prototype.getChannelPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 1));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.AbandonChannelRequest.prototype.setChannelPoint = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.AbandonChannelRequest.prototype.clearChannelPoint = function() {
  this.setChannelPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.AbandonChannelRequest.prototype.hasChannelPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool pending_funding_shim_only = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.AbandonChannelRequest.prototype.getPendingFundingShimOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.AbandonChannelRequest.prototype.setPendingFundingShimOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool i_know_what_i_am_doing = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.AbandonChannelRequest.prototype.getIKnowWhatIAmDoing = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.AbandonChannelRequest.prototype.setIKnowWhatIAmDoing = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.AbandonChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.AbandonChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.AbandonChannelResponse.displayName = 'proto.lnrpc.AbandonChannelResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.AbandonChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.AbandonChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.AbandonChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AbandonChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.AbandonChannelResponse}
 */
proto.lnrpc.AbandonChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.AbandonChannelResponse;
  return proto.lnrpc.AbandonChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.AbandonChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.AbandonChannelResponse}
 */
proto.lnrpc.AbandonChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.AbandonChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.AbandonChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.AbandonChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.AbandonChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DebugLevelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DebugLevelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DebugLevelRequest.displayName = 'proto.lnrpc.DebugLevelRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DebugLevelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DebugLevelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DebugLevelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DebugLevelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    show: jspb.Message.getFieldWithDefault(msg, 1, false),
    levelSpec: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DebugLevelRequest}
 */
proto.lnrpc.DebugLevelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DebugLevelRequest;
  return proto.lnrpc.DebugLevelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DebugLevelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DebugLevelRequest}
 */
proto.lnrpc.DebugLevelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShow(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevelSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DebugLevelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DebugLevelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DebugLevelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DebugLevelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShow();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLevelSpec();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool show = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.DebugLevelRequest.prototype.getShow = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.DebugLevelRequest.prototype.setShow = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string level_spec = 2;
 * @return {string}
 */
proto.lnrpc.DebugLevelRequest.prototype.getLevelSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.DebugLevelRequest.prototype.setLevelSpec = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DebugLevelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DebugLevelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DebugLevelResponse.displayName = 'proto.lnrpc.DebugLevelResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DebugLevelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DebugLevelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DebugLevelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DebugLevelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subSystems: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DebugLevelResponse}
 */
proto.lnrpc.DebugLevelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DebugLevelResponse;
  return proto.lnrpc.DebugLevelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DebugLevelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DebugLevelResponse}
 */
proto.lnrpc.DebugLevelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubSystems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DebugLevelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DebugLevelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DebugLevelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DebugLevelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubSystems();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sub_systems = 1;
 * @return {string}
 */
proto.lnrpc.DebugLevelResponse.prototype.getSubSystems = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.DebugLevelResponse.prototype.setSubSystems = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PayReqString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.PayReqString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PayReqString.displayName = 'proto.lnrpc.PayReqString';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PayReqString.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PayReqString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PayReqString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PayReqString.toObject = function(includeInstance, msg) {
  var f, obj = {
    payReq: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PayReqString}
 */
proto.lnrpc.PayReqString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PayReqString;
  return proto.lnrpc.PayReqString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PayReqString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PayReqString}
 */
proto.lnrpc.PayReqString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayReq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PayReqString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PayReqString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PayReqString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PayReqString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayReq();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pay_req = 1;
 * @return {string}
 */
proto.lnrpc.PayReqString.prototype.getPayReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.PayReqString.prototype.setPayReq = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PayReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.PayReq.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.PayReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PayReq.displayName = 'proto.lnrpc.PayReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.PayReq.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PayReq.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PayReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PayReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PayReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    destination: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numSatoshis: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    expiry: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    descriptionHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fallbackAddr: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cltvExpiry: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    routeHintsList: jspb.Message.toObjectList(msg.getRouteHintsList(),
    proto.lnrpc.RouteHint.toObject, includeInstance),
    paymentAddr: msg.getPaymentAddr_asB64(),
    numMsat: jspb.Message.getFieldWithDefault(msg, 12, "0"),
    featuresMap: (f = msg.getFeaturesMap()) ? f.toObject(includeInstance, proto.lnrpc.Feature.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PayReq}
 */
proto.lnrpc.PayReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PayReq;
  return proto.lnrpc.PayReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PayReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PayReq}
 */
proto.lnrpc.PayReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentHash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setNumSatoshis(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setExpiry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionHash(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFallbackAddr(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setCltvExpiry(value);
      break;
    case 10:
      var value = new proto.lnrpc.RouteHint;
      reader.readMessage(value,proto.lnrpc.RouteHint.deserializeBinaryFromReader);
      msg.addRouteHints(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPaymentAddr(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setNumMsat(value);
      break;
    case 13:
      var value = msg.getFeaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.Feature.deserializeBinaryFromReader, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PayReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PayReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PayReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PayReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumSatoshis();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      4,
      f
    );
  }
  f = message.getExpiry();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescriptionHash();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFallbackAddr();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCltvExpiry();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      9,
      f
    );
  }
  f = message.getRouteHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.lnrpc.RouteHint.serializeBinaryToWriter
    );
  }
  f = message.getPaymentAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getNumMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      12,
      f
    );
  }
  f = message.getFeaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.Feature.serializeBinaryToWriter);
  }
};


/**
 * optional string destination = 1;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setDestination = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_hash = 2;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getPaymentHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setPaymentHash = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 num_satoshis = 3;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getNumSatoshis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setNumSatoshis = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional int64 expiry = 5;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getExpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setExpiry = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description_hash = 7;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getDescriptionHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setDescriptionHash = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fallback_addr = 8;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getFallbackAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setFallbackAddr = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 cltv_expiry = 9;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getCltvExpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setCltvExpiry = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * repeated RouteHint route_hints = 10;
 * @return {!Array<!proto.lnrpc.RouteHint>}
 */
proto.lnrpc.PayReq.prototype.getRouteHintsList = function() {
  return /** @type{!Array<!proto.lnrpc.RouteHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.RouteHint, 10));
};


/** @param {!Array<!proto.lnrpc.RouteHint>} value */
proto.lnrpc.PayReq.prototype.setRouteHintsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.lnrpc.RouteHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.RouteHint}
 */
proto.lnrpc.PayReq.prototype.addRouteHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.lnrpc.RouteHint, opt_index);
};


proto.lnrpc.PayReq.prototype.clearRouteHintsList = function() {
  this.setRouteHintsList([]);
};


/**
 * optional bytes payment_addr = 11;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.PayReq.prototype.getPaymentAddr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes payment_addr = 11;
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getPaymentAddr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPaymentAddr()));
};


/**
 * optional bytes payment_addr = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPaymentAddr()`
 * @return {!Uint8Array}
 */
proto.lnrpc.PayReq.prototype.getPaymentAddr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPaymentAddr()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.PayReq.prototype.setPaymentAddr = function(value) {
  jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional int64 num_msat = 12;
 * @return {string}
 */
proto.lnrpc.PayReq.prototype.getNumMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, "0"));
};


/** @param {string} value */
proto.lnrpc.PayReq.prototype.setNumMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 12, value);
};


/**
 * map<uint32, Feature> features = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.lnrpc.Feature>}
 */
proto.lnrpc.PayReq.prototype.getFeaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.lnrpc.Feature>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      proto.lnrpc.Feature));
};


proto.lnrpc.PayReq.prototype.clearFeaturesMap = function() {
  this.getFeaturesMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Feature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Feature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Feature.displayName = 'proto.lnrpc.Feature';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Feature.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Feature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Feature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Feature.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isRequired: jspb.Message.getFieldWithDefault(msg, 3, false),
    isKnown: jspb.Message.getFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Feature}
 */
proto.lnrpc.Feature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Feature;
  return proto.lnrpc.Feature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Feature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Feature}
 */
proto.lnrpc.Feature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsKnown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Feature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Feature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Feature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Feature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsKnown();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.lnrpc.Feature.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.Feature.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_required = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Feature.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.Feature.prototype.setIsRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_known = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.Feature.prototype.getIsKnown = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.lnrpc.Feature.prototype.setIsKnown = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FeeReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FeeReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FeeReportRequest.displayName = 'proto.lnrpc.FeeReportRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FeeReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FeeReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FeeReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FeeReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FeeReportRequest}
 */
proto.lnrpc.FeeReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FeeReportRequest;
  return proto.lnrpc.FeeReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FeeReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FeeReportRequest}
 */
proto.lnrpc.FeeReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FeeReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FeeReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FeeReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FeeReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelFeeReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelFeeReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelFeeReport.displayName = 'proto.lnrpc.ChannelFeeReport';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelFeeReport.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelFeeReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelFeeReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelFeeReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanId: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    channelPoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseFeeMsat: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    feePerMil: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    feeRate: +jspb.Message.getFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelFeeReport}
 */
proto.lnrpc.ChannelFeeReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelFeeReport;
  return proto.lnrpc.ChannelFeeReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelFeeReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelFeeReport}
 */
proto.lnrpc.ChannelFeeReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelPoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setBaseFeeMsat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setFeePerMil(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelFeeReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelFeeReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelFeeReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelFeeReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getChannelPoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseFeeMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getFeePerMil();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getFeeRate();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional uint64 chan_id = 5;
 * @return {string}
 */
proto.lnrpc.ChannelFeeReport.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelFeeReport.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional string channel_point = 1;
 * @return {string}
 */
proto.lnrpc.ChannelFeeReport.prototype.getChannelPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.ChannelFeeReport.prototype.setChannelPoint = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 base_fee_msat = 2;
 * @return {string}
 */
proto.lnrpc.ChannelFeeReport.prototype.getBaseFeeMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelFeeReport.prototype.setBaseFeeMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 fee_per_mil = 3;
 * @return {string}
 */
proto.lnrpc.ChannelFeeReport.prototype.getFeePerMil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelFeeReport.prototype.setFeePerMil = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional double fee_rate = 4;
 * @return {number}
 */
proto.lnrpc.ChannelFeeReport.prototype.getFeeRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.lnrpc.ChannelFeeReport.prototype.setFeeRate = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FeeReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.FeeReportResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.FeeReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FeeReportResponse.displayName = 'proto.lnrpc.FeeReportResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.FeeReportResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FeeReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FeeReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FeeReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FeeReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelFeesList: jspb.Message.toObjectList(msg.getChannelFeesList(),
    proto.lnrpc.ChannelFeeReport.toObject, includeInstance),
    dayFeeSum: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    weekFeeSum: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    monthFeeSum: jspb.Message.getFieldWithDefault(msg, 4, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FeeReportResponse}
 */
proto.lnrpc.FeeReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FeeReportResponse;
  return proto.lnrpc.FeeReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FeeReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FeeReportResponse}
 */
proto.lnrpc.FeeReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelFeeReport;
      reader.readMessage(value,proto.lnrpc.ChannelFeeReport.deserializeBinaryFromReader);
      msg.addChannelFees(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setDayFeeSum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setWeekFeeSum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMonthFeeSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FeeReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FeeReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FeeReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FeeReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.ChannelFeeReport.serializeBinaryToWriter
    );
  }
  f = message.getDayFeeSum();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getWeekFeeSum();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getMonthFeeSum();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
};


/**
 * repeated ChannelFeeReport channel_fees = 1;
 * @return {!Array<!proto.lnrpc.ChannelFeeReport>}
 */
proto.lnrpc.FeeReportResponse.prototype.getChannelFeesList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelFeeReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelFeeReport, 1));
};


/** @param {!Array<!proto.lnrpc.ChannelFeeReport>} value */
proto.lnrpc.FeeReportResponse.prototype.setChannelFeesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.ChannelFeeReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelFeeReport}
 */
proto.lnrpc.FeeReportResponse.prototype.addChannelFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.ChannelFeeReport, opt_index);
};


proto.lnrpc.FeeReportResponse.prototype.clearChannelFeesList = function() {
  this.setChannelFeesList([]);
};


/**
 * optional uint64 day_fee_sum = 2;
 * @return {string}
 */
proto.lnrpc.FeeReportResponse.prototype.getDayFeeSum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.FeeReportResponse.prototype.setDayFeeSum = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 week_fee_sum = 3;
 * @return {string}
 */
proto.lnrpc.FeeReportResponse.prototype.getWeekFeeSum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.FeeReportResponse.prototype.setWeekFeeSum = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional uint64 month_fee_sum = 4;
 * @return {string}
 */
proto.lnrpc.FeeReportResponse.prototype.getMonthFeeSum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.FeeReportResponse.prototype.setMonthFeeSum = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PolicyUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.PolicyUpdateRequest.oneofGroups_);
};
goog.inherits(proto.lnrpc.PolicyUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PolicyUpdateRequest.displayName = 'proto.lnrpc.PolicyUpdateRequest';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.PolicyUpdateRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.lnrpc.PolicyUpdateRequest.ScopeCase = {
  SCOPE_NOT_SET: 0,
  GLOBAL: 1,
  CHAN_POINT: 2
};

/**
 * @return {proto.lnrpc.PolicyUpdateRequest.ScopeCase}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getScopeCase = function() {
  return /** @type {proto.lnrpc.PolicyUpdateRequest.ScopeCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.PolicyUpdateRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PolicyUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PolicyUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PolicyUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    global: jspb.Message.getFieldWithDefault(msg, 1, false),
    chanPoint: (f = msg.getChanPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    baseFeeMsat: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    feeRate: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    feeRatePpm: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeLockDelta: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxHtlcMsat: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    minHtlcMsat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    minHtlcMsatSpecified: jspb.Message.getFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PolicyUpdateRequest}
 */
proto.lnrpc.PolicyUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PolicyUpdateRequest;
  return proto.lnrpc.PolicyUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PolicyUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PolicyUpdateRequest}
 */
proto.lnrpc.PolicyUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGlobal(value);
      break;
    case 2:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChanPoint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setBaseFeeMsat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeRate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFeeRatePpm(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeLockDelta(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMaxHtlcMsat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMinHtlcMsat(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMinHtlcMsatSpecified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PolicyUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PolicyUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PolicyUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getChanPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getBaseFeeMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getFeeRate();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFeeRatePpm();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTimeLockDelta();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMaxHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getMinHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getMinHtlcMsatSpecified();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional bool global = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getGlobal = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setGlobal = function(value) {
  jspb.Message.setOneofField(this, 1, proto.lnrpc.PolicyUpdateRequest.oneofGroups_[0], value);
};


proto.lnrpc.PolicyUpdateRequest.prototype.clearGlobal = function() {
  jspb.Message.setOneofField(this, 1, proto.lnrpc.PolicyUpdateRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.hasGlobal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChannelPoint chan_point = 2;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getChanPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 2));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setChanPoint = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.lnrpc.PolicyUpdateRequest.oneofGroups_[0], value);
};


proto.lnrpc.PolicyUpdateRequest.prototype.clearChanPoint = function() {
  this.setChanPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.hasChanPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 base_fee_msat = 3;
 * @return {string}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getBaseFeeMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setBaseFeeMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional double fee_rate = 4;
 * @return {number}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getFeeRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setFeeRate = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint32 fee_rate_ppm = 9;
 * @return {number}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getFeeRatePpm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setFeeRatePpm = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 time_lock_delta = 5;
 * @return {number}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getTimeLockDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setTimeLockDelta = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 max_htlc_msat = 6;
 * @return {string}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getMaxHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setMaxHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint64 min_htlc_msat = 7;
 * @return {string}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getMinHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setMinHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional bool min_htlc_msat_specified = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.PolicyUpdateRequest.prototype.getMinHtlcMsatSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.lnrpc.PolicyUpdateRequest.prototype.setMinHtlcMsatSpecified = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.FailedUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.FailedUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.FailedUpdate.displayName = 'proto.lnrpc.FailedUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.FailedUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.FailedUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.FailedUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FailedUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    outpoint: (f = msg.getOutpoint()) && proto.lnrpc.OutPoint.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updateError: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.FailedUpdate}
 */
proto.lnrpc.FailedUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.FailedUpdate;
  return proto.lnrpc.FailedUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.FailedUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.FailedUpdate}
 */
proto.lnrpc.FailedUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.OutPoint;
      reader.readMessage(value,proto.lnrpc.OutPoint.deserializeBinaryFromReader);
      msg.setOutpoint(value);
      break;
    case 2:
      var value = /** @type {!proto.lnrpc.UpdateFailure} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.FailedUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.FailedUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.FailedUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.FailedUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutpoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.OutPoint.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUpdateError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional OutPoint outpoint = 1;
 * @return {?proto.lnrpc.OutPoint}
 */
proto.lnrpc.FailedUpdate.prototype.getOutpoint = function() {
  return /** @type{?proto.lnrpc.OutPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.OutPoint, 1));
};


/** @param {?proto.lnrpc.OutPoint|undefined} value */
proto.lnrpc.FailedUpdate.prototype.setOutpoint = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.FailedUpdate.prototype.clearOutpoint = function() {
  this.setOutpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.FailedUpdate.prototype.hasOutpoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateFailure reason = 2;
 * @return {!proto.lnrpc.UpdateFailure}
 */
proto.lnrpc.FailedUpdate.prototype.getReason = function() {
  return /** @type {!proto.lnrpc.UpdateFailure} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.lnrpc.UpdateFailure} value */
proto.lnrpc.FailedUpdate.prototype.setReason = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string update_error = 3;
 * @return {string}
 */
proto.lnrpc.FailedUpdate.prototype.getUpdateError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.FailedUpdate.prototype.setUpdateError = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.PolicyUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.PolicyUpdateResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.PolicyUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.PolicyUpdateResponse.displayName = 'proto.lnrpc.PolicyUpdateResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.PolicyUpdateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.PolicyUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.PolicyUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.PolicyUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PolicyUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    failedUpdatesList: jspb.Message.toObjectList(msg.getFailedUpdatesList(),
    proto.lnrpc.FailedUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.PolicyUpdateResponse}
 */
proto.lnrpc.PolicyUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.PolicyUpdateResponse;
  return proto.lnrpc.PolicyUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.PolicyUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.PolicyUpdateResponse}
 */
proto.lnrpc.PolicyUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.FailedUpdate;
      reader.readMessage(value,proto.lnrpc.FailedUpdate.deserializeBinaryFromReader);
      msg.addFailedUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.PolicyUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.PolicyUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.PolicyUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.PolicyUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFailedUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.FailedUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FailedUpdate failed_updates = 1;
 * @return {!Array<!proto.lnrpc.FailedUpdate>}
 */
proto.lnrpc.PolicyUpdateResponse.prototype.getFailedUpdatesList = function() {
  return /** @type{!Array<!proto.lnrpc.FailedUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.FailedUpdate, 1));
};


/** @param {!Array<!proto.lnrpc.FailedUpdate>} value */
proto.lnrpc.PolicyUpdateResponse.prototype.setFailedUpdatesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.FailedUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.FailedUpdate}
 */
proto.lnrpc.PolicyUpdateResponse.prototype.addFailedUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.FailedUpdate, opt_index);
};


proto.lnrpc.PolicyUpdateResponse.prototype.clearFailedUpdatesList = function() {
  this.setFailedUpdatesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ForwardingHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ForwardingHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ForwardingHistoryRequest.displayName = 'proto.lnrpc.ForwardingHistoryRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ForwardingHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ForwardingHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ForwardingHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ForwardingHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    indexOffset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numMaxEvents: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ForwardingHistoryRequest}
 */
proto.lnrpc.ForwardingHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ForwardingHistoryRequest;
  return proto.lnrpc.ForwardingHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ForwardingHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ForwardingHistoryRequest}
 */
proto.lnrpc.ForwardingHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndexOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumMaxEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ForwardingHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ForwardingHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ForwardingHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ForwardingHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getIndexOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNumMaxEvents();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint64 start_time = 1;
 * @return {string}
 */
proto.lnrpc.ForwardingHistoryRequest.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingHistoryRequest.prototype.setStartTime = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 end_time = 2;
 * @return {string}
 */
proto.lnrpc.ForwardingHistoryRequest.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingHistoryRequest.prototype.setEndTime = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint32 index_offset = 3;
 * @return {number}
 */
proto.lnrpc.ForwardingHistoryRequest.prototype.getIndexOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.lnrpc.ForwardingHistoryRequest.prototype.setIndexOffset = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 num_max_events = 4;
 * @return {number}
 */
proto.lnrpc.ForwardingHistoryRequest.prototype.getNumMaxEvents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.ForwardingHistoryRequest.prototype.setNumMaxEvents = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ForwardingEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ForwardingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ForwardingEvent.displayName = 'proto.lnrpc.ForwardingEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ForwardingEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ForwardingEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ForwardingEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ForwardingEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    chanIdIn: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    chanIdOut: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    amtIn: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    amtOut: jspb.Message.getFieldWithDefault(msg, 6, "0"),
    fee: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    feeMsat: jspb.Message.getFieldWithDefault(msg, 8, "0"),
    amtInMsat: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    amtOutMsat: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    timestampNs: jspb.Message.getFieldWithDefault(msg, 11, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ForwardingEvent}
 */
proto.lnrpc.ForwardingEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ForwardingEvent;
  return proto.lnrpc.ForwardingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ForwardingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ForwardingEvent}
 */
proto.lnrpc.ForwardingEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanIdIn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanIdOut(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmtIn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmtOut(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setFeeMsat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmtInMsat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setAmtOutMsat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setTimestampNs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ForwardingEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ForwardingEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ForwardingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ForwardingEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getChanIdIn();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getChanIdOut();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getAmtIn();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      5,
      f
    );
  }
  f = message.getAmtOut();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      6,
      f
    );
  }
  f = message.getFee();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getFeeMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      8,
      f
    );
  }
  f = message.getAmtInMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getAmtOutMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      10,
      f
    );
  }
  f = message.getTimestampNs();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
};


/**
 * optional uint64 timestamp = 1;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint64 chan_id_in = 2;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getChanIdIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setChanIdIn = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 chan_id_out = 4;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getChanIdOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setChanIdOut = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional uint64 amt_in = 5;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getAmtIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setAmtIn = function(value) {
  jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * optional uint64 amt_out = 6;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getAmtOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setAmtOut = function(value) {
  jspb.Message.setProto3StringIntField(this, 6, value);
};


/**
 * optional uint64 fee = 7;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setFee = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional uint64 fee_msat = 8;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getFeeMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setFeeMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 8, value);
};


/**
 * optional uint64 amt_in_msat = 9;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getAmtInMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setAmtInMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint64 amt_out_msat = 10;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getAmtOutMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setAmtOutMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional uint64 timestamp_ns = 11;
 * @return {string}
 */
proto.lnrpc.ForwardingEvent.prototype.getTimestampNs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/** @param {string} value */
proto.lnrpc.ForwardingEvent.prototype.setTimestampNs = function(value) {
  jspb.Message.setProto3StringIntField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ForwardingHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ForwardingHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ForwardingHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ForwardingHistoryResponse.displayName = 'proto.lnrpc.ForwardingHistoryResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ForwardingHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ForwardingHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ForwardingHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ForwardingHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ForwardingHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingEventsList: jspb.Message.toObjectList(msg.getForwardingEventsList(),
    proto.lnrpc.ForwardingEvent.toObject, includeInstance),
    lastOffsetIndex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ForwardingHistoryResponse}
 */
proto.lnrpc.ForwardingHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ForwardingHistoryResponse;
  return proto.lnrpc.ForwardingHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ForwardingHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ForwardingHistoryResponse}
 */
proto.lnrpc.ForwardingHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ForwardingEvent;
      reader.readMessage(value,proto.lnrpc.ForwardingEvent.deserializeBinaryFromReader);
      msg.addForwardingEvents(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastOffsetIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ForwardingHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ForwardingHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ForwardingHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ForwardingHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.ForwardingEvent.serializeBinaryToWriter
    );
  }
  f = message.getLastOffsetIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated ForwardingEvent forwarding_events = 1;
 * @return {!Array<!proto.lnrpc.ForwardingEvent>}
 */
proto.lnrpc.ForwardingHistoryResponse.prototype.getForwardingEventsList = function() {
  return /** @type{!Array<!proto.lnrpc.ForwardingEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ForwardingEvent, 1));
};


/** @param {!Array<!proto.lnrpc.ForwardingEvent>} value */
proto.lnrpc.ForwardingHistoryResponse.prototype.setForwardingEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.ForwardingEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ForwardingEvent}
 */
proto.lnrpc.ForwardingHistoryResponse.prototype.addForwardingEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.ForwardingEvent, opt_index);
};


proto.lnrpc.ForwardingHistoryResponse.prototype.clearForwardingEventsList = function() {
  this.setForwardingEventsList([]);
};


/**
 * optional uint32 last_offset_index = 2;
 * @return {number}
 */
proto.lnrpc.ForwardingHistoryResponse.prototype.getLastOffsetIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.lnrpc.ForwardingHistoryResponse.prototype.setLastOffsetIndex = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ExportChannelBackupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ExportChannelBackupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ExportChannelBackupRequest.displayName = 'proto.lnrpc.ExportChannelBackupRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ExportChannelBackupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ExportChannelBackupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ExportChannelBackupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ExportChannelBackupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanPoint: (f = msg.getChanPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ExportChannelBackupRequest}
 */
proto.lnrpc.ExportChannelBackupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ExportChannelBackupRequest;
  return proto.lnrpc.ExportChannelBackupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ExportChannelBackupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ExportChannelBackupRequest}
 */
proto.lnrpc.ExportChannelBackupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChanPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ExportChannelBackupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ExportChannelBackupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ExportChannelBackupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ExportChannelBackupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChannelPoint chan_point = 1;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ExportChannelBackupRequest.prototype.getChanPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 1));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ExportChannelBackupRequest.prototype.setChanPoint = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.ExportChannelBackupRequest.prototype.clearChanPoint = function() {
  this.setChanPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ExportChannelBackupRequest.prototype.hasChanPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelBackup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelBackup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelBackup.displayName = 'proto.lnrpc.ChannelBackup';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelBackup.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelBackup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelBackup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBackup.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanPoint: (f = msg.getChanPoint()) && proto.lnrpc.ChannelPoint.toObject(includeInstance, f),
    chanBackup: msg.getChanBackup_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelBackup}
 */
proto.lnrpc.ChannelBackup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelBackup;
  return proto.lnrpc.ChannelBackup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelBackup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelBackup}
 */
proto.lnrpc.ChannelBackup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.setChanPoint(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChanBackup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelBackup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelBackup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelBackup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBackup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getChanBackup_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional ChannelPoint chan_point = 1;
 * @return {?proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.ChannelBackup.prototype.getChanPoint = function() {
  return /** @type{?proto.lnrpc.ChannelPoint} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelPoint, 1));
};


/** @param {?proto.lnrpc.ChannelPoint|undefined} value */
proto.lnrpc.ChannelBackup.prototype.setChanPoint = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.ChannelBackup.prototype.clearChanPoint = function() {
  this.setChanPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChannelBackup.prototype.hasChanPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chan_backup = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelBackup.prototype.getChanBackup = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chan_backup = 2;
 * This is a type-conversion wrapper around `getChanBackup()`
 * @return {string}
 */
proto.lnrpc.ChannelBackup.prototype.getChanBackup_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChanBackup()));
};


/**
 * optional bytes chan_backup = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChanBackup()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelBackup.prototype.getChanBackup_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChanBackup()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelBackup.prototype.setChanBackup = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.MultiChanBackup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.MultiChanBackup.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.MultiChanBackup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.MultiChanBackup.displayName = 'proto.lnrpc.MultiChanBackup';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.MultiChanBackup.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.MultiChanBackup.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.MultiChanBackup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.MultiChanBackup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MultiChanBackup.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanPointsList: jspb.Message.toObjectList(msg.getChanPointsList(),
    proto.lnrpc.ChannelPoint.toObject, includeInstance),
    multiChanBackup: msg.getMultiChanBackup_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.MultiChanBackup}
 */
proto.lnrpc.MultiChanBackup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.MultiChanBackup;
  return proto.lnrpc.MultiChanBackup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.MultiChanBackup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.MultiChanBackup}
 */
proto.lnrpc.MultiChanBackup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelPoint;
      reader.readMessage(value,proto.lnrpc.ChannelPoint.deserializeBinaryFromReader);
      msg.addChanPoints(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMultiChanBackup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.MultiChanBackup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.MultiChanBackup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.MultiChanBackup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MultiChanBackup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.ChannelPoint.serializeBinaryToWriter
    );
  }
  f = message.getMultiChanBackup_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * repeated ChannelPoint chan_points = 1;
 * @return {!Array<!proto.lnrpc.ChannelPoint>}
 */
proto.lnrpc.MultiChanBackup.prototype.getChanPointsList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelPoint, 1));
};


/** @param {!Array<!proto.lnrpc.ChannelPoint>} value */
proto.lnrpc.MultiChanBackup.prototype.setChanPointsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.ChannelPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelPoint}
 */
proto.lnrpc.MultiChanBackup.prototype.addChanPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.ChannelPoint, opt_index);
};


proto.lnrpc.MultiChanBackup.prototype.clearChanPointsList = function() {
  this.setChanPointsList([]);
};


/**
 * optional bytes multi_chan_backup = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.MultiChanBackup.prototype.getMultiChanBackup = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes multi_chan_backup = 2;
 * This is a type-conversion wrapper around `getMultiChanBackup()`
 * @return {string}
 */
proto.lnrpc.MultiChanBackup.prototype.getMultiChanBackup_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMultiChanBackup()));
};


/**
 * optional bytes multi_chan_backup = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMultiChanBackup()`
 * @return {!Uint8Array}
 */
proto.lnrpc.MultiChanBackup.prototype.getMultiChanBackup_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMultiChanBackup()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.MultiChanBackup.prototype.setMultiChanBackup = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChanBackupExportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChanBackupExportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChanBackupExportRequest.displayName = 'proto.lnrpc.ChanBackupExportRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChanBackupExportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChanBackupExportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChanBackupExportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanBackupExportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChanBackupExportRequest}
 */
proto.lnrpc.ChanBackupExportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChanBackupExportRequest;
  return proto.lnrpc.ChanBackupExportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChanBackupExportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChanBackupExportRequest}
 */
proto.lnrpc.ChanBackupExportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChanBackupExportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChanBackupExportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChanBackupExportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanBackupExportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChanBackupSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChanBackupSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChanBackupSnapshot.displayName = 'proto.lnrpc.ChanBackupSnapshot';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChanBackupSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChanBackupSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChanBackupSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanBackupSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    singleChanBackups: (f = msg.getSingleChanBackups()) && proto.lnrpc.ChannelBackups.toObject(includeInstance, f),
    multiChanBackup: (f = msg.getMultiChanBackup()) && proto.lnrpc.MultiChanBackup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChanBackupSnapshot}
 */
proto.lnrpc.ChanBackupSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChanBackupSnapshot;
  return proto.lnrpc.ChanBackupSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChanBackupSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChanBackupSnapshot}
 */
proto.lnrpc.ChanBackupSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelBackups;
      reader.readMessage(value,proto.lnrpc.ChannelBackups.deserializeBinaryFromReader);
      msg.setSingleChanBackups(value);
      break;
    case 2:
      var value = new proto.lnrpc.MultiChanBackup;
      reader.readMessage(value,proto.lnrpc.MultiChanBackup.deserializeBinaryFromReader);
      msg.setMultiChanBackup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChanBackupSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChanBackupSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChanBackupSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChanBackupSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingleChanBackups();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelBackups.serializeBinaryToWriter
    );
  }
  f = message.getMultiChanBackup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.MultiChanBackup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChannelBackups single_chan_backups = 1;
 * @return {?proto.lnrpc.ChannelBackups}
 */
proto.lnrpc.ChanBackupSnapshot.prototype.getSingleChanBackups = function() {
  return /** @type{?proto.lnrpc.ChannelBackups} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelBackups, 1));
};


/** @param {?proto.lnrpc.ChannelBackups|undefined} value */
proto.lnrpc.ChanBackupSnapshot.prototype.setSingleChanBackups = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.lnrpc.ChanBackupSnapshot.prototype.clearSingleChanBackups = function() {
  this.setSingleChanBackups(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChanBackupSnapshot.prototype.hasSingleChanBackups = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MultiChanBackup multi_chan_backup = 2;
 * @return {?proto.lnrpc.MultiChanBackup}
 */
proto.lnrpc.ChanBackupSnapshot.prototype.getMultiChanBackup = function() {
  return /** @type{?proto.lnrpc.MultiChanBackup} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.MultiChanBackup, 2));
};


/** @param {?proto.lnrpc.MultiChanBackup|undefined} value */
proto.lnrpc.ChanBackupSnapshot.prototype.setMultiChanBackup = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.lnrpc.ChanBackupSnapshot.prototype.clearMultiChanBackup = function() {
  this.setMultiChanBackup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.ChanBackupSnapshot.prototype.hasMultiChanBackup = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelBackups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ChannelBackups.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ChannelBackups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelBackups.displayName = 'proto.lnrpc.ChannelBackups';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ChannelBackups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelBackups.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelBackups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelBackups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBackups.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanBackupsList: jspb.Message.toObjectList(msg.getChanBackupsList(),
    proto.lnrpc.ChannelBackup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelBackups}
 */
proto.lnrpc.ChannelBackups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelBackups;
  return proto.lnrpc.ChannelBackups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelBackups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelBackups}
 */
proto.lnrpc.ChannelBackups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelBackup;
      reader.readMessage(value,proto.lnrpc.ChannelBackup.deserializeBinaryFromReader);
      msg.addChanBackups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelBackups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelBackups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelBackups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBackups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanBackupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.ChannelBackup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChannelBackup chan_backups = 1;
 * @return {!Array<!proto.lnrpc.ChannelBackup>}
 */
proto.lnrpc.ChannelBackups.prototype.getChanBackupsList = function() {
  return /** @type{!Array<!proto.lnrpc.ChannelBackup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.ChannelBackup, 1));
};


/** @param {!Array<!proto.lnrpc.ChannelBackup>} value */
proto.lnrpc.ChannelBackups.prototype.setChanBackupsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.ChannelBackup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.ChannelBackup}
 */
proto.lnrpc.ChannelBackups.prototype.addChanBackups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.ChannelBackup, opt_index);
};


proto.lnrpc.ChannelBackups.prototype.clearChanBackupsList = function() {
  this.setChanBackupsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RestoreChanBackupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.RestoreChanBackupRequest.oneofGroups_);
};
goog.inherits(proto.lnrpc.RestoreChanBackupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RestoreChanBackupRequest.displayName = 'proto.lnrpc.RestoreChanBackupRequest';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.RestoreChanBackupRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.lnrpc.RestoreChanBackupRequest.BackupCase = {
  BACKUP_NOT_SET: 0,
  CHAN_BACKUPS: 1,
  MULTI_CHAN_BACKUP: 2
};

/**
 * @return {proto.lnrpc.RestoreChanBackupRequest.BackupCase}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.getBackupCase = function() {
  return /** @type {proto.lnrpc.RestoreChanBackupRequest.BackupCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.RestoreChanBackupRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RestoreChanBackupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RestoreChanBackupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RestoreChanBackupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chanBackups: (f = msg.getChanBackups()) && proto.lnrpc.ChannelBackups.toObject(includeInstance, f),
    multiChanBackup: msg.getMultiChanBackup_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RestoreChanBackupRequest}
 */
proto.lnrpc.RestoreChanBackupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RestoreChanBackupRequest;
  return proto.lnrpc.RestoreChanBackupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RestoreChanBackupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RestoreChanBackupRequest}
 */
proto.lnrpc.RestoreChanBackupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.ChannelBackups;
      reader.readMessage(value,proto.lnrpc.ChannelBackups.deserializeBinaryFromReader);
      msg.setChanBackups(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMultiChanBackup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RestoreChanBackupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RestoreChanBackupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RestoreChanBackupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanBackups();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.lnrpc.ChannelBackups.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional ChannelBackups chan_backups = 1;
 * @return {?proto.lnrpc.ChannelBackups}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.getChanBackups = function() {
  return /** @type{?proto.lnrpc.ChannelBackups} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelBackups, 1));
};


/** @param {?proto.lnrpc.ChannelBackups|undefined} value */
proto.lnrpc.RestoreChanBackupRequest.prototype.setChanBackups = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.lnrpc.RestoreChanBackupRequest.oneofGroups_[0], value);
};


proto.lnrpc.RestoreChanBackupRequest.prototype.clearChanBackups = function() {
  this.setChanBackups(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.hasChanBackups = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes multi_chan_backup = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.getMultiChanBackup = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes multi_chan_backup = 2;
 * This is a type-conversion wrapper around `getMultiChanBackup()`
 * @return {string}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.getMultiChanBackup_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMultiChanBackup()));
};


/**
 * optional bytes multi_chan_backup = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMultiChanBackup()`
 * @return {!Uint8Array}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.getMultiChanBackup_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMultiChanBackup()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.RestoreChanBackupRequest.prototype.setMultiChanBackup = function(value) {
  jspb.Message.setOneofField(this, 2, proto.lnrpc.RestoreChanBackupRequest.oneofGroups_[0], value);
};


proto.lnrpc.RestoreChanBackupRequest.prototype.clearMultiChanBackup = function() {
  jspb.Message.setOneofField(this, 2, proto.lnrpc.RestoreChanBackupRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RestoreChanBackupRequest.prototype.hasMultiChanBackup = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RestoreBackupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.RestoreBackupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RestoreBackupResponse.displayName = 'proto.lnrpc.RestoreBackupResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RestoreBackupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RestoreBackupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RestoreBackupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RestoreBackupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RestoreBackupResponse}
 */
proto.lnrpc.RestoreBackupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RestoreBackupResponse;
  return proto.lnrpc.RestoreBackupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RestoreBackupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RestoreBackupResponse}
 */
proto.lnrpc.RestoreBackupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RestoreBackupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RestoreBackupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RestoreBackupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RestoreBackupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelBackupSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelBackupSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelBackupSubscription.displayName = 'proto.lnrpc.ChannelBackupSubscription';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelBackupSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelBackupSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelBackupSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBackupSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelBackupSubscription}
 */
proto.lnrpc.ChannelBackupSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelBackupSubscription;
  return proto.lnrpc.ChannelBackupSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelBackupSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelBackupSubscription}
 */
proto.lnrpc.ChannelBackupSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelBackupSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelBackupSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelBackupSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelBackupSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.VerifyChanBackupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.VerifyChanBackupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.VerifyChanBackupResponse.displayName = 'proto.lnrpc.VerifyChanBackupResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.VerifyChanBackupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.VerifyChanBackupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.VerifyChanBackupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.VerifyChanBackupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.VerifyChanBackupResponse}
 */
proto.lnrpc.VerifyChanBackupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.VerifyChanBackupResponse;
  return proto.lnrpc.VerifyChanBackupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.VerifyChanBackupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.VerifyChanBackupResponse}
 */
proto.lnrpc.VerifyChanBackupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.VerifyChanBackupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.VerifyChanBackupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.VerifyChanBackupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.VerifyChanBackupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.MacaroonPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.MacaroonPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.MacaroonPermission.displayName = 'proto.lnrpc.MacaroonPermission';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.MacaroonPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.MacaroonPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.MacaroonPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MacaroonPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.MacaroonPermission}
 */
proto.lnrpc.MacaroonPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.MacaroonPermission;
  return proto.lnrpc.MacaroonPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.MacaroonPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.MacaroonPermission}
 */
proto.lnrpc.MacaroonPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.MacaroonPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.MacaroonPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.MacaroonPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MacaroonPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity = 1;
 * @return {string}
 */
proto.lnrpc.MacaroonPermission.prototype.getEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.MacaroonPermission.prototype.setEntity = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action = 2;
 * @return {string}
 */
proto.lnrpc.MacaroonPermission.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.MacaroonPermission.prototype.setAction = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.BakeMacaroonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.BakeMacaroonRequest.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.BakeMacaroonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.BakeMacaroonRequest.displayName = 'proto.lnrpc.BakeMacaroonRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.BakeMacaroonRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.BakeMacaroonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.BakeMacaroonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.BakeMacaroonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BakeMacaroonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.lnrpc.MacaroonPermission.toObject, includeInstance),
    rootKeyId: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    allowExternalPermissions: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.BakeMacaroonRequest}
 */
proto.lnrpc.BakeMacaroonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.BakeMacaroonRequest;
  return proto.lnrpc.BakeMacaroonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.BakeMacaroonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.BakeMacaroonRequest}
 */
proto.lnrpc.BakeMacaroonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.MacaroonPermission;
      reader.readMessage(value,proto.lnrpc.MacaroonPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRootKeyId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowExternalPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.BakeMacaroonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.BakeMacaroonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.BakeMacaroonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BakeMacaroonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.MacaroonPermission.serializeBinaryToWriter
    );
  }
  f = message.getRootKeyId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getAllowExternalPermissions();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated MacaroonPermission permissions = 1;
 * @return {!Array<!proto.lnrpc.MacaroonPermission>}
 */
proto.lnrpc.BakeMacaroonRequest.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.lnrpc.MacaroonPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.MacaroonPermission, 1));
};


/** @param {!Array<!proto.lnrpc.MacaroonPermission>} value */
proto.lnrpc.BakeMacaroonRequest.prototype.setPermissionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.MacaroonPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.MacaroonPermission}
 */
proto.lnrpc.BakeMacaroonRequest.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.MacaroonPermission, opt_index);
};


proto.lnrpc.BakeMacaroonRequest.prototype.clearPermissionsList = function() {
  this.setPermissionsList([]);
};


/**
 * optional uint64 root_key_id = 2;
 * @return {string}
 */
proto.lnrpc.BakeMacaroonRequest.prototype.getRootKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/** @param {string} value */
proto.lnrpc.BakeMacaroonRequest.prototype.setRootKeyId = function(value) {
  jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional bool allow_external_permissions = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.BakeMacaroonRequest.prototype.getAllowExternalPermissions = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.BakeMacaroonRequest.prototype.setAllowExternalPermissions = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.BakeMacaroonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.BakeMacaroonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.BakeMacaroonResponse.displayName = 'proto.lnrpc.BakeMacaroonResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.BakeMacaroonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.BakeMacaroonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.BakeMacaroonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BakeMacaroonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    macaroon: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.BakeMacaroonResponse}
 */
proto.lnrpc.BakeMacaroonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.BakeMacaroonResponse;
  return proto.lnrpc.BakeMacaroonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.BakeMacaroonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.BakeMacaroonResponse}
 */
proto.lnrpc.BakeMacaroonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacaroon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.BakeMacaroonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.BakeMacaroonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.BakeMacaroonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.BakeMacaroonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacaroon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string macaroon = 1;
 * @return {string}
 */
proto.lnrpc.BakeMacaroonResponse.prototype.getMacaroon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.BakeMacaroonResponse.prototype.setMacaroon = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListMacaroonIDsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListMacaroonIDsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListMacaroonIDsRequest.displayName = 'proto.lnrpc.ListMacaroonIDsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListMacaroonIDsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListMacaroonIDsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListMacaroonIDsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListMacaroonIDsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListMacaroonIDsRequest}
 */
proto.lnrpc.ListMacaroonIDsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListMacaroonIDsRequest;
  return proto.lnrpc.ListMacaroonIDsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListMacaroonIDsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListMacaroonIDsRequest}
 */
proto.lnrpc.ListMacaroonIDsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListMacaroonIDsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListMacaroonIDsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListMacaroonIDsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListMacaroonIDsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListMacaroonIDsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.ListMacaroonIDsResponse.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.ListMacaroonIDsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListMacaroonIDsResponse.displayName = 'proto.lnrpc.ListMacaroonIDsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.ListMacaroonIDsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListMacaroonIDsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListMacaroonIDsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListMacaroonIDsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListMacaroonIDsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootKeyIdsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListMacaroonIDsResponse}
 */
proto.lnrpc.ListMacaroonIDsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListMacaroonIDsResponse;
  return proto.lnrpc.ListMacaroonIDsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListMacaroonIDsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListMacaroonIDsResponse}
 */
proto.lnrpc.ListMacaroonIDsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<string>} */ (reader.readPackedUint64String());
      msg.setRootKeyIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListMacaroonIDsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListMacaroonIDsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListMacaroonIDsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListMacaroonIDsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootKeyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64String(
      1,
      f
    );
  }
};


/**
 * repeated uint64 root_key_ids = 1;
 * @return {!Array<string>}
 */
proto.lnrpc.ListMacaroonIDsResponse.prototype.getRootKeyIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.lnrpc.ListMacaroonIDsResponse.prototype.setRootKeyIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.lnrpc.ListMacaroonIDsResponse.prototype.addRootKeyIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.lnrpc.ListMacaroonIDsResponse.prototype.clearRootKeyIdsList = function() {
  this.setRootKeyIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DeleteMacaroonIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DeleteMacaroonIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DeleteMacaroonIDRequest.displayName = 'proto.lnrpc.DeleteMacaroonIDRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DeleteMacaroonIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DeleteMacaroonIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DeleteMacaroonIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteMacaroonIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootKeyId: jspb.Message.getFieldWithDefault(msg, 1, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DeleteMacaroonIDRequest}
 */
proto.lnrpc.DeleteMacaroonIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DeleteMacaroonIDRequest;
  return proto.lnrpc.DeleteMacaroonIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DeleteMacaroonIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DeleteMacaroonIDRequest}
 */
proto.lnrpc.DeleteMacaroonIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRootKeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DeleteMacaroonIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DeleteMacaroonIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DeleteMacaroonIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteMacaroonIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootKeyId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
};


/**
 * optional uint64 root_key_id = 1;
 * @return {string}
 */
proto.lnrpc.DeleteMacaroonIDRequest.prototype.getRootKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.DeleteMacaroonIDRequest.prototype.setRootKeyId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.DeleteMacaroonIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.DeleteMacaroonIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.DeleteMacaroonIDResponse.displayName = 'proto.lnrpc.DeleteMacaroonIDResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.DeleteMacaroonIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.DeleteMacaroonIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.DeleteMacaroonIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteMacaroonIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleted: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.DeleteMacaroonIDResponse}
 */
proto.lnrpc.DeleteMacaroonIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.DeleteMacaroonIDResponse;
  return proto.lnrpc.DeleteMacaroonIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.DeleteMacaroonIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.DeleteMacaroonIDResponse}
 */
proto.lnrpc.DeleteMacaroonIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.DeleteMacaroonIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.DeleteMacaroonIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.DeleteMacaroonIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.DeleteMacaroonIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool deleted = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.DeleteMacaroonIDResponse.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.DeleteMacaroonIDResponse.prototype.setDeleted = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.MacaroonPermissionList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.MacaroonPermissionList.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.MacaroonPermissionList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.MacaroonPermissionList.displayName = 'proto.lnrpc.MacaroonPermissionList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.MacaroonPermissionList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.MacaroonPermissionList.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.MacaroonPermissionList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.MacaroonPermissionList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MacaroonPermissionList.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.lnrpc.MacaroonPermission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.MacaroonPermissionList}
 */
proto.lnrpc.MacaroonPermissionList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.MacaroonPermissionList;
  return proto.lnrpc.MacaroonPermissionList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.MacaroonPermissionList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.MacaroonPermissionList}
 */
proto.lnrpc.MacaroonPermissionList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.lnrpc.MacaroonPermission;
      reader.readMessage(value,proto.lnrpc.MacaroonPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.MacaroonPermissionList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.MacaroonPermissionList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.MacaroonPermissionList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MacaroonPermissionList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.lnrpc.MacaroonPermission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MacaroonPermission permissions = 1;
 * @return {!Array<!proto.lnrpc.MacaroonPermission>}
 */
proto.lnrpc.MacaroonPermissionList.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.lnrpc.MacaroonPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.MacaroonPermission, 1));
};


/** @param {!Array<!proto.lnrpc.MacaroonPermission>} value */
proto.lnrpc.MacaroonPermissionList.prototype.setPermissionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.lnrpc.MacaroonPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.MacaroonPermission}
 */
proto.lnrpc.MacaroonPermissionList.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.lnrpc.MacaroonPermission, opt_index);
};


proto.lnrpc.MacaroonPermissionList.prototype.clearPermissionsList = function() {
  this.setPermissionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListPermissionsRequest.displayName = 'proto.lnrpc.ListPermissionsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListPermissionsRequest}
 */
proto.lnrpc.ListPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListPermissionsRequest;
  return proto.lnrpc.ListPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListPermissionsRequest}
 */
proto.lnrpc.ListPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ListPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ListPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ListPermissionsResponse.displayName = 'proto.lnrpc.ListPermissionsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ListPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ListPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ListPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodPermissionsMap: (f = msg.getMethodPermissionsMap()) ? f.toObject(includeInstance, proto.lnrpc.MacaroonPermissionList.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ListPermissionsResponse}
 */
proto.lnrpc.ListPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ListPermissionsResponse;
  return proto.lnrpc.ListPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ListPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ListPermissionsResponse}
 */
proto.lnrpc.ListPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getMethodPermissionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.lnrpc.MacaroonPermissionList.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ListPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ListPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ListPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ListPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodPermissionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.lnrpc.MacaroonPermissionList.serializeBinaryToWriter);
  }
};


/**
 * map<string, MacaroonPermissionList> method_permissions = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.lnrpc.MacaroonPermissionList>}
 */
proto.lnrpc.ListPermissionsResponse.prototype.getMethodPermissionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.lnrpc.MacaroonPermissionList>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.lnrpc.MacaroonPermissionList));
};


proto.lnrpc.ListPermissionsResponse.prototype.clearMethodPermissionsMap = function() {
  this.getMethodPermissionsMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Failure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.Failure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Failure.displayName = 'proto.lnrpc.Failure';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Failure.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Failure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Failure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Failure.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    channelUpdate: (f = msg.getChannelUpdate()) && proto.lnrpc.ChannelUpdate.toObject(includeInstance, f),
    htlcMsat: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    onionSha256: msg.getOnionSha256_asB64(),
    cltvExpiry: jspb.Message.getFieldWithDefault(msg, 6, 0),
    flags: jspb.Message.getFieldWithDefault(msg, 7, 0),
    failureSourceIndex: jspb.Message.getFieldWithDefault(msg, 8, 0),
    height: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Failure}
 */
proto.lnrpc.Failure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Failure;
  return proto.lnrpc.Failure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Failure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Failure}
 */
proto.lnrpc.Failure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.lnrpc.Failure.FailureCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto.lnrpc.ChannelUpdate;
      reader.readMessage(value,proto.lnrpc.ChannelUpdate.deserializeBinaryFromReader);
      msg.setChannelUpdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setHtlcMsat(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOnionSha256(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCltvExpiry(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFlags(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFailureSourceIndex(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Failure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Failure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Failure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Failure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getChannelUpdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.ChannelUpdate.serializeBinaryToWriter
    );
  }
  f = message.getHtlcMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getOnionSha256_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getCltvExpiry();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getFlags();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getFailureSourceIndex();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.lnrpc.Failure.FailureCode = {
  RESERVED: 0,
  INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS: 1,
  INCORRECT_PAYMENT_AMOUNT: 2,
  FINAL_INCORRECT_CLTV_EXPIRY: 3,
  FINAL_INCORRECT_HTLC_AMOUNT: 4,
  FINAL_EXPIRY_TOO_SOON: 5,
  INVALID_REALM: 6,
  EXPIRY_TOO_SOON: 7,
  INVALID_ONION_VERSION: 8,
  INVALID_ONION_HMAC: 9,
  INVALID_ONION_KEY: 10,
  AMOUNT_BELOW_MINIMUM: 11,
  FEE_INSUFFICIENT: 12,
  INCORRECT_CLTV_EXPIRY: 13,
  CHANNEL_DISABLED: 14,
  TEMPORARY_CHANNEL_FAILURE: 15,
  REQUIRED_NODE_FEATURE_MISSING: 16,
  REQUIRED_CHANNEL_FEATURE_MISSING: 17,
  UNKNOWN_NEXT_PEER: 18,
  TEMPORARY_NODE_FAILURE: 19,
  PERMANENT_NODE_FAILURE: 20,
  PERMANENT_CHANNEL_FAILURE: 21,
  EXPIRY_TOO_FAR: 22,
  MPP_TIMEOUT: 23,
  INVALID_ONION_PAYLOAD: 24,
  INTERNAL_FAILURE: 997,
  UNKNOWN_FAILURE: 998,
  UNREADABLE_FAILURE: 999
};

/**
 * optional FailureCode code = 1;
 * @return {!proto.lnrpc.Failure.FailureCode}
 */
proto.lnrpc.Failure.prototype.getCode = function() {
  return /** @type {!proto.lnrpc.Failure.FailureCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.lnrpc.Failure.FailureCode} value */
proto.lnrpc.Failure.prototype.setCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ChannelUpdate channel_update = 3;
 * @return {?proto.lnrpc.ChannelUpdate}
 */
proto.lnrpc.Failure.prototype.getChannelUpdate = function() {
  return /** @type{?proto.lnrpc.ChannelUpdate} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.ChannelUpdate, 3));
};


/** @param {?proto.lnrpc.ChannelUpdate|undefined} value */
proto.lnrpc.Failure.prototype.setChannelUpdate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.lnrpc.Failure.prototype.clearChannelUpdate = function() {
  this.setChannelUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.Failure.prototype.hasChannelUpdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 htlc_msat = 4;
 * @return {string}
 */
proto.lnrpc.Failure.prototype.getHtlcMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.lnrpc.Failure.prototype.setHtlcMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional bytes onion_sha_256 = 5;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.Failure.prototype.getOnionSha256 = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes onion_sha_256 = 5;
 * This is a type-conversion wrapper around `getOnionSha256()`
 * @return {string}
 */
proto.lnrpc.Failure.prototype.getOnionSha256_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOnionSha256()));
};


/**
 * optional bytes onion_sha_256 = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOnionSha256()`
 * @return {!Uint8Array}
 */
proto.lnrpc.Failure.prototype.getOnionSha256_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOnionSha256()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.Failure.prototype.setOnionSha256 = function(value) {
  jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional uint32 cltv_expiry = 6;
 * @return {number}
 */
proto.lnrpc.Failure.prototype.getCltvExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.Failure.prototype.setCltvExpiry = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 flags = 7;
 * @return {number}
 */
proto.lnrpc.Failure.prototype.getFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.lnrpc.Failure.prototype.setFlags = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 failure_source_index = 8;
 * @return {number}
 */
proto.lnrpc.Failure.prototype.getFailureSourceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.lnrpc.Failure.prototype.setFailureSourceIndex = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 height = 9;
 * @return {number}
 */
proto.lnrpc.Failure.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.lnrpc.Failure.prototype.setHeight = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.ChannelUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.ChannelUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.ChannelUpdate.displayName = 'proto.lnrpc.ChannelUpdate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.ChannelUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.ChannelUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.ChannelUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    signature: msg.getSignature_asB64(),
    chainHash: msg.getChainHash_asB64(),
    chanId: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    messageFlags: jspb.Message.getFieldWithDefault(msg, 10, 0),
    channelFlags: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timeLockDelta: jspb.Message.getFieldWithDefault(msg, 6, 0),
    htlcMinimumMsat: jspb.Message.getFieldWithDefault(msg, 7, "0"),
    baseFee: jspb.Message.getFieldWithDefault(msg, 8, 0),
    feeRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    htlcMaximumMsat: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    extraOpaqueData: msg.getExtraOpaqueData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.ChannelUpdate}
 */
proto.lnrpc.ChannelUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.ChannelUpdate;
  return proto.lnrpc.ChannelUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.ChannelUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.ChannelUpdate}
 */
proto.lnrpc.ChannelUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChainHash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setChanId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimestamp(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMessageFlags(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelFlags(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeLockDelta(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setHtlcMinimumMsat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaseFee(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFeeRate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setHtlcMaximumMsat(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setExtraOpaqueData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.ChannelUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.ChannelUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.ChannelUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChainHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getChanId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMessageFlags();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getChannelFlags();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTimeLockDelta();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getHtlcMinimumMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
  f = message.getBaseFee();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getFeeRate();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getHtlcMaximumMsat();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
  f = message.getExtraOpaqueData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
};


/**
 * optional bytes signature = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelUpdate.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes signature = 1;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.lnrpc.ChannelUpdate.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelUpdate.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelUpdate.prototype.setSignature = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes chain_hash = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelUpdate.prototype.getChainHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chain_hash = 2;
 * This is a type-conversion wrapper around `getChainHash()`
 * @return {string}
 */
proto.lnrpc.ChannelUpdate.prototype.getChainHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChainHash()));
};


/**
 * optional bytes chain_hash = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChainHash()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelUpdate.prototype.getChainHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChainHash()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelUpdate.prototype.setChainHash = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional uint64 chan_id = 3;
 * @return {string}
 */
proto.lnrpc.ChannelUpdate.prototype.getChanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelUpdate.prototype.setChanId = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional uint32 timestamp = 4;
 * @return {number}
 */
proto.lnrpc.ChannelUpdate.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelUpdate.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 message_flags = 10;
 * @return {number}
 */
proto.lnrpc.ChannelUpdate.prototype.getMessageFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelUpdate.prototype.setMessageFlags = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 channel_flags = 5;
 * @return {number}
 */
proto.lnrpc.ChannelUpdate.prototype.getChannelFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelUpdate.prototype.setChannelFlags = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 time_lock_delta = 6;
 * @return {number}
 */
proto.lnrpc.ChannelUpdate.prototype.getTimeLockDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelUpdate.prototype.setTimeLockDelta = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 htlc_minimum_msat = 7;
 * @return {string}
 */
proto.lnrpc.ChannelUpdate.prototype.getHtlcMinimumMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelUpdate.prototype.setHtlcMinimumMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};


/**
 * optional uint32 base_fee = 8;
 * @return {number}
 */
proto.lnrpc.ChannelUpdate.prototype.getBaseFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelUpdate.prototype.setBaseFee = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 fee_rate = 9;
 * @return {number}
 */
proto.lnrpc.ChannelUpdate.prototype.getFeeRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.lnrpc.ChannelUpdate.prototype.setFeeRate = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 htlc_maximum_msat = 11;
 * @return {string}
 */
proto.lnrpc.ChannelUpdate.prototype.getHtlcMaximumMsat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/** @param {string} value */
proto.lnrpc.ChannelUpdate.prototype.setHtlcMaximumMsat = function(value) {
  jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional bytes extra_opaque_data = 12;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.ChannelUpdate.prototype.getExtraOpaqueData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes extra_opaque_data = 12;
 * This is a type-conversion wrapper around `getExtraOpaqueData()`
 * @return {string}
 */
proto.lnrpc.ChannelUpdate.prototype.getExtraOpaqueData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getExtraOpaqueData()));
};


/**
 * optional bytes extra_opaque_data = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getExtraOpaqueData()`
 * @return {!Uint8Array}
 */
proto.lnrpc.ChannelUpdate.prototype.getExtraOpaqueData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getExtraOpaqueData()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.ChannelUpdate.prototype.setExtraOpaqueData = function(value) {
  jspb.Message.setProto3BytesField(this, 12, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.MacaroonId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.MacaroonId.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.MacaroonId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.MacaroonId.displayName = 'proto.lnrpc.MacaroonId';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.MacaroonId.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.MacaroonId.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.MacaroonId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.MacaroonId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MacaroonId.toObject = function(includeInstance, msg) {
  var f, obj = {
    nonce: msg.getNonce_asB64(),
    storageid: msg.getStorageid_asB64(),
    opsList: jspb.Message.toObjectList(msg.getOpsList(),
    proto.lnrpc.Op.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.MacaroonId}
 */
proto.lnrpc.MacaroonId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.MacaroonId;
  return proto.lnrpc.MacaroonId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.MacaroonId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.MacaroonId}
 */
proto.lnrpc.MacaroonId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNonce(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStorageid(value);
      break;
    case 3:
      var value = new proto.lnrpc.Op;
      reader.readMessage(value,proto.lnrpc.Op.deserializeBinaryFromReader);
      msg.addOps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.MacaroonId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.MacaroonId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.MacaroonId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MacaroonId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNonce_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getStorageid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getOpsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.lnrpc.Op.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes nonce = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.MacaroonId.prototype.getNonce = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes nonce = 1;
 * This is a type-conversion wrapper around `getNonce()`
 * @return {string}
 */
proto.lnrpc.MacaroonId.prototype.getNonce_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNonce()));
};


/**
 * optional bytes nonce = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNonce()`
 * @return {!Uint8Array}
 */
proto.lnrpc.MacaroonId.prototype.getNonce_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNonce()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.MacaroonId.prototype.setNonce = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes storageId = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.MacaroonId.prototype.getStorageid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes storageId = 2;
 * This is a type-conversion wrapper around `getStorageid()`
 * @return {string}
 */
proto.lnrpc.MacaroonId.prototype.getStorageid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStorageid()));
};


/**
 * optional bytes storageId = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStorageid()`
 * @return {!Uint8Array}
 */
proto.lnrpc.MacaroonId.prototype.getStorageid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStorageid()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.MacaroonId.prototype.setStorageid = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated Op ops = 3;
 * @return {!Array<!proto.lnrpc.Op>}
 */
proto.lnrpc.MacaroonId.prototype.getOpsList = function() {
  return /** @type{!Array<!proto.lnrpc.Op>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.Op, 3));
};


/** @param {!Array<!proto.lnrpc.Op>} value */
proto.lnrpc.MacaroonId.prototype.setOpsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.lnrpc.Op=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.Op}
 */
proto.lnrpc.MacaroonId.prototype.addOps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.lnrpc.Op, opt_index);
};


proto.lnrpc.MacaroonId.prototype.clearOpsList = function() {
  this.setOpsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.Op = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.Op.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.Op, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.Op.displayName = 'proto.lnrpc.Op';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.Op.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.Op.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.Op.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.Op} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Op.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionsList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.Op}
 */
proto.lnrpc.Op.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.Op;
  return proto.lnrpc.Op.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.Op} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.Op}
 */
proto.lnrpc.Op.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.Op.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.Op.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.Op} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.Op.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string entity = 1;
 * @return {string}
 */
proto.lnrpc.Op.prototype.getEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.Op.prototype.setEntity = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string actions = 2;
 * @return {!Array<string>}
 */
proto.lnrpc.Op.prototype.getActionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.lnrpc.Op.prototype.setActionsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.lnrpc.Op.prototype.addActions = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.lnrpc.Op.prototype.clearActionsList = function() {
  this.setActionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.CheckMacPermRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.lnrpc.CheckMacPermRequest.repeatedFields_, null);
};
goog.inherits(proto.lnrpc.CheckMacPermRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.CheckMacPermRequest.displayName = 'proto.lnrpc.CheckMacPermRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.lnrpc.CheckMacPermRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.CheckMacPermRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.CheckMacPermRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.CheckMacPermRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CheckMacPermRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    macaroon: msg.getMacaroon_asB64(),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.lnrpc.MacaroonPermission.toObject, includeInstance),
    fullmethod: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.CheckMacPermRequest}
 */
proto.lnrpc.CheckMacPermRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.CheckMacPermRequest;
  return proto.lnrpc.CheckMacPermRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.CheckMacPermRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.CheckMacPermRequest}
 */
proto.lnrpc.CheckMacPermRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMacaroon(value);
      break;
    case 2:
      var value = new proto.lnrpc.MacaroonPermission;
      reader.readMessage(value,proto.lnrpc.MacaroonPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullmethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.CheckMacPermRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.CheckMacPermRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.CheckMacPermRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CheckMacPermRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacaroon_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.lnrpc.MacaroonPermission.serializeBinaryToWriter
    );
  }
  f = message.getFullmethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bytes macaroon = 1;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.CheckMacPermRequest.prototype.getMacaroon = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes macaroon = 1;
 * This is a type-conversion wrapper around `getMacaroon()`
 * @return {string}
 */
proto.lnrpc.CheckMacPermRequest.prototype.getMacaroon_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMacaroon()));
};


/**
 * optional bytes macaroon = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMacaroon()`
 * @return {!Uint8Array}
 */
proto.lnrpc.CheckMacPermRequest.prototype.getMacaroon_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMacaroon()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.CheckMacPermRequest.prototype.setMacaroon = function(value) {
  jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * repeated MacaroonPermission permissions = 2;
 * @return {!Array<!proto.lnrpc.MacaroonPermission>}
 */
proto.lnrpc.CheckMacPermRequest.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.lnrpc.MacaroonPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.lnrpc.MacaroonPermission, 2));
};


/** @param {!Array<!proto.lnrpc.MacaroonPermission>} value */
proto.lnrpc.CheckMacPermRequest.prototype.setPermissionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.lnrpc.MacaroonPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.lnrpc.MacaroonPermission}
 */
proto.lnrpc.CheckMacPermRequest.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.lnrpc.MacaroonPermission, opt_index);
};


proto.lnrpc.CheckMacPermRequest.prototype.clearPermissionsList = function() {
  this.setPermissionsList([]);
};


/**
 * optional string fullMethod = 3;
 * @return {string}
 */
proto.lnrpc.CheckMacPermRequest.prototype.getFullmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.CheckMacPermRequest.prototype.setFullmethod = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.CheckMacPermResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.CheckMacPermResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.CheckMacPermResponse.displayName = 'proto.lnrpc.CheckMacPermResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.CheckMacPermResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.CheckMacPermResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.CheckMacPermResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CheckMacPermResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.CheckMacPermResponse}
 */
proto.lnrpc.CheckMacPermResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.CheckMacPermResponse;
  return proto.lnrpc.CheckMacPermResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.CheckMacPermResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.CheckMacPermResponse}
 */
proto.lnrpc.CheckMacPermResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.CheckMacPermResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.CheckMacPermResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.CheckMacPermResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.CheckMacPermResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.CheckMacPermResponse.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.lnrpc.CheckMacPermResponse.prototype.setValid = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RPCMiddlewareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.RPCMiddlewareRequest.oneofGroups_);
};
goog.inherits(proto.lnrpc.RPCMiddlewareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RPCMiddlewareRequest.displayName = 'proto.lnrpc.RPCMiddlewareRequest';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.RPCMiddlewareRequest.oneofGroups_ = [[4,5,6]];

/**
 * @enum {number}
 */
proto.lnrpc.RPCMiddlewareRequest.InterceptTypeCase = {
  INTERCEPT_TYPE_NOT_SET: 0,
  STREAM_AUTH: 4,
  REQUEST: 5,
  RESPONSE: 6
};

/**
 * @return {proto.lnrpc.RPCMiddlewareRequest.InterceptTypeCase}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getInterceptTypeCase = function() {
  return /** @type {proto.lnrpc.RPCMiddlewareRequest.InterceptTypeCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.RPCMiddlewareRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RPCMiddlewareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RPCMiddlewareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RPCMiddlewareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    rawMacaroon: msg.getRawMacaroon_asB64(),
    customCaveatCondition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    streamAuth: (f = msg.getStreamAuth()) && proto.lnrpc.StreamAuth.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && proto.lnrpc.RPCMessage.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && proto.lnrpc.RPCMessage.toObject(includeInstance, f),
    msgId: jspb.Message.getFieldWithDefault(msg, 7, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RPCMiddlewareRequest}
 */
proto.lnrpc.RPCMiddlewareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RPCMiddlewareRequest;
  return proto.lnrpc.RPCMiddlewareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RPCMiddlewareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RPCMiddlewareRequest}
 */
proto.lnrpc.RPCMiddlewareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawMacaroon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomCaveatCondition(value);
      break;
    case 4:
      var value = new proto.lnrpc.StreamAuth;
      reader.readMessage(value,proto.lnrpc.StreamAuth.deserializeBinaryFromReader);
      msg.setStreamAuth(value);
      break;
    case 5:
      var value = new proto.lnrpc.RPCMessage;
      reader.readMessage(value,proto.lnrpc.RPCMessage.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 6:
      var value = new proto.lnrpc.RPCMessage;
      reader.readMessage(value,proto.lnrpc.RPCMessage.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setMsgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RPCMiddlewareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RPCMiddlewareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RPCMiddlewareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getRawMacaroon_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getCustomCaveatCondition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStreamAuth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.lnrpc.StreamAuth.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.lnrpc.RPCMessage.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.lnrpc.RPCMessage.serializeBinaryToWriter
    );
  }
  f = message.getMsgId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      7,
      f
    );
  }
};


/**
 * optional uint64 request_id = 1;
 * @return {string}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setRequestId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional bytes raw_macaroon = 2;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getRawMacaroon = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes raw_macaroon = 2;
 * This is a type-conversion wrapper around `getRawMacaroon()`
 * @return {string}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getRawMacaroon_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawMacaroon()));
};


/**
 * optional bytes raw_macaroon = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawMacaroon()`
 * @return {!Uint8Array}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getRawMacaroon_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawMacaroon()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setRawMacaroon = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string custom_caveat_condition = 3;
 * @return {string}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getCustomCaveatCondition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setCustomCaveatCondition = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional StreamAuth stream_auth = 4;
 * @return {?proto.lnrpc.StreamAuth}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getStreamAuth = function() {
  return /** @type{?proto.lnrpc.StreamAuth} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.StreamAuth, 4));
};


/** @param {?proto.lnrpc.StreamAuth|undefined} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setStreamAuth = function(value) {
  jspb.Message.setOneofWrapperField(this, 4, proto.lnrpc.RPCMiddlewareRequest.oneofGroups_[0], value);
};


proto.lnrpc.RPCMiddlewareRequest.prototype.clearStreamAuth = function() {
  this.setStreamAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.hasStreamAuth = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RPCMessage request = 5;
 * @return {?proto.lnrpc.RPCMessage}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getRequest = function() {
  return /** @type{?proto.lnrpc.RPCMessage} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.RPCMessage, 5));
};


/** @param {?proto.lnrpc.RPCMessage|undefined} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setRequest = function(value) {
  jspb.Message.setOneofWrapperField(this, 5, proto.lnrpc.RPCMiddlewareRequest.oneofGroups_[0], value);
};


proto.lnrpc.RPCMiddlewareRequest.prototype.clearRequest = function() {
  this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RPCMessage response = 6;
 * @return {?proto.lnrpc.RPCMessage}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getResponse = function() {
  return /** @type{?proto.lnrpc.RPCMessage} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.RPCMessage, 6));
};


/** @param {?proto.lnrpc.RPCMessage|undefined} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setResponse = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.lnrpc.RPCMiddlewareRequest.oneofGroups_[0], value);
};


proto.lnrpc.RPCMiddlewareRequest.prototype.clearResponse = function() {
  this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 msg_id = 7;
 * @return {string}
 */
proto.lnrpc.RPCMiddlewareRequest.prototype.getMsgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, "0"));
};


/** @param {string} value */
proto.lnrpc.RPCMiddlewareRequest.prototype.setMsgId = function(value) {
  jspb.Message.setProto3StringIntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.StreamAuth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.StreamAuth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.StreamAuth.displayName = 'proto.lnrpc.StreamAuth';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.StreamAuth.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.StreamAuth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.StreamAuth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.StreamAuth.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodFullUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.StreamAuth}
 */
proto.lnrpc.StreamAuth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.StreamAuth;
  return proto.lnrpc.StreamAuth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.StreamAuth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.StreamAuth}
 */
proto.lnrpc.StreamAuth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodFullUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.StreamAuth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.StreamAuth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.StreamAuth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.StreamAuth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodFullUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string method_full_uri = 1;
 * @return {string}
 */
proto.lnrpc.StreamAuth.prototype.getMethodFullUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.StreamAuth.prototype.setMethodFullUri = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RPCMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.RPCMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RPCMessage.displayName = 'proto.lnrpc.RPCMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RPCMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RPCMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RPCMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RPCMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodFullUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    streamRpc: jspb.Message.getFieldWithDefault(msg, 2, false),
    typeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serialized: msg.getSerialized_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RPCMessage}
 */
proto.lnrpc.RPCMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RPCMessage;
  return proto.lnrpc.RPCMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RPCMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RPCMessage}
 */
proto.lnrpc.RPCMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodFullUri(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreamRpc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeName(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSerialized(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RPCMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RPCMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RPCMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RPCMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodFullUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreamRpc();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTypeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSerialized_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string method_full_uri = 1;
 * @return {string}
 */
proto.lnrpc.RPCMessage.prototype.getMethodFullUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.RPCMessage.prototype.setMethodFullUri = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool stream_rpc = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.RPCMessage.prototype.getStreamRpc = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.RPCMessage.prototype.setStreamRpc = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string type_name = 3;
 * @return {string}
 */
proto.lnrpc.RPCMessage.prototype.getTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.lnrpc.RPCMessage.prototype.setTypeName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes serialized = 4;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.RPCMessage.prototype.getSerialized = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes serialized = 4;
 * This is a type-conversion wrapper around `getSerialized()`
 * @return {string}
 */
proto.lnrpc.RPCMessage.prototype.getSerialized_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSerialized()));
};


/**
 * optional bytes serialized = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSerialized()`
 * @return {!Uint8Array}
 */
proto.lnrpc.RPCMessage.prototype.getSerialized_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSerialized()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.RPCMessage.prototype.setSerialized = function(value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.RPCMiddlewareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.lnrpc.RPCMiddlewareResponse.oneofGroups_);
};
goog.inherits(proto.lnrpc.RPCMiddlewareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.RPCMiddlewareResponse.displayName = 'proto.lnrpc.RPCMiddlewareResponse';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.lnrpc.RPCMiddlewareResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.lnrpc.RPCMiddlewareResponse.MiddlewareMessageCase = {
  MIDDLEWARE_MESSAGE_NOT_SET: 0,
  REGISTER: 2,
  FEEDBACK: 3
};

/**
 * @return {proto.lnrpc.RPCMiddlewareResponse.MiddlewareMessageCase}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.getMiddlewareMessageCase = function() {
  return /** @type {proto.lnrpc.RPCMiddlewareResponse.MiddlewareMessageCase} */(jspb.Message.computeOneofCase(this, proto.lnrpc.RPCMiddlewareResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.RPCMiddlewareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.RPCMiddlewareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RPCMiddlewareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    refMsgId: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    register: (f = msg.getRegister()) && proto.lnrpc.MiddlewareRegistration.toObject(includeInstance, f),
    feedback: (f = msg.getFeedback()) && proto.lnrpc.InterceptFeedback.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.RPCMiddlewareResponse}
 */
proto.lnrpc.RPCMiddlewareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.RPCMiddlewareResponse;
  return proto.lnrpc.RPCMiddlewareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.RPCMiddlewareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.RPCMiddlewareResponse}
 */
proto.lnrpc.RPCMiddlewareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRefMsgId(value);
      break;
    case 2:
      var value = new proto.lnrpc.MiddlewareRegistration;
      reader.readMessage(value,proto.lnrpc.MiddlewareRegistration.deserializeBinaryFromReader);
      msg.setRegister(value);
      break;
    case 3:
      var value = new proto.lnrpc.InterceptFeedback;
      reader.readMessage(value,proto.lnrpc.InterceptFeedback.deserializeBinaryFromReader);
      msg.setFeedback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.RPCMiddlewareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.RPCMiddlewareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.RPCMiddlewareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefMsgId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      1,
      f
    );
  }
  f = message.getRegister();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.lnrpc.MiddlewareRegistration.serializeBinaryToWriter
    );
  }
  f = message.getFeedback();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.lnrpc.InterceptFeedback.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 ref_msg_id = 1;
 * @return {string}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.getRefMsgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/** @param {string} value */
proto.lnrpc.RPCMiddlewareResponse.prototype.setRefMsgId = function(value) {
  jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional MiddlewareRegistration register = 2;
 * @return {?proto.lnrpc.MiddlewareRegistration}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.getRegister = function() {
  return /** @type{?proto.lnrpc.MiddlewareRegistration} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.MiddlewareRegistration, 2));
};


/** @param {?proto.lnrpc.MiddlewareRegistration|undefined} value */
proto.lnrpc.RPCMiddlewareResponse.prototype.setRegister = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.lnrpc.RPCMiddlewareResponse.oneofGroups_[0], value);
};


proto.lnrpc.RPCMiddlewareResponse.prototype.clearRegister = function() {
  this.setRegister(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.hasRegister = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InterceptFeedback feedback = 3;
 * @return {?proto.lnrpc.InterceptFeedback}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.getFeedback = function() {
  return /** @type{?proto.lnrpc.InterceptFeedback} */ (
    jspb.Message.getWrapperField(this, proto.lnrpc.InterceptFeedback, 3));
};


/** @param {?proto.lnrpc.InterceptFeedback|undefined} value */
proto.lnrpc.RPCMiddlewareResponse.prototype.setFeedback = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.lnrpc.RPCMiddlewareResponse.oneofGroups_[0], value);
};


proto.lnrpc.RPCMiddlewareResponse.prototype.clearFeedback = function() {
  this.setFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.lnrpc.RPCMiddlewareResponse.prototype.hasFeedback = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.MiddlewareRegistration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.MiddlewareRegistration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.MiddlewareRegistration.displayName = 'proto.lnrpc.MiddlewareRegistration';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.MiddlewareRegistration.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.MiddlewareRegistration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.MiddlewareRegistration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MiddlewareRegistration.toObject = function(includeInstance, msg) {
  var f, obj = {
    middlewareName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customMacaroonCaveatName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    readOnlyMode: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.MiddlewareRegistration}
 */
proto.lnrpc.MiddlewareRegistration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.MiddlewareRegistration;
  return proto.lnrpc.MiddlewareRegistration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.MiddlewareRegistration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.MiddlewareRegistration}
 */
proto.lnrpc.MiddlewareRegistration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlewareName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMacaroonCaveatName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnlyMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.MiddlewareRegistration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.MiddlewareRegistration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.MiddlewareRegistration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.MiddlewareRegistration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMiddlewareName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomMacaroonCaveatName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReadOnlyMode();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string middleware_name = 1;
 * @return {string}
 */
proto.lnrpc.MiddlewareRegistration.prototype.getMiddlewareName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.MiddlewareRegistration.prototype.setMiddlewareName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string custom_macaroon_caveat_name = 2;
 * @return {string}
 */
proto.lnrpc.MiddlewareRegistration.prototype.getCustomMacaroonCaveatName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.lnrpc.MiddlewareRegistration.prototype.setCustomMacaroonCaveatName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool read_only_mode = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.MiddlewareRegistration.prototype.getReadOnlyMode = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.lnrpc.MiddlewareRegistration.prototype.setReadOnlyMode = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.lnrpc.InterceptFeedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.lnrpc.InterceptFeedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.lnrpc.InterceptFeedback.displayName = 'proto.lnrpc.InterceptFeedback';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.lnrpc.InterceptFeedback.prototype.toObject = function(opt_includeInstance) {
  return proto.lnrpc.InterceptFeedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.lnrpc.InterceptFeedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.InterceptFeedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getFieldWithDefault(msg, 1, ""),
    replaceResponse: jspb.Message.getFieldWithDefault(msg, 2, false),
    replacementSerialized: msg.getReplacementSerialized_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.lnrpc.InterceptFeedback}
 */
proto.lnrpc.InterceptFeedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.lnrpc.InterceptFeedback;
  return proto.lnrpc.InterceptFeedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.lnrpc.InterceptFeedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.lnrpc.InterceptFeedback}
 */
proto.lnrpc.InterceptFeedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReplaceResponse(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReplacementSerialized(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.lnrpc.InterceptFeedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.lnrpc.InterceptFeedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.lnrpc.InterceptFeedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.lnrpc.InterceptFeedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReplaceResponse();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReplacementSerialized_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.lnrpc.InterceptFeedback.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.lnrpc.InterceptFeedback.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool replace_response = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.lnrpc.InterceptFeedback.prototype.getReplaceResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.lnrpc.InterceptFeedback.prototype.setReplaceResponse = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bytes replacement_serialized = 3;
 * @return {!(string|Uint8Array)}
 */
proto.lnrpc.InterceptFeedback.prototype.getReplacementSerialized = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes replacement_serialized = 3;
 * This is a type-conversion wrapper around `getReplacementSerialized()`
 * @return {string}
 */
proto.lnrpc.InterceptFeedback.prototype.getReplacementSerialized_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReplacementSerialized()));
};


/**
 * optional bytes replacement_serialized = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReplacementSerialized()`
 * @return {!Uint8Array}
 */
proto.lnrpc.InterceptFeedback.prototype.getReplacementSerialized_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReplacementSerialized()));
};


/** @param {!(string|Uint8Array)} value */
proto.lnrpc.InterceptFeedback.prototype.setReplacementSerialized = function(value) {
  jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.lnrpc.OutputScriptType = {
  SCRIPT_TYPE_PUBKEY_HASH: 0,
  SCRIPT_TYPE_SCRIPT_HASH: 1,
  SCRIPT_TYPE_WITNESS_V0_PUBKEY_HASH: 2,
  SCRIPT_TYPE_WITNESS_V0_SCRIPT_HASH: 3,
  SCRIPT_TYPE_PUBKEY: 4,
  SCRIPT_TYPE_MULTISIG: 5,
  SCRIPT_TYPE_NULLDATA: 6,
  SCRIPT_TYPE_NON_STANDARD: 7,
  SCRIPT_TYPE_WITNESS_UNKNOWN: 8
};

/**
 * @enum {number}
 */
proto.lnrpc.AddressType = {
  WITNESS_PUBKEY_HASH: 0,
  NESTED_PUBKEY_HASH: 1,
  UNUSED_WITNESS_PUBKEY_HASH: 2,
  UNUSED_NESTED_PUBKEY_HASH: 3,
  TAPROOT_PUBKEY: 4,
  UNUSED_TAPROOT_PUBKEY: 5
};

/**
 * @enum {number}
 */
proto.lnrpc.CommitmentType = {
  UNKNOWN_COMMITMENT_TYPE: 0,
  LEGACY: 1,
  STATIC_REMOTE_KEY: 2,
  ANCHORS: 3,
  SCRIPT_ENFORCED_LEASE: 4
};

/**
 * @enum {number}
 */
proto.lnrpc.Initiator = {
  INITIATOR_UNKNOWN: 0,
  INITIATOR_LOCAL: 1,
  INITIATOR_REMOTE: 2,
  INITIATOR_BOTH: 3
};

/**
 * @enum {number}
 */
proto.lnrpc.ResolutionType = {
  TYPE_UNKNOWN: 0,
  ANCHOR: 1,
  INCOMING_HTLC: 2,
  OUTGOING_HTLC: 3,
  COMMIT: 4
};

/**
 * @enum {number}
 */
proto.lnrpc.ResolutionOutcome = {
  OUTCOME_UNKNOWN: 0,
  CLAIMED: 1,
  UNCLAIMED: 2,
  ABANDONED: 3,
  FIRST_STAGE: 4,
  TIMEOUT: 5
};

/**
 * @enum {number}
 */
proto.lnrpc.NodeMetricType = {
  UNKNOWN: 0,
  BETWEENNESS_CENTRALITY: 1
};

/**
 * @enum {number}
 */
proto.lnrpc.InvoiceHTLCState = {
  ACCEPTED: 0,
  SETTLED: 1,
  CANCELED: 2
};

/**
 * @enum {number}
 */
proto.lnrpc.PaymentFailureReason = {
  FAILURE_REASON_NONE: 0,
  FAILURE_REASON_TIMEOUT: 1,
  FAILURE_REASON_NO_ROUTE: 2,
  FAILURE_REASON_ERROR: 3,
  FAILURE_REASON_INCORRECT_PAYMENT_DETAILS: 4,
  FAILURE_REASON_INSUFFICIENT_BALANCE: 5
};

/**
 * @enum {number}
 */
proto.lnrpc.FeatureBit = {
  DATALOSS_PROTECT_REQ: 0,
  DATALOSS_PROTECT_OPT: 1,
  INITIAL_ROUING_SYNC: 3,
  UPFRONT_SHUTDOWN_SCRIPT_REQ: 4,
  UPFRONT_SHUTDOWN_SCRIPT_OPT: 5,
  GOSSIP_QUERIES_REQ: 6,
  GOSSIP_QUERIES_OPT: 7,
  TLV_ONION_REQ: 8,
  TLV_ONION_OPT: 9,
  EXT_GOSSIP_QUERIES_REQ: 10,
  EXT_GOSSIP_QUERIES_OPT: 11,
  STATIC_REMOTE_KEY_REQ: 12,
  STATIC_REMOTE_KEY_OPT: 13,
  PAYMENT_ADDR_REQ: 14,
  PAYMENT_ADDR_OPT: 15,
  MPP_REQ: 16,
  MPP_OPT: 17,
  WUMBO_CHANNELS_REQ: 18,
  WUMBO_CHANNELS_OPT: 19,
  ANCHORS_REQ: 20,
  ANCHORS_OPT: 21,
  ANCHORS_ZERO_FEE_HTLC_REQ: 22,
  ANCHORS_ZERO_FEE_HTLC_OPT: 23,
  AMP_REQ: 30,
  AMP_OPT: 31
};

/**
 * @enum {number}
 */
proto.lnrpc.UpdateFailure = {
  UPDATE_FAILURE_UNKNOWN: 0,
  UPDATE_FAILURE_PENDING: 1,
  UPDATE_FAILURE_NOT_FOUND: 2,
  UPDATE_FAILURE_INTERNAL_ERR: 3,
  UPDATE_FAILURE_INVALID_PARAMETER: 4
};

goog.object.extend(exports, proto.lnrpc);
