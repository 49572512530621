/* eslint-disable */
var proto = { litrpc: {} };

/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.litrpc.AddSessionRequest', null, global);
goog.exportSymbol('proto.litrpc.AddSessionResponse', null, global);
goog.exportSymbol('proto.litrpc.ListSessionsRequest', null, global);
goog.exportSymbol('proto.litrpc.ListSessionsResponse', null, global);
goog.exportSymbol('proto.litrpc.MacaroonPermission', null, global);
goog.exportSymbol('proto.litrpc.RevokeSessionRequest', null, global);
goog.exportSymbol('proto.litrpc.RevokeSessionResponse', null, global);
goog.exportSymbol('proto.litrpc.Session', null, global);
goog.exportSymbol('proto.litrpc.SessionState', null, global);
goog.exportSymbol('proto.litrpc.SessionType', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.AddSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.AddSessionRequest.repeatedFields_, null);
};
goog.inherits(proto.litrpc.AddSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.AddSessionRequest.displayName = 'proto.litrpc.AddSessionRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.AddSessionRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.AddSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.AddSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.AddSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiryTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    mailboxServerAddr: jspb.Message.getFieldWithDefault(msg, 4, ""),
    devServer: jspb.Message.getFieldWithDefault(msg, 5, false),
    macaroonCustomPermissionsList: jspb.Message.toObjectList(msg.getMacaroonCustomPermissionsList(),
    proto.litrpc.MacaroonPermission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.AddSessionRequest}
 */
proto.litrpc.AddSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.AddSessionRequest;
  return proto.litrpc.AddSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.AddSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.AddSessionRequest}
 */
proto.litrpc.AddSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.litrpc.SessionType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setExpiryTimestampSeconds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailboxServerAddr(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDevServer(value);
      break;
    case 6:
      var value = new proto.litrpc.MacaroonPermission;
      reader.readMessage(value,proto.litrpc.MacaroonPermission.deserializeBinaryFromReader);
      msg.addMacaroonCustomPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.AddSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.AddSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.AddSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getExpiryTimestampSeconds();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      3,
      f
    );
  }
  f = message.getMailboxServerAddr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDevServer();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMacaroonCustomPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.litrpc.MacaroonPermission.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.litrpc.AddSessionRequest.prototype.setLabel = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SessionType session_type = 2;
 * @return {!proto.litrpc.SessionType}
 */
proto.litrpc.AddSessionRequest.prototype.getSessionType = function() {
  return /** @type {!proto.litrpc.SessionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.litrpc.SessionType} value */
proto.litrpc.AddSessionRequest.prototype.setSessionType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 expiry_timestamp_seconds = 3;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getExpiryTimestampSeconds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/** @param {string} value */
proto.litrpc.AddSessionRequest.prototype.setExpiryTimestampSeconds = function(value) {
  jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional string mailbox_server_addr = 4;
 * @return {string}
 */
proto.litrpc.AddSessionRequest.prototype.getMailboxServerAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.litrpc.AddSessionRequest.prototype.setMailboxServerAddr = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool dev_server = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.litrpc.AddSessionRequest.prototype.getDevServer = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.litrpc.AddSessionRequest.prototype.setDevServer = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated MacaroonPermission macaroon_custom_permissions = 6;
 * @return {!Array<!proto.litrpc.MacaroonPermission>}
 */
proto.litrpc.AddSessionRequest.prototype.getMacaroonCustomPermissionsList = function() {
  return /** @type{!Array<!proto.litrpc.MacaroonPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.litrpc.MacaroonPermission, 6));
};


/** @param {!Array<!proto.litrpc.MacaroonPermission>} value */
proto.litrpc.AddSessionRequest.prototype.setMacaroonCustomPermissionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.litrpc.MacaroonPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.AddSessionRequest.prototype.addMacaroonCustomPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.litrpc.MacaroonPermission, opt_index);
};


proto.litrpc.AddSessionRequest.prototype.clearMacaroonCustomPermissionsList = function() {
  this.setMacaroonCustomPermissionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.MacaroonPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.MacaroonPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.MacaroonPermission.displayName = 'proto.litrpc.MacaroonPermission';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.MacaroonPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.MacaroonPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.MacaroonPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.MacaroonPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.MacaroonPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.MacaroonPermission;
  return proto.litrpc.MacaroonPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.MacaroonPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.MacaroonPermission}
 */
proto.litrpc.MacaroonPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.MacaroonPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.MacaroonPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.MacaroonPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.MacaroonPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity = 1;
 * @return {string}
 */
proto.litrpc.MacaroonPermission.prototype.getEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.litrpc.MacaroonPermission.prototype.setEntity = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action = 2;
 * @return {string}
 */
proto.litrpc.MacaroonPermission.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.litrpc.MacaroonPermission.prototype.setAction = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.AddSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.AddSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.AddSessionResponse.displayName = 'proto.litrpc.AddSessionResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.AddSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.AddSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.AddSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.litrpc.Session.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.AddSessionResponse}
 */
proto.litrpc.AddSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.AddSessionResponse;
  return proto.litrpc.AddSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.AddSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.AddSessionResponse}
 */
proto.litrpc.AddSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.Session;
      reader.readMessage(value,proto.litrpc.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.AddSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.AddSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.AddSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.AddSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.litrpc.Session.serializeBinaryToWriter
    );
  }
};


/**
 * optional Session session = 1;
 * @return {?proto.litrpc.Session}
 */
proto.litrpc.AddSessionResponse.prototype.getSession = function() {
  return /** @type{?proto.litrpc.Session} */ (
    jspb.Message.getWrapperField(this, proto.litrpc.Session, 1));
};


/** @param {?proto.litrpc.Session|undefined} value */
proto.litrpc.AddSessionResponse.prototype.setSession = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.litrpc.AddSessionResponse.prototype.clearSession = function() {
  this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.litrpc.AddSessionResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.Session = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.Session.displayName = 'proto.litrpc.Session';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.Session.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.Session.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.Session} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.Session.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expiryTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 4, "0"),
    mailboxServerAddr: jspb.Message.getFieldWithDefault(msg, 5, ""),
    devServer: jspb.Message.getFieldWithDefault(msg, 6, false),
    pairingSecret: msg.getPairingSecret_asB64(),
    pairingSecretMnemonic: jspb.Message.getFieldWithDefault(msg, 8, ""),
    localPublicKey: msg.getLocalPublicKey_asB64(),
    remotePublicKey: msg.getRemotePublicKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.Session}
 */
proto.litrpc.Session.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.Session;
  return proto.litrpc.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.Session}
 */
proto.litrpc.Session.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.litrpc.SessionState} */ (reader.readEnum());
      msg.setSessionState(value);
      break;
    case 3:
      var value = /** @type {!proto.litrpc.SessionType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setExpiryTimestampSeconds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailboxServerAddr(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDevServer(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPairingSecret(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPairingSecretMnemonic(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLocalPublicKey(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRemotePublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.Session.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getExpiryTimestampSeconds();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      4,
      f
    );
  }
  f = message.getMailboxServerAddr();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDevServer();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPairingSecret_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getPairingSecretMnemonic();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocalPublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getRemotePublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.litrpc.Session.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.litrpc.Session.prototype.setLabel = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SessionState session_state = 2;
 * @return {!proto.litrpc.SessionState}
 */
proto.litrpc.Session.prototype.getSessionState = function() {
  return /** @type {!proto.litrpc.SessionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.litrpc.SessionState} value */
proto.litrpc.Session.prototype.setSessionState = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SessionType session_type = 3;
 * @return {!proto.litrpc.SessionType}
 */
proto.litrpc.Session.prototype.getSessionType = function() {
  return /** @type {!proto.litrpc.SessionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.litrpc.SessionType} value */
proto.litrpc.Session.prototype.setSessionType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint64 expiry_timestamp_seconds = 4;
 * @return {string}
 */
proto.litrpc.Session.prototype.getExpiryTimestampSeconds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, "0"));
};


/** @param {string} value */
proto.litrpc.Session.prototype.setExpiryTimestampSeconds = function(value) {
  jspb.Message.setProto3StringIntField(this, 4, value);
};


/**
 * optional string mailbox_server_addr = 5;
 * @return {string}
 */
proto.litrpc.Session.prototype.getMailboxServerAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.litrpc.Session.prototype.setMailboxServerAddr = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool dev_server = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.litrpc.Session.prototype.getDevServer = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.litrpc.Session.prototype.setDevServer = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bytes pairing_secret = 7;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getPairingSecret = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes pairing_secret = 7;
 * This is a type-conversion wrapper around `getPairingSecret()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getPairingSecret_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPairingSecret()));
};


/**
 * optional bytes pairing_secret = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPairingSecret()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getPairingSecret_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPairingSecret()));
};


/** @param {!(string|Uint8Array)} value */
proto.litrpc.Session.prototype.setPairingSecret = function(value) {
  jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional string pairing_secret_mnemonic = 8;
 * @return {string}
 */
proto.litrpc.Session.prototype.getPairingSecretMnemonic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.litrpc.Session.prototype.setPairingSecretMnemonic = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bytes local_public_key = 9;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getLocalPublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes local_public_key = 9;
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getLocalPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLocalPublicKey()));
};


/**
 * optional bytes local_public_key = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getLocalPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLocalPublicKey()));
};


/** @param {!(string|Uint8Array)} value */
proto.litrpc.Session.prototype.setLocalPublicKey = function(value) {
  jspb.Message.setProto3BytesField(this, 9, value);
};


/**
 * optional bytes remote_public_key = 10;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.Session.prototype.getRemotePublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes remote_public_key = 10;
 * This is a type-conversion wrapper around `getRemotePublicKey()`
 * @return {string}
 */
proto.litrpc.Session.prototype.getRemotePublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRemotePublicKey()));
};


/**
 * optional bytes remote_public_key = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRemotePublicKey()`
 * @return {!Uint8Array}
 */
proto.litrpc.Session.prototype.getRemotePublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRemotePublicKey()));
};


/** @param {!(string|Uint8Array)} value */
proto.litrpc.Session.prototype.setRemotePublicKey = function(value) {
  jspb.Message.setProto3BytesField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ListSessionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.ListSessionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.ListSessionsRequest.displayName = 'proto.litrpc.ListSessionsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ListSessionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ListSessionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ListSessionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ListSessionsRequest}
 */
proto.litrpc.ListSessionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ListSessionsRequest;
  return proto.litrpc.ListSessionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ListSessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ListSessionsRequest}
 */
proto.litrpc.ListSessionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ListSessionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ListSessionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ListSessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.ListSessionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.litrpc.ListSessionsResponse.repeatedFields_, null);
};
goog.inherits(proto.litrpc.ListSessionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.ListSessionsResponse.displayName = 'proto.litrpc.ListSessionsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.litrpc.ListSessionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.ListSessionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.ListSessionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.ListSessionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.litrpc.Session.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.ListSessionsResponse}
 */
proto.litrpc.ListSessionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.ListSessionsResponse;
  return proto.litrpc.ListSessionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.ListSessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.ListSessionsResponse}
 */
proto.litrpc.ListSessionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.litrpc.Session;
      reader.readMessage(value,proto.litrpc.Session.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.ListSessionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.ListSessionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.ListSessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.ListSessionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.litrpc.Session.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Session sessions = 1;
 * @return {!Array<!proto.litrpc.Session>}
 */
proto.litrpc.ListSessionsResponse.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.litrpc.Session>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.litrpc.Session, 1));
};


/** @param {!Array<!proto.litrpc.Session>} value */
proto.litrpc.ListSessionsResponse.prototype.setSessionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.litrpc.Session=} opt_value
 * @param {number=} opt_index
 * @return {!proto.litrpc.Session}
 */
proto.litrpc.ListSessionsResponse.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.litrpc.Session, opt_index);
};


proto.litrpc.ListSessionsResponse.prototype.clearSessionsList = function() {
  this.setSessionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RevokeSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.RevokeSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.RevokeSessionRequest.displayName = 'proto.litrpc.RevokeSessionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RevokeSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RevokeSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RevokeSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    localPublicKey: msg.getLocalPublicKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RevokeSessionRequest}
 */
proto.litrpc.RevokeSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RevokeSessionRequest;
  return proto.litrpc.RevokeSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RevokeSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RevokeSessionRequest}
 */
proto.litrpc.RevokeSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLocalPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RevokeSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RevokeSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RevokeSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalPublicKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
};


/**
 * optional bytes local_public_key = 8;
 * @return {!(string|Uint8Array)}
 */
proto.litrpc.RevokeSessionRequest.prototype.getLocalPublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes local_public_key = 8;
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {string}
 */
proto.litrpc.RevokeSessionRequest.prototype.getLocalPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLocalPublicKey()));
};


/**
 * optional bytes local_public_key = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLocalPublicKey()`
 * @return {!Uint8Array}
 */
proto.litrpc.RevokeSessionRequest.prototype.getLocalPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLocalPublicKey()));
};


/** @param {!(string|Uint8Array)} value */
proto.litrpc.RevokeSessionRequest.prototype.setLocalPublicKey = function(value) {
  jspb.Message.setProto3BytesField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.litrpc.RevokeSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.litrpc.RevokeSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.litrpc.RevokeSessionResponse.displayName = 'proto.litrpc.RevokeSessionResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.litrpc.RevokeSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.litrpc.RevokeSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.litrpc.RevokeSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.litrpc.RevokeSessionResponse}
 */
proto.litrpc.RevokeSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.litrpc.RevokeSessionResponse;
  return proto.litrpc.RevokeSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.litrpc.RevokeSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.litrpc.RevokeSessionResponse}
 */
proto.litrpc.RevokeSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.litrpc.RevokeSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.litrpc.RevokeSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.litrpc.RevokeSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.litrpc.RevokeSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.litrpc.SessionType = {
  TYPE_MACAROON_READONLY: 0,
  TYPE_MACAROON_ADMIN: 1,
  TYPE_MACAROON_CUSTOM: 2,
  TYPE_UI_PASSWORD: 3
};

/**
 * @enum {number}
 */
proto.litrpc.SessionState = {
  STATE_CREATED: 0,
  STATE_IN_USE: 1,
  STATE_REVOKED: 2,
  STATE_EXPIRED: 3
};

goog.object.extend(exports, proto.litrpc);
